import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { Container } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import "../../styling/app.css";
import useTranslations from "../../../Context/actions/useTranslations";

function AllSubmissions(props) {
  const navigate = useNavigate();
  const userID = props.userID;
  const { t } = useTranslations()
  // Get User's Submission History
  const [loading, setLoading] = useState(false);
  const [userHistory, setUserHistory] = useState([]);
  const [doneGettingSubmissions, setDoneGettingSubmissions] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const getAllSubmissions = async () => {
    setLoading(true);
    setDoneGettingSubmissions(false);
    setUserHistory([]);
    setFilteredSubmissions([]);
    
    const q = query(
      collection(db, "submissions"),
      where("status", "!=", "Deleted")
    );
    const queryData = await getDocs(q);
    const queryResults = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setUserHistory(queryResults);

    if (selectedStatus !== "All") {
      const q1 = query(
        collection(db, "submissions"),
        where("status", "==", selectedStatus)
      );
      const queryData1 = await getDocs(q1);
      const queryResults1 = queryData1.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setFilteredSubmissions(queryResults1);
      setDoneGettingSubmissions(true);
      setLoading(false);
    } else {
      setDoneGettingSubmissions(true);
      setLoading(false);
    }
  };

  // Count Submissions
  const countSubmissions = () => {
    let incompleteCount = 0;
    let approvedCount = 0;
    let pendingCount = 0;
    const array = userHistory;
    array.map((submission) => {
      if (submission.status === "Incomplete") incompleteCount++;
      if (submission.status === "Approved") approvedCount++;
      if (submission.status === "Submitted") pendingCount++;
    })
    props.setIncompleteSubmissionCount(incompleteCount);
    props.setApprovedSubmissions(approvedCount);
    props.setPendingQuoteRequestCount(pendingCount);
  }

  // Delete A Submission From History
  const auth = getAuth();

  const deleteThisSubmission = async (thisID) => {
    let verify = prompt(
      "You are about to delete a Submission! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      const applicationRef = doc(db, "submissions", thisID);
      await updateDoc(applicationRef, {
        status: "Deleted",
      });
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "Business Submission (" +
          thisID +
          ") Deleted by: " +
          auth.currentUser.displayName,
      });
      getAllSubmissions(userID);
    }
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    !doneGettingSubmissions && getAllSubmissions();
    doneGettingSubmissions && countSubmissions();
  }, [db, doneGettingSubmissions]);

  return (
    <div className="card">
      <div className="card-header bg-gray-200">
        <button onClick={e => setDisplay(!display)} className="text-xl font-bold text-[#072a48]">
          {t('account.business-submissions.title')}
        </button>
      </div>
      {display && (
      <div className="card-body">
        <div>{t('account.business-submissions.showing')}: {selectedStatus} {t('account.business-submissions.submissions')}</div>
        {t('account.business-submissions.view')}:{" "}
        <button
          onClick={(e) => {
            setFilteredSubmissions([]);
            setSelectedStatus("All");
            getAllSubmissions(userID);
          }}
          className="text-[#072a48]"
        >
          {t('account.business-submissions.all')}
        </button>
        {" | "}
        <button
          onClick={(e) => {
            setFilteredSubmissions([]);
            setSelectedStatus("Submitted");
            getAllSubmissions(userID);
          }}
          className="text-[#072a48]"
        >
          {t('account.business-submissions.pending')}
        </button>
        {" | "}
        <button
          onClick={(e) => {
            setFilteredSubmissions([]);
            setSelectedStatus("Incomplete");
            getAllSubmissions(userID);
          }}
          className="text-[#072a48]"
        >
          {t('account.business-submissions.incomplete')}
        </button>
        {" | "}
        <button
          onClick={(e) => {
            setFilteredSubmissions([]);
            setSelectedStatus("Approved");
            getAllSubmissions(userID);
          }}
          className="text-[#072a48]"
        >
          {t('account.business-submissions.approved')}
        </button>
        {!loading && selectedStatus === "All" ? (
          <>
            <div className="">
              <Table className="data-table my-3">
                <Thead>
                  <Tr>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.status')}</Td>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.named-insured')}</Td>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.state')}</Td>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.agent')}</Td>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.effective')}</Td>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.actions')}</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {userHistory.map((myData, i) => (
                    <Tr key={myData.id}>
                      <Td>{myData.status}</Td>
                      <Td>{myData.namedInsured}</Td>
                      <Td>{myData.state}</Td>
                      <Td>{myData.author}</Td>
                      <Td>{convertDate(myData.effectiveDate.seconds)}</Td>
                      <Td>
                        <button
                          title="View/Edit"
                          onClick={(e) =>
                            navigate(
                              "../submit-business/" +
                                myData.authorID +
                                "/" +
                                myData.id
                            )
                          }
                          className="text-primary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </button>
                        <button
                          title="Delete"
                          onClick={(e) => deleteThisSubmission(myData.id)}
                          className="text-[#8B0000] d-inline mx-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-x-octagon-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                          </svg>
                        </button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </>
        ) : (
          <></>
        )}
        {!loading && selectedStatus !== "All" ? (
          <>
            <div className="data-table-container-2 shadow-md mt-3">
              <Table className="data-table my-3">
                <Thead>
                  <Tr className="data-table-header">
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.status')}</Td>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.named-insured')}</Td>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.state')}</Td>\
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.effective')}</Td>
                    <Td className="bg-[#072a48] text-white">{t('account.business-submissions.actions')}</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredSubmissions.map((myData, i) => (
                    <Tr key={myData.id}>
                      <Td>{myData.status}</Td>
                      <Td>{myData.namedInsured}</Td>
                      <Td>{myData.state}</Td>
                      <Td>{convertDate(myData.effectiveDate.seconds)}</Td>
                      <Td>
                        <button
                          title="View/Edit"
                          onClick={(e) =>
                            navigate(
                              "../submit-business/" +
                                myData.authorID +
                                "/" +
                                myData.id
                            )
                          }
                          className="text-primary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </button>
                        <button
                          title="Delete"
                          onClick={(e) => deleteThisSubmission(myData.id)}
                          className="text-[#8B0000] d-inline mx-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-x-octagon-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                          </svg>
                        </button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      )}
      {loading && <>{t('account.business-submissions.loading')}</>}
    </div>
  );
}

export default AllSubmissions;
