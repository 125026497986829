import React, { useState } from "react";
import CreateApplicationPanel from "../../Components/agent/applications/CreateApplicationPanel";
import ApplicationList from "../../Components/agent/applications/ApplicationList";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import Graphs from "../../Components/agent/applications/Graphs";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";

export default function ManageApplications(props) {
  const {
    userInfo,
    loading,
    setLoading
  } = props
  const [showCreateApplicationPanel, setShowCreateApplicationPanel] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const { t } = useTranslations()

  const breadcrumbs = [
    { label: "Home", link: ACCOUNT_ROUTE },
    { label: t('breadcrumbs.manage-applications') },
  ];

  return (
    <div className="my-4 max-w-[1000px] mx-auto bg-white rounded-md shadow-md p-6">
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          {t('manage-applications.title')}
        </span>
        {!showCreateApplicationPanel && (
          <button
            type="button"
            className="md:ml-auto md:mt-2 sm:mt-2 bg-[#072a48] hover:bg-[#072a48] text-white py-2 px-4 mb-4 rounded"
            onClick={() => setShowCreateApplicationPanel(true)}
          >
            {t('manage-applications.add-application')}
          </button>
        )}
      </div>
      <p className="mb-4">
      {t('manage-applications.view-info')}
      </p>
      {!showCreateApplicationPanel && (
        // List of Applications
        <ApplicationList
          applicationList={applicationList}
          setApplicationList={setApplicationList}
          userInfo={userInfo}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {!showCreateApplicationPanel && (
        // Graphs for Insights From List of Applications
        <div className="mt-4">
          {/* <Graphs applicationList={applicationList} /> */}
        </div>
      )}
      {showCreateApplicationPanel && (
        // Panel to Create a New Application
        <CreateApplicationPanel
          setShowCreateApplicationPanel={setShowCreateApplicationPanel}
          userInfo={userInfo}
        />
      )}
    </div>
  );
}
