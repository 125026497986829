import useTranslations from "../../../../Context/actions/useTranslations";
import MultiUpload from "../../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import calculateAge from "../../../utils/calculateAge";

const LicenseUploadForm = ({
  licenseNumber,
  dob,
  type,
  tutor,
  fileObjectsLicenseFront,
  setFileObjectsLicenseFront,
  fileObjectsLicenseBack,
  setFileObjectsLicenseBack,
  fileObjectsMvr,
  setFileObjectsMvr,
  fileObjectsCaDriverAbstract,
  setFileObjectsCaDriverAbstract,
  fileObjectsMedicalForm,
  setFileObjectsMedicalForm,
  fileObjectsAdditionalForm,
  setFileObjectsAdditionalForm,
  fileObjectsOtherDocuments,
  setFileObjectsOtherDocuments,
  isViewMode = false,
}) => {

  const { t } = useTranslations()

  return (
    <>
      {(isViewMode && type === "CA" && fileObjectsCaDriverAbstract.length > 0) ||
      (!isViewMode && type === "CA")
      ? (
        <div className='mb-2'>
          <MultiUpload
            label={t("plm.drivers.details.ca-driver-abstract")}
            property="caDriverAbstract"
            formData={fileObjectsCaDriverAbstract}
            setFormData={setFileObjectsCaDriverAbstract}
            storageFile={`files/drivers/${licenseNumber}`}
            multiple={false}
            changeNormal={true}
            fileInButton={true}
            width="100%"
            dissapearWhenImgExists={true}
            showInAPopup={isViewMode ? false : true}
            disabled={isViewMode}
          />
        </div>
      ) : null}
      {(isViewMode && fileObjectsLicenseFront.length > 0) ||
      !isViewMode ?
        <div className='mb-2'>
          <MultiUpload
            label={t("plm.drivers.details.front-license")}
            property="driverLicenseFront"
            formData={fileObjectsLicenseFront}
            setFormData={setFileObjectsLicenseFront}
            storageFile={`files/drivers/${licenseNumber}`}
            multiple={false}
            changeNormal={true}
            fileInButton={isViewMode}
            width="100%"
            dissapearWhenImgExists={true}
            showInAPopup={isViewMode ? false : true}
            disabled={isViewMode}
            isViewMode={isViewMode}
          />
        </div> : null
      }
      {(isViewMode && fileObjectsLicenseBack.length > 0) ||
      !isViewMode ?
        <div className='mb-2'>
          <MultiUpload
            label={t("plm.drivers.details.back-license")}
            property="driverLicenseBack"
            formData={fileObjectsLicenseBack}
            setFormData={setFileObjectsLicenseBack}
            storageFile={`files/drivers/${licenseNumber}`}
            multiple={false}
            changeNormal={true}
            fileInButton={true}
            width="100%"
            dissapearWhenImgExists={true}
            showInAPopup={isViewMode ? false : true}
            disabled={isViewMode}
            isViewMode={isViewMode}
          />
        </div> : null
      }
      {(isViewMode && type === "USA" && fileObjectsMvr.length > 0) ||
      (!isViewMode && type === "USA")
      ? (
        <div className='mb-2'>
          <MultiUpload
            label={t("plm.drivers.details.mvr")}
            property="MVR"
            formData={fileObjectsMvr}
            setFormData={setFileObjectsMvr}
            storageFile={`files/drivers/${licenseNumber}`}
            multiple={false}
            changeNormal={true}
            fileInButton={true}
            width="100%"
            dissapearWhenImgExists={true}
            showInAPopup={isViewMode ? false : true}
            disabled={isViewMode}
            isViewMode={isViewMode}
          />
        </div>
      ) : null}
      {(isViewMode && calculateAge(dob) > 64 && fileObjectsMedicalForm.length > 0) ||
      (!isViewMode && calculateAge(dob) > 64)
      ? (
        <div className='mb-2'>
          <MultiUpload
            label={t("plm.drivers.details.medical-long-form")}
            property="medicalLongForm"
            formData={fileObjectsMedicalForm}
            setFormData={setFileObjectsMedicalForm}
            storageFile={`files/drivers/${licenseNumber}`}
            multiple={false}
            changeNormal={true}
            fileInButton={true}
            width="100%"
            dissapearWhenImgExists={true}
            showInAPopup={isViewMode ? false : true}
            disabled={isViewMode}
            isViewMode={isViewMode}
          />
        </div>
      ) : null}
      {(isViewMode && tutor && fileObjectsAdditionalForm.length > 0) ||
      (!isViewMode && tutor)
      ? (
        <div className='mb-2'>
          <MultiUpload
            label={t("plm.drivers.details.driver-additional-documents")}
            property="additionalDocument"
            formData={fileObjectsAdditionalForm}
            setFormData={setFileObjectsAdditionalForm}
            storageFile={`files/drivers/${licenseNumber}/additional-docs`}
            multiple={true}
            changeNormal={true}
            fileInButton={true}
            width="100%"
            dissapearWhenImgExists={true}
            showInAPopup={isViewMode ? false : true}
            disabled={isViewMode}
            isViewMode={isViewMode}
          />
        </div>
      ) : null}
      {(isViewMode && fileObjectsOtherDocuments.length > 0) ||
      !isViewMode ?
        <div className='mb-2'>
          <MultiUpload
            label={t("plm.drivers.details.other-documents")}
            property="otherDocuments"
            formData={fileObjectsOtherDocuments}
            setFormData={setFileObjectsOtherDocuments}
            storageFile={`files/drivers/${licenseNumber}/other-docs`}
            multiple={true}
            changeNormal={true}
            fileInButton={true}
            width="100%"
            dissapearWhenImgExists={true}
            showInAPopup={isViewMode ? false : true}
            disabled={isViewMode}
            isViewMode={isViewMode}
          />
        </div> : null
      }
    </>
  );
};

export default LicenseUploadForm;
