import React, { useState } from "react";
import {
  Button,
  Modal,
  TextField,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import returnToAgent from "../../utils/returnToAgent";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import requestBind from "../../utils/requestBind";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import sendQuote from "../../utils/sendQuote";

const SendQuoteModal = ({
  open,
  onClose,
  policyID,
  formData,
  insuredInfo
}) => {

  const { user: userAuth } = UserAuth()
  const { user: userState } = useUsersState()

  const handleSubmit = async (e) => {
    await sendQuote(e, formData, policyID, userAuth, userState, insuredInfo)
    onClose();
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  
  const breadcrumbs = [
    { label: "Policy" },
    { label: "Quote Request" },
    { label: "Send Quote" }
  ];

  return (
    <Modal open={open} onClose={null} disableBackdropClick={true}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100vh",
            overflowY: "auto",
            width: isMobileScreen ? "100%" : 400,
            height: isMobileScreen ? "100%" : "auto",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
        <Breadcrumbs items={breadcrumbs} />
          <Typography className="mt-2" variant="h6" gutterBottom>
            Send Quote
          </Typography>
          <p className="my-3">Confirm the fulfillment of your quote.</p>
          <ApprovalButtons
            onApprove={null}
            onDecline={onClose}
            titleAccept={"Submit"}
            titleDecline={"Cancel"}
            disabledAccept={false}
            disabledDecline={false}
            handleAsSubmit={true}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default SendQuoteModal;
