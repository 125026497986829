import React, { useState } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import assignFrequency from "../utils/assignFrequency";
import assignSeverity from "../utils/assignSeverity";
import assignCombined from "../utils/assignCombined";
import { Tooltip } from "@mui/material";
import getAge from "../../utils/getAge";
import useTranslations from "../../../Context/actions/useTranslations";

export default function MiniFitnessTable({
  aggregatedDriverInfo,
  frequencyMatrix,
  severityMatrix,
  combinedMatrix,
  dataReady,
}) {
  const [show, setShow] = useState(false);
  const { t } = useTranslations();
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Td className="bg-[#072a48] text-center text-white rounded-tl-md cursor-pointer hover:bg-[#072a48]">
              {t('plm.drivers.details.result')}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              {t('plm.drivers.details.driver-score')}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              {t('plm.drivers.details.risk-factor-frequency')}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              {t('plm.drivers.details.risk-factor-severity')}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48] rounded-tr-md">
              {t('plm.drivers.details.risk-factor-combined')}
            </Td>
          </Tr>
        </Thead>
        <Tbody>
          {aggregatedDriverInfo.map((driver, i) => (
            <Tr key={driver.licenseNumber}>
              <Td className="text-center">{driver?.result}</Td>
              <Td className="text-center">{driver?.scoreTotal.toFixed(4)}</Td>
              <Td className="text-center" title={driver.claimsCount}>
                {dataReady &&
                  frequencyMatrix &&
                  frequencyMatrix.length !== 0 &&
                  assignFrequency(driver, dataReady, frequencyMatrix)}
              </Td>
              <Td className="text-center">
                {dataReady &&
                  severityMatrix &&
                  severityMatrix.length !== 0 &&
                  assignSeverity(driver, dataReady, severityMatrix)}
              </Td>
              <Td className="text-center">
                {dataReady &&
                  combinedMatrix &&
                  combinedMatrix.length !== 0 &&
                  assignCombined(driver, dataReady, combinedMatrix)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
