import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { t } from "i18next";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import History from ".";

export default function MiniHistory(props) {
  const numberOfEntriesToShow = props.formData.documentType === 'Policy' ? 8 : 5;
  const [gotHistory, setGotHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const getHistory = async () => {
    const q = query(
      collection(db, "system-activity"),
      where("policyID", "==", props.policyID)
    );
    const querySnapshot = await getDocs(q);
    let values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    setHistory(values);
    setGotHistory(true);
  };

  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const handleOpenHistoryModal = () => {
    setOpenHistoryModal(true);
  };

  const handleCloseHistoryModal = () => {
    setOpenHistoryModal(false);
  };
  useEffect(() => {
    if (!props.showOverviewAndHistory) {
      return () => {};
    }
    !gotHistory && getHistory();
    return () => {};
  }, [gotHistory, props.showOverviewAndHistory]);

  return (
    <section className="card h-100">
      <div className="card-header text-lg text-white bg-gradient-to-r from-[#0c4a6e] to-[#096384]">
        <button
          onClick={(e) =>
            props.setShowOverviewAndHistory(!props.showOverviewAndHistory)
          }
        >
          {t("History")}

          <ExpandCollapseArrow
            show={props.showOverviewAndHistory}
            setShow={props.setShowOverviewAndHistory}
          />
        </button>
        <button
          onClick={(e) => handleOpenHistoryModal()}
          className="float-right text-sky-900 hover:text-[#072a48] text-white"
        >
          {t("View All")}
        </button>
      </div>
      {props.showOverviewAndHistory && (
        <div className="card-body bg-gradient-to-r from-slate-50 to-stone-200">
          <div>
            <ul>
              {[...history].slice(0, numberOfEntriesToShow).map((entry) => {
                return (
                  <li key={entry.id} title={entry.description}>
                    {t(entry.shortDescription)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      <History
        open={openHistoryModal}
        onClose={handleCloseHistoryModal}
        policyID={props.policyID}
      />
    </section>
  );
}
