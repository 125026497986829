import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import handleBlurCommodity from "./utils/handleBlurCommodity";

export default function CommodityValueInput({
  label,
  property,
  formData,
  policyID,
  defaultValue,
  min,
  disabled,
}) {
  const handleInputChange = (event) => {
    let value = Math.max(min, Number(event.target.value));
    const updatedValues = { ...formData.standardCommodityValues, [property]: value };

    updateDoc(doc(db, "policy-applications", policyID), {
      standardCommodityValues: updatedValues,
    });
  };


  return (
    <>
      {formData?.standardCommodityPercentages?.[property] > 0 && (
        <div className="flex flex-col space-y-2">
          <label className="text-xs font-semibold">{label} Value:</label>
          <input
            name={label}
            type="number"
            className="p-2 border rounded focus:outline-none focus:border-blue-500"
            value={formData?.standardCommodityValues?.[property] || defaultValue}
            onChange={handleInputChange}
            min={min}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
}
