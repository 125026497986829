import React from "react";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../../../firebase";
import deleteFileFromStorage from "../../../utils/deleteFromStorage";
import { t } from "i18next";
import updatePolicyField from "../../../utils/updatePolicyField";

const FileDisplayAndReplacement = ({
  year,
  policyID,
  formData,
  setFormData,
  setProgressState,
  coverageType
}) => {

  return (
    <div>
      {t("Loss Runs Uploaded")}:{" "}
      <a
        href={formData[`year${year}${coverageType}LossRunsFile`]}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sky-900 hover:text-[#072a48]"
      >
        {t("View")}
      </a>{" "}
      {formData.documentType === "Application" &&
        !formData.lossHistory?.[`year${year}LossData`] && (
          <>
            {" | "}
            <button
              className="text-[#8B0000]"
              onClick={(e) => {
                let verify = prompt(
                  "You are about to delete loss runs! If you are sure, type DELETE below: "
                );
                if (verify === "DELETE") {
                  updatePolicyField(policyID, `year${year}LossRunsFile`, "");
                  deleteFileFromStorage(formData[`year${year}LossRunsFile`]);
                  setProgressState(0);
                }
              }}
            >
              {t("Delete")}
            </button>
          </>
        )}
    </div>
  );
};

export default FileDisplayAndReplacement;
