import Modal from "react-modal";
import { t } from "i18next";
import decodeVIN from "../../utils/validation/validateTruck/decodeVIN";
import useGeneralState from "../../../Context/actions/useGeneralState";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");



export default function TruckModal({
    truck,
    isOpen,
    onAfterOpen,
    onRequestClose,
    contentLabel,
    formData,
    policyID
}) {

  const { controlToast } = useGeneralState();

  const policyDetailToShow = truck?.policiesDetails?.find(
    (policy) => policy?.id === policyID
  )

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={contentLabel}
    >
      <button onClick={onRequestClose}>X</button>
      <section>
        {t("Power Unit Information:")}
        <table>
          <tbody>
            <tr>
              <td>VIN</td>
              <td>
                <div className="flex items-center">
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        truck?.truckVIN
                      );
                      controlToast(true, "The VIN was copied to clipboard", "success");
                    }}
                    className="text-sky-900 hover:text-[#072a48] mr-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-copy d-inline"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                      />
                    </svg>
                  </button>
                  {truck?.truckVIN}
                </div>
              </td>
            </tr>
            {formData?.hasALCoverage && (
              <tr>
                <td>{t("Rating")}:</td>
                <td>{policyDetailToShow?.rating}</td>
              </tr>
            )}
            <tr>
              <td>{t("Year")}:</td>
              <td>{truck?.truckYear}</td>
            </tr>
            <tr>
              <td>{t("Make")}:</td>
              <td>{truck?.truckMake}</td>
            </tr>
            <tr>
              <td>{t("Model")}:</td>
              <td>{truck?.truckModel}</td>
            </tr>
            <tr>
              <td>{t("Actual Cash Value")}:</td>
              <td>{policyDetailToShow?.truckACV}</td>
            </tr>
            <tr>
              <td>{t("Radius")}:</td>
              <td>{policyDetailToShow?.truckRadius}</td>
            </tr>
            <tr>
              <td>{t("Class")}:</td>
              <td>{t(truck?.truckClass)}</td>
            </tr>
            <tr>
              <td>{t("Weight")}:</td>
              <td>{truck?.truckWeight}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </Modal>
  );
}
