import React from "react";
import NewApplication from "../../PolicyLifecycleManager/CreateNewApp";
import useTranslations from "../../../Context/actions/useTranslations";

const CreateApplicationPanel = ({
  userInfo,
  setShowCreateApplicationPanel
}) => {
  const { t } = useTranslations()

  return (
    <div className="container mx-auto my-8">
      <div className="max-w-xl mx-auto">
        <div className="bg-white shadow-md rounded-md overflow-hidden">
          <div className="bg-[#072a48] text-white p-3 font-md text-lg">{t('manage-applications.create-new-application')}</div>
          <div className="pt-2 pb-4  bg-gradient-to-r from-slate-200 to-slate-400">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col space-y-2">
                <NewApplication
                agencyID={userInfo.agencyID}
                  setShowCreateApplicationPanel={setShowCreateApplicationPanel}
                  userInfo={userInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateApplicationPanel;
