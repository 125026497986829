import useTranslations from "../../../Context/actions/useTranslations";
import usePreviousRoute from "../../../hooks/history/usePreviousRoute";
import { useNavigate } from "react-router-dom";

const GoBack = ({
  throughHistory,
  defaultRoute,
  handleFn,
  label,
  disabled = false,
  className,
}) => {
  const previousRoute = usePreviousRoute();
  const { t } = useTranslations();
  const navigate = useNavigate();

  const handleNavigation = (e) => {
    if (handleFn) {
      handleFn(e);
    }
    if (throughHistory) {
      navigate(defaultRoute ? defaultRoute : previousRoute);
    }
  };

  return (
    <button
      disabled={disabled}
      onClick={handleNavigation}
      className={
        `${className ? className : ''} text-[#8B0000] hover:text-[#8B0000] mb-1`
      }
    >
      {label ? label : t("plm.drivers.cancel")}
    </button>
  );
};

export default GoBack;
