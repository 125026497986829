import Modal from "react-modal";

const customStyles = (maxWidth, overflow, zIndex) => {
  return {
    content: {
      top: "10px",
      left: "10px",
      right: "10px",
      bottom: "10px",
      maxWidth: maxWidth ? maxWidth : '426px',
      margin: 'auto',
      height: 'min-content',
      maxHeight: '100vh',
      zIndex: zIndex ? zIndex : 1000,
      overflow: overflow ? overflow : 'auto'
    }
  }
};

Modal.setAppElement("#root");

export default function Popup({
    isOpen,
    onRequestClose,
    children,
    contentLabel = '',
    maxWidth,
    overflow,
    zIndex
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles(maxWidth, overflow, zIndex)}
      contentLabel={contentLabel}
    >
      <button onClick={onRequestClose}>X</button>
      <section>
        {children}
      </section>
    </Modal>
  );
}
