import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const getPolicyData = async (policyID, formData, isEndorsement, adjustmentPercentage) => {
    let adjustmentPercent;
    let policyData = {};
    if (!isEndorsement) {
      policyData = formData;
      adjustmentPercent = adjustmentPercentage;
    } else {
      const docSnap = await getDoc(doc(db, "policy-applications", policyID));
      policyData = docSnap.data();
      adjustmentPercent = docSnap.data().alPremiums.adjustment;
    }
    return [policyData, adjustmentPercent]
  };

  export default getPolicyData;