import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

const handleBlurCommodity = async (e, policyID, formData) => {
    // e.preventDefault();
    const policyRef = doc(db, "policy-applications", policyID);
    await updateDoc(policyRef, {
      commodityFields: formData.commodityFields,
      standardCommodityPercentages: formData.standardCommodityPercentages,
    });
  };

  export default handleBlurCommodity;