import BlueButton from "../Components/assets/forms/BlueButton";
import {
    Engineering as EngineeringIcon,
    ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import {
  NEW_SUPPORT_TICKET_ROUTE
} from "../utils/routes";
import { useNavigate } from "react-router-dom";

const Maintenance = () => {
  const navigate = useNavigate()

  const handleNavigate = (e) => {
    e.preventDefault();
    navigate(NEW_SUPPORT_TICKET_ROUTE);
  }

    return (
        <div className="flex w-full justify-center items-center h-[75vh] flex-col">
            <EngineeringIcon className="text-[#072a48]" sx={{ width: 100, height: 100, margin: '0 0 8px' }}  />
            <p className="mb-4" style={{ fontSize: 24 }}>Maintenance</p>
            <p className="mb-4 text-center">
                The Southern Star Portal is down for maintenance.<br/>
                If you need help immediately, please submit a support ticket.
            </p>
            <BlueButton
                onClickFunction={handleNavigate}
                icon={<ArrowBackIcon />}
                text="Submit support ticket"
            />
        </div>
    )
  
};

export default Maintenance;
