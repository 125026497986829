async function countAllViolations(violations, givenLicenseNumber) {
    let counts = {
      claimsCount: 0,
      outOfDrivingCount: 0,
      notIncludedCount: 0,
      majorViolationsCount: 0,
      minorViolationsCount: 0,
      caseByCaseCount: 0,
      claimsAmount: 0
    };
  
    for (const violation of violations) {
      if (violation?.LicenseNumber && violation.LicenseNumber === givenLicenseNumber) {
        if (violation.violationCode === "Crash") {
          counts.claimsCount++;
          counts.claimsAmount += violation.claimAmount
        }
        if (violation.finalClassification === "Not Driving Risk") {
          counts.outOfDrivingCount++;
        }
        if (violation.notIncluded === "Not included") {
          counts.notIncludedCount++;
        }
        if (violation.finalClassification === "Major") {
          counts.majorViolationsCount++;
        }
        if (violation.finalClassification === "Minor") {
          counts.minorViolationsCount++;
        }
        if (violation.special === "Case by Case") {
          counts.caseByCaseCount++;
        }
      }
    }
  
    return counts;
  }

  export default countAllViolations;