export const formatDate = (date) => {
  if (!date || date === '-') return "-";
  const d = new Date(date);
  const month = ('0' + (d.getUTCMonth() + 1)).slice(-2);
  const day = ('0' + d.getUTCDate()).slice(-2);
  const year = d.getUTCFullYear();
  return `${month}/${day}/${year}`;
};

export const calculateLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const humanDate = (date) => {
  if (date) {
    return date
  }
  return "-";
};

export const calculateDaysDifference = (policyEffectiveDate, endoEffectiveDate) => {
  if (!policyEffectiveDate || !endoEffectiveDate) {
    return "-";
  }
  const policyDate = policyEffectiveDate instanceof Date 
    ? policyEffectiveDate 
    : policyEffectiveDate.toDate();
  const endoDate = endoEffectiveDate instanceof Date 
    ? endoEffectiveDate 
    : endoEffectiveDate.toDate();
  if (policyDate.getTime() === endoDate.getTime()) {
    const policyYear = policyDate.getFullYear();
    if (calculateLeapYear(policyYear)) {
      return "366";
    } else {
      return "365";
    }
  }
  const differenceInMilliseconds = Math.abs(policyDate - endoDate);
  const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  return differenceInDays;
};

export const isLeapYear = (policyEffectiveDate) => {
  if (policyEffectiveDate instanceof Date) {
    const policyYear = policyEffectiveDate.getFullYear();
    return calculateLeapYear(policyYear);
  } else if (policyEffectiveDate && typeof policyEffectiveDate.toDate === 'function') {
    const policyYear = policyEffectiveDate.toDate().getFullYear();
    return calculateLeapYear(policyYear);
  }
  return false;
};

export const calculateExpirationDate = (effectiveDate, daysToAdd = 365) => {
  if (!effectiveDate) return "-";
  const seconds = effectiveDate.seconds || 0;
  const nanoseconds = effectiveDate.nanoseconds || 0;
  const milliseconds = seconds * 1000 + nanoseconds / 1000000;
  const expirationDate = new Date(milliseconds);
  expirationDate.setDate(expirationDate.getDate() + daysToAdd);
  return humanDate(expirationDate);
};

export const formatPrice = (number) => {
  if (number === undefined || number === null || isNaN(number)) {
    return "";
  }
  const roundedNumber = parseFloat(number).toFixed(2);
  const priceWithCommas = roundedNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${priceWithCommas}`;
};

export const itemVehicleCount = (item) => `${item?.bindData?.powerUnitCountAtBind ? item?.bindData?.powerUnitCountAtBind : item?.bindData?.powerUnitCount ? item?.bindData?.powerUnitCount : "-"}`
export const itemProgram = (item) => `${item?.program || "-"}`
export const itemInsuredName = (item) => `${item?.namedInsured || "-"}`
export const itemPolicyNo = (item) => `${item?.takeGL ? item?.glPolicyNo : item?.takeAL ? item?.alPolicyNo : "-"}`
export const itemType = (item) => `${item?.takeGL ? "GL" : item?.takeAL ? "AL" : "-"}`
export const itemCompany = (item) => `${item?.takeGL || item?.takeAL ? "Accredited" : "-"}`
export const itemState = (item) => `${item?.stateOfEntry || "-"}`
export const itemEffectiveDate = (item) => `${item?.effectiveDate ? item?.effectiveDate?.toDate() : "-"}`
export const itemExpirationDate = (item) => `${item?.expirationDate ? item?.expirationDate?.toDate() : "-"}`
export const itemBindDate = (item) => `${item?.bindData?.bindDate ? item?.bindData?.bindDate.toDate() : "-"}`
export const itemPremium = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.GWP) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.GWP) : "-"}`
export const itemProcessingFee = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.processingFeeAmount) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.processingFeeAmount) : "-"}`
export const itemOtherFees = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.otherFees) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.otherFees) : "-"}`
export const itemSurplusLinesPlusTaxes = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : "-"}`
export const itemStampFee = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.stampTaxAmount) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.stampTaxAmount) : "-"}`
export const itemTotalPremium = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.totalPremium) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.totalPremium) : "-"}`
export const itemCoverageLimit = (item) => `${item?.coverageSingleLimit || "-"}`
export const itemZipCode = (item) => `${item?.insuredInfo?.zip || "-"}`

export const titleItemVehicleCount = "Vehicle Count"
export const titleItemProgram = "Program"
export const titleItemInsuredName = "Insured Name"
export const titleItemPolicyNo = "Policy No"
export const titleItemType = "Type"
export const titleItemCompany = "Company"
export const titleItemState = "State"
export const titleItemEffectiveDate = "Effective Date" 
export const titleItemExpirationDate = "Expiration Date"
export const titleItemBindDate = "Issue Date"
export const titleItemPremium = "Premium"
export const titleItemProcessingFee = "Processing Fee"
export const titleItemOtherFees = "Other Fees"
export const titleItemSurplusLinesPlusTaxes = "Surplus Lines Taxes"
export const titleItemStampFee = "Stamp Fee"
export const titleItemTotalPremium = "Total Premium"
export const titleItemCoverageLimit = "Coverage Limit"
export const titleItemZipCode = "Zip Code"

export const handleCopyToClipboardPolicy = (item, controlToast) => {
  const textToCopy = `
        ${titleItemVehicleCount}: ${itemVehicleCount(item)};
        ${titleItemProgram}: ${itemProgram(item)};
        ${titleItemInsuredName}: ${itemInsuredName(item)};
        ${titleItemPolicyNo}: ${itemPolicyNo(item)};
        ${titleItemType}: ${itemType(item)};
        ${titleItemCompany}: ${itemCompany(item)};
        ${titleItemState}: ${itemState(item)};
        ${titleItemEffectiveDate}: ${itemEffectiveDate(item)};
        ${titleItemExpirationDate}: ${itemExpirationDate(item)};
        ${titleItemBindDate}: ${itemBindDate(item)};
        ${titleItemPremium}: ${itemPremium(item)};
        ${titleItemProcessingFee}: ${itemProcessingFee(item)};
        ${titleItemOtherFees}: ${itemOtherFees(item)};
        ${titleItemSurplusLinesPlusTaxes}: ${itemSurplusLinesPlusTaxes(item)};
        ${titleItemStampFee}: ${itemStampFee(item)};
        ${titleItemTotalPremium}: ${itemTotalPremium(item)};
        ${titleItemCoverageLimit}: ${itemCoverageLimit(item)};
        ${titleItemZipCode}: ${itemZipCode(item)};
      `;
      const textToSearch = `
        ${itemVehicleCount(item)}
        ${itemProgram(item)}
        ${itemInsuredName(item)}
        ${itemPolicyNo(item)}
        ${itemType(item)}
        ${itemCompany(item)}
        ${itemState(item)}
        ${itemEffectiveDate(item)}
        ${itemExpirationDate(item)}
        ${itemBindDate(item)}
        ${itemPremium(item)}
        ${itemProcessingFee(item)}
        ${itemOtherFees(item)}
        ${itemSurplusLinesPlusTaxes(item)}
        ${itemStampFee(item)}
        ${itemTotalPremium(item)}
        ${itemCoverageLimit(item)}
        ${itemZipCode(item)}
      `;
  if (controlToast) {
    navigator.clipboard.writeText(textToCopy);
    controlToast(true, "The policy data was copied to clipboard", "success");
  }
  return textToSearch;
};

export const itemEndoNumber = (item) => `${item?.endorsementNumber || "-"}`
export const itemEndoProgram = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.program || "-"}`
}
export const itemEndoEffectiveDate = (item) => `${item?.endoEffectiveDate ? humanDate(item?.endoEffectiveDate?.toDate()) : "-"}`
export const itemEndoTypeAddDelete = (item) => `${item?.type || "-"}`
export const itemEndoRadius = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.truckRadius || item?.data?.trailerRadius || "-"}`
}
export const itemEndoYear = (item) => {
  return `${item?.data?.truckYear || item?.data?.trailerYear || "-"}`
}
export const itemEndoMake = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.truckMake || item?.data?.trailerMake || "-"}`
}
export const itemEndoVIN = (item) => {
  return `${item?.data?.truckVIN || item?.data?.trailerVIN || "-"}`
}
export const item355366Days = (item) => `${isLeapYear(item?.endoEffectiveDate) ? "366" : "365"}`
export const itemEndoState = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.stateOfEntry || "-"}`
}
export const itemEndoALNoPolicy = (item) => `${item?.alPolicyNo || "-"}`
export const itemEndoALExp = (item) => `${calculateExpirationDate(item.policyEffectiveDate, isLeapYear(item?.policyEffectiveDate))}`
export const itemEndoDaysAL = (item) => `${calculateDaysDifference(item?.policyEffectiveDate, item?.endoEffectiveDate)}`
export const itemEndoALPremium = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.alPremiums ? formatPrice(policyDetail?.alPremiums?.GWP) : "-"}`
}
export const itemEndoGLPremium = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.glPremiums ? formatPrice(policyDetail?.glPremiums?.GWP) : "-"}`
}
export const itemEndoProcessingFee = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.alPremiums ? formatPrice(policyDetail?.alPremiums?.processingFeeAmount) : "-"}`
}
export const itemEndoOtherFees = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.alPremiums ? formatPrice(policyDetail?.alPremiums?.otherFees) : "-"}`
}
export const itemEndoSurplusLinesPlusTaxes = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.alPremiums ? formatPrice(policyDetail?.alPremiums?.surplusLinesTaxAmount) : "-"}`
}
export const itemEndoStampFee = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.alPremiums ? formatPrice(policyDetail?.alPremiums?.stampTaxAmount) : "-"}`
}
export const itemEndoCoverageLimit = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.coverageSingleLimit || "-"}`
}

export const titleItemEndoNumber = "ID"
export const titleItemEndoEffectiveDate = "Effective Date"
export const titleItemEndoTypeAddDelete = "Endorsement Type (Add/Delete)"
export const titleItemEndoRadius = "Radius"
export const titleItemEndoYear = "Year"
export const titleItemEndoMake = "Make"
export const titleItemEndoVIN = "VIN"
export const titleItem355366Days = "365/366 Days"
export const titleItemEndoState = "State"
export const titleItemEndoALNoPolicy = "AL Policy"
export const titleItemEndoALExp = "AL Exp."
export const titleItemEndoDaysAL = "Days AL"
export const titleItemEndoALPremium = "AL Premium"
export const titleItemEndoProcessingFee = "Processing Fee"
export const titleItemEndoOtherFees = "Other Fees"
export const titleItemEndoSurplusLinesPlusTaxes = "Surplus Lines Taxes"
export const titleItemEndoStampFee = "Stamp Fee"
export const titleItemEndoCoverageLimit = "Coverage Limit"

export const handleCopyToClipboardEndo = (item, controlToast) => {
  const textToCopy = `
      ${titleItemEndoNumber}: ${itemEndoNumber(item)};
      ${titleItemProgram}: ${itemEndoProgram(item)};
      ${titleItemInsuredName}: ${itemInsuredName(item)};
      ${titleItemEndoEffectiveDate}: ${itemEndoEffectiveDate(item)};
      ${titleItemEndoTypeAddDelete}: ${itemEndoTypeAddDelete(item)};
      ${titleItemEndoYear}: ${itemEndoYear(item)};
      ${titleItemEndoRadius}: ${itemEndoRadius(item)};
      ${titleItemEndoMake}: ${itemEndoMake(item)};
      ${titleItemEndoVIN}: ${itemEndoVIN(item)};
      ${titleItem355366Days}: ${item355366Days(item)};
      ${titleItemEndoState}: ${itemEndoState(item)};
      ${titleItemEndoALNoPolicy}: ${itemEndoALNoPolicy(item)};
      ${titleItemEndoALExp}: ${itemEndoALExp(item)};
      ${titleItemEndoDaysAL}: ${itemEndoDaysAL(item)};
      ${titleItemEndoALPremium}: ${itemEndoALPremium(item)};
      ${titleItemEndoProcessingFee}: ${itemEndoProcessingFee(item)};
      ${titleItemEndoOtherFees}: ${itemEndoOtherFees(item)};
      ${titleItemEndoSurplusLinesPlusTaxes}: ${itemEndoSurplusLinesPlusTaxes(item)};
      ${titleItemEndoStampFee}: ${itemEndoStampFee(item)};
      ${titleItemEndoCoverageLimit}: ${itemEndoCoverageLimit(item)};
    `;
    const textToSearch = `
      ${itemEndoNumber(item)}
      ${itemEndoProgram(item)}
      ${itemInsuredName(item)}
      ${itemEndoEffectiveDate(item)}
      ${itemEndoTypeAddDelete(item)}
      ${itemEndoYear(item)}
      ${itemEndoRadius(item)}
      ${itemEndoMake(item)}
      ${itemEndoVIN(item)}
      ${item355366Days(item)}
      ${itemEndoState(item)}
      ${itemEndoALNoPolicy(item)}
      ${itemEndoALExp(item)}
      ${itemEndoDaysAL(item)}
      ${itemEndoALPremium(item)}
      ${itemEndoProcessingFee(item)}
      ${itemEndoOtherFees(item)}
      ${itemEndoSurplusLinesPlusTaxes(item)}
      ${itemEndoStampFee(item)}
      ${itemEndoCoverageLimit(item)}
    `;
    if (controlToast) {
        navigator.clipboard.writeText(textToCopy);
        controlToast(true, "The endorsement data was copied to clipboard", "success");
    }
    return textToSearch;
};

const extractPrefix = (input) => {
  if (typeof input !== 'string') {
    return '-';
  }
  const match = input.match(/^[A-Z]+-[A-Z]+/);
  return match ? match[0] : '-';
}

// VIN BORDEREAUX

export const vinItemBordereauxId = (item) => {
  const isPolicy = !Boolean(item?.type)
  const id = isPolicy ? (
    item?.takeAL ?
    item?.insuredInfo?.uniqueIdentifierNAFTAAL :
    item?.takeGL ?
    item?.insuredInfo?.uniqueIdentifierNAFTAGL : ''
  ) : item?.endorsementNumber
  return id || "-"
}
export const vinItemBordereauxPolicyNo = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyNo = isPolicy ? itemPolicyNo(item) : (item?.alPolicyNo || item?.glPolicyNo)
  return policyNo || "-"
}
export const vinItemBordereauxProgram = (item) => {
  const isPolicy = !Boolean(item?.type)
  const program = isPolicy ? itemProgram(item) : itemEndoProgram(item)
  return program
}
export const vinItemBordereauxEndoEffectiveDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const effectiveDate = isPolicy ? '-' : itemEndoEffectiveDate(item)
  return effectiveDate
}
export const vinItemBordereauxPolicyEffectiveDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const effectiveDate = isPolicy ? itemEffectiveDate(item) : itemEndoEffectiveDate(item)
  return effectiveDate
}
export const vinItemBordereauxTransactionDate = (item) => {
  return vinItemBordereauxEndoEffectiveDate(item)
}
export const vinItemBordereauxTermExpiryDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const expirationDate = isPolicy ? itemExpirationDate(item) : itemEndoALExp(item)
  return expirationDate
}
export const vinItemBordereauxCountry = (item) => {
  const isPolicy = !Boolean(item?.type)
  const country = isPolicy ? (
    item?.insuredInfo?.state ? 'USA' : 'MX'
  ) : (
    item?.stateOfEntry ? 'USA' : 'MX'
  )
  return country
}
export const vinItemBordereauxTransactionCode = (item) => {
  const isPolicy = !Boolean(item?.type)
  const transactionCode = isPolicy ? 'Policy/Application' :
    extractPrefix(item?.endorsementNumber)
  return transactionCode
}
export const vinItemBordereauxTransactionType = (item) => {
  return item?.documentType || '-'
}
export const vinItemBordereauxPolicyType = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyType = item?.status
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  const policyInEndoType = policyDetail?.policyStatus
  const type = isPolicy ? policyType : policyInEndoType
  return type || '-'
}
export const vinItemBordereauxCurrentPolicyStatus = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyType = item?.status
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  const policyInEndoType = policyDetail?.policyStatus
  const type = isPolicy ? policyType : policyInEndoType
  return type || '-'
}
export const vinItemBordereauxInsuredName = (item) => {
  return itemInsuredName(item)
}
export const vinItemBordereauxAddress1 = (item) => {
  return item?.insuredInfo?.address || '-'
}
export const vinItemBordereauxAddress2 = (item) => {
  return item?.insuredInfo?.address2 || '-'
}
export const vinItemBordereauxCity = (item) => {
  return item?.insuredInfo?.city || '-'
}
export const vinItemBordereauxState = (item) => {
  return item?.insuredInfo?.state || '-'
}
export const vinItemBordereauxZipCode = (item) => {
  return item?.insuredInfo?.zip || '-'
}
export const vinItemBordereauxCounty = (item) => {
  if (item?.insuredInfo?.counties && item?.insuredInfo?.counties?.length > 0) {
    return item?.insuredInfo?.counties[0]
  }
  return '-'
}
export const vinItemBordereauxMGA = (item) => {
  return 'SSM'
}
export const vinItemBordereauxLineOfBusiness = (item) => {
  return item?.takeAL ? 'Auto Liability' : item?.takeGL ? 'General Liability' : '-'
}
export const vinItemBordereauxPrimaryClass = (item) => {
  return item?.takeAL ? '19' : item?.takeGL ? '17' : '-'
}
export const vinItemBordereauxPrimaryDescription = (item) => {
  return item?.takeAL ? 'Auto Liability' : item?.takeGL ? 'General Liability' : '-'
}
export const vinItemBordereauxPolicyState = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyState = isPolicy ? item?.insuredInfo?.state : item?.stateOfEntry
  return policyState || '-'
}
export const vinItemBordereauxPolicyCurrency = (item) => {
  return 'USD'
}
export const vinItemBordereauxCoverageLimit = (item) => {
  const isPolicy = !Boolean(item?.type)
  const coverageSingleLimitPolicy = itemCoverageLimit(item)
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  const coverageSingleLimitEndo = policyDetail?.coverageSingleLimit
  const coverageSingleLimit = isPolicy ? coverageSingleLimitPolicy : coverageSingleLimitEndo
  return coverageSingleLimit || '-'
}
export const vinItemBordereauxCoverageDeductible = (item) => {
  return '1000'
}
export const vinItemBordereauxBordereauNotes = (item) => {
  return '0'
}
export const vinItemBordereauxGrossWrittenPremium = (item) => {
  const isPolicy = !Boolean(item?.type)
  const grossWrittenPremiumPolicy = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.GWP) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.GWP) : '-'
    ) : '-') :
    (item?.takeAL ? itemEndoALPremium(item) : item?.takeGL ? itemEndoGLPremium(item) : '-')
  return grossWrittenPremiumPolicy || '-'
}
export const vinItemBordereauxMGACommissionAmount = (item) => {
  const commissionPercentage = vinItemBordereauxMGACommissionPercentage(item)
  const grossWrittenPremium = vinItemBordereauxGrossWrittenPremium(item)
  if (grossWrittenPremium !== '-' && commissionPercentage !== '-') {
    const purgedNumber = grossWrittenPremium.replace(/[\$,]/g, '');
    const floatNumber = parseFloat(purgedNumber);
    if (!isNaN(floatNumber)) {
      const commissionAmount = commissionPercentage * floatNumber / 100
      return formatPrice(commissionAmount)
    }
    return '-'
  }
  return '-'
}
export const vinItemBordereauxMGACommissionPercentage = (item) => {
  const policyEffectiveDate = vinItemBordereauxPolicyEffectiveDate(item)
  const year = policyEffectiveDate?.split(', ')?.[1] || ''
  const parsedYear = parseInt(year)
  if (typeof parsedYear === 'number') {
    if (parsedYear >= 2021 && parsedYear <= 2022) {
      return 12
    } else if (parsedYear >= 2023) {
      return 14.50
    }
  }
  return '-'
}
export const vinItemBordereauxFrontingFeeAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const grossWrittenPremiumPolicy = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.processingFeeAmount) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.processingFeeAmount) : '-'
    ) : '-') :
    (item?.takeAL ? itemEndoALPremium(item) : item?.takeGL ? itemEndoGLPremium(item) : '-')
  return grossWrittenPremiumPolicy || '-'
}
export const vinItemBordereauxFetTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const surplusLinesTaxAmount = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : '-'
    ) : '-') :
    itemEndoSurplusLinesPlusTaxes(item)
  return surplusLinesTaxAmount || '-'
}
export const vinItemBordereauxStatePremiumTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const surplusLinesTaxAmount = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : '-'
    ) : '-') :
    itemEndoSurplusLinesPlusTaxes(item)
  return surplusLinesTaxAmount || '-'
}
export const vinItemBordereauxMunicipalTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const stampTaxAmount = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.stampTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.stampTaxAmount) : '-'
    ) : '-') :
    itemEndoStampFee(item)
  return stampTaxAmount || '-'
}
export const vinItemBordereauxTotalTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  const surplusAndStampFeeSum = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? (
        formatPrice(
          (item?.alPremiums?.surplusLinesTaxAmount || 0) +
          (item?.alPremiums?.stampTaxAmount || 0)
        )
      ) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? (
        formatPrice(
          (item?.alPremiums?.surplusLinesTaxAmount || 0) +
          (item?.alPremiums?.stampTaxAmount || 0)
        )
      ) : '-'
    ) : '-'
  ) :
    (policyDetail?.alPremiums ? (
      formatPrice(
        (policyDetail?.alPremiums?.stampTaxAmount || 0) +
        (policyDetail?.alPremiums?.surplusLinesTaxAmount || 0)
      )
    ) : '-'
  )
  return surplusAndStampFeeSum || '-'
}
export const vinItemBordereauxOtherFeesAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const grossWrittenPremiumPolicy = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.otherFees) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.otherFees) : '-'
    ) : '-') :
    (item?.takeAL ? itemEndoALPremium(item) : item?.takeGL ? itemEndoGLPremium(item) : '-')
  return grossWrittenPremiumPolicy || '-'
}
export const vinItemBordereauxNetAmountDueAmount = (item) => {
  return '-' // como se calcula esto?
}
export const vinItemBordereauxEndReportingPeriod = (item) => {
  const expiryData = vinItemBordereauxTermExpiryDate(item)
  return expiryData
}
export const vinItemBordereauxFrontingFeePercentage = (item) => {
  return '5%'
}
export const vinItemBordereauxRiskManagementFeeAmount = (item) => {
  return '5%'
}
export const vinItemBordereauxManagementFeeAmount = (item) => {
  const frontingFeeAmount = vinItemBordereauxFrontingFeeAmount(item)
  return frontingFeeAmount
}
export const vinItemBordereauxPowerUnitsCount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const vehicleCount = isPolicy ? itemVehicleCount(item) : itemVehicleCount(item?.policyData)
  return vehicleCount
}
export const vinItemBordereauxGarageAddress = (item) => {
  const isPolicy = !Boolean(item?.type)
  const address =
    isPolicy ?
    item?.questionnaire?.insuredInfo?.locationFields?.[0]?.address :
    item?.policyData?.questionnaire?.insuredInfo?.locationFields?.[0]?.address
  return address || '-'
}
export const vinItemBordereauxGarageCity = (item) => {
  const isPolicy = !Boolean(item?.type)
  const city =
    isPolicy ?
    item?.questionnaire?.insuredInfo?.locationFields?.[0]?.city :
    item?.policyData?.questionnaire?.insuredInfo?.locationFields?.[0]?.city
  return city || '-'
}
export const vinItemBordereauxGarageState = (item) => {
  const isPolicy = !Boolean(item?.type)
  const state =
    isPolicy ?
    item?.questionnaire?.insuredInfo?.locationFields?.[0]?.state :
    item?.policyData?.questionnaire?.insuredInfo?.locationFields?.[0]?.state
  return state || '-'
}
export const vinItemBordereauxGarageZip = (item) => {
  const isPolicy = !Boolean(item?.type)
  const zip =
    isPolicy ?
    item?.questionnaire?.insuredInfo?.locationFields?.[0]?.zip :
    item?.policyData?.questionnaire?.insuredInfo?.locationFields?.[0]?.zip
  return zip || '-'
}
export const vinItemBordereauxGarageVIN = (item) => {
  const isPolicy = !Boolean(item?.type)
  const truckVIN = isPolicy ? item?.['power-units']?.truckVIN : item?.data?.truckVIN
  return truckVIN || '-'
}
export const vinItemBordereauxGarageYear = (item) => {
  const isPolicy = !Boolean(item?.type)
  const truckYear = isPolicy ? item?.['power-units']?.truckYear : item?.data?.truckYear
  return truckYear || '-'
}
export const vinItemBordereauxGarageMake = (item) => {
  const isPolicy = !Boolean(item?.type)
  const truckMake = isPolicy ? item?.['power-units']?.truckMake : item?.data?.truckMake
  return truckMake || '-'
}
export const vinItemBordereauxGarageVehModel = (item) => {
  const isPolicy = !Boolean(item?.type)
  const truckModel = isPolicy ? item?.['power-units']?.truckModel : item?.data?.truckModel
  return truckModel || '-'
}
export const vinItemBordereauxGarageType = (item) => {
  return '-' // es 4 o 2, como determino esto?
}
export const vinItemBordereauxGaragePuNet = (item) => {
  const isPolicy = !Boolean(item?.type)
  const individualGWP = isPolicy ? item?.['power-units']?.individualGWP : item?.data?.individualGWP
  return individualGWP || '-'
}
export const vinItemBordereauxEventTask = (item) => {
  return '-' // que es esto?
}

// MAIN BORDEREAUX

export const mainItemBordereauxId = (item) => {
  const isPolicy = !Boolean(item?.type)
  const id = isPolicy ? (
    item?.takeAL ?
    item?.insuredInfo?.uniqueIdentifierNAFTAAL :
    item?.takeGL ?
    item?.insuredInfo?.uniqueIdentifierNAFTAGL : ''
  ) : item?.endorsementNumber
  return id || "-"
}
export const mainItemBordereauxPolicyNo = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyNo = isPolicy ? itemPolicyNo(item) : (
    item?.takeGL ? item?.policyData?.glPolicyNo :
    item?.takeAL ? item?.policyData?.alPolicyNo : '-'
  )
  return policyNo || "-"
}
export const mainItemBordereauxProgram = (item) => {
  const isPolicy = !Boolean(item?.type)
  const program = isPolicy ? itemProgram(item) : itemProgram(item?.policyData)
  return program
}
export const mainItemBordereauxEndoEffectiveDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const effectiveDate = isPolicy ? '-' : itemEndoEffectiveDate(item)
  return effectiveDate
}
export const mainItemBordereauxPolicyEffectiveDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const effectiveDate = isPolicy ? itemEffectiveDate(item) : itemEffectiveDate(item?.policyData)
  return effectiveDate
}
export const mainItemBordereauxTransactionDate = (item) => {
  return mainItemBordereauxEndoEffectiveDate(item)
}
export const mainItemBordereauxTermExpiryDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const expirationDate = isPolicy ? itemExpirationDate(item) : itemExpirationDate(item?.policyData)
  return expirationDate
}
export const mainItemBordereauxCountry = (item) => {
  const isPolicy = !Boolean(item?.type)
  const country = isPolicy ? (
    item?.insuredInfo?.state ? 'USA' : 'MX'
  ) : (
    item?.policyData?.stateOfEntry ? 'USA' : 'MX'
  )
  return country
}
export const mainItemBordereauxTransactionCode = (item) => {
  const isPolicy = !Boolean(item?.type)
  const transactionCode = isPolicy ? 'Policy/Application' :
    extractPrefix(item?.endorsementNumber)
  return transactionCode
}
export const mainItemBordereauxTransactionType = (item) => {
  return item?.documentType || '-'
}
export const mainItemBordereauxPolicyType = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyType = item?.status
  const policyInEndoType = item?.policyData?.status
  const type = isPolicy ? policyType : policyInEndoType
  return type || '-'
}
export const mainItemBordereauxCurrentPolicyStatus = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyType = item?.status
  const policyInEndoType = item?.policyData?.status
  const type = isPolicy ? policyType : policyInEndoType
  return type || '-'
}
export const mainItemBordereauxInsuredName = (item) => {
  return itemInsuredName(item)
}
export const mainItemBordereauxAddress1 = (item) => {
  return item?.insuredInfo?.address || '-'
}
export const mainItemBordereauxAddress2 = (item) => {
  return item?.insuredInfo?.address2 || '-'
}
export const mainItemBordereauxCity = (item) => {
  return item?.insuredInfo?.city || '-'
}
export const mainItemBordereauxState = (item) => {
  return item?.insuredInfo?.state || '-'
}
export const mainItemBordereauxZipCode = (item) => {
  return item?.insuredInfo?.zip || '-'
}
export const mainItemBordereauxCounty = (item) => {
  if (item?.insuredInfo?.counties && item?.insuredInfo?.counties?.length > 0) {
    return item?.insuredInfo?.counties[0]
  }
  return '-'
}
export const mainItemBordereauxMGA = (item) => {
  return 'SSM'
}
export const mainItemBordereauxLineOfBusiness = (item) => {
  return item?.takeAL ? 'Auto Liability' : item?.takeGL ? 'General Liability' : '-'
}
export const mainItemBordereauxPrimaryClass = (item) => {
  return item?.takeAL ? '19' : item?.takeGL ? '17' : '-'
}
export const mainItemBordereauxPrimaryDescription = (item) => {
  return item?.takeAL ? 'Auto Liability' : item?.takeGL ? 'General Liability' : '-'
}
export const mainItemBordereauxPolicyState = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyState = isPolicy ? item?.insuredInfo?.state : item?.policyData?.stateOfEntry
  return policyState || '-'
}
export const mainItemBordereauxPolicyCurrency = (item) => {
  return 'USD'
}
export const mainItemBordereauxCoverageLimit = (item) => {
  const isPolicy = !Boolean(item?.type)
  const coverageSingleLimitPolicy = itemCoverageLimit(item)
  const coverageSingleLimitEndo = itemCoverageLimit(item?.policyData)
  const coverageSingleLimit = isPolicy ? coverageSingleLimitPolicy : coverageSingleLimitEndo
  return coverageSingleLimit || '-'
}
export const mainItemBordereauxCoverageDeductible = (item) => {
  return '1000'
}
export const mainItemBordereauxBordereauNotes = (item) => {
  return '0'
}
export const mainItemBordereauxGrossWrittenPremium = (item) => {
  const isPolicy = !Boolean(item?.type)
  const grossWrittenPremiumPolicy =
  isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.GWP) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.GWP) : '-'
    ) : '-') :
    (item?.takeAL ? (
      item?.policyData?.alPremiums ? formatPrice(item?.policyData?.alPremiums?.GWP) : '-'
    ) : item?.takeGL ? (
      item?.policyData?.glPremiums ? formatPrice(item?.policyData?.glPremiums?.GWP) : '-'
    ) : '-')
  return grossWrittenPremiumPolicy || '-'
}
export const mainItemBordereauxMGACommissionAmount = (item) => {
  const commissionPercentage = mainItemBordereauxMGACommissionPercentage(item)
  const grossWrittenPremium = mainItemBordereauxGrossWrittenPremium(item)
  if (grossWrittenPremium !== '-' && commissionPercentage !== '-') {
    const purgedNumber = grossWrittenPremium.replace(/[\$,]/g, '');
    const floatNumber = parseFloat(purgedNumber);
    if (!isNaN(floatNumber)) {
      const commissionAmount = commissionPercentage * floatNumber / 100
      return formatPrice(commissionAmount)
    }
    return '-'
  }
  return '-'
}
export const mainItemBordereauxMGACommissionPercentage = (item) => {
  const policyEffectiveDate = mainItemBordereauxPolicyEffectiveDate(item)
  const year = policyEffectiveDate?.split(', ')?.[1] || ''
  const parsedYear = parseInt(year)
  if (typeof parsedYear === 'number') {
    if (parsedYear >= 2021 && parsedYear <= 2022) {
      return 12
    } else if (parsedYear >= 2023) {
      return 14.50
    }
  }
  return '-'
}
export const mainItemBordereauxFrontingFeeAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const grossWrittenPremiumPolicy = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.processingFeeAmount) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.processingFeeAmount) : '-'
    ) : '-') :
    (item?.takeAL ? (
      item?.policyData?.alPremiums ? formatPrice(item?.policyData?.alPremiums?.processingFeeAmount) : '-'
    ) : item?.takeGL ? (
      item?.policyData?.glPremiums ? formatPrice(item?.policyData?.glPremiums?.processingFeeAmount) : '-'
    ) : '-')
  return grossWrittenPremiumPolicy || '-'
}
export const mainItemBordereauxFetTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const surplusLinesTaxAmount = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : '-'
    ) : '-') :
    (item?.takeAL ? (
      item?.policyData?.alPremiums ? formatPrice(item?.policyData?.alPremiums?.surplusLinesTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.policyData?.glPremiums ? formatPrice(item?.policyData?.glPremiums?.surplusLinesTaxAmount) : '-'
    ) : '-')
  return surplusLinesTaxAmount || '-'
}
export const mainItemBordereauxStatePremiumTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const surplusLinesTaxAmount = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : '-'
    ) : '-') :
    (item?.takeAL ? (
      item?.policyData?.alPremiums ? formatPrice(item?.policyData?.alPremiums?.surplusLinesTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.policyData?.glPremiums ? formatPrice(item?.policyData?.glPremiums?.surplusLinesTaxAmount) : '-'
    ) : '-')
  return surplusLinesTaxAmount || '-'
}
export const mainItemBordereauxMunicipalTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const stampTaxAmount = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.stampTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.stampTaxAmount) : '-'
    ) : '-') :
    (item?.takeAL ? (
      item?.policyData?.alPremiums ? formatPrice(item?.policyData?.alPremiums?.stampTaxAmount) : '-'
    ) : item?.takeGL ? (
      item?.policyData?.glPremiums ? formatPrice(item?.policyData?.glPremiums?.stampTaxAmount) : '-'
    ) : '-')
  return stampTaxAmount || '-'
}
export const mainItemBordereauxTotalTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const stampTaxAmount = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? (
        formatPrice(
          (item?.alPremiums?.surplusLinesTaxAmount || 0) +
          (item?.alPremiums?.stampTaxAmount || 0)
        )
      ) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? (
        formatPrice(
          (item?.glPremiums?.surplusLinesTaxAmount || 0) +
          (item?.glPremiums?.stampTaxAmount || 0)
        )
      ) : '-'
    ) : '-') :
    (item?.takeAL ? (
      item?.policyData?.alPremiums ? (
        formatPrice(
          (item?.policyData?.alPremiums?.surplusLinesTaxAmount || 0) +
          (item?.policyData?.alPremiums?.stampTaxAmount || 0)
        )
      ) : '-'
    ) : item?.takeGL ? (
      item?.policyData?.glPremiums ? (
        formatPrice(
          (item?.policyData?.glPremiums?.surplusLinesTaxAmount || 0) +
          (item?.policyData?.glPremiums?.stampTaxAmount || 0)
        )
      ) : '-'
    ) : '-')
  return stampTaxAmount || '-'
}
export const mainItemBordereauxOtherFeesAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const grossWrittenPremiumPolicy = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums ? formatPrice(item?.alPremiums?.otherFees) : '-'
    ) : item?.takeGL ? (
      item?.glPremiums ? formatPrice(item?.glPremiums?.otherFees) : '-'
    ) : '-') :
    (item?.takeAL ? (
      item?.policyData?.alPremiums ? formatPrice(item?.policyData?.alPremiums?.otherFees) : '-'
    ) : item?.takeGL ? (
      item?.policyData?.glPremiums ? formatPrice(item?.policyData?.glPremiums?.otherFees) : '-'
    ) : '-')
  return grossWrittenPremiumPolicy || '-'
}
export const mainItemBordereauxNetAmountDueAmount = (item) => {
  return '-' // como se calcula esto?
}
export const mainItemBordereauxEndReportingPeriod = (item) => {
  const expiryData = mainItemBordereauxTermExpiryDate(item)
  return expiryData
}
export const mainItemBordereauxFrontingFeePercentage = (item) => {
  return '5%'
}
export const mainItemBordereauxRiskManagementFeeAmount = (item) => {
  return '5%'
}
export const mainItemBordereauxManagementFeeAmount = (item) => {
  const frontingFeeAmount = mainItemBordereauxFrontingFeeAmount(item)
  return frontingFeeAmount
}
export const mainItemBordereauxPowerUnitsCount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const vehicleCount = isPolicy ? itemVehicleCount(item) : itemVehicleCount(item?.policyData)
  return vehicleCount
}
export const mainItemBordereauxGarageAddress = (item) => {
  const isPolicy = !Boolean(item?.type)
  const address =
    isPolicy ?
    item?.questionnaire?.insuredInfo?.locationFields?.[0]?.address :
    item?.policyData?.questionnaire?.insuredInfo?.locationFields?.[0]?.address
  return address || '-'
}
export const mainItemBordereauxGarageCity = (item) => {
  const isPolicy = !Boolean(item?.type)
  const city =
    isPolicy ?
    item?.questionnaire?.insuredInfo?.locationFields?.[0]?.city :
    item?.policyData?.questionnaire?.insuredInfo?.locationFields?.[0]?.city
  return city || '-'
}
export const mainItemBordereauxGarageState = (item) => {
  const isPolicy = !Boolean(item?.type)
  const state =
    isPolicy ?
    item?.questionnaire?.insuredInfo?.locationFields?.[0]?.state :
    item?.policyData?.questionnaire?.insuredInfo?.locationFields?.[0]?.state
  return state || '-'
}
export const mainItemBordereauxGarageZip = (item) => {
  const isPolicy = !Boolean(item?.type)
  const zip =
    isPolicy ?
    item?.questionnaire?.insuredInfo?.locationFields?.[0]?.zip :
    item?.policyData?.questionnaire?.insuredInfo?.locationFields?.[0]?.zip
  return zip || '-'
}
export const mainItemBordereauxGarageVIN = (item) => {
  return '-'
}
export const mainItemBordereauxGarageYear = (item) => {
  return '-'
}
export const mainItemBordereauxGarageMake = (item) => {
  return '-'
}
export const mainItemBordereauxGarageVehModel = (item) => {
  return '-'
}
export const mainItemBordereauxGarageType = (item) => {
  return '-' // es 4 o 2, como determino esto?
}
export const mainItemBordereauxGaragePuNet = (item) => {
  return '0.00'
}
export const mainItemBordereauxEventTask = (item) => {
  return '-' // que es esto?
}

export const titleItemBordereauxUniqueReferenceNumber = 'Unique Reference Number'
export const titleItemBordereauxPolicyNumber = 'Policy Number'
export const titleItemBordereauxProgram = 'Program'
export const titleItemBordereauxBeginningReportingPeriod = 'Beginning Reporting Period'
export const titleItemBordereauxPolicyEffectiveDate = 'Policy Effective Date'
export const titleItemBordereauxTransactionEffectiveDate = 'Transaction Effective Date'
export const titleItemBordereauxTermExpiryDate = 'Term Expiry Date'
export const titleItemBordereauxCountry = 'Country'
export const titleItemBordereauxTransactionCode = 'Transaction Code'
export const titleItemBordereauxTransactionType = 'Transaction Type'
export const titleItemBordereauxPolicyType = 'Policy Type'
export const titleItemBordereauxCurrentPolicyStatus = 'Current Policy Status'
export const titleItemBordereauxInsuredName = 'Insured Name'
export const titleItemBordereauxAddress1 = 'Address 1'
export const titleItemBordereauxAddress2 = 'Address 2'
export const titleItemBordereauxCity = 'City'
export const titleItemBordereauxState = 'State'
export const titleItemBordereauxZipCode = 'Zip Code'
export const titleItemBordereauxCounty = 'County'
export const titleItemBordereauxMGA = 'MGA'
export const titleItemBordereauxLineOfBusiness = 'Line of Business'
export const titleItemBordereauxPrimaryClass = 'Primary Class'
export const titleItemBordereauxPrimaryDescription = 'Primary Description'
export const titleItemBordereauxPolicyState = 'Policy State'
export const titleItemBordereauxPolicyCurrency = 'Policy Currency'
export const titleItemBordereauxCoverageLimit = 'Coverage Limit'
export const titleItemBordereauxCoverageDeductible = 'Coverage Deductible'
export const titleItemBordereauxBordereauNotes = 'Bordereau Notes'
export const titleItemBordereauxGrossWrittenPremium = 'Gross Written Premium'
export const titleItemBordereauxMGACommission$ = 'MGA Commission $'
export const titleItemBordereauxMGACommissionPer = 'MGA Commission %'
export const titleItemBordereauxFrontingFee = 'Fronting Fee'
export const titleItemBordereauxFETTax$ = 'FET Tax $'
export const titleItemBordereauxStatePremiumTax$ = 'State Premium Tax $'
export const titleItemBordereauxMunicipalTax$ = 'Municipal Tax $'
export const titleItemBordereauxTotalTax$ = 'Total Tax $'
export const titleItemBordereauxOtherFees = 'Other Fees'
export const titleItemBordereauxNetAmountDue$ = 'Net Amount Due $'
export const titleItemBordereauxEndReportingPeriod = 'End Reporting Period'
export const titleItemBordereauxFrontingFeePer = 'Fronting Fee %'
export const titleItemBordereauxRiskManagementFee = 'Risk Management Fee'
export const titleItemBordereauxManagementFee = 'Management Fee'
export const titleItemBordereauxPowerUnitsCount = 'Power Units Count'
export const titleItemBordereauxGarageAddress = 'Garage Address'
export const titleItemBordereauxGarageCity = 'Garage City'
export const titleItemBordereauxGarageState = 'Garage State'
export const titleItemBordereauxGarageZip = 'Garage Zip'
export const titleItemBordereauxVIN = 'VIN'
export const titleItemBordereauxYear = 'Year'
export const titleItemBordereauxMake = 'Make'
export const titleItemBordereauxVehModel = 'Veh Model'
export const titleItemBordereauxType = 'Type'
export const titleItemBordereauxPUNET = 'PU NET'
export const titleItemBordereauxEventTask = 'EventTask'

export const handleCopyToClipboardBordereaux = (item, controlToast, bordereauxType) => {
  const isMain = bordereauxType === 'main'
  const textToCopy = `
      ${titleItemBordereauxUniqueReferenceNumber}: ${isMain ? mainItemBordereauxId(item) : vinItemBordereauxId(item)};
      ${titleItemBordereauxPolicyNumber}: ${isMain ? mainItemBordereauxPolicyNo(item) : vinItemBordereauxPolicyNo(item)};
      ${titleItemBordereauxProgram}: ${isMain ? mainItemBordereauxProgram(item) : vinItemBordereauxProgram(item)};
      ${titleItemBordereauxBeginningReportingPeriod}: ${isMain ? mainItemBordereauxEndoEffectiveDate(item) : vinItemBordereauxEndoEffectiveDate(item)};
      ${titleItemBordereauxPolicyEffectiveDate}: ${isMain ? mainItemBordereauxPolicyEffectiveDate(item) : vinItemBordereauxPolicyEffectiveDate(item)};
      ${titleItemBordereauxTransactionEffectiveDate}: ${isMain ? mainItemBordereauxTransactionDate(item) : vinItemBordereauxTransactionDate(item)};
      ${titleItemBordereauxTermExpiryDate}: ${isMain ? mainItemBordereauxTermExpiryDate(item) : vinItemBordereauxTermExpiryDate(item)};
      ${titleItemBordereauxCountry}: ${isMain ? mainItemBordereauxCountry(item) : vinItemBordereauxCountry(item)};
      ${titleItemBordereauxTransactionCode}: ${isMain ? mainItemBordereauxTransactionCode(item) : vinItemBordereauxTransactionCode(item)};
      ${titleItemBordereauxTransactionType}: ${isMain ? mainItemBordereauxTransactionType(item) : vinItemBordereauxTransactionType(item)};
      ${titleItemBordereauxPolicyType}: ${isMain ? mainItemBordereauxPolicyType(item) : vinItemBordereauxPolicyType(item)};
      ${titleItemBordereauxCurrentPolicyStatus}: ${isMain ? mainItemBordereauxCurrentPolicyStatus(item) : vinItemBordereauxCurrentPolicyStatus(item)};
      ${titleItemBordereauxInsuredName}: ${isMain ? mainItemBordereauxInsuredName(item) : vinItemBordereauxInsuredName(item)};
      ${titleItemBordereauxAddress1}: ${isMain ? mainItemBordereauxAddress1(item) : vinItemBordereauxAddress1(item)};
      ${titleItemBordereauxAddress2}: ${isMain ? mainItemBordereauxAddress2(item) : vinItemBordereauxAddress2(item)};
      ${titleItemBordereauxCity}: ${isMain ? mainItemBordereauxCity(item) : vinItemBordereauxCity(item)};
      ${titleItemBordereauxState}: ${isMain ? mainItemBordereauxState(item) : vinItemBordereauxState(item)};
      ${titleItemBordereauxZipCode}: ${isMain ? mainItemBordereauxZipCode(item) : vinItemBordereauxZipCode(item)};
      ${titleItemBordereauxCounty}: ${isMain ? mainItemBordereauxCounty(item) : vinItemBordereauxCounty(item)};
      ${titleItemBordereauxMGA}: ${isMain ? mainItemBordereauxMGA(item) : vinItemBordereauxMGA(item)};
      ${titleItemBordereauxLineOfBusiness}: ${isMain ? mainItemBordereauxLineOfBusiness(item) : vinItemBordereauxLineOfBusiness(item)};
      ${titleItemBordereauxPrimaryClass}: ${isMain ? mainItemBordereauxPrimaryClass(item) : vinItemBordereauxPrimaryClass(item)};
      ${titleItemBordereauxPrimaryDescription}: ${isMain ? mainItemBordereauxPrimaryDescription(item) : vinItemBordereauxPrimaryDescription(item)};
      ${titleItemBordereauxPolicyState}: ${isMain ? mainItemBordereauxPolicyState(item) : vinItemBordereauxPolicyState(item)};
      ${titleItemBordereauxPolicyCurrency}: ${isMain ? mainItemBordereauxPolicyCurrency(item) : vinItemBordereauxPolicyCurrency(item)};
      ${titleItemBordereauxCoverageLimit}: ${isMain ? mainItemBordereauxCoverageLimit(item) : vinItemBordereauxCoverageLimit(item)};
      ${titleItemBordereauxCoverageDeductible}: ${isMain ? mainItemBordereauxCoverageDeductible(item) : vinItemBordereauxCoverageDeductible(item)};
      ${titleItemBordereauxBordereauNotes}: ${isMain ? mainItemBordereauxBordereauNotes(item) : vinItemBordereauxBordereauNotes(item)};
      ${titleItemBordereauxGrossWrittenPremium}: ${isMain ? mainItemBordereauxGrossWrittenPremium(item) : vinItemBordereauxGrossWrittenPremium(item)};
      ${titleItemBordereauxMGACommission$}: ${isMain ? mainItemBordereauxMGACommissionAmount(item) : vinItemBordereauxMGACommissionAmount(item)};
      ${titleItemBordereauxMGACommissionPer}: ${isMain ? mainItemBordereauxMGACommissionPercentage(item) : vinItemBordereauxMGACommissionPercentage(item)};
      ${titleItemBordereauxFrontingFee}: ${isMain ? mainItemBordereauxFrontingFeeAmount(item) : vinItemBordereauxFrontingFeeAmount(item)};
      ${titleItemBordereauxFETTax$}: ${isMain ? mainItemBordereauxFetTaxAmount(item) : vinItemBordereauxFetTaxAmount(item)};
      ${titleItemBordereauxStatePremiumTax$}: ${isMain ? mainItemBordereauxStatePremiumTaxAmount(item) : vinItemBordereauxStatePremiumTaxAmount(item)};
      ${titleItemBordereauxMunicipalTax$}: ${isMain ? mainItemBordereauxMunicipalTaxAmount(item) : vinItemBordereauxMunicipalTaxAmount(item)};
      ${titleItemBordereauxTotalTax$}: ${isMain ? mainItemBordereauxTotalTaxAmount(item) : vinItemBordereauxTotalTaxAmount(item)};
      ${titleItemBordereauxOtherFees}: ${isMain ? mainItemBordereauxOtherFeesAmount(item) : vinItemBordereauxOtherFeesAmount(item)};
      ${titleItemBordereauxNetAmountDue$}: ${isMain ? mainItemBordereauxNetAmountDueAmount(item) : vinItemBordereauxNetAmountDueAmount(item)};
      ${titleItemBordereauxEndReportingPeriod}: ${isMain ? mainItemBordereauxEndReportingPeriod(item) : vinItemBordereauxEndReportingPeriod(item)};
      ${titleItemBordereauxFrontingFeePer}: ${isMain ? mainItemBordereauxFrontingFeePercentage(item) : vinItemBordereauxFrontingFeePercentage(item)};
      ${titleItemBordereauxRiskManagementFee}: ${isMain ? mainItemBordereauxRiskManagementFeeAmount(item) : vinItemBordereauxRiskManagementFeeAmount(item)};
      ${titleItemBordereauxManagementFee}: ${isMain ? mainItemBordereauxManagementFeeAmount(item) : vinItemBordereauxManagementFeeAmount(item)};
      ${titleItemBordereauxPowerUnitsCount}: ${isMain ? mainItemBordereauxPowerUnitsCount(item) : vinItemBordereauxPowerUnitsCount(item)};
      ${titleItemBordereauxGarageAddress}: ${isMain ? mainItemBordereauxGarageAddress(item) : vinItemBordereauxGarageAddress(item)};
      ${titleItemBordereauxGarageCity}: ${isMain ? mainItemBordereauxGarageCity(item) : vinItemBordereauxGarageCity(item)};
      ${titleItemBordereauxGarageState}: ${isMain ? mainItemBordereauxGarageState(item) : vinItemBordereauxGarageState(item)};
      ${titleItemBordereauxGarageZip}: ${isMain ? mainItemBordereauxGarageZip(item) : vinItemBordereauxGarageZip(item)};
      ${titleItemBordereauxVIN}: ${isMain ? mainItemBordereauxGarageVIN(item) : vinItemBordereauxGarageVIN(item)};
      ${titleItemBordereauxYear}: ${isMain ? mainItemBordereauxGarageYear(item) : vinItemBordereauxGarageYear(item)};
      ${titleItemBordereauxMake}: ${isMain ? mainItemBordereauxGarageMake(item) : vinItemBordereauxGarageMake(item)};
      ${titleItemBordereauxVehModel}: ${isMain ? mainItemBordereauxGarageVehModel(item) : vinItemBordereauxGarageVehModel(item)};
      ${titleItemBordereauxType}: ${isMain ? mainItemBordereauxGarageType(item) : vinItemBordereauxGarageType(item)};
      ${titleItemBordereauxPUNET}: ${isMain ? mainItemBordereauxGaragePuNet(item) : vinItemBordereauxGaragePuNet(item)};
      ${titleItemBordereauxEventTask}: ${isMain ? mainItemBordereauxEventTask(item) : vinItemBordereauxEventTask(item)};
    `;
    const textToSearch = `
      ${isMain ? mainItemBordereauxId(item) : vinItemBordereauxId(item)}
      ${isMain ? mainItemBordereauxPolicyNo(item) : vinItemBordereauxPolicyNo(item)}
      ${isMain ? mainItemBordereauxProgram(item) : vinItemBordereauxProgram(item)}
      ${isMain ? mainItemBordereauxEndoEffectiveDate(item) : vinItemBordereauxEndoEffectiveDate(item)}
      ${isMain ? mainItemBordereauxPolicyEffectiveDate(item) : vinItemBordereauxPolicyEffectiveDate(item)}
      ${isMain ? mainItemBordereauxTransactionDate(item) : vinItemBordereauxTransactionDate(item)}
      ${isMain ? mainItemBordereauxTermExpiryDate(item) : vinItemBordereauxTermExpiryDate(item)}
      ${isMain ? mainItemBordereauxCountry(item) : vinItemBordereauxCountry(item)}
      ${isMain ? mainItemBordereauxTransactionCode(item) : vinItemBordereauxTransactionCode(item)}
      ${isMain ? mainItemBordereauxTransactionType(item) : vinItemBordereauxTransactionType(item)}
      ${isMain ? mainItemBordereauxPolicyType(item) : vinItemBordereauxPolicyType(item)}
      ${isMain ? mainItemBordereauxCurrentPolicyStatus(item) : vinItemBordereauxCurrentPolicyStatus(item)}
      ${isMain ? mainItemBordereauxInsuredName(item) : vinItemBordereauxInsuredName(item)}
      ${isMain ? mainItemBordereauxAddress1(item) : vinItemBordereauxAddress1(item)}
      ${isMain ? mainItemBordereauxAddress2(item) : vinItemBordereauxAddress2(item)}
      ${isMain ? mainItemBordereauxCity(item) : vinItemBordereauxCity(item)}
      ${isMain ? mainItemBordereauxState(item) : vinItemBordereauxState(item)}
      ${isMain ? mainItemBordereauxZipCode(item) : vinItemBordereauxZipCode(item)}
      ${isMain ? mainItemBordereauxCounty(item) : vinItemBordereauxCounty(item)}
      ${isMain ? mainItemBordereauxMGA(item) : vinItemBordereauxMGA(item)}
      ${isMain ? mainItemBordereauxLineOfBusiness(item) : vinItemBordereauxLineOfBusiness(item)}
      ${isMain ? mainItemBordereauxPrimaryClass(item) : vinItemBordereauxPrimaryClass(item)}
      ${isMain ? mainItemBordereauxPrimaryDescription(item) : vinItemBordereauxPrimaryDescription(item)}
      ${isMain ? mainItemBordereauxPolicyState(item) : vinItemBordereauxPolicyState(item)}
      ${isMain ? mainItemBordereauxPolicyCurrency(item) : vinItemBordereauxPolicyCurrency(item)}
      ${isMain ? mainItemBordereauxCoverageLimit(item) : vinItemBordereauxCoverageLimit(item)}
      ${isMain ? mainItemBordereauxCoverageDeductible(item) : vinItemBordereauxCoverageDeductible(item)}
      ${isMain ? mainItemBordereauxBordereauNotes(item) : vinItemBordereauxBordereauNotes(item)}
      ${isMain ? mainItemBordereauxGrossWrittenPremium(item) : vinItemBordereauxGrossWrittenPremium(item)}
      ${isMain ? mainItemBordereauxMGACommissionAmount(item) : vinItemBordereauxMGACommissionAmount(item)}
      ${isMain ? mainItemBordereauxMGACommissionPercentage(item) : vinItemBordereauxMGACommissionPercentage(item)}
      ${isMain ? mainItemBordereauxFrontingFeeAmount(item) : vinItemBordereauxFrontingFeeAmount(item)}
      ${isMain ? mainItemBordereauxFetTaxAmount(item) : vinItemBordereauxFetTaxAmount(item)}
      ${isMain ? mainItemBordereauxStatePremiumTaxAmount(item) : vinItemBordereauxStatePremiumTaxAmount(item)}
      ${isMain ? mainItemBordereauxMunicipalTaxAmount(item) : vinItemBordereauxMunicipalTaxAmount(item)}
      ${isMain ? mainItemBordereauxTotalTaxAmount(item) : vinItemBordereauxTotalTaxAmount(item)}
      ${isMain ? mainItemBordereauxOtherFeesAmount(item) : vinItemBordereauxOtherFeesAmount(item)}
      ${isMain ? mainItemBordereauxNetAmountDueAmount(item) : vinItemBordereauxNetAmountDueAmount(item)}
      ${isMain ? mainItemBordereauxEndReportingPeriod(item) : vinItemBordereauxEndReportingPeriod(item)}
      ${isMain ? mainItemBordereauxFrontingFeePercentage(item) : vinItemBordereauxFrontingFeePercentage(item)}
      ${isMain ? mainItemBordereauxRiskManagementFeeAmount(item) : vinItemBordereauxRiskManagementFeeAmount(item)}
      ${isMain ? mainItemBordereauxManagementFeeAmount(item) : vinItemBordereauxManagementFeeAmount(item)}
      ${isMain ? mainItemBordereauxPowerUnitsCount(item) : vinItemBordereauxPowerUnitsCount(item)}
      ${isMain ? mainItemBordereauxGarageAddress(item) : vinItemBordereauxGarageAddress(item)}
      ${isMain ? mainItemBordereauxGarageCity(item) : vinItemBordereauxGarageCity(item)}
      ${isMain ? mainItemBordereauxGarageState(item) : vinItemBordereauxGarageState(item)}
      ${isMain ? mainItemBordereauxGarageZip(item) : vinItemBordereauxGarageZip(item)}
      ${isMain ? mainItemBordereauxGarageVIN(item) : vinItemBordereauxGarageVIN(item)}
      ${isMain ? mainItemBordereauxGarageYear(item) : vinItemBordereauxGarageYear(item)}
      ${isMain ? mainItemBordereauxGarageMake(item) : vinItemBordereauxGarageMake(item)}
      ${isMain ? mainItemBordereauxGarageVehModel(item) : vinItemBordereauxGarageVehModel(item)}
      ${isMain ? mainItemBordereauxGarageType(item) : vinItemBordereauxGarageType(item)}
      ${isMain ? mainItemBordereauxGaragePuNet(item) : vinItemBordereauxGaragePuNet(item)}
      ${isMain ? mainItemBordereauxEventTask(item) : vinItemBordereauxEventTask(item)}
    `;
    if (controlToast) {
        navigator.clipboard.writeText(textToCopy);
        controlToast(true, "The bordereaux data was copied to clipboard", "success");
    }
    return textToSearch;
};