import React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

export default function BlueButton({
  text,
  hasContentToLeft,
  hasContentToBottom,
  hasContentToRight,
  hasContentToTop,
  onClickFunction,
  disabled = false,
  className,
  icon,
  ref
}) {
  return (
    <Button
      ref={ref}
      onClick={(e) => {
        if (onClickFunction) onClickFunction(e)
      }}
      variant="contained"
      className={`${className ? className : ''} rounded`}
      disabled={disabled}
      startIcon={
        icon === 'no-icon' ? null :
        icon ? icon :
        <AddIcon className="w-3 h-3" />
      }
      sx={{
        borderRadius: 8,
        backgroundColor: '#0c4a6e', 
        '&:hover': {
          backgroundColor: '#072a48', 
        },
        marginLeft: hasContentToLeft ? '8px' : '0',
        marginBottom: hasContentToBottom ? '8px' : '0',
        marginRight: hasContentToRight ? '8px' : '0',
        marginTop: hasContentToTop ? '8px' : '0',
        fontSize: '0.75rem',
      }}
    >
      {text}
    </Button>
  );
}