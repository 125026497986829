import calculateAge from "./calculateAge";
import camelCaseToTitleCase from "./camelCaseToTitleCase";

const checkDriverForFlags = (driver, t) => {
    const redFlags = [];
    const frontLicense = driver?.licensePhoto ? driver?.licensePhoto : driver?.driverLicenseFront;

    if (driver?.flags?.flaggedByUnderwriter) {
      const arrayOfReasons = driver?.flags?.flaggedByUnderwriterReasons;
      arrayOfReasons.forEach((reason) => {
        redFlags.push(camelCaseToTitleCase(reason));
      });
    }
    if (!frontLicense) {
      redFlags.push(t("plm.drivers.copy"));
    }
  
    if (!driver?.dob || !calculateAge(driver?.dob?.toDate())) {
      redFlags.push("Invalid Date of Birth");
    }
  
    if (driver.licenseCountry === "MX" && !driver?.mxNMP) {
      redFlags.push("Missing Numero Medico Preventivo");
    }
  
    if (driver?.dob && calculateAge(driver?.dob?.toDate()) > 64) {
      redFlags.push(t("plm.drivers.details.older"));
    }
  
    if (driver?.dob && calculateAge(driver?.dob?.toDate()) < 23) {
      redFlags.push(t("plm.drivers.details.younger"));
    }
  
    if (
      driver?.sctResults?.determination ===
      "El operador NO cumple con los requisitos para poder conducir"
    ) {
      redFlags.push(t("plm.drivers.details.no-requirements"));
    }
  
    if (parseInt(driver?.yoe) < 2) {
      redFlags.push(t("plm.drivers.details.not-enough-experience"));
    }

    return redFlags;
}

export default checkDriverForFlags; 