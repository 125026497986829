import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

const updatePolicy = async (policyID, nestedObject) => {
  const policyRef = doc(db, "policy-applications", policyID);
  await setDoc(policyRef, nestedObject, { merge: true });
};

export const updatePrimaryContact = async (agencyID, insuredID, nestedObject) => {
  const primaryContactRef = doc(db, "agencies", agencyID, 'insureds', insuredID);
  await setDoc(primaryContactRef, nestedObject, { merge: true });
};

export default updatePolicy;
