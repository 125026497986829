import countAllViolations from "../countAllViolations";
import getScoreWeights from "../scores/getScoreWeights";
import scoreAge from "../scores/scoreAge";
import scoreClaimsAmount from "../scores/scoreClaimsAmount";
import scoreExp from "../scores/scoreExp";
import scoreMajor from "../scores/scoreMajor";
import scoreMinor from "../scores/scoreMinor";
import scoreNotIncluded from "../scores/scoreNotIncluded";
import scoreNumberOfClaims from "../scores/scoreNumberOfClaims";
import scoreOutOfDriving from "../scores/scoreOutOfDriving";

const aggregateDriverInfo = async (violations, drivers, setDataReady) => {
  const aggregatedDrivers = {};

  violations.forEach(violation => {
    const licenseNumber = violation?.LicenseNumber
      ? violation.LicenseNumber
      : null;

    if (!aggregatedDrivers[licenseNumber]) {
      aggregatedDrivers[licenseNumber] = {
        ...violation,
        ...drivers[licenseNumber],
        onPoliciesCount: 0,
        majorViolationsCount: 0,
        minorViolationsCount: 0,
        caseByCaseCount: 0,
        claimsCount: 0,
        claimsCountScore: 0,
        claimsAmountScore: 0,
        claimsAmount: 0,
        // Add more properties here as needed
      };
    }

    // Aggregate information
    aggregatedDrivers[licenseNumber].onPoliciesCount +=
      drivers[licenseNumber]?.policies?.length;
  });

  await Promise.all(
    Object.keys(aggregatedDrivers).map(async licenseNumber => {
      const counts = await countAllViolations(violations, licenseNumber);

      const weights = await getScoreWeights();
      const ageScore = await scoreAge(aggregatedDrivers[licenseNumber].dob);
      const expScore = await scoreExp(aggregatedDrivers[licenseNumber].yoe);
      const majorScore = await scoreMajor(counts.majorViolationsCount);
      const minorScore = await scoreMinor(counts.minorViolationsCount);
      const outOfDrivingScore = await scoreOutOfDriving(
        counts.outOfDrivingCount
      );
      const notIncludedScore = await scoreNotIncluded(counts.notIncludedCount);
      const weightedAge = ageScore * weights.age;
      const weightedExp = expScore * weights.exp;
      const weightedMajor = majorScore * weights.major;
      const weightedMinor = minorScore * weights.minor;
      const weightedOutOfDriving = outOfDrivingScore * weights.outOfDriving;
      const weightedNotIncluded = notIncludedScore * weights.notIncluded;
      const scoreTotal =
        weightedAge +
        weightedExp +
        weightedMajor +
        weightedMinor +
        weightedOutOfDriving +
        weightedNotIncluded;
      const result = scoreTotal > 0.6 ? "Need Training" : "No Training Needed";

      aggregatedDrivers[licenseNumber] = {
        ...aggregatedDrivers[licenseNumber],
        ...counts,
        claimsAmountScore: await scoreClaimsAmount(counts.claimsAmount),
        claimsCountScore: await scoreNumberOfClaims(counts.claimsCount),
        ageScore,
        expScore,
        majorScore,
        minorScore,
        outOfDrivingScore,
        notIncludedScore,
        weightedAge,
        weightedExp,
        weightedMajor,
        weightedMinor,
        weightedOutOfDriving,
        weightedNotIncluded,
        scoreTotal,
        result,
      };
    })
  );

  setDataReady(true);
  const returnValue = Object.values(aggregatedDrivers);
  console.log(returnValue)
  return returnValue;
};


// const aggregateDriverInfo = async (violations, drivers, setDataReady) => {
//   const aggregatedDrivers = {};

//   await Promise.all(
//     violations.map(async (violation) => {
//       const licenseNumber = violation?.LicenseNumber
//         ? violation.LicenseNumber
//         : null;

//       if (!aggregatedDrivers[licenseNumber]) {
//         aggregatedDrivers[licenseNumber] = {
//           ...violation,
//           ...drivers[licenseNumber],
//           onPoliciesCount: 0,
//           majorViolationsCount: 0,
//           minorViolationsCount: 0,
//           caseByCaseCount: 0,
//           claimsCount: 0,
//           claimsCountScore: 0,
//           claimsAmountScore: 0,
//           claimsAmount: 0,
//           // Add more properties here as needed
//         };
//       }

//       // Aggregate information
//       aggregatedDrivers[licenseNumber].onPoliciesCount +=
//         drivers[licenseNumber]?.policies?.length;

//       // Get counts
//       const counts = await countAllViolations(violations, licenseNumber);
//       // aggregatedDrivers[licenseNumber].claimsCountScore = await scoreNumberOfClaims(counts.claimsCount)
//       // aggregatedDrivers[licenseNumber].claimsAmountScore = await scoreClaimsAmount(counts.claimsAmount)

//       const weights = await getScoreWeights();
//       const ageScore = await scoreAge(aggregatedDrivers[licenseNumber].dob);
//       const expScore = await scoreExp(aggregatedDrivers[licenseNumber].yoe);
//       const majorScore = await scoreMajor(counts.majorViolationsCount);
//       const minorScore = await scoreMinor(counts.minorViolationsCount);
//       const outOfDrivingScore = await scoreOutOfDriving(
//         counts.outOfDrivingCount
//       );
//       const notIncludedScore = await scoreNotIncluded(counts.notIncludedCount);
//       const weightedAge = ageScore * weights.age;
//       const weightedExp = expScore * weights.exp;
//       const weightedMajor = majorScore * weights.major;
//       const weightedMinor = minorScore * weights.minor;
//       const weightedOutOfDriving = outOfDrivingScore * weights.outOfDriving;
//       const weightedNotIncluded = notIncludedScore * weights.notIncluded;
//       const scoreTotal =
//         weightedAge +
//         weightedExp +
//         weightedMajor +
//         weightedMinor +
//         weightedOutOfDriving +
//         weightedNotIncluded;
//       const result = scoreTotal > 0.6 ? "Need Training" : "No Training Needed";

//       aggregatedDrivers[licenseNumber] = {
//         ...aggregatedDrivers[licenseNumber],
//         ...counts,
//         claimsAmountScore: await scoreClaimsAmount(counts.claimsAmount),
//         claimsCountScore: await scoreNumberOfClaims(counts.claimsCount),
//         ageScore,
//         expScore,
//         majorScore,
//         minorScore,
//         outOfDrivingScore,
//         notIncludedScore,
//         weightedAge,
//         weightedExp,
//         weightedMajor,
//         weightedMinor,
//         weightedOutOfDriving,
//         weightedNotIncluded,
//         scoreTotal,
//         result,
//       };
//       return counts;
//     })
//   );

//   setDataReady(true);
//   const returnValue = Object.values(aggregatedDrivers);
//   return returnValue;
// };

export default aggregateDriverInfo;
