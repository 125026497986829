import { useContext } from "react"
import { SET_CALENDAR_TOKEN, SET_CALENDAR_SELECTED_DATA } from "../types"
import { AppContext, initialState } from "../AppContext"

const useCalendarState = () => {

    const { state, dispatch } = useContext(AppContext)
    const { calendar } = state || initialState
    
    const createToken = async (token) => {        
        try {
            dispatch({ type: SET_CALENDAR_TOKEN, payload: token })
        } catch (error) {
            throw error
        }
    }

    const setSelectedData = async (selectedData) => {        
        try {
            dispatch({ type: SET_CALENDAR_SELECTED_DATA, payload: selectedData })
        } catch (error) {
            throw error
        }
    }

    return {
        calendar,
        createToken,
        setSelectedData
    }
}

export default useCalendarState