import React from "react";
import renewPolicy from "../../utils/renewPolicy";
import { Typography } from "@mui/material";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";

export default function Renew({ policyID, onClose, setRenew, insuredInfo, formData }) {

  const { user: userAuth } = UserAuth()
  const { user: userState } = useUsersState()

  return (
    <>
      <Typography className="mt-2" variant="h6" gutterBottom>
        Policy Renewal
      </Typography>
      <p className="mb-2">
        Proceeding will create a duplicate application of this policy.
      </p>
      <p className="mb-3">
        Changes made on an In-Renewal Application will reflect on the policy
        (and visa versa) and as such may require the approval of an underwriter.
      </p>

      <ApprovalButtons
        onApprove={(e) => {
          renewPolicy(
            e,
            policyID,
            onClose,
            formData,
            userAuth,
            userState,
            insuredInfo
          ); setRenew(false);}}
        onDecline={e => {setRenew(false); onClose(e);}}
        titleAccept={"Continue"}
        titleDecline={"Cancel"}
        disabledAccept={false}
        disabledDecline={false}
        handleAsSubmit={false}
      />
    </>
  );
}
