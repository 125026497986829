import React, { useMemo, useState } from "react";
import {
  Modal,
  Box,
  useMediaQuery,
} from "@mui/material";
import PrintOptions from "./PrintOptions";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import { isPastSomeMinutes } from "../../../utils/helpers";
import PdfGenerator from "../../generals/PdfGenerator";

const PrintOptionsModal = ({
  open,
  onClose,
  printPolicy,
  policiesPdf,
  percentage
}) => {

  const {
    loading,
    cancelRequest,
    loadingCancel
  } = policiesPdf || {
    loading: false,
    url: '',
    error: false,
    cancelRequest: false,
    loadingCancel: false,
    dateWasCancel: null
  }

  const handleCancel = async () => {
    if (cancelRequest) {
      await cancelRequest()
      onClose();
    }
  }

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  
  const [printOptions, setPrintOptions] = useState({
    printDecPageOnly: false,
    printEntirePolicy: false,
    printMCS09: false,
  });

  const disabledAccept = useMemo(() => {
    const trueOption = Object.values(printOptions).find(option => Boolean(option))
    if (trueOption) {
      return false
    }
    return true
  })

  const getTrueOptionFromObject = (options) => {
    for (const option in options) {
      if (options[option] === true) {
        return option;
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    try {
      const option = getTrueOptionFromObject(printOptions)
      await printPolicy(e, option)
      onClose();
    } catch (error) {
      throw error
    }
  };

  return (
    <Modal open={open}>
      <div>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100vh",
            overflowY: "auto",
            width: isMobileScreen ? "100%" : 400,
            height: isMobileScreen ? "100%" : "auto",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <div className="mb-4">
            <PrintOptions
              printOptions={printOptions}
              setPrintOptions={setPrintOptions}
            />
          </div>

          {isPastSomeMinutes(policiesPdf?.dateWasCancel) ?
            <PdfGenerator percentage={percentage} policiesPdf={policiesPdf} /> : null
          }

          <ApprovalButtons
            onApprove={handleSubmit}
            onDecline={
              !loading ?
              onClose : handleCancel
            }
            titleAccept={"Print"}
            titleDecline={"Cancel"}
            disabledAccept={disabledAccept}
            manuallyTotalDeclineDisabled={loadingCancel}
            handleAsSubmit={false}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default PrintOptionsModal;
