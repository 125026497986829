import React from 'react';
import { Button, useMediaQuery } from '@mui/material';
import usePoliciesPdfState from '../../../Context/actions/usePoliciesPdfState';
import { isPastSomeMinutes } from '../../../utils/helpers';

const MenuBar = ({ labels, icons, onClickFunctions }) => {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const { policiesPdf } = usePoliciesPdfState();

  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: isMobileScreen ? 'column' : 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '0px',
    borderRadius: '10px', // Apply border-radius to the container
    overflow: 'hidden', // Ensure the border-radius affects the buttons
  };

  const buttonStyle = {
    flex: '1',
    borderRadius: 0, // No border-radius on individual buttons
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: isMobileScreen ? '' : 'gray',
    backgroundColor: '#0c4a6e',
    color: '#fff',
    marginRight: 0,
    marginBottom: isMobileScreen ? '8px' : 0,
    '&:hover': {
      backgroundColor: '#8B0000',
    },
  };

  const iconStyle = {
    marginRight: '8px',
  };

  return (
    <div style={buttonContainerStyle}>
      {labels.map((label, index) => {
        const disabled =
          Boolean(label === 'Print Policy' && policiesPdf?.loading) ||
          !isPastSomeMinutes(policiesPdf?.dateWasCancel)
        return (
          <Button
            key={label}
            variant="contained"
            style={buttonStyle}
            onClick={onClickFunctions[index]}
            disabled={disabled}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {React.cloneElement(icons[index], { style: iconStyle })}
              <span>{label}</span>
            </div>
          </Button>
        )
      })}
    </div>
  );
};

export default MenuBar;
