import React from "react";
import DatePicker from "react-datepicker";
import { formatPrice } from "../Finances/Tables/utils";

export default function BindDetailsInfo(props) {
  const { formData } = props;

  const parseValue = (value, type) => {
    if (type === "date") {
        if (typeof value === 'object') {
            const date = new Date(value?.toDate())
            return date
        } else {
            return null
        }
    }
    if (
        typeof value !== "undefined" &&
        typeof value === "boolean"
    ) {
      return value ? "Yes" : "No";
    }
    if (
      typeof value !== "undefined" &&
      typeof value === "number" &&
      type === "price"
    ) {
      return formatPrice(value);
    }
    if (typeof value !== "undefined") {
      return value;
    }
    return "-";
  };

  return (
    <>
      <fieldset disabled={true}>
        <form>
          <div>
            <>
                <div className="mb-2">
                    <label
                        htmlFor="boundBy"
                        className="block text-sm font-medium text-gray-600"
                    >
                        Bound By:
                    </label>
                    <input
                        required
                        name="boundBy"
                        type="text"
                        value={parseValue(formData?.bindData?.boundBy)}
                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="powerUnitCountAtBind"
                        className="block text-sm font-medium text-gray-600"
                    >
                        Power Unit Count At Bind:
                    </label>
                    <input
                        required
                        name="powerUnitCountAtBind"
                        type="text"
                        value={parseValue(formData?.bindData?.powerUnitCountAtBind)}
                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="bindDate"
                        className="block text-sm font-medium text-gray-600"
                    >
                        Bind Date:
                    </label>
                    <DatePicker
                        required
                        wrapperClassName="datePicker"
                        selected={parseValue(formData?.bindData?.bindDate, "date")}
                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
            </>
            <>
              <p className="text-lg font-semibold my-4">
                Bound Premiums Auto Liability
              </p>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityCoverage"
                  className="block text-sm font-medium text-gray-600"
                >
                  Has AL Coverage:
                </label>
                <input
                  required
                  name="autoLiabilityCoverage"
                  type="text"
                  value={parseValue(formData?.bindData?.coverages?.hasALCoverage)}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityGWP"
                  className="block text-sm font-medium text-gray-600"
                >
                  GWP:
                </label>
                <input
                  required
                  name="autoLiabilityGWP"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability?.GWP,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityAdjustment"
                  className="block text-sm font-medium text-gray-600"
                >
                  Adjustment:
                </label>
                <input
                  required
                  name="autoLiabilityAdjustment"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability?.adjustment
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityDebitOrCredit"
                  className="block text-sm font-medium text-gray-600"
                >
                  Debit Or Credit:
                </label>
                <input
                  required
                  name="autoLiabilityDebitOrCredit"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability
                      ?.debitOrCredit
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityOtherFees"
                  className="block text-sm font-medium text-gray-600"
                >
                  Other Fees:
                </label>
                <input
                  required
                  name="autoLiabilityOtherFees"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability?.otherFees,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityProcessingFeeAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  Processing Fee Amount:
                </label>
                <input
                  required
                  name="autoLiabilityProcessingFeeAmount"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability
                      ?.processingFeeAmount,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityStampTaxAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  Stamp Tax Amount:
                </label>
                <input
                  required
                  name="autoLiabilityStampTaxAmount"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability
                      ?.stampTaxAmount,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilitySurplusLinesTaxAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  Surplus Lines Tax Amount
                </label>
                <input
                  required
                  name="autoLiabilitySurplusLinesTaxAmount"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability
                      ?.surplusLinesTaxAmount,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityTotalPremium"
                  className="block text-sm font-medium text-gray-600"
                >
                  Total Premium
                </label>
                <input
                  required
                  name="autoLiabilityTotalPremium"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability
                      ?.totalPremium,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </>
            <>
              <p className="text-lg font-semibold my-4">
                Bound Premiums General Liability
              </p>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityCoverage"
                  className="block text-sm font-medium text-gray-600"
                >
                  Has GL Coverage:
                </label>
                <input
                  required
                  name="generalLiabilityCoverage"
                  type="text"
                  value={parseValue(formData?.bindData?.coverages?.hasGLCoverage)}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityGWP"
                  className="block text-sm font-medium text-gray-600"
                >
                  GWP:
                </label>
                <input
                  required
                  name="generalLiabilityGWP"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.generalLiability?.GWP,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityAdjustment"
                  className="block text-sm font-medium text-gray-600"
                >
                  Adjustment:
                </label>
                <input
                  required
                  name="generalLiabilityAdjustment"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.generalLiability?.adjustment
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityDebitOrCredit"
                  className="block text-sm font-medium text-gray-600"
                >
                  Debit Or Credit:
                </label>
                <input
                  required
                  name="generalLiabilityDebitOrCredit"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.generalLiability
                      ?.debitOrCredit
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityOtherFees"
                  className="block text-sm font-medium text-gray-600"
                >
                  Other Fees:
                </label>
                <input
                  required
                  name="generalLiabilityOtherFees"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.generalLiability?.otherFees,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityProcessingFeeAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  Processing Fee Amount:
                </label>
                <input
                  required
                  name="generalLiabilityProcessingFeeAmount"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.generalLiability
                      ?.processingFeeAmount,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityStampTaxAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  Stamp Tax Amount:
                </label>
                <input
                  required
                  name="generalLiabilityStampTaxAmount"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.generalLiability
                      ?.stampTaxAmount,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilitySurplusLinesTaxAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  Surplus Lines Tax Amount
                </label>
                <input
                  required
                  name="generalLiabilitySurplusLinesTaxAmount"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.generalLiability
                      ?.surplusLinesTaxAmount,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityTotalPremium"
                  className="block text-sm font-medium text-gray-600"
                >
                  Total Premium
                </label>
                <input
                  required
                  name="generalLiabilityTotalPremium"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.generalLiability
                      ?.totalPremium,
                    "price"
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </>
            <>
                <p className="text-lg font-semibold my-4">
                    Additional Information
                </p>
                <div className="mb-2">
                <label
                    htmlFor="hasMCP65"
                    className="block text-sm font-medium text-gray-600"
                >
                    MCP-65 (State):
                </label>
                <input
                    required
                    name="hasMCP65"
                    type="text"
                    value={parseValue(formData?.bindData?.additionalInfo?.hasMCP65)}
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
                </div>
                <div className="mb-2">
                <label
                    htmlFor="has91X"
                    className="block text-sm font-medium text-gray-600"
                >
                    91X (Federal):
                </label>
                <input
                    required
                    name="has91X"
                    type="text"
                    value={parseValue(formData?.bindData?.additionalInfo?.has91X)}
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
                </div>
                <div className="mb-2">
                <label
                    htmlFor="hasFormE"
                    className="block text-sm font-medium text-gray-600"
                >
                    Form E (State):
                </label>
                <input
                    required
                    name="hasFormE"
                    type="text"
                    value={parseValue(formData?.bindData?.additionalInfo?.hasFormE)}
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
                </div>
            </>
          </div>
        </form>
      </fieldset>
    </>
  );
}
