import { doc, getDoc, setDoc } from "firebase/firestore";
import ApiService from "../services/southern-api/apiService";
import { db } from "../firebase";

const apiService = new ApiService()

const humanDate = (date) => {
    if (!date) return '-'
    return date.toDate().toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    });
}

const formatPrice = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return "-";
    }
    const roundedNumber = parseFloat(number).toFixed(2);
    const priceWithCommas = roundedNumber
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `$${priceWithCommas}`;
};

export const endorsementPdfPayloads = async (
    endorsement,
    auth,
    insuredInfo
) => {
    try {
        let data = []
        if (
            endorsement?.type === 'Add Drivers' ||
            endorsement?.type === 'Remove Drivers' ||
            endorsement?.type === 'Exclude Drivers'
        ) {
            data = endorsement?.data?.map((driver, index) => {
                const policyDetail = endorsement?.data?.policyDetails?.find(
                    (policy) => policy.id === endorsement?.policyID
                )
                return {
                    no: String(index + 1) || '-',
                    driverName: driver?.driverFirst ? `${driver?.driverFirst} ${driver?.driverLast ? `${driver?.driverLast}` : ''}` : '-',
                    dob: humanDate(driver?.dob),
                    licenseNumber: driver?.licenseNumber || '-',
                    expDate: humanDate(driver?.licenseExpirationDate || driver?.licenseExpiration),
                    licenseType: policyDetail?.inspasData?.LicClass || '-'
                }
            })
        }
        if (
            endorsement?.type === 'Add Driver' ||
            endorsement?.type === 'Remove Driver' ||
            endorsement?.type === 'Exclude Driver'
        ) {
            const driver = endorsement?.data
            data = [{
                no: '1',
                driverName: driver?.driverFirst ? `${driver?.driverFirst} ${driver?.driverLast ? `${driver?.driverLast}` : ''}` : '-',
                dob: humanDate(driver?.dob),
                licenseNumber: driver?.licenseNumber || '-',
                expDate: humanDate(driver?.licenseExpirationDate || driver?.licenseExpiration),
                licenseType: driver?.licenseClass || '-'
            }]
        }
        if (
            endorsement?.type === 'Add Power Units' ||
            endorsement?.type === 'Remove Power Units'
        ) {
            data = endorsement?.data?.map((powerUnit, index) => {
                return {
                    no: String(index + 1) || '-',
                    vin: powerUnit?.truckVIN || '-',
                    make: powerUnit?.truckMake || '-',
                    model: powerUnit?.truckModel || '-',
                    year: powerUnit?.truckYear || '-',
                    grossWeight: powerUnit?.truckWeight || '-',
                    radius: powerUnit?.truckRadius || '-',
                    puNet: formatPrice(powerUnit?.truckACV),
                }
            })
        }
        if (
            endorsement?.type === 'Add Power Unit' ||
            endorsement?.type === 'Remove Power Unit'
        ) {
            const powerUnit = endorsement?.data
            data = [{
                no: '1',
                vin: powerUnit?.truckVIN || '-',
                make: powerUnit?.truckMake || '-',
                model: powerUnit?.truckModel || '-',
                year: powerUnit?.truckYear || '-',
                grossWeight: powerUnit?.truckWeight || '-',
                radius: powerUnit?.truckRadius || '-',
                puNet: formatPrice(powerUnit?.truckACV),
            }]
        }
        if (
            endorsement?.type === 'Add Trailers' ||
            endorsement?.type === 'Remove Trailers'
        ) {
            data = endorsement?.data?.map((trailer, index) => {
                return {
                    no: String(index + 1) || '-',
                    vin: trailer?.trailerVIN || '-',
                    make: trailer?.trailerMake || '-',
                    year: trailer?.trailerYear || '-',
                    acv: trailer?.trailerACV || '-',
                }
            })
        }
        if (
            endorsement?.type === 'Add Trailer' ||
            endorsement?.type === 'Remove Trailer'
        ) {
            const trailer = endorsement?.data
            data = [{
                no: '1',
                vin: trailer?.trailerVIN || '-',
                make: trailer?.trailerMake || '-',
                year: trailer?.trailerYear || '-',
                acv: trailer?.trailerACV || '-',
            }]
        }
        if (endorsement?.type === 'Adjust Radius') {
            const powerUnit = endorsement?.data
            data = [{
                no: '1',
                vin: powerUnit?.truckVIN || '-',
                make: powerUnit?.truckMake || '-',
                model: powerUnit?.truckModel || '-',
                year: powerUnit?.truckYear || '-',
                grossWeight: powerUnit?.truckWeight || '-',
                newRadius: powerUnit?.truckRadius || '-',
                oldRadius: powerUnit?.oldRadius || '-',
                puNet: formatPrice(powerUnit?.truckACV),
            }]
        }
        if (endorsement?.type === 'Swap Power Units') {
            data = {
                newVehicles: endorsement?.data?.newVehicles?.map((powerUnit, index) => {
                    return {
                        no: String(index + 1) || '-',
                        vin: powerUnit?.truckVIN || '-',
                        make: powerUnit?.truckMake || '-',
                        model: powerUnit?.truckModel || '-',
                        year: powerUnit?.truckYear || '-',
                        grossWeight: powerUnit?.truckWeight || '-',
                        radius: powerUnit?.truckRadius || '-',
                        puNet: formatPrice(powerUnit?.truckACV),
                    }                
                }),
                oldVehicles: endorsement?.data?.oldVehicles?.map((powerUnit, index) => {
                    return {
                        no: String(index + 1) || '-',
                        vin: powerUnit?.truckVIN || '-',
                        make: powerUnit?.truckMake || '-',
                        model: powerUnit?.truckModel || '-',
                        year: powerUnit?.truckYear || '-',
                        grossWeight: powerUnit?.truckWeight || '-',
                        radius: powerUnit?.truckRadius || '-',
                        puNet: formatPrice(powerUnit?.truckACV),
                    }                
                }),
            }
        }
    
        let premium = null
        let surplusLinesTax = null
        let stampingFee = null
        let processingFee = null
        let totalAmount = null
        let otherFees = null
        if (
            endorsement?.data?.policiesDetails ||
            endorsement?.data?.[0]?.policiesDetails ||
            endorsement?.type !== 'Swap Power Units'
        ) {
            let policyDetailMatch
            if (endorsement?.data?.policiesDetails) {
                policyDetailMatch = endorsement?.data?.policiesDetails
                    ?.find((policyDetail) => policyDetail.id === endorsement?.policyID)
            } else if (endorsement?.data?.[0]?.policiesDetails) {
                policyDetailMatch = endorsement?.data?.[0]?.policiesDetails
                    ?.find((policyDetail) => policyDetail.id === endorsement?.policyID)
            }
            premium = policyDetailMatch?.alPremiums?.GWP || null
            surplusLinesTax = policyDetailMatch?.alPremiums?.surplusLinesTaxAmount || null
            stampingFee = policyDetailMatch?.alPremiums?.stampTaxAmount || null
            processingFee = policyDetailMatch?.alPremiums?.processingFeeAmount || null
            totalAmount = policyDetailMatch?.alPremiums?.totalPremium || null
            otherFees = policyDetailMatch?.alPremiums?.otherFees || null
        } else if (
            endorsement?.data?.newVehicles ||
            endorsement?.policyID ||
            endorsement?.type === 'Swap Power Units'
        ) {
            const policyRef = doc(
                db,
                "policy-applications",
                endorsement?.policyID,
            );
            const policySnapshot = await getDoc(policyRef);
            const policyDetail = policySnapshot.data();
            premium = policyDetail?.alPremiums?.GWP || null
            surplusLinesTax = policyDetail?.alPremiums?.surplusLinesTaxAmount || null
            stampingFee = policyDetail?.alPremiums?.stampTaxAmount || null
            processingFee = policyDetail?.alPremiums?.processingFeeAmount || null
            totalAmount = policyDetail?.alPremiums?.totalPremium || null
            otherFees = policyDetail?.alPremiums?.otherFees || null
        }
    
        let endoPayload = {
            id: endorsement?.id,
            userId: auth?.uid,
            type: endorsement?.type,
            payload: [{
              policyNo: endorsement?.alPolicyNo || '-',
              effectiveDate: humanDate(endorsement?.endoEffectiveDate),
              namedInsured: endorsement?.namedInsured || '-',
              dba: insuredInfo?.dba || '-',
              issueDate: humanDate(endorsement?.issuedDate),
              premium: formatPrice(premium),
              surplusLinesTax: formatPrice(surplusLinesTax),
              stampingFee: formatPrice(stampingFee),
              processingFee: formatPrice(processingFee),
              totalAmount: formatPrice(totalAmount),
            }]
        }
    
        if (
            endoPayload?.type === 'Add Drivers' ||
            endoPayload?.type === 'Add Driver' ||
            endoPayload?.type === 'Remove Drivers' ||
            endoPayload?.type === 'Remove Driver' ||
            endoPayload?.type === 'Add Power Units' ||
            endoPayload?.type === 'Add Power Unit' ||
            endoPayload?.type === 'Remove Power Units' ||
            endoPayload?.type === 'Remove Power Unit' ||
            endoPayload?.type === 'Add Trailers' ||
            endoPayload?.type === 'Add Trailer' ||
            endoPayload?.type === 'Remove Trailers' ||
            endoPayload?.type === 'Remove Trailer' ||
            endoPayload?.type === 'Exclude Drivers' ||
            endoPayload?.type === 'Exclude Driver' ||
            endoPayload?.type === 'Adjust Radius' ||
            endoPayload?.type === 'Swap Power Units'
        ) {
            endoPayload.payload[0].company = insuredInfo?.company || '-'
            endoPayload.payload[0].data = data || []
            endoPayload.payload[0].endoNo = endorsement?.endorsementNumber || '-'
        }
        if (endoPayload?.type === 'Add Additional Insureds') {
            endoPayload.payload[0].name = insuredInfo?.company || '-'
            endoPayload.payload[0].address = insuredInfo?.address || '-'
            endoPayload.payload[0].otherFees = formatPrice(otherFees) || '-'
        }
        if (
            endoPayload?.type === 'Add Drivers' ||
            endoPayload?.type === 'Add Driver'
        ) {
            endoPayload.payload[0].tableTitle = 'Added Driver(s)'
        } else if (
            endoPayload?.type === 'Remove Drivers' ||
            endoPayload?.type === 'Remove Driver'
        ) {
            endoPayload.payload[0].tableTitle = 'Removed Driver(s)'
        } else if (
            endoPayload?.type === 'Add Power Units' ||
            endoPayload?.type === 'Add Power Unit'
        ) {
            endoPayload.payload[0].tableTitle = 'Added Power Unit(s)'
        } else if (
            endoPayload?.type === 'Remove Power Units' ||
            endoPayload?.type === 'Remove Power Unit'
        ) {
            endoPayload.payload[0].tableTitle = 'Removed Power Unit(s)'
        } else if (
            endoPayload?.type === 'Add Trailers' ||
            endoPayload?.type === 'Add Trailer'
        ) {
            endoPayload.payload[0].tableTitle = 'Added Trailer(s)'
        } else if (
            endoPayload?.type === 'Remove Trailers' ||
            endoPayload?.type === 'Remove Trailer'
        ) {
            endoPayload.payload[0].tableTitle = 'Removed Trailer(s)'
        } else if (
            endoPayload?.type === 'Exclude Drivers' ||
            endoPayload?.type === 'Exclude Driver'
        ) {
            endoPayload.payload[0].tableTitle = 'Excluded Driver(s)'
        } else if (endoPayload?.type === 'Adjust Radius') {
            endoPayload.payload[0].tableTitle = 'Adjust Operational Radius'
        } else if (endoPayload?.type === 'Swap Power Units') {
            endoPayload.payload[0].tableTitle1 = 'Swapped Power Unit(s)'
            endoPayload.payload[0].tableTitle2 = 'Swapped Power Unit(s)'
        }

        if (endorsement?.type === 'Add Additional Insureds') {
            const address =
                endorsement?.data?.addressLine1 ?
                `${endorsement?.data?.addressLine1} ${endorsement?.data?.addressLine2 ? `, ${endorsement?.data?.addressLine2}` : ''} ${endorsement?.data?.city ? `, ${endorsement?.data?.city}` : ''} ${endorsement?.data?.state ? `, ${endorsement?.data?.state}` : ''} ${endorsement?.data?.zip ? `, ${endorsement?.data?.zip}` : ''}` :
                endorsement?.data?.Address ?
                `${endorsement?.data?.Address} ${endorsement?.data?.AddressLineTwo ? `, ${endorsement?.data?.AddressLineTwo}` : ''} ${endorsement?.data?.City ? `, ${endorsement?.data?.City}` : ''} ${endorsement?.data?.State ? `, ${endorsement?.data?.State}` : ''} ${endorsement?.data?.Zip ? `, ${endorsement?.data?.Zip}` : ''}` : '-'

            const premium = endorsement?.data?.alPremiums?.GWP || ''
            const surplusLinesTax = endorsement?.data?.alPremiums?.surplusLinesTaxAmount || ''
            const stampingFee = endorsement?.data?.alPremiums?.stampTaxAmount || ''
            const processingFee = endorsement?.data?.alPremiums?.processingFeeAmount || ''
            const otherFees = endorsement?.data?.alPremiums?.otherFees || ''
            const totalAmount = endorsement?.data?.alPremiums?.totalPremium || ''

            endoPayload = {
                id: endorsement?.id,
                userId: auth?.uid,
                type: endorsement?.type,
                payload: [{
                    policyNo: endorsement?.alPolicyNo || '-',
                    namedInsured: endorsement?.namedInsured || '-',
                    effectiveDate: humanDate(endorsement?.endoEffectiveDate),
                    issueDate: humanDate(endorsement?.issuedDate),
                    dba: insuredInfo?.dba || '-',
                    name: endorsement?.data?.name || '-',
                    address,
                    premium: formatPrice(premium).replace('$', ''),
                    surplusLinesTax: formatPrice(surplusLinesTax).replace('$', ''),
                    stampingFee: formatPrice(stampingFee).replace('$', ''),
                    processingFee: formatPrice(processingFee).replace('$', ''),
                    otherFees: formatPrice(otherFees).replace('$', ''),
                    totalAmount: formatPrice(totalAmount).replace('$', ''),
                }]
            }
        }

        if (endorsement?.type === 'Add Waiver Of Subrogation') {
            const waiverData = endorsement?.data
            waiverData['waiverId'] =
                waiverData.type === "AL" ? "auto-liability" :
                waiverData.type === "GL" ? "general-liability" : "";
            waiverData['effectiveDate'] = endorsement?.endoEffectiveDate?.seconds
            const result = await apiService.createLiabilityPdf(waiverData)
            window.open(result?.url);
            return
        }

        const pdfResult = await apiService.printEndorsement(endoPayload)
        if (pdfResult?.url) {
            const endoRef = doc(
                db,
                "policy-applications",
                endorsement?.policyID,
                "endorsements",
                endorsement?.id,
            );
            await setDoc(endoRef, {
                file: [pdfResult?.url]
            }, { merge: true });
            window.open(pdfResult?.url);
            return
        }
    } catch (error) {
        console.log('error', error)
    }
};
