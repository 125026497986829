const buildDetailIndividualObject = (detail, policyInfo, typeOfProcess, data) => {
  let detailPayload = { ...detail }
  if (!detail) {
    detailPayload = {
      id: policyInfo?.policyID,
    }
  } else {
    detailPayload = { ...detail }
  }

  if (data?.truckVIN) {
    if (data?.rating) detailPayload["rating"] = data?.rating
    if (data?.truckACV) detailPayload["truckACV"] = data?.truckACV
    if (data?.truckClass) detailPayload["truckClass"] = data?.truckClass
    if (data?.truckLookupStatus) detailPayload["truckLookupStatus"] = data?.truckLookupStatus
    if (data?.truckMake) detailPayload["truckMake"] = data?.truckMake
    if (data?.truckModel) detailPayload["truckModel"] = data?.truckModel
    if (data?.truckRadius) detailPayload["truckRadius"] = data?.truckRadius
    if (data?.fleetCredit) detailPayload["fleetCredit"] = data?.fleetCredit
    if (data?.adjustedRate) detailPayload["adjustedRate"] = data?.adjustedRate
    if (data?.documentSource) detailPayload["documentSource"] = data?.documentSource
    if (data?.nonOwnedAdjustment) detailPayload["nonOwnedAdjustment"] = data?.nonOwnedAdjustment
    if (data?.oldRadius) detailPayload["oldRadius"] = data?.oldRadius
  }

  if (policyInfo?.status)
    detailPayload["status"] = policyInfo?.status;
  if (policyInfo?.alPolicyNo)
    detailPayload["alPolicyNo"] = policyInfo?.alPolicyNo;
  if (policyInfo?.glPolicyNo)
    detailPayload["glPolicyNo"] = policyInfo?.glPolicyNo

  if (policyInfo?.alPremiums)
    detailPayload["alPremiums"] = policyInfo?.alPremiums
  if (policyInfo?.glPremiums)
    detailPayload["glPremiums"] = policyInfo?.glPremiums
  if (policyInfo?.program)
    detailPayload["program"] = policyInfo?.program
  if (policyInfo?.stateOfEntry)
    detailPayload["stateOfEntry"] = policyInfo?.stateOfEntry
  if (policyInfo?.coverageSingleLimit)
    detailPayload["coverageSingleLimit"] = policyInfo?.coverageSingleLimit
  if (policyInfo?.inspasData)
    detailPayload["inspasData"] = policyInfo?.inspasData

  if (policyInfo?.documentType)
    detailPayload["documentType"] = policyInfo?.documentType
  if (policyInfo?.policyStatus)
    detailPayload["policyStatus"] = policyInfo?.policyStatus

  if (policyInfo?.agencyID)
    detailPayload["agencyID"] = policyInfo?.agencyID
  if (policyInfo?.insuredID)
    detailPayload["insuredID"] = policyInfo?.insuredID

  if (policyInfo?.bindData)
    detailPayload["bindData"] = policyInfo?.bindData

  if (typeOfProcess === 'direct-addition') {
    detailPayload["addedDate"] = new Date();
    detailPayload["removedDate"] = null;
  } else if (typeOfProcess === 'endo-addition') {
    detailPayload["addedDate"] = policyInfo?.policyEffectiveDate
    detailPayload["removedDate"] = null;
  } if (typeOfProcess === 'direct-deletion') {
    detailPayload["addedDate"] = detail?.addedDate ? detail?.addedDate : new Date();
    detailPayload["removedDate"] = new Date();
  } else if (typeOfProcess === 'endo-deletion') {
    detailPayload["addedDate"] = detail?.addedDate ? detail?.addedDate : new Date();
    detailPayload["removedDate"] = policyInfo?.policyEffectiveDate || null
  }
  return detailPayload
}

export const setPolicyInfo = (data, policyInfo, typeOfProcess) => {
  const payload = { ...data };

  if (
    payload?.policies?.length &&
    payload?.policies?.length > 0 &&
    policyInfo?.status === "Active"
  ) {
    payload["policies"] = [
      ...payload?.policies,
      policyInfo?.policyID,
    ];
  } else if (policyInfo?.status === "Active") {
    payload["policies"] = [policyInfo?.policyID];
  } else if (payload?.policies && policyInfo?.status !== "Active") {
    payload["policies"] = payload?.policies.filter(policy => policy !== policyInfo?.policyID)
  }

  let thereWasAMatchInDetails = false

  const newPoliciesDetails = payload?.policiesDetails?.map((detail) => {
    if (
      detail?.id === policyInfo?.policyID ||
      detail?.policyID === policyInfo?.policyID
    ) {
      thereWasAMatchInDetails = true
      const newDetailPayload = buildDetailIndividualObject(
        detail,
        policyInfo,
        typeOfProcess,
        payload
      )
      return newDetailPayload
    } else {
      return detail
    }
  }) || []

  if (!thereWasAMatchInDetails) {
    const newDetailPayload = buildDetailIndividualObject(
      null,
      policyInfo,
      typeOfProcess,
      payload
    )
    newPoliciesDetails.push(newDetailPayload)
  }

  payload["policiesDetails"] = newPoliciesDetails;
  if (policyInfo?.policyEffectiveDate) {
    payload["effectiveDate"] = policyInfo?.policyEffectiveDate;
  }
  if (policyInfo?.bindData) {
    payload["bindData"] = policyInfo?.bindData;
  }
  if (policyInfo?.powerUnitCountAtBind) {
    payload["powerUnitCountAtBind"] = policyInfo?.powerUnitCountAtBind;
  }

  if (data?.truckVIN) {
    if (data?.truckVIN) payload["truckVIN"] = data?.truckVIN
    if (data?.truckWeight) payload["truckWeight"] = data?.truckWeight
    if (data?.truckYear) payload["truckYear"] = data?.truckYear
    if (data?.rating) delete payload.rating
    if (data?.truckACV) delete payload.truckACV
    if (data?.truckClass) delete payload.truckClass
    if (data?.truckLookupStatus) delete payload.truckLookupStatus
    if (data?.truckMake) delete payload.truckMake
    if (data?.truckModel) delete payload.truckModel
    if (data?.truckRadius) delete payload.truckRadius
    if (data?.fleetCredit) delete payload.fleetCredit
    if (data?.adjustedRate) delete payload.adjustedRate
    if (data?.documentSource) delete payload.documentSource
    if (data?.nonOwnedAdjustment) delete payload.nonOwnedAdjustment
  }

  return payload;
};

export const buildShowingInfo = (values, policyID) => {
  const filteredValues = []
  values.forEach((value) => {
    let thisValueWillBeAdded = false
    value?.policiesDetails?.forEach((detail) => {
      const validPolicyDetail = (
        (detail?.policyID === policyID || detail?.id === policyID) &&
        (
          detail?.status === "Active" ||
          detail?.status === "Pending" ||
          detail?.status === "Pending Deletion" ||
          detail?.status === "Pending Exclusion"
        )
      );
      if (validPolicyDetail) {
        thisValueWillBeAdded = true;
      }
    });
    if (thisValueWillBeAdded) {
      filteredValues.push(value)
    }
  })
  return filteredValues
}

export const filterRightPoliciesDetails = (policiesDetails, policyID) => {
  const chosenPolicyDetail = policiesDetails.find((detail) => {
    const validPolicyDetail = (
      (detail?.policyID === policyID || detail?.id === policyID) &&
      (
        detail?.status === "Active" ||
        detail?.status === "Pending" ||
        detail?.status === "Pending Deletion" ||
        detail?.status === "Pending Exclusion"
      )
    );
    return validPolicyDetail
  });
  return chosenPolicyDetail
}