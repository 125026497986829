import getAge from "../../utils/getAge";

const assignFrequency = (driver, dataReady, frequencyMatrix) => {
  if (!frequencyMatrix || frequencyMatrix.length === 0 || !dataReady) {
    console.log("Not ready");
    return null;
  }
  console.log('driver yoe: ', driver.yoe);
  // console.log('frequencyMatrix: ', frequencyMatrix);
  // console.log('dataReady: ', dataReady);
  
  const ageRanges = [
    { min: 23, max: 25, row: 0 },
    { min: 26, max: 30, row: 1 },
    { min: 31, max: 35, row: 2 },
    { min: 36, max: 40, row: 3 },
    { min: 41, max: 45, row: 4 },
    { min: 46, max: 50, row: 5 },
    { min: 51, max: 55, row: 6 },
    { min: 56, max: 60, row: 7 },
    { min: 61, max: Infinity, row: 8 },
  ];

  const yoeRanges = [
    { min: 0, max: 5, col: "col_0" },
    { min: 6, max: 10, col: "col_1" },
    { min: 11, max: 15, col: "col_2" },
    { min: 16, max: 20, col: "col_3" },
    { min: 21, max: 25, col: "col_4" },
    { min: 26, max: 30, col: "col_5" },
    { min: 31, max: 35, col: "col_6" },
    { min: 36, max: 40, col: "col_7" },
    { min: 41, max: 45, col: "col_8" },
    { min: 46, max: Infinity, col: "col_9" },
  ];

  function getCol(number) {
    for (const range of yoeRanges) {
      if (number >= range.min && number <= range.max) {
        return range.col;
      }
    }
    return null;
  }

  function getRow(age) {
    for (const range of ageRanges) {
      if (age >= range.min && age <= range.max) {
        return range.row;
      }
    }
    return null;
  }

  let age;
  if (driver.dob) {
    age = getAge(
      driver.dob.toDate().toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })
    );
  } else {
    age = 99;
  }
  const yoe = driver.yoe;
  const column = getCol(yoe);
  const row = getRow(age);
  if (!row) {
    console.log("Invalid row value.");
    return null;
  }
  if (!column) {
    console.log("Invalid column value.");
    return null;
  }

  const value = frequencyMatrix[row][column];
  return value;
};

export default assignFrequency;
