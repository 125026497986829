import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import ApiService from "../../services/southern-api/apiService";

const apiService = new ApiService('southern')

const updatePolicyField = async (policyID, fieldName, value) => {
    const isTemporalUrl =
      typeof window !== 'undefined' ?
      window?.location?.pathname?.includes('temporarily') : false
    const policyRef = doc(db, "policy-applications", policyID);
    if (!isTemporalUrl) {
      await setDoc(policyRef, {
        [fieldName]: value
      }, { merge: true });
    } else {
      await apiService.renewalProcessUpdatePolicyField({
        policyID,
        payload: {
          [fieldName]: value
        }
      })
    }
  };

  export default updatePolicyField;