import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import getTaxSchedule from "../getTaxSchedule";
import getStampFee from "./getStampFee";
import getSurplusLinesRate from "./getSurplusLinesRate";
import numberWithCommas from "../numberWithCommas";

const calculateTaxesMTC = async (policyID, formData, mtcPremium) => {
  if (!formData.hasMTCCoverage) return 0;

  const taxSchedule = await getTaxSchedule();
  const taxesAndFees = { ...taxSchedule };

  const stampFee = getStampFee(formData.stateOfEntry, taxesAndFees);
  const surplusLinesTax = getSurplusLinesRate(
    formData.stateOfEntry,
    taxesAndFees
  );
  const otherFees = taxesAndFees.otherFees;
  const processingFee = taxesAndFees.processingFee;

  const taxAmounts = {
    stampTaxAmount: (mtcPremium * stampFee * 100) / 100,
    surplusLinesTaxAmount: (mtcPremium * surplusLinesTax * 100) / 100,
  };

  updateDoc(doc(db, "policy-applications", policyID), {
    mtcDetails: { ...formData?.mtcDetails, ...taxAmounts },
  });

  return taxAmounts;
};


export default calculateTaxesMTC