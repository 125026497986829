import React from "react";
import { Td, Tr } from "react-super-responsive-table";
import QuillModal from "../../../../QuillModal";
import { Button, ButtonGroup, Grid } from "@mui/material";
import BlueButton from "../../../../assets/forms/BlueButton";
import {
  CheckCircle as CheckCircleIcon,
  Visibility as VisibilityIcon,
  HourglassBottom as HourglassBottomIcon,
} from "@mui/icons-material";

const OfacReview = ({
  task,
  formData,
  index,
  handleUpload,
  setFiles,
  isOpen,
  setIsOpen,
  handleOFAC,
  ofacExists,
  setOfacResultsModal,
  insuredInfo,
  ofacWasRequested,
  ofacLoading,
}) => {
  return (
    <>
      <Tr key={"otherLossRun" + index}>
        <Td valign="top">
          <p className="my-1">
            {formData.underwritingTasks?.[task.description]?.completed
              ? "Complete"
              : "Incomplete"}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">
            {task.cabURL ? (
              <a
                className="text-blue"
                target="_blank"
                rel="noreferrer"
                href={task.cabURL + insuredInfo.dot}
              >
                {task.description}
              </a>
            ) : (
              <>{task.description}</>
            )}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">{task.dueDate}</p>
        </Td>
        <Td valign="top">
          {task.type === "note" && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <QuillModal
                        handleUpload={handleUpload}
                        isOpen={isOpen[task.description] || false}
                        setIsOpen={(state) =>
                          setIsOpen((prev) => ({
                            ...prev,
                            [task.description]: state,
                          }))
                        }
                        label={task.description}
                        setFile={(files) =>
                          setFiles((prev) => ({
                            ...prev,
                            [task.description]: files,
                          }))
                        }
                        taskLocation={
                          formData.underwritingTasks?.[task.description]
                            ?.note || ""
                        }
                        hideButtonLabel={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <BlueButton
                    text={
                      ofacLoading
                        ? "Getting OFAC Results..."
                        : formData?.underwritingTasks?.[task.description]
                            ?.completed
                        ? "Update OFAC Check Results"
                        : !ofacWasRequested
                        ? "Run OFAC Check"
                        : "Re-Run OFAC Check"
                    }
                    onClickFunction={(e) => {
                      const existingTaskList =
                        formData?.underwritingTasks || {};
                      const newTaskList = {
                        ...existingTaskList,
                        [task.description]: {
                          completed: true,
                        },
                      };
                      handleOFAC(e, newTaskList);
                    }}
                    icon={
                      ofacLoading ? (
                        <HourglassBottomIcon />
                      ) : (
                        <CheckCircleIcon />
                      )
                    }
                    disabled={ofacLoading}
                    className={"w-full"}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {ofacExists ? (
                    <>
                      <ButtonGroup
                        fullWidth
                        disableElevation
                        variant="contained"
                      >
                        <Button
                          onClick={() => setOfacResultsModal(true)}
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            flexGrow: 1,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            marginTop: "8px",
                            padding: "4px 8px",
                            fontSize: "0.75rem",
                            marginBottom: "8px",
                          }}
                          startIcon={<VisibilityIcon />}
                        >
                          View OFAC Results
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : null}
                </Grid>
                {formData?.underwritingTasks?.[task.description]?.completed && (
                  <Grid item xs={12} md={12}>
                    <ButtonGroup fullWidth disableElevation variant="contained">
                      <Button
                        style={{
                          backgroundColor: "green",
                          color: "white",
                          flexGrow: 1,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          padding: "4px 8px",
                          fontSize: "0.75rem",
                        }}
                      >
                        Complete
                      </Button>
                    </ButtonGroup>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Td>
      </Tr>
    </>
  );
};

export default OfacReview;
