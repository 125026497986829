import React, { useState, useEffect } from "react";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import AddViolation from "../../Components/LossControl/AddViolation";
import aggregateDriverInfo from "../../Components/LossControl/utils/listeners/aggregateDriverInfo";
import mapEventualities from "../../Components/LossControl/utils/getData/mapEventualities";
import mapClassifications from "../../Components/LossControl/utils/getData/mapClassifications";
import getViolationsQuery from "../../Components/LossControl/utils/getData/getViolationsQuery";
import searchByLicense from "../../Components/LossControl/utils/getData/searchByLicense";
import performJoins from "../../Components/LossControl/utils/getData/performJoins";
import searchByPolicy from "../../Components/LossControl/utils/getData/searchByPolicy";
import processQueryInChunks from "../../Components/LossControl/utils/getData/processQueryInChunks";
import searchByName from "../../Components/LossControl/utils/getData/searchByName";
import MiniDriverFitness from "../../Components/LossControl/FullViolationsList/MiniDriverFitness";
import MiniViolationsList from "../../Components/LossControl/FullViolationsList/MiniViolationsList";
import TableSkeleton from "../generals/TableSkeleton";
import useTranslations from "../../Context/actions/useTranslations";
import { onSnapshot, collection, query, where, limit } from "firebase/firestore";
import { db } from "../../firebase";

export default function MiniLossControl({ userInfo, driver }) {
  const licenseNumber = driver.licenseNumber;
  const [dataReady, setDataReady] = useState(false);
  const [show, setShow] = useState(false);
  const [violations, setViolations] = useState([]);
  const [showAddViolationModal, setShowAddViolationModal] = useState(false);

  // Track readiness of each data source
  const [aggregatedDriverInfo, setAggregatedDriverInfo] = useState([]);
  const [searchValue, setSearchValue] = useState(licenseNumber);
  const [searchType, setSearchType] = useState("license");
  const [selectedInsured, setSelectedInsured] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [skeletonLoader, setSkeletonLoader] = useState(true);

  // Additional states to track data readiness
  const [violationsReady, setViolationsReady] = useState(false);
  const [driversReady, setDriversReady] = useState(false);

  const { t } = useTranslations();

  useEffect(() => {
    const loadData = async () => {
      try {
        let driversMap;
        if (searchType === "license") {
          driversMap = await searchByLicense(searchValue);
        } else if (searchType === "name") {
          driversMap = await searchByName(searchValue);
        } else if (searchType === "policy") {
          driversMap = await searchByPolicy(searchValue, selectedInsured, selectedPolicy);
        }
        setDriversReady(true);

        const violationsQuery = query(
          collection(db, "loss-control", "driverData", "violations"),
          where("LicenseNumber", "==", searchValue),
          limit(10)
        );

        const unsubscribeViolations = onSnapshot(violationsQuery, async snapshot => {
          try {
            const violationsArray = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setViolationsReady(true);

            const eventualitiesMap = await mapEventualities();
            const classificationsMap = await mapClassifications();

            const licenseArray = Object.values(driversMap).map((driver) => {
              return driver.licenseNumber;
            });
            const violationsQuerySnapshot =
              searchType === "policy"
                ? null
                : await getViolationsQuery(searchType, searchValue, licenseArray);

            // Ensure all states are ready before processing
            if (violationsReady && driversReady) {
              const fullyJoinedViolations = await performJoins(
                violationsQuerySnapshot,
                eventualitiesMap,
                classificationsMap,
                searchType === "policy" || searchType === "name" ? true : false,
                violationsArray
              );

              const aggregatedData = await aggregateDriverInfo(
                fullyJoinedViolations,
                driversMap,
                setDataReady
              );

              setAggregatedDriverInfo(aggregatedData);
              setViolations(fullyJoinedViolations);
              setSkeletonLoader(false);
              setDataReady(true);
            }
          } catch (error) {
            console.error("Error processing snapshot data:", error);
          }
        });

        return () => unsubscribeViolations();
      } catch (error) {
        console.error("Error setting up Firestore listeners:", error);
      }
    };

    loadData();
  }, [searchValue, searchType, selectedInsured, selectedPolicy, violationsReady, driversReady]);

  return (
    <div>
      {(userInfo.role === "Admin" || userInfo.role === "Underwriter") && (
        <>
          {show && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              onClick={null}
            >
              <CircularProgress color="inherit" />
              <Typography variant="h6">{t('plm.drivers.details.please-wait')}</Typography>
            </Backdrop>
          )}
          {skeletonLoader ?
            <div className="mb-3">
              <TableSkeleton rows={2} columns={4} />
            </div> :
          violations.length === 0 && !skeletonLoader ?
            <p>{t('plm.drivers.details.no-violations')}</p> :
            <>
              <h1 className="font-bold mb-2">{t('plm.drivers.details.driver-violations')}</h1>
              <Button sx={{ mb: "5px" }} onClick={() => setShowAddViolationModal(true)}>
                Add Violation
              </Button>
              <MiniViolationsList violations={violations} />
            </>
          }
          {skeletonLoader ?
            <TableSkeleton rows={2} columns={4} /> :
          aggregatedDriverInfo.length === 0 && !skeletonLoader ?
            <p>{t('plm.drivers.details.no-violations')}</p> :
            <>
              <h1 className="font-bold mb-2">{t('plm.drivers.details.driver-score')}</h1>
              <MiniDriverFitness
                dataReady={dataReady}
                aggregatedDriverInfo={aggregatedDriverInfo}
              />
            </>
          }
        </>
      )}
      <AddViolation
        open={showAddViolationModal}
        onClose={() => setShowAddViolationModal(false)}
        driverLicenseNumber={licenseNumber}
      />
    </div>
  );
}
