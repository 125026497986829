import { useMemo } from "react";
import {
  Loop as LoopIcon,
  Cached as CachedIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import BlueButton from "../../assets/forms/BlueButton";
import Form from "../../generals/Forms/Form";
import { generateMultiSheetXlsxFile } from "../../../utils/excelCreator";
import { CardContent, Card } from "@mui/material";
import {
  formatPrice,
  item355366Days,
  itemCompany,
  itemCoverageLimit,
  itemEffectiveDate,
  itemEndoALExp,
  itemEndoALNoPolicy,
  itemEndoALPremium,
  itemEndoCoverageLimit,
  itemEndoDaysAL,
  itemEndoEffectiveDate,
  itemEndoMake,
  itemEndoNumber,
  itemEndoOtherFees,
  itemEndoProcessingFee,
  itemEndoProgram,
  itemEndoRadius,
  itemEndoStampFee,
  itemEndoState,
  itemEndoSurplusLinesPlusTaxes,
  itemEndoTypeAddDelete,
  itemEndoVIN,
  itemEndoYear,
  itemExpirationDate,
  itemInsuredName,
  itemOtherFees,
  itemPolicyNo,
  itemPremium,
  itemProcessingFee,
  itemProgram,
  itemStampFee,
  itemState,
  itemSurplusLinesPlusTaxes,
  itemTotalPremium,
  itemType,
  itemVehicleCount,
  itemZipCode,
  mainItemBordereauxAddress1,
  mainItemBordereauxAddress2,
  mainItemBordereauxBordereauNotes,
  mainItemBordereauxCity,
  mainItemBordereauxCountry,
  mainItemBordereauxCounty,
  mainItemBordereauxCoverageDeductible,
  mainItemBordereauxCoverageLimit,
  mainItemBordereauxCurrentPolicyStatus,
  mainItemBordereauxEndoEffectiveDate,
  mainItemBordereauxEndReportingPeriod,
  mainItemBordereauxEventTask,
  mainItemBordereauxFetTaxAmount,
  mainItemBordereauxFrontingFeeAmount,
  mainItemBordereauxFrontingFeePercentage,
  mainItemBordereauxGarageAddress,
  mainItemBordereauxGarageCity,
  mainItemBordereauxGarageMake,
  mainItemBordereauxGaragePuNet,
  mainItemBordereauxGarageState,
  mainItemBordereauxGarageType,
  mainItemBordereauxGarageVehModel,
  mainItemBordereauxGarageVIN,
  mainItemBordereauxGarageYear,
  mainItemBordereauxGarageZip,
  mainItemBordereauxGrossWrittenPremium,
  mainItemBordereauxId,
  mainItemBordereauxInsuredName,
  mainItemBordereauxLineOfBusiness,
  mainItemBordereauxManagementFeeAmount,
  mainItemBordereauxMGA,
  mainItemBordereauxMGACommissionAmount,
  mainItemBordereauxMGACommissionPercentage,
  mainItemBordereauxMunicipalTaxAmount,
  mainItemBordereauxNetAmountDueAmount,
  mainItemBordereauxOtherFeesAmount,
  mainItemBordereauxPolicyCurrency,
  mainItemBordereauxPolicyEffectiveDate,
  mainItemBordereauxPolicyNo,
  mainItemBordereauxPolicyState,
  mainItemBordereauxPolicyType,
  mainItemBordereauxPowerUnitsCount,
  mainItemBordereauxPrimaryClass,
  mainItemBordereauxPrimaryDescription,
  mainItemBordereauxProgram,
  mainItemBordereauxRiskManagementFeeAmount,
  mainItemBordereauxState,
  mainItemBordereauxStatePremiumTaxAmount,
  mainItemBordereauxTermExpiryDate,
  mainItemBordereauxTotalTaxAmount,
  mainItemBordereauxTransactionCode,
  mainItemBordereauxTransactionDate,
  mainItemBordereauxTransactionType,
  mainItemBordereauxZipCode,
  titleItem355366Days,
  titleItemBordereauxAddress1,
  titleItemBordereauxAddress2,
  titleItemBordereauxBeginningReportingPeriod,
  titleItemBordereauxBordereauNotes,
  titleItemBordereauxCity,
  titleItemBordereauxCountry,
  titleItemBordereauxCounty,
  titleItemBordereauxCoverageDeductible,
  titleItemBordereauxCoverageLimit,
  titleItemBordereauxCurrentPolicyStatus,
  titleItemBordereauxEndReportingPeriod,
  titleItemBordereauxEventTask,
  titleItemBordereauxFETTax$,
  titleItemBordereauxFrontingFee,
  titleItemBordereauxFrontingFeePer,
  titleItemBordereauxGarageAddress,
  titleItemBordereauxGarageCity,
  titleItemBordereauxGarageState,
  titleItemBordereauxGarageZip,
  titleItemBordereauxGrossWrittenPremium,
  titleItemBordereauxInsuredName,
  titleItemBordereauxLineOfBusiness,
  titleItemBordereauxMake,
  titleItemBordereauxManagementFee,
  titleItemBordereauxMGA,
  titleItemBordereauxMGACommission$,
  titleItemBordereauxMGACommissionPer,
  titleItemBordereauxMunicipalTax$,
  titleItemBordereauxNetAmountDue$,
  titleItemBordereauxOtherFees,
  titleItemBordereauxPolicyCurrency,
  titleItemBordereauxPolicyEffectiveDate,
  titleItemBordereauxPolicyNumber,
  titleItemBordereauxPolicyState,
  titleItemBordereauxPolicyType,
  titleItemBordereauxPowerUnitsCount,
  titleItemBordereauxPrimaryClass,
  titleItemBordereauxPrimaryDescription,
  titleItemBordereauxProgram,
  titleItemBordereauxPUNET,
  titleItemBordereauxRiskManagementFee,
  titleItemBordereauxState,
  titleItemBordereauxStatePremiumTax$,
  titleItemBordereauxTermExpiryDate,
  titleItemBordereauxTotalTax$,
  titleItemBordereauxTransactionCode,
  titleItemBordereauxTransactionEffectiveDate,
  titleItemBordereauxTransactionType,
  titleItemBordereauxType,
  titleItemBordereauxUniqueReferenceNumber,
  titleItemBordereauxVehModel,
  titleItemBordereauxVIN,
  titleItemBordereauxYear,
  titleItemBordereauxZipCode,
  titleItemCompany,
  titleItemCoverageLimit,
  titleItemEffectiveDate,
  titleItemEndoALExp,
  titleItemEndoALNoPolicy,
  titleItemEndoALPremium,
  titleItemEndoCoverageLimit,
  titleItemEndoDaysAL,
  titleItemEndoEffectiveDate,
  titleItemEndoMake,
  titleItemEndoNumber,
  titleItemEndoOtherFees,
  titleItemEndoProcessingFee,
  titleItemEndoRadius,
  titleItemEndoStampFee,
  titleItemEndoState,
  titleItemEndoSurplusLinesPlusTaxes,
  titleItemEndoTypeAddDelete,
  titleItemEndoVIN,
  titleItemEndoYear,
  titleItemExpirationDate,
  titleItemInsuredName,
  titleItemOtherFees,
  titleItemPolicyNo,
  titleItemPremium,
  titleItemProcessingFee,
  titleItemProgram,
  titleItemStampFee,
  titleItemState,
  titleItemSurplusLinesPlusTaxes,
  titleItemTotalPremium,
  titleItemType,
  titleItemVehicleCount,
  titleItemZipCode,
  vinItemBordereauxAddress1,
  vinItemBordereauxAddress2,
  vinItemBordereauxBordereauNotes,
  vinItemBordereauxCity,
  vinItemBordereauxCountry,
  vinItemBordereauxCounty,
  vinItemBordereauxCoverageDeductible,
  vinItemBordereauxCoverageLimit,
  vinItemBordereauxCurrentPolicyStatus,
  vinItemBordereauxEndoEffectiveDate,
  vinItemBordereauxEndReportingPeriod,
  vinItemBordereauxEventTask,
  vinItemBordereauxFetTaxAmount,
  vinItemBordereauxFrontingFeeAmount,
  vinItemBordereauxFrontingFeePercentage,
  vinItemBordereauxGarageAddress,
  vinItemBordereauxGarageCity,
  vinItemBordereauxGarageMake,
  vinItemBordereauxGaragePuNet,
  vinItemBordereauxGarageState,
  vinItemBordereauxGarageType,
  vinItemBordereauxGarageVehModel,
  vinItemBordereauxGarageVIN,
  vinItemBordereauxGarageYear,
  vinItemBordereauxGarageZip,
  vinItemBordereauxGrossWrittenPremium,
  vinItemBordereauxId,
  vinItemBordereauxInsuredName,
  vinItemBordereauxLineOfBusiness,
  vinItemBordereauxManagementFeeAmount,
  vinItemBordereauxMGA,
  vinItemBordereauxMGACommissionAmount,
  vinItemBordereauxMGACommissionPercentage,
  vinItemBordereauxMunicipalTaxAmount,
  vinItemBordereauxNetAmountDueAmount,
  vinItemBordereauxOtherFeesAmount,
  vinItemBordereauxPolicyCurrency,
  vinItemBordereauxPolicyEffectiveDate,
  vinItemBordereauxPolicyNo,
  vinItemBordereauxPolicyState,
  vinItemBordereauxPolicyType,
  vinItemBordereauxPowerUnitsCount,
  vinItemBordereauxPrimaryClass,
  vinItemBordereauxPrimaryDescription,
  vinItemBordereauxProgram,
  vinItemBordereauxRiskManagementFeeAmount,
  vinItemBordereauxState,
  vinItemBordereauxStatePremiumTaxAmount,
  vinItemBordereauxTermExpiryDate,
  vinItemBordereauxTotalTaxAmount,
  vinItemBordereauxTransactionCode,
  vinItemBordereauxTransactionDate,
  vinItemBordereauxTransactionType,
  vinItemBordereauxZipCode,
} from "../Tables/utils";
import useUsersState from "../../../Context/actions/useUsersState";

const PeriodFilters = ({
  onSubmit,
  section = "policies",
  setSection,
  itemList,
  setItemList,
  type,
  program,
  bordereauxType,
  setType,
  startDateUsed,
  endDateUsed,
  setStartDateUsed,
  setEndDateUsed,
  setProgram,
  setBordereauxType
}) => {
  
  const handleSwitchType = () => {
    setItemList([]);
    setStartDateUsed(null);
    setEndDateUsed(null);
    setType(type === "effective-date" ? "issued-date" : "effective-date");
  };

  const handleBordereauxType = () => {
    setItemList([]);
    setStartDateUsed(null);
    setEndDateUsed(null);
    setBordereauxType(bordereauxType === 'main' ? 'vin' : 'main');
  }

  const switchButtonLabel =
    type === "effective-date"
      ? "Switch to Issued Date"
      : "Switch to Effective Date";

  const inputs = useMemo(() => {
    const inputObj = [
      {
        label: "Start Date",
        type: "date",
        name: "start-date",
      },
      {
        label: "End Date",
        type: "date",
        name: "end-date",
      },
    ];
    return inputObj;
  }, [type]);

  const formSettings = {
    onSubmit,
    inputs,
    buttonLabel: "Search",
  };

  const kebabToTitleCase = (str) => {
    return str
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleSectionChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setItemList([]);
    setSection(value);
  };

  const convertToArrays = (data) => {
    const keys = Object.keys(data[0]);
    const result = [keys];
    data.forEach((obj) => {
      const row = keys.map((key) => obj[key]);
      result.push(row);
    });
    return result;
  };

  const handleExport = (e) => {
    e.preventDefault();
    const fileTitle =
      section === "policies" ? "Policies" :
      section === "endorsements" ? "Endorsements" :
      section === "bordereaux" && bordereauxType === 'main' ?
      "Bordereaux MAIN" :
      "Bordereaux VIN"
    const dateField =
      type === "effective-date" ? " with Effective Date" : " with Issued Date";
    const dateInfo =
      startDateUsed && endDateUsed
        ? `${dateField} From ${startDateUsed} to ${endDateUsed}`
        : startDateUsed
        ? `${dateField} on ${startDateUsed} and onwards`
        : endDateUsed
        ? `${dateField} on ${endDateUsed} and before`
        : "";
    const title = `${fileTitle}${dateInfo}`;
    const dataToExport = () => {
      const parsedItems = itemList.map((item) => {
        if (section === "endorsements") {
          return {
            [titleItemEndoNumber]: itemEndoNumber(item),
            [titleItemProgram]: itemEndoProgram(item),
            [titleItemInsuredName]: itemInsuredName(item),
            [titleItemEndoEffectiveDate]: itemEndoEffectiveDate(item),
            [titleItemEndoTypeAddDelete]: itemEndoTypeAddDelete(item),
            [titleItemEndoYear]: itemEndoYear(item),
            [titleItemEndoRadius]: itemEndoRadius(item),
            [titleItemEndoMake]: itemEndoMake(item),
            [titleItemEndoVIN]: itemEndoVIN(item),
            [titleItem355366Days]: item355366Days(item),
            [titleItemEndoState]: itemEndoState(item),
            [titleItemEndoALNoPolicy]: itemEndoALNoPolicy(item),
            [titleItemEndoALExp]: itemEndoALExp(item),
            [titleItemEndoDaysAL]: itemEndoDaysAL(item),
            [titleItemEndoALPremium]: itemEndoALPremium(item),
            [titleItemEndoProcessingFee]: itemEndoProcessingFee(item),
            [titleItemEndoOtherFees]: itemEndoOtherFees(item),
            [titleItemEndoSurplusLinesPlusTaxes]:
              itemEndoSurplusLinesPlusTaxes(item),
            [titleItemEndoStampFee]: itemEndoStampFee(item),
            [titleItemEndoCoverageLimit]: itemEndoCoverageLimit(item),
          };
        } else if (section === "policies") {
          return {
            [titleItemVehicleCount]: itemVehicleCount(item),
            [titleItemProgram]: itemProgram(item),
            [titleItemInsuredName]: itemInsuredName(item),
            [titleItemPolicyNo]: itemPolicyNo(item),
            [titleItemType]: itemType(item),
            [titleItemCompany]: itemCompany(item),
            [titleItemState]: itemState(item),
            [titleItemEffectiveDate]: itemEffectiveDate(item),
            [titleItemExpirationDate]: itemExpirationDate(item),
            [titleItemPremium]: itemPremium(item),
            [titleItemProcessingFee]: itemProcessingFee(item),
            [titleItemOtherFees]: itemOtherFees(item),
            [titleItemSurplusLinesPlusTaxes]: itemSurplusLinesPlusTaxes(item),
            [titleItemStampFee]: itemStampFee(item),
            [titleItemTotalPremium]: itemTotalPremium(item),
            [titleItemCoverageLimit]: itemCoverageLimit(item),
            [titleItemZipCode]: itemZipCode(item),
          };
        } else {
          const isMain = bordereauxType === 'main';
          return {
            [titleItemBordereauxUniqueReferenceNumber]: isMain ? mainItemBordereauxId(item) : vinItemBordereauxId(item),
            [titleItemBordereauxPolicyNumber]: isMain ? mainItemBordereauxPolicyNo(item) : vinItemBordereauxPolicyNo(item),
            [titleItemBordereauxProgram]: isMain ? mainItemBordereauxProgram(item) : vinItemBordereauxProgram(item),
            [titleItemBordereauxBeginningReportingPeriod]: isMain ? mainItemBordereauxEndoEffectiveDate(item) : vinItemBordereauxEndoEffectiveDate(item),
            [titleItemBordereauxPolicyEffectiveDate]: isMain ? mainItemBordereauxPolicyEffectiveDate(item) : vinItemBordereauxPolicyEffectiveDate(item),
            [titleItemBordereauxTransactionEffectiveDate]: isMain ? mainItemBordereauxTransactionDate(item) : vinItemBordereauxTransactionDate(item),
            [titleItemBordereauxTermExpiryDate]: isMain ? mainItemBordereauxTermExpiryDate(item) : vinItemBordereauxTermExpiryDate(item),
            [titleItemBordereauxCountry]: isMain ? mainItemBordereauxCountry(item) : vinItemBordereauxCountry(item),
            [titleItemBordereauxTransactionCode]: isMain ? mainItemBordereauxTransactionCode(item) : vinItemBordereauxTransactionCode(item),
            [titleItemBordereauxTransactionType]: isMain ? mainItemBordereauxTransactionType(item) : vinItemBordereauxTransactionType(item),
            [titleItemBordereauxPolicyType]: isMain ? mainItemBordereauxPolicyType(item) : vinItemBordereauxPolicyType(item),
            [titleItemBordereauxCurrentPolicyStatus]: isMain ? mainItemBordereauxCurrentPolicyStatus(item) : vinItemBordereauxCurrentPolicyStatus(item),
            [titleItemBordereauxInsuredName]: isMain ? mainItemBordereauxInsuredName(item) : vinItemBordereauxInsuredName(item),
            [titleItemBordereauxAddress1]: isMain ? mainItemBordereauxAddress1(item) : vinItemBordereauxAddress1(item),
            [titleItemBordereauxAddress2]: isMain ? mainItemBordereauxAddress2(item) : vinItemBordereauxAddress2(item),
            [titleItemBordereauxCity]: isMain ? mainItemBordereauxCity(item) : vinItemBordereauxCity(item),
            [titleItemBordereauxState]: isMain ? mainItemBordereauxState(item) : vinItemBordereauxState(item),
            [titleItemBordereauxZipCode]: isMain ? mainItemBordereauxZipCode(item) : vinItemBordereauxZipCode(item),
            [titleItemBordereauxCounty]: isMain ? mainItemBordereauxCounty(item) : vinItemBordereauxCounty(item),
            [titleItemBordereauxMGA]: isMain ? mainItemBordereauxMGA(item) : vinItemBordereauxMGA(item),
            [titleItemBordereauxLineOfBusiness]: isMain ? mainItemBordereauxLineOfBusiness(item) : vinItemBordereauxLineOfBusiness(item),
            [titleItemBordereauxPrimaryClass]: isMain ? mainItemBordereauxPrimaryClass(item) : vinItemBordereauxPrimaryClass(item),
            [titleItemBordereauxPrimaryDescription]: isMain ? mainItemBordereauxPrimaryDescription(item) : vinItemBordereauxPrimaryDescription(item),
            [titleItemBordereauxPolicyState]: isMain ? mainItemBordereauxPolicyState(item) : vinItemBordereauxPolicyState(item),
            [titleItemBordereauxPolicyCurrency]: isMain ? mainItemBordereauxPolicyCurrency(item) : vinItemBordereauxPolicyCurrency(item),
            [titleItemBordereauxCoverageLimit]: isMain ? mainItemBordereauxCoverageLimit(item) : vinItemBordereauxCoverageLimit(item),
            [titleItemBordereauxCoverageDeductible]: isMain ? mainItemBordereauxCoverageDeductible(item) : vinItemBordereauxCoverageDeductible(item),
            [titleItemBordereauxBordereauNotes]: isMain ? mainItemBordereauxBordereauNotes(item) : vinItemBordereauxBordereauNotes(item),
            [titleItemBordereauxGrossWrittenPremium]: isMain ? mainItemBordereauxGrossWrittenPremium(item) : vinItemBordereauxGrossWrittenPremium(item),
            [titleItemBordereauxMGACommission$]: isMain ? mainItemBordereauxMGACommissionAmount(item) : vinItemBordereauxMGACommissionAmount(item),
            [titleItemBordereauxMGACommissionPer]: isMain ? mainItemBordereauxMGACommissionPercentage(item) : vinItemBordereauxMGACommissionPercentage(item),
            [titleItemBordereauxFrontingFee]: isMain ? mainItemBordereauxFrontingFeeAmount(item) : vinItemBordereauxFrontingFeeAmount(item),
            [titleItemBordereauxFETTax$]: isMain ? mainItemBordereauxFetTaxAmount(item) : vinItemBordereauxFetTaxAmount(item),
            [titleItemBordereauxStatePremiumTax$]: isMain ? mainItemBordereauxStatePremiumTaxAmount(item) : vinItemBordereauxStatePremiumTaxAmount(item),
            [titleItemBordereauxMunicipalTax$]: isMain ? mainItemBordereauxMunicipalTaxAmount(item) : vinItemBordereauxMunicipalTaxAmount(item),
            [titleItemBordereauxTotalTax$]: isMain ? mainItemBordereauxTotalTaxAmount(item) : vinItemBordereauxTotalTaxAmount(item),
            [titleItemBordereauxOtherFees]: isMain ? mainItemBordereauxOtherFeesAmount(item) : vinItemBordereauxOtherFeesAmount(item),
            [titleItemBordereauxNetAmountDue$]: isMain ? mainItemBordereauxNetAmountDueAmount(item) : vinItemBordereauxNetAmountDueAmount(item),
            [titleItemBordereauxEndReportingPeriod]: isMain ? mainItemBordereauxEndReportingPeriod(item) : vinItemBordereauxEndReportingPeriod(item),
            [titleItemBordereauxFrontingFeePer]: isMain ? mainItemBordereauxFrontingFeePercentage(item) : vinItemBordereauxFrontingFeePercentage(item),
            [titleItemBordereauxRiskManagementFee]: isMain ? mainItemBordereauxRiskManagementFeeAmount(item) : vinItemBordereauxRiskManagementFeeAmount(item),
            [titleItemBordereauxManagementFee]: isMain ? mainItemBordereauxManagementFeeAmount(item) : vinItemBordereauxManagementFeeAmount(item),
            [titleItemBordereauxPowerUnitsCount]: isMain ? mainItemBordereauxPowerUnitsCount(item) : vinItemBordereauxPowerUnitsCount(item),
            [titleItemBordereauxGarageAddress]: isMain ? mainItemBordereauxGarageAddress(item) : vinItemBordereauxGarageAddress(item),
            [titleItemBordereauxGarageCity]: isMain ? mainItemBordereauxGarageCity(item) : vinItemBordereauxGarageCity(item),
            [titleItemBordereauxGarageState]: isMain ? mainItemBordereauxGarageState(item) : vinItemBordereauxGarageState(item),
            [titleItemBordereauxGarageZip]: isMain ? mainItemBordereauxGarageZip(item) : vinItemBordereauxGarageZip(item),
            [titleItemBordereauxVIN]: isMain ? mainItemBordereauxGarageVIN(item) : vinItemBordereauxGarageVIN(item),
            [titleItemBordereauxYear]: isMain ? mainItemBordereauxGarageYear(item) : vinItemBordereauxGarageYear(item),
            [titleItemBordereauxMake]: isMain ? mainItemBordereauxGarageMake(item) : vinItemBordereauxGarageMake(item),
            [titleItemBordereauxVehModel]: isMain ? mainItemBordereauxGarageVehModel(item) : vinItemBordereauxGarageVehModel(item),
            [titleItemBordereauxType]: isMain ? mainItemBordereauxGarageType(item) : vinItemBordereauxGarageType(item),
            [titleItemBordereauxPUNET]: isMain ? mainItemBordereauxGaragePuNet(item) : vinItemBordereauxGaragePuNet(item),
            [titleItemBordereauxEventTask]: isMain ? mainItemBordereauxEventTask(item) : vinItemBordereauxEventTask(item),
          }
        }
      });
      const list = convertToArrays(parsedItems);
      return list;
    };

    const data = dataToExport();
    const cells =
      section === "policies" ? ["A1", "Q1"] :
      section === "endorsements" ? ["A1", "T1"] :
      ["A1", "BB1"];
    const cellsWidth =
      section === "policies" ? 150 :
      section === "endorsements" ? 150 : 200;
    const styles = {
      rectangle: cells,
      width: [...cells, cellsWidth],
      titles: cells,
    };

    generateMultiSheetXlsxFile(
      [data],
      ["Report"],
      `${title}.xlsx`,
      [styles],
      0,
      true
    );
  };

  const { user } = useUsersState();

  const beAbleToWatchBordereaux = Boolean(
    user.email === "amarino@southernstarmga.com" ||
      user.email === "felicona@liconainsurance.com" ||
      user.email === "colt@southernstarmga.com" ||
      user.email === "abdiel@southernstarmga.com"
  );

  const totalALGWPPolicy = itemList.reduce((acc, item) => {
    if (item?.takeAL === true) {
      const GWP =
        item?.alPremiums?.GWP ? item?.alPremiums?.GWP :
        item?.policyData?.alPremiums?.GWP ? item?.policyData?.alPremiums?.GWP : 0;
      return acc + GWP;
    }
    return acc;
  }, 0) || 0;

  const totalALGWPEndo = itemList.reduce((acc, item) => {
    const source = item?.policiesDetails ?
    (
      Array.isArray(item?.policiesDetails) ?
      item?.policiesDetails : [item?.policiesDetails]
    )
    : item?.data?.policiesDetails ? (
      Array.isArray(item?.data?.policiesDetails) ?
      item?.data?.policiesDetails : [item?.data?.policiesDetails]
    ) : []
    const policyDetail = source?.find(detail => detail?.id === item?.policyID)
    if (policyDetail?.alPremiums?.GWP) {
      const GWP = policyDetail?.alPremiums?.GWP || 0;
      return acc + GWP;
    }
    return acc;
  }, 0) || 0;

  const totalGLGWPPolicy = itemList.reduce((acc, item) => {
    if (item?.takeGL === true) {
      const GWP =
        item?.glPremiums?.GWP ? item?.glPremiums?.GWP :
        item?.policyData?.glPremiums?.GWP ? item?.policyData?.glPremiums?.GWP : 0;
      return acc + GWP;
    }
    return acc;
  }, 0) || 0;

  const totalALGLGWP =
    section === "policies" ?
    formatPrice(totalALGWPPolicy + totalGLGWPPolicy) :
    section === "endorsements" ?
    formatPrice(totalALGWPEndo) :
    section === "bordereaux" && bordereauxType === 'main' ?
    formatPrice(totalALGWPPolicy + totalGLGWPPolicy) :
    section === "bordereaux" && bordereauxType === 'vin' ?
    formatPrice(totalALGWPPolicy + totalGLGWPPolicy + totalALGWPEndo) : 0

  return (
    <Card className="mb-2">
      <CardContent
        style={{ cursor: "pointer", fontSize: "14px", padding: "16px" }}
      >
        <span className="text-xl font-semibold">
          Period Filters By {kebabToTitleCase(type)}
        </span>
        <div className="flex flex-wrap">
          <Form
            formSettings={formSettings}
            isSmallVersion={true}
            wrapperStyles="m-0 !ml-0 !mr-8 !p-0"
          />
          <div className="flex flex-col mt-3 mr-8">
            <div className="mb-3">
              <label
                htmlFor="report-type"
                className="block text-sm font-medium"
              >
                Select Report Type
              </label>
              <select
                required
                name="report-type"
                value={section}
                defaultValue={"policies"}
                onChange={handleSectionChange}
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="policies">Policies Reports</option>
                <option value="endorsements">Endorsements Reports</option>
                {beAbleToWatchBordereaux ? (
                  <option value="bordereaux">Bordereaux Reports</option>
                ) : null}
              </select>
            </div>
            <div className="mb-3">
              <label
                htmlFor="report-type"
                className="block text-sm font-medium"
              >
                Select Program
              </label>
              <select
                required
                name="program"
                value={program}
                onChange={(e) => {
                  e.preventDefault();
                  setProgram(e.target.value);
                }}
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">All</option>
                <option value="NAFTA">NAFTA</option>
                <option value="Domestic">Domestic</option>
              </select>
            </div>
            <BlueButton
              text={switchButtonLabel}
              onClickFunction={(e) => {
                e.preventDefault();
                handleSwitchType();
              }}
              hasContentToTop={true}
              hasContentToBottom={true}
              icon={<LoopIcon />}
              className="h-[33px] mb-3"
            />
          </div>
          <div className="flex flex-col">
            <span className="font-semibold mt-3">
              Total: {itemList.length}
            </span>
            <span className="font-semibold my-3">
              Premium Total: {totalALGLGWP}
            </span>
            <BlueButton
              text="Export"
              onClickFunction={handleExport}
              hasContentToTop={true}
              hasContentToBottom={true}
              icon={<DownloadIcon />}
              className="h-[33px] mb-3"
              disabled={!itemList.length}
            />
            <BlueButton
              text="Load All"
              onClickFunction={(e) => {
                onSubmit(e, "load-all");
              }}
              hasContentToTop={true}
              hasContentToBottom={true}
              icon={<CachedIcon />}
              className="h-[33px] mb-3"
            />
            {section === "bordereaux" ? (
              <BlueButton
                text={bordereauxType === 'main' ? 'Switch to VIN' : 'Switch to Main'}
                onClickFunction={(e) => {
                  e.preventDefault();
                  handleBordereauxType()
                }}
                hasContentToTop={true}
                hasContentToBottom={true}
                icon={<CachedIcon />}
                className="h-[33px] mb-3"
              />
            ) : null}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PeriodFilters;
