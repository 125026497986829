import React, { useState } from "react";
import CreatePolicyPanel from "../../Components/agent/policies/CreatePolicyPanel";
import PoliciesList from "../../Components/agent/policies/PoliciesList";
import Graphs from "../../Components/agent/policies/Graphs";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";

export default function ManagePolicies({
  userInfo,
  loading,
  setLoading
}) {
  const [showCreatePolicyPanel, setShowCreatePolicyPanel] = useState(false);
  const [policyList, setPolicyList] = useState([]);
  const { t } = useTranslations()

  const breadcrumbs = [
    { label: "Home", link: ACCOUNT_ROUTE },
    { label: t('breadcrumbs.manage-policies') },
  ];

  return (
    <div className="my-4 max-w-[1000px] mx-auto bg-white rounded-md shadow-md p-6">
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          {t('manage-policies.title')}
        </span>
      </div>
      <p className="mb-4">
      {t('manage-policies.view-information')}
      </p>
      {!showCreatePolicyPanel && (
        // List of Policies
        <PoliciesList
          policyList={policyList}
          setPolicyList={setPolicyList}
          loading={loading}
          setLoading={setLoading}
          userInfo={userInfo}
        />
      )}
      {!showCreatePolicyPanel && (
        // Graphs for Insights From List of Policies
        <div className="mt-4">
          {/* <Graphs policyList={policyList} /> */}
        </div>
      )}
    </div>
  );
}
