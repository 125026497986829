import LoadingScreen from "react-loading-screen";
import {
  Row,
  Col,
  Container,
} from "../../../../Components/generals/GridSystem";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useTranslations from "../../../../Context/actions/useTranslations";
import DriverPanel from "../../../../Components/PolicyLifecycleManager/Drivers";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApiService from "../../../../services/southern-api/apiService";
import BigAlert from "../../../../Components/generals/Toast/BigAlert";

export default function DriverPanelPage() {
  const { t } = useTranslations();

  let { paramRefID } = useParams();
  const policyID = paramRefID;
  const [loading, setLoading] = useState(false);
  const loadingText = t("manage-policies.loading");
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [driverList, setDriverList] = useState([]);
  const [formSaved, setFormSaved] = useState(true);
  const [insuredInfo, setInsuredInfo] = useState({});
  const policyRef = doc(db, "policy-applications", policyID);

  const [formData, setFormData] = useState({
    alPremiums: {
      GWP: 0,
      totalPremium: 0,
      stampFee: 0,
      stampTaxAmount: 0,
      surplusLinesTax: 0,
      surplusLinesTaxAmount: 0,
      otherFees: 0,
      processingFee: 0,
      processingFeeAmount: 0,
    },
    effectiveDate: "",
  });

  const apiService = new ApiService();
  const fetchData = async () => {
    try {
      const data = await apiService.renewalProcessPolicyData({ policyID });
      setFormData(data?.policyData);
      setInsuredInfo(data?.insuredData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    fetchData();
  }, [policyID]);

  const saveForm = async () => {
    try {
      const isTemporalUrl =
        typeof window !== "undefined"
          ? window?.location?.pathname?.includes("temporarily")
          : false;
      if (!isTemporalUrl) {
        await setDoc(policyRef, formData, { merge: true });
      } else {
        await apiService.renewalProcessPolicyData({
          policyID,
          payload: formData,
        });
      }
      setFormSaved(true);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const driverPanel = {
    formData,
    setDriverList,
    insuredInfo,
    driverCount: driverList.length,
    policyID,
    userInfo: null,
    userID: null,
    saveForm,
    setFormSaved,
    driverList,
    formSaved,
  };

  const [didSuccess, setDidSuccess] = useState(false)

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      padding="0"
      logoSrc=""
      text={loadingText}
    >
      {!loading && (
        <Container className="p-0 px-4 mt-4 mb-4 w-full bg-white rounded" fluid>
          <Row>
            <Col lg={12}>
              <Row>
                <Col
                  spacing={isMobileScreen ? 0 : 12}
                  className={isMobileScreen ? "mt-2" : ""}
                >
                    {!insuredInfo?.isOpenForPublicEditing || didSuccess ?
                        <BigAlert
                            title="Endorsements in Renewal were created."
                            subtitle="An email was send to let you know that the operation is done."
                            className="mt-2"
                            status="successful"
                        /> :
                        <DriverPanel
                          getEndorsements={() => {}}
                          {...driverPanel}
                          setDidSuccess={setDidSuccess}
                        />
                    }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </LoadingScreen>
  );
}
