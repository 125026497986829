import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

export default function StandardCommodityName({
  label,
  property,
  formData,
  policyID,
  disabledSlider,
  defaultValue,
}) {
  const handleChangeStandardCommodityPercentage = async (e) => {
    let newValue = e.target.value.replace(/^0+(?=\d)/, '');
    const updatedPercentages = { ...formData.standardCommodityPercentages };
    updatedPercentages[e.target.name] = newValue;

    const policyRef = doc(db, "policy-applications", policyID);

    if (disabledSlider && newValue === 0) {
      const updatedValues = { ...formData.standardCommodityValues, [property]: 0 };
      updateDoc(policyRef, { standardCommodityValues: updatedValues });
    }

    await updateDoc(policyRef, { standardCommodityPercentages: updatedPercentages });
  };

  return (
    <div className="flex flex-col space-y-2">
      <label className="text-sm font-semibold">{label} Percentage:</label>
      <input
        name={property}
        type="number"
        onChange={handleChangeStandardCommodityPercentage}
        className="p-2 border rounded focus:outline-none focus:border-blue-500"
        value={formData?.standardCommodityPercentages?.[property] || 0}
      />
    </div>
  );
}
