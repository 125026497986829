import React, { useState, cloneElement, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import timezone from "moment-timezone";
import FormPopup from "../../Components/generals/Popup/FormPopup";
import ApiService from "../../services/southern-api/apiService";
import useCalendarState from "../../Context/actions/useCalendarState";
import useGeneralState from "../../Context/actions/useGeneralState";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import BlueButton from "../../Components/assets/forms/BlueButton";

const CalendarRoute = () => {
  const {
    calendar: calendarState,
    setSelectedData,
    createToken,
  } = useCalendarState();
  const { controlToast } = useGeneralState();
  const apiService = new ApiService("southern");
  const localizer = momentLocalizer(moment);

  const convertEvents = (inputEvents) => {
    return inputEvents.map((event, eventIndex) => ({
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
      allDay: false,
      data: { appointment: { id: eventIndex } },
      resourceId: eventIndex,
    }));
  };

  const [valuesInModal, setValuesInModal] = useState({
    selectedStartTime: "",
    selectedEndTime: "",
    selectedTimezone: "",
    resourceId: "",
  });

  const [openNewEventModal, setOpenNewEventModal] = useState(false);

  const handleParseDate = (date) => {
    const originalMoment = moment(date);
    const monthName = originalMoment.format("MMMM");
    const dayOfMonth = originalMoment.format("Do");
    const year = originalMoment.format("YYYY");
    const hour = originalMoment.format("h");
    const minute = originalMoment.format("mm");
    const amPm = originalMoment.format("a");
    const formattedDateStr = `${monthName} ${dayOfMonth} ${year} at ${hour}:${minute}${amPm}`;
    return formattedDateStr;
  };

  const handleParseOtherDate = (date) => {
    const formattedMoment = moment(date, "MMMM Do YYYY [at] h:mma");
    const newFormattedDateStr = formattedMoment.format("YYYY-MM-DD[T]HH:mm:ss");
    return newFormattedDateStr;
  };

  const components = {
    events: ({ event }) => {
      const data = event?.data;
      if (data?.appointment) {
        return (
          <div>
            <p>{data.appointment.location}</p>
            <p>{data.appointment.resource}</p>
            <p>{data.appointment.address}</p>
          </div>
        );
      } else if (data?.blockout) {
        return (
          <div>
            <p>{data.blockout.name}</p>
          </div>
        );
      } else {
        return (
          <div>
            <p>{event.title}</p>
          </div>
        );
      }
    },
    timeSlotWrapper: ({ children, value, resource }) => {
      const handleClick = (e) => {
        e.preventDefault();
        const originalDate = moment(value);
        const modifiedDate = originalDate.add(1, "hour");
        const timeZone = timezone.tz.guess();
        setValuesInModal({
          selectedStartTime: value.toString(),
          selectedEndTime: modifiedDate.toDate().toString(),
          selectedTimezone: timeZone,
          resourceId: resource,
        });
        setOpenNewEventModal(true);
      };
      return <div onClick={handleClick}>{children}</div>;
    },
  };

  const sendCalendarEvent = async (token, selectedData) => {
    if (calendarState?.selectedData || selectedData) {
      try {
        if (calendarState?.selectedData) {
          await apiService.createCalendarEvent({
            ...token,
            ...calendarState?.selectedData,
          });
        } else if (selectedData) {
          await apiService.createCalendarEvent({
            ...token,
            ...selectedData,
          });
        }
        controlToast(true, "Event calendar added successfully", "success");
      } catch (error) {
        controlToast(true, "There was an error in the service", "error");
        console.error("Token error:", error);
      }
    }
  };

  const isExpiredToken = (timestamp) => {
    const currentTime = new Date().getTime();
    if (timestamp < currentTime) {
      return true;
    } else {
      return false;
    }
  };

  const authenticateInCalendar = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    try {
      const result = await apiService.generateOAuth();
      setSelectedData(null);
      window.location.href = result.authUrl;
      return result;
    } catch (error) {
      throw error;
    }
  };

  const handleNewEvent = async (e, value) => {
    try {
      e.preventDefault();
      value["start-date"] = handleParseOtherDate(value["start-date"]);
      value["end-date"] = handleParseOtherDate(value["end-date"]);
      if (
        isExpiredToken(calendarState?.token?.["expiry_date"]) ||
        !calendarState?.token
      ) {
        const result = await apiService.generateOAuth();
        setSelectedData(value);
        window.location.href = result.authUrl;
        setOpenNewEventModal(false);
      } else {
        setSelectedData(value);
        await sendCalendarEvent(calendarState.token, value);
        setOpenNewEventModal(false);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleTokenProcessing = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenString = urlParams.get("token");
    if (tokenString) {
      try {
        const token = JSON.parse(tokenString);
        window.history.replaceState({}, "", "/calendar");
        createToken(token);
        await sendCalendarEvent(token);
      } catch (error) {
        console.error("Token error:", error);
      }
    } else {
      if (
        isExpiredToken(calendarState?.token?.["expiry_date"]) ||
        !calendarState?.token
      ) {
        try {
          await authenticateInCalendar()
        } catch (error) {
          console.error("Token error:", error);
        }
      }
    }
  };

  useEffect(() => {
    handleTokenProcessing();
  }, []);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (calendarState.token) {
      const fetchEvents = () => {
        apiService
          .getCalendarEvents(calendarState.token)
          .then((events) => {
            const parsedEvents = convertEvents(events);
            setEvents(parsedEvents);
          })
          .catch((error) => {
            console.error("Error getting events", error);
          });
      };
      fetchEvents();
      const intervalId = setInterval(fetchEvents, 60000);
      return () => clearInterval(intervalId);
    }
  }, [calendarState]);

  const formSettingsNewEvent = {
    onSubmit: handleNewEvent,
    inputs: [
      {
        label: "Start date of new event",
        type: "just-text",
        name: "start-date",
        defaultValue: handleParseDate(valuesInModal?.selectedStartTime) || "",
      },
      {
        label: "End date of new event",
        type: "just-text",
        name: "end-date",
        defaultValue: handleParseDate(valuesInModal?.selectedEndTime) || "",
      },
      {
        label: "Timezone",
        name: "timezone",
        type: "just-text",
        defaultValue: valuesInModal?.selectedTimezone || "",
      },
      {
        label: "Summary",
        name: "summary",
        required: true,
        type: "text",
        defaultValue: "Cancel Insured",
      },
      {
        label: "Description",
        name: "description",
        required: true,
        type: "text",
      },
    ],
    buttonLabel: "Add New Event",
  };

  return (
    <div>
      <div className="mt-2">
        <p className="text-2xl font-bold text-[#072a48]">Calendar</p>
      </div>
      <BlueButton
        disabled={false}
        text={"Refresh Calendar Data"}
        onClickFunction={authenticateInCalendar}
        hasContentToTop={true}
        hasContentToBottom={true}
        icon={<RefreshIcon />}
      />
      <div className="bg-white p-3 rounded shadow text-[#072a48] relative">
        <FormPopup
          isOpen={openNewEventModal}
          formSettings={formSettingsNewEvent}
          onRequestClose={() => setOpenNewEventModal(false)}
          title="Calendar event to add"
        />
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["month", "week", "day", "agenda"]}
          defaultView="week"
          style={{ height: 500, cursor: "pointer" }}
          components={components}
          selectable={true}
          step={30}
          timeslots={1}
          onSelectSlot={(slotInfo) => {
            const originalDate = moment(slotInfo.start);
            const modifiedDate = originalDate.add(1, "hour");
            const timeZone = timezone.tz.guess();
            setValuesInModal({
              selectedStartTime: slotInfo.start.toString(),
              selectedEndTime: modifiedDate.toDate().toString(),
              selectedTimezone: timeZone,
              resourceId: '',
            });
            setOpenNewEventModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default CalendarRoute;