import React from "react";

export default function NumberInput(props) {

  const handleChange = (e) => {
    props.setFormSaved(false);
    const { value } = e.target
    const formattedValue = value.replace(/^0+([1-9])/, '$1').replace(/^\./, '0.');
    props.setFormData({
      ...props.formData,
      [props.property]: formattedValue,
    });
  }

  return (
    <div className="my-2">
      <label htmlFor={props.property} className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      <input
        id={props.property}
        required={props.required || false}
        onChange={handleChange}
        onBlur={props.saveForm}
        className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        type="number"
        value={props.formData?.[props.property]}
      />
    </div>
  );
}