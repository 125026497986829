import React, { useEffect } from "react";
import { Modal, Box, useMediaQuery } from "@mui/material";
import { useDropzone } from "react-dropzone";
import ApprovalButtons from "../assets/forms/ApprovalButtons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import deleteFileFromStorage from "../utils/deleteFromStorage";
import BlueButton from "../assets/forms/BlueButton";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function FileUploadModal({
  isOpen,
  setIsOpen,
  label,
  setFile,
  handleUpload,
  alreadyUploadedRule,
  fileLocation,
  additionalDeleteFunction,
  hideButtonLabel
}) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setFile([]); // Clear files state when closing modal
  };

  const onDrop = async (acceptedFiles) => {
    // Handle dropped files here
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.type.startsWith("image/")) {
        // Handle image file upload here
        console.log("Uploading image:", file);
        setFile(file); // Set files state with accepted files
        await handleUpload(file);
      }
    }
  };

  const handlePaste = async (event) => {
    // Prevent default paste behavior to avoid pasting into the dropzone
    event.preventDefault();
    // Access the pasted data from the event
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file") {
        const blob = item.getAsFile();
        console.log("Pasted file:", blob);
        // Create a File object from the pasted blob
        const pastedFile = new File([blob], "pasted_image.png", {
          type: blob.type,
        });
        // Handle pasted file here
        if (pastedFile.type.startsWith("image/")) {
          // Handle image file upload here
          console.log("Uploading pasted image:", pastedFile);
          setFile([pastedFile]); // Set files state with pasted file
          await handleUpload(pastedFile);
        }
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <div>
      {!hideButtonLabel && (
      <h3 className="text-[#072a48] mb-2">
        {alreadyUploadedRule ? "Upload " : ""}
        {label}
      </h3>)}
      {!alreadyUploadedRule && (
        <BlueButton
          text={"Upload A File"}
          onClickFunction={openModal}
          hasContentToBottom={true}
          icon={<CloudUploadIcon />}
          className={"w-full"}
        />
      )}
      {alreadyUploadedRule && (
        <>
          <a
            href={fileLocation}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sky-900 hover:text-[#072a48]"
          >
            <VisibilityIcon /> View
          </a>
          <button
            className={`text-[#8B0000] ${!isMobileScreen ? "ml-2" : ""}`}
            onClick={(e) => {
              let verify = prompt(
                "You are about to delete a file! If you are sure, type DELETE below: "
              );
              if (verify === "DELETE") {
                additionalDeleteFunction();

                deleteFileFromStorage(fileLocation);
              }
            }}
          >
            <DeleteIcon />
            Delete
          </button>
        </>
      )}
      <Modal open={isOpen} onClose={null}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100vh",
            overflowY: "auto",
            width: isMobileScreen ? "100%" : 500,
            height: isMobileScreen ? "100%" : "auto",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <h2 className="mb-4 font-bold text-large text-center text-[#072a48]">
            Upload {label}
          </h2>
          <p></p>
          <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} onPaste={handlePaste} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>CTRL+V, Drag/Drop or Click to Upload a File</p>
            )}
          </div>
          <div className="mt-4">
            <ApprovalButtons
              onDecline={closeModal}
              handleAsSubmit={false}
              disabledDecline={false}
              dissapearDecline={false}
              titleAccept=""
              titleDecline="Cancel"
              disabledAccept={true}
              dissapearAccept={true}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const dropzoneStyles = {
  border: "2px dashed #0087F7",
  borderRadius: "4px",
  borderColor: "#116582",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

const customModalStyles = {
  content: {
    margin: 0,
  },
};
