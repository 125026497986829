import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import { collection, onSnapshot, query, orderBy, getDoc, getDocs, collectionGroup, where, doc } from "firebase/firestore";
import { db } from "../../firebase";
import Pagination from "../assets/lists/DynamicTable/Pagination";
import TableSkeleton from "../generals/TableSkeleton";
import { Typography, Box, Divider, CardContent, Card, Tooltip } from "@mui/material";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { POLICY_ROUTE } from "../../utils/routes";
import {
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import Popup from "../generals/Popup";
import BlueButton from "../assets/forms/BlueButton";
import useUsersState from "../../Context/actions/useUsersState";
import SendLetterPanel from "../PolicyLifecycleManager/UnderwriterPanel/letters/SendLetterPanel";
import useGeneralState from "../../Context/actions/useGeneralState";

const todosPerPage = 5;

function ToDoList({ loading, setLoading }) {
  const initialSortConfig = {
    key: "dateCreated",
    direction: "asc",
  };

  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");
  const [todoList, setTodoList] = useState([]);

  const parseRawText = async (rawText) => {
    const accountNumberMatch = rawText.match(/Account Number:\s*([\d]+)/);
    const insuredNameMatch = rawText.match(/Insured Name:\s*([A-Za-z\s]+?)(?=\s*Policy Number\(s\):)/);
    const policyNumbersMatch = rawText.match(/Policy Number\(s\):\s*([^\n]+?)(?=\s*Policy Effective Date\(s\):)/);
    const policyDatesMatch = rawText.match(/Policy Effective Date\(s\):\s*([^\n]+)/);
  
    const accountNumber = accountNumberMatch ? accountNumberMatch[1] : "";
    const insuredName = insuredNameMatch ? insuredNameMatch[1].trim() : "";
    const policyNumbers = policyNumbersMatch
      ? policyNumbersMatch[1].split(",").map((policy) => policy.trim())
      : [];
    const policyDates = policyDatesMatch
      ? policyDatesMatch[1].split(",").map((date) => date.trim())
      : [];
  
    const policies = policyNumbers.map((number, index) => ({
      number,
      date: policyDates[index] || "",
    }));
  
    const insuredCollection = collectionGroup(db, "insureds");
    let insuredRef = query(
      insuredCollection,
      where("company", "==", insuredName)
    );
    const insuredData = await getDocs(insuredRef);
    const insureds = insuredData.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    });  
    let activePolicy = null;
    let insuredInfo = null;
  
    for (const insured of insureds) {
      const policyPromises = insured?.alPolicies.map(async (policy) => {
        const policyRef = doc(db, "policy-applications", policy?.policyID);
        const policyData = await getDoc(policyRef);
        const policyInfo = {
          ...policyData?.data(),
          id: policyData?.id
        }
        return policyInfo
      });
      const activePolicies = await Promise.all(policyPromises);
      activePolicy = activePolicies.find(policy => {
        return Boolean(
          policy?.status === 'In-Force' ||
          policy?.status === 'In-Cancellation'
        )
      });
      insuredInfo = insured;
      if (activePolicy) break;
    }

    return {
      accountNumber,
      insuredName,
      policies,
      activePolicy,
      insuredInfo
    };
  };
  
  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      const q = query(
        collection(db, "stonemark"),
        orderBy("dateReceived", "desc")
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        let todos = [];
        const promises = querySnapshot.docs.map((doc) => {
          return parseRawText(doc.data().rawText)
            .then((parsedText) => {
              
              // const insuredData = doc(
              //   db,
              //   "agencies",
              //   userInfo.agencyID,
              //   "insureds",
              //   policyData.insuredID
              // );
              // const insuredData 
              if (doc?.data()?.status !== 'Deleted') {
                todos.push({
                  id: doc.id,
                  stonemarkAccountNumber: parsedText.accountNumber,
                  namedInsured: parsedText.insuredName,
                  policies: parsedText.policies,
                  activePolicy: parsedText.activePolicy,
                  insuredInfo: parsedText.insuredInfo,
                  ...doc.data(),
                });
              }
            })
            .catch((error) => {
              console.error("Error parsing text:", error);
            });
        });
        await Promise.all(promises);
        setTodoList(todos);
        setLoading(false);
      });
      return () => unsubscribe();
    };
  
    fetchData();
  }, [setLoading]);  

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = todoList.filter((todo) =>
    `${todo.title} ${todo.description}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * todosPerPage;
  const currentItems = sortedData.slice(offset, offset + todosPerPage);
  const pageCount = Math.ceil(filteredData.length / todosPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const navigate = useNavigate()

  const [stoneMarkData, setStoneMarkData] = useState(null);
  const [openChangeSendLetterModal, setOpenChangeSendLetterModal] = useState(false);

  const handleChangeSendLetterModal = (todo) => {
    setStoneMarkData(todo?.id ? todo : null);
    setOpenChangeSendLetterModal(!openChangeSendLetterModal);
  }

  const { user: userInfo } = useUsersState()
  const { controlToast } = useGeneralState()

  return (
    <main>
      {stoneMarkData?.activePolicy ?
        <Popup
          overflow='scroll'
          maxWidth='50%'
          isOpen={openChangeSendLetterModal}
          onRequestClose={handleChangeSendLetterModal}
        >
          <SendLetterPanel
            policyID={stoneMarkData?.activePolicy?.id}
            formData={stoneMarkData?.activePolicy}
            userInfo={userInfo}
            insuredInfo={stoneMarkData?.insuredInfo}
            agencyID={userInfo?.agencyID}
            display={true}
            setDisplay={() => {}}
            setDisplayOthers={() => {}}
            initialLetterType={stoneMarkData?.type}
            stoneMarkData={stoneMarkData}
            closeModal={() => {
              handleChangeSendLetterModal()
              controlToast(true, 'Letter was sent successfully', 'success')
            }}
          />
        </Popup> : null
      }
      {loading ? (
        <TableSkeleton rows={5} columns={5} />
      ) : todoList.length === 0 ? (
        <p>No todos available</p>
      ) : (
        <Box sx={{ maxWidth: 700 }}>
          <Card sx={{ marginBottom: 4 }}>
            <CardContent>
              <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
                Stonemark Notifications
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Table>
                <Thead>
                  <Tr>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer",
                        {
                          "hover:bg-[#072a48]": sortConfig.key !== "title",
                          "bg-blue-500": sortConfig.key === "title",
                        }
                      )}
                      onClick={() => handleSort("title")}
                    >
                      Type
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer",
                        {
                          "hover:bg-[#072a48]":
                            sortConfig.key !== "description",
                          "bg-blue-500": sortConfig.key === "description",
                        }
                      )}
                      onClick={() => handleSort("description")}
                    >
                      Named Insured
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer",
                        {
                          "hover:bg-[#072a48]":
                            sortConfig.key !== "dateCreated",
                          "bg-blue-500": sortConfig.key === "dateCreated",
                        }
                      )}
                      onClick={() => handleSort("dateCreated")}
                    >
                      Details
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItems.map((todo, index) => (
                    <Tr
                      key={todo.id}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          hoveredRow === index ? "#e6f7ff" : "inherit",
                      }}
                    >
                      <Td>{todo.type}</Td>
                      <Td>{todo.namedInsured}</Td>
                      <Td>
                        <DropdownButton
                            variant=""
                            drop="down"
                            style={{ padding: 0 }}
                            title={
                              <div className="flex">
                                <p className="mr-2">View Details</p>
                                <VisibilityIcon className="mt-1" />
                              </div>
                            }
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Dropdown.Item onClick={(e) => {
                              e.stopPropagation();
                              window.open(todo.attachmentGoogleDrive, "_blank")
                            }}>
                              View Pdf
                            </Dropdown.Item>
                            {
                              todo.activePolicy?.id ?
                              <Dropdown.Item onClick={(e) => {
                                e.stopPropagation();
                                handleChangeSendLetterModal(todo)
                              }}>
                                Send Letter
                              </Dropdown.Item> : null
                            }
                            <Tooltip
                              title={
                                <Box>
                                  <Typography variant="body2">Policies:</Typography>
                                  <ul>
                                    {todo.policies.map((policy, index) => (
                                      <li key={index}>
                                        {policy.number} ({policy.date})
                                      </li>
                                    ))}
                                  </ul>
                                </Box>
                              }
                            >  
                              <Dropdown.Item>
                                View Policies
                              </Dropdown.Item>
                            </Tooltip>
                          </DropdownButton>
                        {todo.dateReceived.toString().slice(0, 11)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                pageCount={pageCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </main>
  );
}

export default ToDoList;
