import React, { useState } from "react";
import InsuredInfo from "./InsuredInfo";
import Breadcrumbs from "../assets/Breadcrumbs";
import GoBack from "../generals/GoBack";

export default function InsuredProfile(props) {
  const agencyID = props.agencyID;
  const insuredsID = props.insuredsID;
  const [enableEditing, setEnableEditing] = useState(false);
  const userInfo = props.userInfo;
  const [success, setSuccess] = useState(false);

  const breadcrumbs = [{ label: "Insured" }, { label: "Profile" }];

  return (
    <div className="max-w-[800px] mx-auto bg-white rounded-md shadow-md p-6 my-4">
      <Breadcrumbs items={breadcrumbs} />
      <GoBack
        handleFn={() => props.setDisplayCompanyDetails(false)}
      />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          Insured Profile
        </span>
        {!enableEditing && (
          <button
            type="button"
            className="md:ml-auto md:mt-2 sm:mt-2 bg-[#072a48] hover:bg-[#072a48] text-white py-2 px-4 mb-4 rounded"
            onClick={() => {
              setEnableEditing(true);
              setSuccess(false);
            }}
          >
            Edit Company Info
          </button>
        )}
      </div>
      <p className="mb-4 max-w-[700px]">
        Here you may edit an insured's information. Name changes and address
        changes will trigger an endorsement if the insured has an active policy.
      </p>
      <fieldset>
        <InsuredInfo
          insuredsID={insuredsID}
          agencyID={agencyID}
          success={success}
          setSuccess={setSuccess}
          enableEditing={enableEditing}
          setEnableEditing={setEnableEditing}
          userInfo={userInfo}
        />
      </fieldset>
    </div>
  );
}
