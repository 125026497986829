import React, { useEffect, useState } from "react";
import {
  getDocs,
  addDoc,
  collection,
  query,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import { Col, Container, Row } from "react-bootstrap";
import { USER_INFO_ROLE } from "../../utils/constants";
import useTranslations from "../../Context/actions/useTranslations";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/styles/app.css";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Box, Typography } from "@mui/material";

function ManageAnnouncements(props) {
  const { t } = useTranslations();
  const { ADMIN_ROLE, UNDERWRITER_ROLE } = USER_INFO_ROLE;
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  const newAnnouncement = {
    dateCreated: new Date(),
    authorName: props.name,
    authorID: props.userID,
    subject: subject,
    message: message,
    expiration: dateInput,
  };

  const submitAnnouncement = async () => {
    // Add a new document with a generated id.
    await addDoc(collection(db, "announcements"), newAnnouncement);
    getAnnouncements();
    setDisplayNewAnnouncement(false);
    setMessage("");
    setSubject("");
    setDateInput(new Date());
  };

  const deleteAnnouncement = async (docID) => {
    let verify = prompt(
      "You are about to delete an Announcement! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "announcements", docID));
      getAnnouncements();
    }
  };

  const [announcementList, setAnnouncementList] = useState([]);
  const [gotAnnouncements, setGotAnnouncements] = useState(false);
  const today = new Date();
  const todayInSeconds = today.getTime() / 1000;
  const getAnnouncements = async () => {
    setGotAnnouncements(false);

    const q = query(collection(db, "announcements"));
    const queryData = await getDocs(q);
    setAnnouncementList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotAnnouncements(true);
  };

  const [displayNewAnnouncement, setDisplayNewAnnouncement] = useState(false);

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toDateString();
  };

  useEffect(() => {
    if (gotAnnouncements === false) getAnnouncements();
  }, [gotAnnouncements]);

  return (
    <>
      {(announcementList > 0 || props.role === ADMIN_ROLE) && (
        <main className="max-w-[700px]">
          <div
            className="card-header text-[#072a48]"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                marginRight: "0.5rem",
                marginBottom: "-0.1rem", // Adjust margin here
              }}
            >
              <AnnouncementIcon sx={{ fontSize: "xx-large" }} />
            </Box>
            <Typography variant="h6" component="h6">
              <span className="text-[#072a48] text-2xl font-bold pt-3 mb-2 ml-2">
                {t("account.announcements.title")}
              </span>
            </Typography>
          </div>

          {(props.role === UNDERWRITER_ROLE || props.role === ADMIN_ROLE) && (
            <>
              {displayNewAnnouncement === false && (
                <button
                  className="text-[#072a48] mb-3 ml-2"
                  onClick={(e) => setDisplayNewAnnouncement(true)}
                >
                  {t("account.announcements.add-an-announcement")}
                </button>
              )}
            </>
          )}
          {displayNewAnnouncement === true && (
            <section className="bg-gray-200 p-3 rounded-md mb-5">
              <h2 className="text-xl font-medium mb-2">
                {t("account.announcements.new-announcement")}
              </h2>
              <label className="input-label">
                {t("account.announcements.subject")}
              </label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="text-input w-full"
              />
              <label className="input-label">
                {t("account.announcements.message")}
              </label>
              <textarea
                className="text-input w-full"
                placeholder={t(
                  "account.announcements.announcement-placeholder"
                )}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              <label className="input-label">
                {t("account.announcements.expiration")}
              </label>
              <DatePicker
                wrapperClassName="datePicker"
                selected={dateInput}
                onChange={(date) => {
                  setDateInput(date);
                }}
                className={`text-input  w-full`}
              />
              <button
                onClick={() => {
                  setMessage("");
                  setSubject("");
                  setDateInput(new Date());
                  setDisplayNewAnnouncement(false);
                }}
                className="button-1"
              >
                {t("account.announcements.cancel")}
              </button>
              <button onClick={submitAnnouncement} className="button-2">
                {t("account.announcements.submit")}
              </button>
            </section>
          )}
          {announcementList.length > 0 ? (
            <ul>
              {announcementList.map((announcement, i) => {
                if (announcement.expiration.seconds > todayInSeconds)
                  return (
                    <li key={"announcement" + i} className="mb-3">
                      <Card>
                        <Card.Header>
                          <Container fluid>
                            <Row>
                              <Col xs={12} md={6}>
                                {convertDate(announcement.dateCreated.seconds)}{" "}
                                {t("account.announcements.by")}{" "}
                                {announcement.authorName}{" "}
                              </Col>
                              <Col xs={12} md={6}>
                                <span className="mb-2 md:absolute right-3">
                                  {"("}
                                  {t("account.announcements.expires")}{" "}
                                  {convertDate(announcement.expiration.seconds)}
                                  {")"}
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Header>
                        <Card.Body>
                          <Card.Title>{announcement.subject}</Card.Title>
                          <Card.Text>{announcement.message}</Card.Text>
                          {(props.role === UNDERWRITER_ROLE ||
                            props.role === ADMIN_ROLE) && (
                            <button
                              onClick={(e) =>
                                deleteAnnouncement(announcement.id)
                              }
                              className="py-1 px-2 rounded-md mt-2 bg-[#8B0000] hover:bg-[#8B0000] text-white"
                            >
                              {t("account.announcements.delete")}
                            </button>
                          )}
                        </Card.Body>
                      </Card>
                    </li>
                  );
              })}
            </ul>
          ) : (
            <></>
          )}
        </main>
      )}
    </>
  );
}

export default ManageAnnouncements;
