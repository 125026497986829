import React from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import { Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useTranslations from "../../../Context/actions/useTranslations";
import { numberWithCommas } from "../../../utils/helpers";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function MiniViolationsList({
  dataReady,
  aggregatedDriverInfo,
  violations = [],
}) {
  const { t } = useTranslations();

  const handleDeleteViolation = async (violationId) => {
    console.log("violation id: ", violationId);
    try {
      // Reference to the violation document in Firestore
      const violationDocRef = doc(
        db,
        "loss-control",
        "driverData",
        "violations",
        violationId
      );

      // Delete the violation document
      await deleteDoc(violationDocRef);

      console.log("Violation deleted successfully");
    } catch (error) {
      console.error("Error deleting violation:", error);
    }
  };

  return (
    <div>
      <Table className="mb-4">
        <Thead>
          <Tr>
            <Td className="bg-[#072a48] text-center text-white rounded-tl-md cursor-pointer hover:bg-[#072a48]">
              {t("plm.drivers.details.date")}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              {t("plm.drivers.details.violation-code")}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              {t("plm.drivers.details.claim-amount")}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              {t("plm.drivers.details.classification")}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              {t("plm.drivers.details.category")}
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              {t("plm.drivers.details.case-by-case")}
            </Td>
            <Td className="bg-[#072a48] text-center text-white rounded-tr-md cursor-pointer hover:bg-[#072a48]"></Td>
          </Tr>
        </Thead>
        <Tbody>
          {violations.map((violation, i) => (
            <Tr key={"violation" + i}>
              <Td className="text-center">
                {violation?.inspectionDate.toDate().toString().slice(4, 15)}
              </Td>
              <Td className="text-center">
                <Tooltip title={violation?.description} placement="top">
                  {violation?.violationCode}
                </Tooltip>
              </Td>
              <Td className="text-center">
                $
                {numberWithCommas(
                  (parseInt(violation?.claimAmount) || 0).toFixed(2)
                )}
              </Td>
              <Td className="text-center">{violation?.classification}</Td>
              <Td className="text-center">{violation?.finalClassification}</Td>
              <Td className="text-center">{violation?.special}</Td>
              <Td>
                <IconButton
                  color="error"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this violation?"
                      )
                    ) {
                      handleDeleteViolation(violation.id);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
}
