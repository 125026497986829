import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";

const getViolationsQuery = async (searchType, searchValue, licenseArray) => {
    const violationsRef = collection(
        db,
        "loss-control",
        "driverData",
        "violations"
      );
      let violationsQuerySnapshot;
      if (searchType === "license") {
        violationsQuerySnapshot = await getDocs(
          query(
            violationsRef,
            limit(10),
            where("LicenseNumber", "==", searchValue)
          )
        );
      } else if (searchType === "name") {

      } 
      return violationsQuerySnapshot;
}

export default getViolationsQuery;