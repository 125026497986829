import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../../firebase";

const handleDelete = async (policyID, noteID, collectionPath) => {
    const confirmation = prompt("To confirm deletion, type 'DELETE'");
    if (confirmation === "DELETE") {
      await deleteDoc(
        doc(db, collectionPath, noteID)
      );
    } else {
      alert("Deletion cancelled.");
    }
  };

  export default handleDelete;