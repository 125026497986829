import React from "react";
import {
  ADMIN_ROUTE,
  AGENT_COMPANY_PROFILE_ROUTE,
  AGENT_MANAGE_USERS_ROUTE,
  AGENT_MANAGE_NOTIFICATIONS_ROUTE,
} from "../../../utils/routes";
import { Nav, NavDropdown } from "react-bootstrap";
import { Business, People, Notifications } from '@mui/icons-material';
import useTranslations from "../../../Context/actions/useTranslations";

export default function AgentManageMenu() {
  const { t } = useTranslations();
  return (
    <>
      <NavDropdown.Item href={AGENT_COMPANY_PROFILE_ROUTE}>
        <Business className="mr-2" /> {t("navbar.company")}
      </NavDropdown.Item>
      <NavDropdown.Item href={AGENT_MANAGE_USERS_ROUTE}>
        <People className="mr-2" /> {t("navbar.users")}
      </NavDropdown.Item>
      <NavDropdown.Item href={AGENT_MANAGE_NOTIFICATIONS_ROUTE}>
        <Notifications className="mr-2" /> {t("navbar.notifications")}
      </NavDropdown.Item>
    </>
  );
}