import React, { useState } from "react";
import {
  Button,
  Modal,
  TextField,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import returnToAgent from "../../utils/returnToAgent";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import requestBind from "../../utils/requestBind";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";

const RequestBindModal = ({
  open,
  onClose,
  policyID,
  formData,
  insuredInfo
}) => {
  const policyRef = doc(db, "policy-applications", policyID);
  const { user: userAuth } = UserAuth()
  const { user: userState } = useUsersState()

  const [comments, setComments] = useState([""]);

  const handleAddComment = () => {
    setComments([...comments, ""]);
  };

  const handleChangeComment = (index, value) => {
    const updatedComments = [...comments];
    updatedComments[index] = value;
    setComments(updatedComments);
  };

  const handleRemoveComment = (index) => {
    const updatedComments = [...comments];
    updatedComments.splice(index, 1);
    setComments(updatedComments);
  };

  const handleSubmit = async (e) => {
    // Handle submission logic here
    console.log("Comments:", comments);
    await requestBind(
      e,
      policyID,
      comments,
      formData,
      userAuth,
      userState,
      insuredInfo
    )
    setComments([""]);
    onClose();
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  
  const breadcrumbs = [
    { label: "Policy" },
    { label: "Binding" },
    { label: "Bind Request" },
  ];

  return (
    <Modal open={open} onClose={null} disableBackdropClick={true}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100vh",
            overflowY: "auto",
            width: isMobileScreen ? "100%" : 400,
            height: isMobileScreen ? "100%" : "auto",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
        <Breadcrumbs items={breadcrumbs} />
          <Typography className="mt-2" variant="h6" gutterBottom>
            Bind Request
          </Typography>
          <p className="my-3">Please enter any necessary comments to the underwriter here.</p>
          {comments.map((comment, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <TextField InputLabelProps={{ style: { fontSize: '1.2rem' }}}
                required
                label={`Comment ${index + 1}`}
                value={comment}
                onChange={(e) => handleChangeComment(index, e.target.value)}
                fullWidth
              />
              <IconButton onClick={() => handleRemoveComment(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            onClick={handleAddComment}
            variant="outlined"
            sx={{ mb: 2, color: "#072a48", borderColor: "#072a48" }}
          >
            Add A Comment
          </Button>
          <ApprovalButtons
            onApprove={null}
            onDecline={onClose}
            titleAccept={"Submit"}
            titleDecline={"Cancel"}
            disabledAccept={false}
            disabledDecline={false}
            handleAsSubmit={true}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default RequestBindModal;
