import React, { useState } from "react";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { t } from "i18next";
import MyCheckbox from "../../assets/forms/Checkbox";
import SingleCheckbox from "../../assets/forms/SingleCheckbox";

export default function PrintOptions({
  printOptions,
  setPrintOptions
}) {
  const breadcrumbs = [
    { label: "Policy" },
    { label: "Printing" },
    { label: "Options" },
  ];

  const handleSingleCheckbox = (name) => {
    const updatedOptions = {};
    for (const optionName in printOptions) {
      updatedOptions[optionName] = optionName === name ? true : false;
    }
    setPrintOptions(updatedOptions)
  }
  
  return (
    <div className="max-w-[550px]">
      <Breadcrumbs items={breadcrumbs} />
      <h1 className={`${"text-3xl mt-2 mb-2 font-semibold md:mb-0"}`}>
        {`${t("Printing Options")}`}
      </h1>
      <p className="my-3">
        Make a selection to continue. Printing the entire policy may take a few
        minutes.
      </p>
      <SingleCheckbox
        name='printDecPageOnly'
        label={t("Print Declarations Page Only")}
        checked={printOptions['printDecPageOnly']}
        onChange={() => handleSingleCheckbox('printDecPageOnly')}
      />
      <SingleCheckbox
        name='printEntirePolicy'
        label={t("Print Entire Policy")}
        checked={printOptions['printEntirePolicy']}
        onChange={() => handleSingleCheckbox('printEntirePolicy')}
      />
      <SingleCheckbox
        name='printMCS09'
        label={t("Print MCS-09")}
        checked={printOptions['printMCS09']}
        onChange={() => handleSingleCheckbox('printMCS09')}
      />
    </div>
  );
}
