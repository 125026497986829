import { Timestamp } from "firebase/firestore";
import { USER_INFO_ROLE } from "./constants";
import updatePolicy, {
  updatePrimaryContact,
} from "../services/firebase-api/policies";
import ApiService from "../services/southern-api/apiService";
import axios from "axios";

const { HR_ROLE, CLIENT_ROLE, ADMIN_ROLE, AGENT_ROLE, UNDERWRITER_ROLE } =
  USER_INFO_ROLE;

export const getUserRoleTitle = (userInfo, t) => {
  switch (userInfo.role) {
    case HR_ROLE:
      return t("account.hr");
    case CLIENT_ROLE:
      return `${userInfo.company && userInfo.company}`;
    case ADMIN_ROLE:
      return t("account.admin");
    case AGENT_ROLE:
      return t("account.agent");
    case UNDERWRITER_ROLE:
      return t("account.underwriter");
    default:
      return t("account.user");
  }
};

export const getDashboardTitle = (language, userInfo, t, spanish) => {
  const roleTitle = getUserRoleTitle(userInfo, t);
  if (language === spanish) {
    return `${t("account.dashboard")} ${roleTitle}`;
  }
  return `${roleTitle} ${t("account.dashboard")}`;
};

export const currentDate = () => {
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;
  return date;
};

export const generateRandomUid = (uid) => {
  return (
    Math.floor(Math.random() * 9999999) +
    "-" +
    uid +
    "-" +
    Math.floor(Math.random() * 9999999)
  );
};

export const parsePolicyFields = (data) => {
  if (typeof data === "string" && data.includes(",")) {
    return data.replace(",", ", ");
  } else if (Array.isArray(data)) {
    return data.join(", ");
  } else {
    return data;
  }
};

export const fromNewDateToDDMMYY = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const compareValuesInLowerCases = (staticValue, dynamicValue) => {
  return staticValue.toLowerCase().includes(dynamicValue.toLowerCase());
};

export const searchFilterData = (tableDataToBeFiltered, targetValue) => {
  const values = tableDataToBeFiltered.filter((data) =>
    Object.values(data).some(
      (value) =>
        (value instanceof Date &&
          compareValuesInLowerCases(
            fromNewDateToDDMMYY(new Date(value)),
            targetValue
          )) ||
        (typeof value === "string" &&
          compareValuesInLowerCases(value, targetValue)) ||
        (Array.isArray(value) &&
          compareValuesInLowerCases(value.join(", "), targetValue))
    )
  );
  return values;
};

export const chooseKeysToPickInPayload = (keysToPick, data) => {
  const result = keysToPick.reduce((acc, key) => {
    if (data.hasOwnProperty(key)) {
      acc[key] = data[key];
    }
    return acc;
  }, {});
  return result;
};

export const superSearcherFn = (superEndoSearcher, data, searchTerm) => {
  let found = false;
  if (superEndoSearcher) {
    if (searchTerm) {
      if (typeof data === "object" && !Array.isArray(data)) {
        const licenseNumber = typeof data?.licenseNumber === 'string' ? data?.licenseNumber : "";
        const truckVIN = typeof data?.truckVIN === 'string' ? data?.truckVIN : "";
        const trailerVIN = typeof data?.trailerVIN === 'string' ? data?.trailerVIN : "";
        const driverFirst = typeof data?.driverFirst === 'string' ? data?.driverFirst : "";
        const driverLast = typeof data?.driverLast === 'string' ? data?.driverLast : "";
        found = Boolean(
          licenseNumber?.toLowerCase()?.includes(searchTerm) ||
            truckVIN?.toLowerCase()?.includes(searchTerm) ||
            trailerVIN?.toLowerCase()?.includes(searchTerm) ||
            driverFirst?.toLowerCase()?.includes(searchTerm) ||
            driverLast?.toLowerCase()?.includes(searchTerm)
        );
      }
      if (!found && Array.isArray(data)) {
        const filteredData = data.filter((info) => {
          const licenseNumber = typeof info?.["licenseNumber"] === 'string' ? info?.["licenseNumber"] : "";
          const truckVIN = typeof info?.["truckVIN"] === 'string' ? info?.["truckVIN"] : "";
          const trailerVIN = typeof info?.["trailerVIN"] === 'string' ? info?.["trailerVIN"] : "";
          const driverFirst = typeof info?.['driverFirst'] === 'string' ? info?.['driverFirst'] : "";
          const driverLast = typeof info?.['driverLast'] === 'string' ? info?.['driverLast'] : "";
          return Boolean(
            licenseNumber?.toLowerCase()?.includes(searchTerm) ||
              truckVIN?.toLowerCase()?.includes(searchTerm) ||
              trailerVIN?.toLowerCase()?.includes(searchTerm) ||
              driverFirst?.toLowerCase()?.includes(searchTerm) ||
              driverLast?.toLowerCase()?.includes(searchTerm)
          );
        });
        found = filteredData.length > 0;
      }
    }
  }
  return found;
};

export const numberWithCommas = (value) => {
  if (!value) return;
  value = value.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(value)) value = value.replace(pattern, "$1,$2");
  return value;
};

export const convertISOToFirestoreFormat = (isoString) => {
  const date = new Date(isoString);
  const timestamp = Timestamp.fromDate(date);
  return timestamp;
};

export const convertDDMMYYYToFirestoreFormat = (dateString) => {
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!regex.test(dateString)) {
    return "error";
  }
  const [, day, month, year] = dateString.match(regex);
  const timestamp = Timestamp.fromDate(new Date(`${year}-${month}-${day}`));
  return timestamp;
};

export const convertFirestoreToHumanDate = (firestoreTimestamp) => {
  const milliseconds = firestoreTimestamp.seconds * 1000;
  const date = new Date(milliseconds);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year}`;
  return formattedDate;
};

export const convertTimestampToFirestoreFormat = (timestamp) => {
  const firestoreTimestamp = new Timestamp(Math.floor(timestamp / 1000), 0);
  return firestoreTimestamp;
};

export const convertDateToTimestamp = (date) => {
  const firestoreTimestamp = Timestamp.fromDate(date);
  return firestoreTimestamp.seconds;
};

export const convertDateToFirestoreFormat = (date) => {
  const firestoreTimestamp = Timestamp.fromDate(date);
  return firestoreTimestamp;
};

export const formatDateToDDMMYYYY = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const removeLastS = (value) => {
  if (value.charAt(value.length - 1) === "s") {
    return value?.slice(0, -1).replaceAll("-", " ");
  }
  return value?.replaceAll("-", " ");
};

export const convertToNestedObject = (
  references,
  nestedObject,
  existingObject
) => {
  const referencesArray = references.split(".");
  const lastReference = referencesArray.pop();
  let currentObject = existingObject;
  referencesArray.forEach((reference) => {
    if (!currentObject[reference]) {
      currentObject[reference] = {};
    }
    currentObject = currentObject[reference];
  });
  currentObject[lastReference] = nestedObject;
  return { ...existingObject };
};

export const convertToNestedString = (keys, formData) => {
  if (keys && formData) {
    const split = keys?.split(".");
    const value = split?.reduce((obj, ref) => obj?.[ref], formData);
    return value;
  }
  return "";
};

export const handleDynamicQuestionnaireChange = async (
  policyID,
  fieldName,
  value,
  formData,
  setFormData,
  setFormSaved,
  doNotDebounce,
  isPrimaryContact
) => {
  try {
    setFormSaved(false);
    const nestedObject = convertToNestedObject(fieldName, value, formData);
    const newPayload = {
      ...formData,
      ...nestedObject,
    };
    if (setFormData) {
      setFormData(newPayload);
    }
    if (!doNotDebounce) {
      if (isPrimaryContact) {
        await updatePrimaryContact(
          formData.agencyID,
          formData.insuredID,
          value
        );
      } else {
        await updatePolicy(policyID, nestedObject);
      }
    }
    setFormSaved(true);
  } catch (error) {
    throw error;
  }
};

const validateFilename = (filename) => {
  const regex = /^[\w]+(-[\w]+)?\.[a-zA-Z0-9]+$/;
  return regex.test(filename);
};

const removeExtension = (filename) => {
  return filename.replace(/\.[a-zA-Z0-9]+$/, "");
};

const separateFilename = (filename) => {
  if (filename.includes("-")) {
    const parts = filename.split(/-(.+)/);
    const firstPart = parts[0];
    const secondPart = parts[1];
    return {
      firstPart,
      secondPart,
    };
  } else {
    return {
      firstPart: filename,
      secondPart: "",
    };
  }
};

export const handleMultiUploading = async (
  e,
  property,
  storageFile,
  valueToCompare,
  list
) => {
  const apiService = new ApiService("southern");
  try {
    const files = e.target?.files;
    if (!files || files.length === 0) return;
    const newFiles = [];
    const uploadTasks = Array.from(files).map((file) => {
      return new Promise((resolve, reject) => {
        const propertyFolder = property.replace(".", "/");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          let base64String = reader.result;
          if (base64String.includes("application/pdf")) {
            base64String = base64String.replace(/^data:.+;base64,/, "");
          }
          const filename = file?.name;
          let matchElem = null
          const { firstPart } = separateFilename(filename);
          if (valueToCompare) {
            const match = list?.find(
              (elem) => elem[valueToCompare] === firstPart
            );
            if (!match) {
              reject(
                `${valueToCompare} ${firstPart} does not exist in this policy`
              );
            } else {
              matchElem = match
            }
          }
          const fileData = {
            filename: removeExtension(filename),
            file: base64String,
            fileRoute: `${storageFile}/${propertyFolder}`,
            valueCompared: firstPart || null,
            matchElem
          };
          newFiles.push(fileData);
          resolve(fileData);
        };
        reader.onerror = reject;
      });
    });
    const uploadData = await Promise.all(uploadTasks);
    const downloadURLs = await Promise.all(
      uploadData.map(async (uploadTask) => {
        if (uploadTask?.file?.includes("application/pdf")) {
          uploadTask["file"] = uploadTask["file"].replace(
            "data:application/pdf;base64,",
            ""
          );
        }
        const result = await apiService.base64ToStorage({
          file: uploadTask.file,
          fileRoute: uploadTask.fileRoute,
          filename: uploadTask.filename,
        });
        return {
          ...result,
          valueCompared: uploadTask.valueCompared,
          matchElem: uploadTask.matchElem
        }
      })
    );
    return downloadURLs;
  } catch (error) {
    throw error;
  }
};

export const handleDeleteFilesFromStorage = async (fileUrls) => {
  const apiService = new ApiService("southern");
  const deletedUrls = await Promise.all(
    fileUrls.map((fileUrl) => {
      return apiService.deleteFileFromStorage(fileUrl);
    })
  );
  return deletedUrls;
};

export const validateEndorsementNumber = (type) => {
  let code = "";
  switch (type) {
    case "Add Driver":
      code = "AD";
      break;
    case "Add Drivers":
      code = "AD";
      break;
    case "Remove Driver":
      code = "DD";
      break;
    case "Remove Drivers":
      code = "DD";
      break;
    case "Exclude Driver":
      code = "ED";
      break;
    case "Exclude Drivers":
      code = "ED";
      break;
    case "Add Power Unit":
      code = "AU";
      break;
    case "Add Power Units":
      code = "AU";
      break;
    case "Remove Power Unit":
      code = "DU";
      break;
    case "Remove Power Units":
      code = "DU";
      break;
    case "Adjust Radius":
      code = "AR";
      break;
    case "Add Waiver Of Subrogation":
      code = "AW";
      break;
    case "Add Trailer":
      code = "AT";
      break;
    case "Add Trailers":
      code = "AT";
      break;
    case "Remove Trailer":
      code = "DT";
      break;
    case "Remove Trailers":
      code = "DT";
      break;
    default:
      code = "";
      break;
  }
  return code;
};

export const isExpired = (expirationDate) => {
  if (!expirationDate || (expirationDate && !expirationDate.seconds))
    return false;
  const expirationTimestamp = new Date(expirationDate.seconds * 1000);
  const currentDate = new Date();
  return currentDate > expirationTimestamp;
};

export const endsWithLowerCaseS = (value) => {
  return Boolean(value?.endsWith("s"));
};

export const setPrevRoute = (currentPath) => {
  const previousRoute = localStorage.getItem("previousRoute");
  if (previousRoute !== currentPath)
    localStorage.setItem("previousRoute", currentPath);
  return previousRoute;
};

export const toCamelCase = (str) => {
  const words = str.split(" ");
  const result = [words[0].toLowerCase()];
  for (let i = 1; i < words.length; i++) {
    result.push(
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase()
    );
  }
  return result.join("");
};

export const isPastSomeMinutes = (dateToCompare) => {
  if (!dateToCompare) {
    return true;
  }
  const year = parseInt(dateToCompare.slice(0, 4));
  const month = parseInt(dateToCompare.slice(5, 7)) - 1;
  const day = parseInt(dateToCompare.slice(8, 10));
  const hour = parseInt(dateToCompare.slice(11, 13));
  const minute = parseInt(dateToCompare.slice(14, 16));
  const second = parseInt(dateToCompare.slice(17, 19));
  const date1 = new Date(year, month, day, hour, minute, second);
  const date2 = new Date();
  const diffInMilliseconds = date2.getTime() - date1.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60);
  return diffInMinutes >= 1;
};

export const parseStaticDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const staticDate = new Date(Date.UTC(year, month, day, hour, minute, second));
  return staticDate.toISOString();
};
