import calculatePremiums from "../../../utils/calculatePremiums";
import updatePolicyField from "../../../utils/updatePolicyField";

const applyAdjustments = async (e, quoteData, policyID, formData, taxSchedule) => {
    e.preventDefault();
    console.log(quoteData.adjustmentFormData.percentage);
    const totalAdjustment =
      ((parseFloat(quoteData.adjustmentFormData.percentage) +
        parseFloat(quoteData.adjustmentFormData.renewalCredit) +
        parseFloat(quoteData.adjustmentFormData.lossRatioAdjustmentPercentage) +
        parseFloat(quoteData.adjustmentFormData.safetyAdjustmentPercentage)) /
        100) *
      (quoteData.adjustmentFormData.debitOrCredit === "Credit" ? -1 : 1);
      console.log(totalAdjustment)

    await calculatePremiums(
      policyID,
      formData,
      e,
      quoteData.id,
      totalAdjustment,
      false,
      false
    );

    document.getElementById("premiums").scrollIntoView({
      behavior: "smooth",
    });

    quoteData.adjustmentFormData.deductible &&
      (await updatePolicyField(
        policyID,
        "biLiabilityDeductible",
        quoteData.adjustmentFormData.deductible
      ));
  };

export default applyAdjustments;