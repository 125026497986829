import React, { useEffect, useState } from "react";
import DocumentsTable from "../../DocumentsTable";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import ApiService from "../../../services/southern-api/apiService";
import useGeneralState from "../../../Context/actions/useGeneralState";

export default function Documents({
  policyID,
  formData,
  insuredInfo
}) {

  const { controlToast } = useGeneralState()

  const [saferReportFiles, setSaferReportFiles] = useState([]);
  const [cabReportFiles, setCabReportFiles] = useState([]);
  const [signedApplicationFiles, setSignedApplicationFiles] = useState([]);
  const [ssmImportListFiles, setSsmImportListFiles] = useState([]);
  const [additionalDocumentsFiles, setAdditionalDocumentsFiles] = useState([]);

  const handlePolicySnapshot = async () => {
    const policySnapshot = await getDoc(
      doc(db, "policy-applications", policyID)
    );
    return {
      ...policySnapshot.data(),
      id: policySnapshot.id,
    }
  }

  useEffect(() => {
    handlePolicySnapshot().then((policyData) => {
      setSaferReportFiles(policyData.saferReport ? [policyData.saferReport] : []);
      setCabReportFiles(policyData.cabReport ? [policyData.cabReport] : []);
      setSignedApplicationFiles(policyData.signedApplication ? [policyData.signedApplication] : []);
      setSsmImportListFiles(policyData.ssmImportList ? [policyData.ssmImportListF] : []);
      setAdditionalDocumentsFiles(policyData.additionalDocuments ? policyData.additionalDocuments : []);
    })
  }, [])

  const handleSubmit = async (e) => {
    try {
      if (e) e?.preventDefault();
      const payload = {}
      payload["saferReport"] = saferReportFiles[0] ? saferReportFiles[0] : '';
      payload["cabReport"] = cabReportFiles[0] ? cabReportFiles[0] : '';
      payload["signedApplication"] = signedApplicationFiles[0] ? signedApplicationFiles[0] : '';
      payload["ssmImportList"] = ssmImportListFiles[0] ? ssmImportListFiles[0] : '';
      payload["additionalDocuments"] = additionalDocumentsFiles[0] ? additionalDocumentsFiles : [];
      await setDoc(
        doc(db, "policy-applications", policyID),
        payload,
        { merge: true }
      );
    } catch (error) {
      throw error;
    }
  };

  const [didRender, setDidRender] = useState(false)

  useEffect(() => {
    if (!didRender) {
      setDidRender(true)
      return
    }
    handleSubmit()
  }, [
    saferReportFiles,
    cabReportFiles,
    signedApplicationFiles,
    ssmImportListFiles,
    additionalDocumentsFiles
  ])

  const apiService = new ApiService("southern");

  const handleApiRequestFileFn = async (e) => {
    e.preventDefault()
    try {
      controlToast(true, "Generating Safer Report, wait a moment", "info");
      const result = await apiService.getSaferWithDotScreenshot({
        dot: insuredInfo?.dot,
        policyID
      })
      const screenshot = result.screenshot
      setSaferReportFiles([screenshot])
      const policyRef = doc(db, "policy-applications", policyID);
      await setDoc(policyRef, {
        saferReport: screenshot,
      }, { merge: true });
      controlToast(true, "Safer Report Generated Successfully", "success");
    } catch (error) {
      controlToast(true, "Error Generating Safer Report", "error");
      console.error("Error Generating Safer Report", error);
    }
  }

  const documentUploadInfo = [
    {
      name: "Generate SAFER Report",
      key: "saferReport",
      files: saferReportFiles,
      setFiles: setSaferReportFiles,
      storageFile: `files/policies/${policyID}/application-docs`,
      apiRequestFileFn: handleApiRequestFileFn,
    },
    {
      name: "CAB Report",
      URL: "https://subscriber.cabadvantage.com/CABreport.cfm?dot=3462751",
      key: "cabReport",
      files: cabReportFiles,
      setFiles: setCabReportFiles,
      storageFile: `files/policies/${policyID}/application-docs`,
    },
    {
      name: "Signed Application",
      key: "signedApplication",
      files: signedApplicationFiles,
      setFiles: setSignedApplicationFiles,
      storageFile: `files/policies/${policyID}/application-docs`,
    },
    {
      name: "SSM Import List",
      key: "driverAndEquipmentList",
      files: ssmImportListFiles,
      setFiles: setSsmImportListFiles,
      storageFile: `files/policies/${policyID}/application-docs`,
    },
    {
      name: "Additional Documents",
      key: "additionalDocuments",
      files: additionalDocumentsFiles,
      setFiles: setAdditionalDocumentsFiles,
      storageFile: `files/policies/${policyID}/application-docs`,
      multiple: true,
    }
  ];

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Documents" },
  ];

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="text-3xl mt-2 font-semibold md:mb-0">Documents</h1>
      <p className="my-3">
        {formData.documentType === "Application" && (
          <>
            The following checklist of documents is necessary for underwriting
            this policy.
          </>
        )}
        {formData.documentType !== "Application" && (
          <>Documents may only be edited during the application phase.</>
        )}
      </p>
      <div className="mb-4 max-w-[850px] mx-auto">
      <DocumentsTable
        documentUploadInfo={documentUploadInfo}
      /></div>
    </div>
  );
}
