import React, { useEffect, useState } from "react";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

export default function InsuredInfo(props) {
  //   const policyID = props.policyID;
  const userInfo = props.userInfo;
  const agencyID = props.agencyID;
  const enableEditing = props.enableEditing;
  const setEnableEditing = props.setEnableEditing;
  const success = props.success;
  const setSuccess = props.setSuccess;
  const insuredsID = props.insuredsID;

  const [formData, setFormData] = useState({});

  const saveForm = async (e) => {
    e.preventDefault();
    const applicationRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredsID
    );
    setDoc(applicationRef, formData, { merge: true });
    setEnableEditing(false);
    setSuccess(true);
  };

  const getCompanyInfo = (agencyID) => {
    // Check if agencyID is defined before creating the docRef
    if (!agencyID) {
      return () => {}; // Return an empty function if agencyID is undefined
    }

    const docRef = doc(db, "agencies", agencyID, "insureds", insuredsID);

    // Create a real-time listener
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setFormData({ ...docSnap.data(), id: docSnap.id });
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    });

    // Return the unsubscribe function to detach the listener when needed
    return unsubscribe;
  };

  useEffect(() => {
    // Call the function and store the unsubscribe function
    const unsubscribe = getCompanyInfo(agencyID);

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [agencyID]);

  return (
    <>
      <fieldset disabled={!enableEditing}>
        <form onSubmit={(e) => saveForm(e)}>
          {/* Data Fields */}
          <div>
            <div className="mb-2">
              <label
                htmlFor="company"
                className="block text-sm font-medium text-gray-600"
              >
                Company Name:
              </label>
              <input
                required
                name="company"
                type="text"
                value={formData.company}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-600"
              >
                Company E-Mail Address:
              </label>
              <input
                required
                name="email"
                type="text"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="primaryContact"
                className="block text-sm font-medium text-gray-600"
              >
                Primary Contact Full Name:
              </label>
              <input
                required
                name="primaryContact"
                type="text"
                value={formData.primaryContact}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="contactEmail"
                className="block text-sm font-medium text-gray-600"
              >
                Primary Contact E-Mail:
              </label>
              <input
                required
                name="contactEmail"
                type="text"
                value={formData.contactEmail}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            
            <div className="my-4">
              <div className="mb-2">
                <label
                  htmlFor="mailingAddress"
                  className="block text-sm font-medium text-gray-600"
                >
                  Mailing Address (MA):
                </label>
                <input
                  required
                  name="mailingAddress"
                  type="text"
                  value={formData.mailingAddress}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="mailingCity"
                    className="block text-sm font-medium text-gray-600"
                  >
                    MA City:
                  </label>
                  <input
                    required
                    name="mailingCity"
                    type="text"
                    value={formData.mailingCity}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div>
                  <label
                    htmlFor="mailingState"
                    className="block text-sm font-medium text-gray-600"
                  >
                    MA State:
                  </label>
                  <select
                    required
                    name="mailingState"
                    value={formData.mailingState}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="">Select a state</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div className="mb-2 grid grid-cols-3 gap-4">
                <div>
                  <label
                    htmlFor="mailingZip"
                    className="block text-sm font-medium text-gray-600"
                  >
                    MA Zip Code:
                  </label>
                  <input
                    required
                    name="mailingZip"
                    type="text"
                    value={formData.mailingZip}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div className="mb-2">
                  <label
                    htmlFor="countryCode"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Company Country Code:
                  </label>
                  <select
                    required
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="+1">USA (+1)</option>
                    <option value="+52">Mexico (+52)</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Company Phone Number:
                  </label>
                  <input
                    required
                    name="phone"
                    type="text"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>
              </div>
              <div className="mb-2 grid grid-cols-2 gap-4">
                <div className="mb-2">
                  <label
                    htmlFor="contactCountryCode"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Contact Country Code:
                  </label>
                  <select
                    required
                    name="contactCountryCode"
                    value={formData.contactCountryCode}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="+1">USA (+1)</option>
                    <option value="+52">Mexico (+52)</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="contactPhone"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Contact Phone Number:
                  </label>
                  <input
                    required
                    name="contactPhone"
                    type="text"
                    value={formData.contactPhone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>
              </div>
            </div>

            <div className="my-4">
              <div className="mb-2">
                <label
                  htmlFor="physicalAddress"
                  className="block text-sm font-medium text-gray-600"
                >
                  Physical Address (PA):
                </label>
                <input
                  required
                  name="physicalAddress"
                  type="text"
                  value={formData.physicalAddress}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="physicalCity"
                    className="block text-sm font-medium text-gray-600"
                  >
                    PA City:
                  </label>
                  <input
                    required
                    name="physicalCity"
                    type="text"
                    value={formData.physicalCity}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div>
                  <label
                    htmlFor="physicalState"
                    className="block text-sm font-medium text-gray-600"
                  >
                    PA State:
                  </label>
                  <select
                    required
                    name="physicalState"
                    value={formData.physicalState}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="">Select a state</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div className="mb-2 grid grid-cols-3 gap-4">
                <div>
                  <label
                    htmlFor="physicalZip"
                    className="block text-sm font-medium text-gray-600"
                  >
                    PA Zip Code:
                  </label>
                  <input
                    required
                    name="physicalZip"
                    type="text"
                    value={formData.physicalZip}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>
              </div>
            </div>

            <div className="mb-2">
              <label
                htmlFor="businessStructure"
                className="block text-sm font-medium text-gray-600"
              >
                Business Structure:
              </label>
              <select
                required
                name="businessStructure"
                value={formData.businessStructure}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Choose One</option>
                <option value="Sole Proprietorship">Sole Proprietorship</option>
                <option value="Partnership">Partnership</option>
                <option value="LLC">LLC</option>
                <option value="Corporation">Corporation</option>
              </select>
            </div>

            <div className="mb-2 grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="federalTaxID"
                  className="block text-sm font-medium text-gray-600"
                >
                  Federal Tax ID:
                </label>
                <input
                  required
                  name="federalTaxID"
                  type="text"
                  value={formData.federalTaxID}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div>
                <label
                  htmlFor="yearEstablished"
                  className="block text-sm font-medium text-gray-600"
                >
                  Year Established:
                </label>
                <input
                  required
                  name="yearEstablished"
                  type="number"
                  value={formData.yearEstablished}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              {/* <div>
                <label
                  htmlFor="years"
                  className="block text-sm font-medium text-gray-600"
                >
                  Years:
                </label>
                <input
                  required
                  name="years"
                  type="number"
                  value={formData.years}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div> */}
            </div>
          </div>

          {/* Submit/Cancel Buttons */}
          {enableEditing && (
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-[#072a48] focus:outline-none focus:ring focus:border-blue-300"
              >
                Submit
              </button>
              <button
                type="button"
                className="ml-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:border-gray-500"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({});
                  setEnableEditing(false);
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      </fieldset>
      {success && (
        <main className="bg-green-100 p-6 rounded-md shadow-md text-green-700">
          <p className="text-2xl font-semibold mb-4">
            You successfully updated your company information
          </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              setSuccess(false);
            }}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            OK
          </button>
        </main>
      )}
    </>
  );
}
