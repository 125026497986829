import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { setPolicyInfo } from "../PolicyLifecycleManager/Drivers/setPolicyInfo";
import ApiService from "../../services/southern-api/apiService";

const apiService = new ApiService();

export const validateIfBlacklisted = async (drivers, validateCurrentUser, currentUser) => {

  let queryResults = []

  if (validateCurrentUser && !currentUser) {
    const res = await apiService.renewalProcessDriversBlacklist()
    queryResults = res?.drivers
  } else {
    const q = query(collection(db, "drivers-blacklist"));
    const queryData = await getDocs(q);
    queryResults = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  }

  const driversWithBlacklistStatus = [];
  for (const driverData of drivers) {
    let blacklistExpirationDate = '';
    const blacklisted = queryResults.some((blacklistData) => {
      const licenseNumberMatch = blacklistData.licenseNumber === driverData.licenseNumber;
      const expirationDateIsOver =
        blacklistData.blacklistExpirationDate ?
        blacklistData.blacklistExpirationDate.toDate() < new Date() : true;
      const isBlacklisted = licenseNumberMatch && !expirationDateIsOver;
      if (licenseNumberMatch) {
        blacklistExpirationDate = licenseNumberMatch ? blacklistData.blacklistExpirationDate : ''
      }
      return isBlacklisted
    });
    const driversWithBlacklistStatusObj = {
      blacklist: blacklisted,
      ...driverData,
    }
    if (blacklistExpirationDate) {
      driversWithBlacklistStatusObj['blacklistExpirationDate'] = blacklistExpirationDate;
    }
    driversWithBlacklistStatus.push(driversWithBlacklistStatusObj);
  }
  return driversWithBlacklistStatus;
};

export const validateIfExists = async (
  subcollectionInfo,
  agencyID,
  insuredID,
  subcollection
) => {
  const isTemporalUrl =
      typeof window !== 'undefined' ?
      window?.location?.pathname?.includes('temporarily') : false

  let queryResults
  if (!isTemporalUrl) {
    const q = query(
      collection(db, "agencies", agencyID, "insureds", insuredID, subcollection)
    );
    const queryData = await getDocs(q);
    queryResults = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  } else {
    const result = await apiService.renewalProcessDrivers({ agencyID, insuredID })
    queryResults = result?.drivers
  }
  
  const subcollectionInfoVerified = [];
  for (const subcollectionData of subcollectionInfo) {
    const existingValidator = queryResults.some((existingData) => {
      return Boolean(
        (existingData.licenseNumber &&
          subcollectionData.licenseNumber &&
          existingData.licenseNumber === subcollectionData.licenseNumber) ||
          (existingData.truckVIN &&
            subcollectionData.truckVIN &&
            existingData.truckVIN === subcollectionData.truckVIN) ||
          (existingData.trailerVIN &&
            subcollectionData.trailerVIN &&
            existingData.trailerVIN === subcollectionData.trailerVIN)
      );
    });
    if (existingValidator) {
      const matchedSubcollectionData = queryResults.find((result) =>
        Boolean(
          (result.licenseNumber &&
            subcollectionData.licenseNumber &&
            result.licenseNumber === subcollectionData.licenseNumber) ||
            (result.truckVIN &&
              subcollectionData.truckVIN &&
              result.truckVIN === subcollectionData.truckVIN) ||
            (result.trailerVIN &&
              subcollectionData.trailerVIN &&
              result.trailerVIN === subcollectionData.trailerVIN)
        )
      );
      subcollectionInfoVerified.push({
        itExists: true,
        id: matchedSubcollectionData.id,
        ...matchedSubcollectionData,
        ...subcollectionData,
      });
    } else {
      subcollectionInfoVerified.push({
        itExists: false,
        id: null,
        ...subcollectionData,
      });
    }
  }
  return subcollectionInfoVerified;
};

export const selectIfNewOrExistingSubcollection = async (
  payload,
  agencyID,
  insuredID,
  subcollection
) => {
  let response;

  const isTemporalUrl =
      typeof window !== 'undefined' ?
      window?.location?.pathname?.includes('temporarily') : false

  if (Boolean(payload?.itExists)) {
    delete payload.itExists;
    delete payload.blacklist;

    if (!isTemporalUrl) {
      await setDoc(
        doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          subcollection,
          payload?.id
        ),
        payload,
        { merge: true }
      );
    } else {
      await apiService.renewalProcessUpdateDriver({
        agencyID,
        insuredID,
        driverID: payload?.id,
        payload
      })
    }

    response = payload;
  } else {
    delete payload.itExists;
    delete payload.blacklist;
    delete payload.id;

    if (!isTemporalUrl) {
      response = await addDoc(
        collection(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          subcollection
        ),
        payload
      );
    } else {
      response = await apiService.renewalProcessCreateDriver({
        agencyID,
        insuredID,
        driverID: payload?.id,
        payload
      })
    }

  }
  return response;
};

export const selectIfDeleteOrExistingSubcollection = async (
  payload,
  agencyID,
  insuredID,
  subcollection,
  policyID,
  renewalProcess,
  documentTypeToUse,
  formData,
  typeOfDeletionIfDeletion
) => {
  try {
    const payloadResult = setPolicyInfo(
      {
        ...payload,
        dateCreated: new Date(),
      },
      {
        ...formData,
        policyEffectiveDate: payload.effectiveDate,
        glPolicyNo: formData?.glPolicyNo,
        alPolicyNo: formData?.alPolicyNo,
        policyID,
        status:
          typeOfDeletionIfDeletion === 'exclusion' ?
            "Pending Exclusion" :
          !renewalProcess && documentTypeToUse === "Application"
            ? "Deleted"
            : "Pending Deletion",
      },
      !renewalProcess && documentTypeToUse === "Application"
        ? 'direct-deletion' : 'endo-deletion'
    );
    delete payloadResult.itExists;
    
    const isTemporalUrl =
      typeof window !== 'undefined' ?
      window?.location?.pathname?.includes('temporarily') : false

    if (!isTemporalUrl) {
      await setDoc(
        doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          subcollection,
          payloadResult?.id
        ),
        payloadResult,
        { merge: true }
      );
    } else {
      await apiService.renewalProcessUpdateDriver({
        agencyID,
        insuredID,
        driverID: payloadResult?.id,
        payload: payloadResult
      })
    }
    return payloadResult;
  } catch (error) {
    console.error('delete or existing subcollection', error);
  }
};

export const validateExistingVins = async (
  VIN,
  subcollection,
  fieldName
) => {
  try {
    const elemsData = [];
    const q = query(
      collectionGroup(db, subcollection),
      where(fieldName, "==", VIN)
    );
    const elemSnapshot = await getDocs(q);
    for (let doc of elemSnapshot.docs) {
      elemsData.push({
        ...doc.data(),
        id: doc.id,
      });
    }
    return elemsData
  } catch (error) {
    console.error("Error getting power units: ", error);
  }
}

export const validateIfVinExistsInAnotherPolicy = async (
  VIN,
  subcollection,
  fieldName,
  originalPolicyID
) => {
  try {
    const elemData = await validateExistingVins(
      VIN,
      subcollection,
      fieldName
    )
    const policiesDetails = elemData.map((detail) => {
      return detail.policiesDetails
    }).flatMap((detail) => detail)
    const details = policiesDetails.filter((detail) => {
      return Boolean(
        detail.id !== originalPolicyID &&
        detail.documentType === 'Policy' &&
        detail.status === 'Active'
      )
    })

    const newDetails = []
    for (let detail of details) {
      const { agencyID, insuredID } = detail
      const insuredDocs = await getDoc(
        doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID
        )
      );
      const insuredData = {
        ...insuredDocs.data(),
        id: insuredDocs.id
      }
      newDetails.push({
        ...detail,
        insuredData
      })
    }

    return newDetails
  } catch (error) {
    console.error("Error filtering policies details: ", error);
  }
}