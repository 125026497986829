import React, { useMemo, useState } from "react";
import TruckList from "./TruckList";
import AddTruck from "./AddTruck";
import ImportPowerUnits from "./ImportTrucks";
import BlueButton from "../../assets/forms/BlueButton";
import RedButton from "../../assets/forms/RedButton";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { isExpired } from "../../../utils/helpers";
import useTrucksList from "./useTrucksList";
import FormPopup from "../../generals/Popup/FormPopup";
import { useMediaQuery } from "@mui/material";
import { generateXlsxFile } from "../../../utils/excelCreator";
import { Download as DownloadIcon } from "@mui/icons-material";
import { filterRightPoliciesDetails } from "../Drivers/setPolicyInfo";
import { handleDropdownPowerUnit } from "../../assets/lists/DynamicTable/utils";
import ImportAllLists from "../Drivers/ImportAllLists";
import useTranslations from "../../../Context/actions/useTranslations";
import ImportInspas from "../Drivers/ImportInspas";
import DropdownPowerUnitMultiUpload
  from "../../assets/forms/DynamicPropertiesInputs/DropdownPowerUnitMultiUpload";

export default function PolicyTrucks(props) {
  const {
    policyID,
    formData,
    userInfo,
    truckList,
    isEndoManager,
    getTruckList,
    setFormData,
    rerateRequired,
    setRerateRequired,
  } = props;

  const [display, setDisplay] = useState(true);
  const [showAddTruck, setShowAddTruck] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const truckHook = useTrucksList(props);
  const { setDeletionMode, handleSubmit, deletionMode } = truckHook;

  const { t } = useTranslations()

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Power Units" },
  ];

  const count = useMemo(() => {
    const effectiveElementsList = truckList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const counter = effectiveElementsList?.length
      ? effectiveElementsList.length
      : 0;
    return counter;
  }, [truckList]);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const activeList = truckList?.filter(elem => Boolean(
    filterRightPoliciesDetails(elem.policiesDetails, policyID)
      ?.status === 'Active'
  ))

  const handleImportFn = () => {
    setShowImport(true);
    setShowTable(false);
  }

  const [showImportAll, setShowImportAll] = useState(false)
  const [showImportInspas, setShowImportInspas] = useState(false)

  const handleImportAll = () => {
    setShowImportAll(true)
    setShowTable(false);
  }

  const handleImportInspas = () => {
    setShowImportInspas(true)
    setShowTable(false);
  }

  const titleDocumentType = formData?.documentType || ''

  return (
    <>
      {!showAddTruck && !showImport && (
        <>
          {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
          <h1
            className={`${
              isEndoManager
                ? "text-2xl mt-2.5 font-bold md:mb-0"
                : "text-3xl mt-2 font-semibold md:mb-0"
            }`}
          >
            {!isEndoManager
              ? `${
                  deletionMode
                    ? "Delete Power Units"
                    : `${titleDocumentType} Power Units`
                } ${deletionMode ? "" : `(${count})`}`
              : `${
                  deletionMode
                    ? "Delete Power Units"
                    : "Endorse Power Units"
                }`}
          </h1>
          <p className="my-3">
            {(formData.documentType === "Application" ||
              formData.documentType === "Policy") &&
              !deletionMode && (
                <>Here you may view, add, and remove power units.</>
              )}
            {deletionMode && (
              <p>
                Select power units from the bottom list to add to the deletion list. Click submit once you have selected all necessary power units.
              </p>
            )}
            {formData.documentType !== "Application" &&
              formData.documentType !== "Policy" && (
                <>Units may not be edited during the quoting phase.</>
              )}
          </p>
        </>
      )}
      {display && (
        <div className={`card-body ${isEndoManager ? "p-0" : ""}`}>
          {!showImport && !showImportAll && !showAddTruck && (
            <>
              {(formData.documentType === "Application" ||
                formData.documentType === "Policy") && (
                <div className="my-3 flex items-center flex-wrap">
                  <BlueButton
                    text="Add Unit"
                    onClickFunction={(e) => {
                      setShowAddTruck(true);
                      setShowTable(false);
                    }}
                    disabled={deletionMode}
                    hasContentToBottom={true}
                    hasContentToRight={!isMobileScreen}
                    className={isMobileScreen ? "w-[100%]" : ""}
                  />
                  <DropdownPowerUnitMultiUpload
                    containerStyle={`bg-transparent border-none p-0
                      ${isMobileScreen ? "w-[100%]" : ""}
                      ${isMobileScreen ? 'dropdown-multiupload' : ''}
                    `}
                    wrapperStyle={`
                      text-[#8B0000] hover:text-[#8B0000] d-inline
                      ${isMobileScreen ? "w-[100%]" : ""}
                    `}
                    handleDropdown={() => handleDropdownPowerUnit(
                      null,
                      "import",
                      "power-units",
                      handleImportFn,
                      handleImportAll,
                      handleImportInspas,
                      t
                    )}
                    formData={formData}
                    disabled={deletionMode}
                    item={null}
                    icon="import"
                    dropdownType="import"
                    list={truckList}
                  />
                  {activeList?.length > 0 ?
                    <BlueButton
                      text={'Export'}
                      onClickFunction={(e) => {
                        e.preventDefault()
                        generateXlsxFile(
                          activeList,
                          'Active Power Units',
                          'Active Power Units.xlsx'
                        )
                      }}
                      disabled={deletionMode}
                      hasContentToBottom={true}
                      hasContentToRight={!isMobileScreen}
                      className={isMobileScreen ? "w-[100%]" : ""}
                      icon={<DownloadIcon />}
                    /> : null
                  }
                  {(
                    formData?.documentType === "Application" &&
                    formData.status !== "In-Renewal"
                  ) &&
                  Boolean(deletionMode) ? (
                    <RedButton
                      text={"Delete All Units"}
                      onClickFunction={(e) => {
                        handleSubmit(e, "multiple", "massive");
                      }}
                      hasContentToBottom={true}
                      hasContentToRight={!isMobileScreen}
                      className={isMobileScreen ? "w-[100%]" : ""}
                    />
                  ) : null}
                  {!Boolean(deletionMode) && count !== 0 ? (
                    <RedButton
                      text={"Delete"}
                      onClickFunction={(e) => {
                        setDeletionMode(true);
                      }}
                      hasContentToBottom={true}
                      className={isMobileScreen ? "w-[100%]" : ""}
                    />
                  ) : null}
                </div>
              )}
            </>
          )}
          {showAddTruck && (
            <>
              <AddTruck 
                isEndoManager={isEndoManager}
                getEndorsements={props.getEndorsements}
                insuredInfo={props.insuredInfo}
                userInfo={userInfo}
                rerateRequired={rerateRequired}
                setRerateRequired={setRerateRequired}
                getTruckList={getTruckList}
                setTruckList={props.setTruckList}
                truckList={truckList}
                setShowAddTruck={setShowAddTruck}
                userID={props.userID}
                paramRefID={props.paramRefID}
                formData={formData}
                setFormData={setFormData}
                policyID={props.policyID}
                policyNumber={"test"}
                setShowTable={setShowTable}
              />
            </>
          )}
          {showTable && (
            <>
              {formData.documentType === "Policy" && (
                <>
                  {deletionMode
                    ? "Deleting a power unit from the power units list will submit a Remove Power Unit Endorsement."
                    : "Clicking on a power unit VIN will display more details."}
                  <span className="d-block mt-2 mb-3">
                    {deletionMode && formData.documentType === "Policy" && (
                      <>
                        The power unit
                        <b> will not be removed from the policy</b>{" "}
                        until the endorsement is approved by an underwriter.
                      </>
                    )}
                  </span>
                </>
              )}
              <TruckList
                insuredInfo={props.insuredInfo}
                getTruckList={getTruckList}
                policyID={props.policyID}
                setTruckList={props.setTruckList}
                userInfo={userInfo}
                formData={formData}
                truckList={truckList}
                {...truckHook}
              />
              <FormPopup
                isOpen={truckHook.openModal}
                onRequestClose={truckHook.handleCloseModal}
                title={`Select the expiration date of the truck
                ${truckHook.deletionMode ? " and the deletion reason" : ""}`}
                formSettings={truckHook.formSettings}
                loading={truckHook.modalSubmitting}
              />
            </>
          )}
          {showImport && (
            <ImportPowerUnits
              setTruckList={props.setTruckList}
              userInfo={userInfo}
              insuredInfo={props.insuredInfo}
              rerateRequired={rerateRequired}
              setRerateRequired={setRerateRequired}
              getTruckList={getTruckList}
              setShowAddTruck={setShowAddTruck}
              userID={props.userID}
              formData={formData}
              truckList={truckList}
              policyID={props.policyID}
              setShowTable={setShowTable}
              setFormData={props.setFormData}
              setShowImport={setShowImport}
            />
          )}
          {showImportAll && (
            <ImportAllLists
              insuredInfo={props.insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportAll}
            />
          )}
          {showImportInspas && (
            <ImportInspas
              insuredInfo={props.insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportInspas}
            />
          )}
        </div>
      )}
    </>
  );
}
