import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

export default function CommodityDetails({
  formData,
  policyID,
  field,
  conditional,
  label,
  parentValue,
}) {
  const handleChangeStandardCommodityExplanation = async (e) => {
    const updatedValues = { ...formData.standardCommodityPercentages };
    updatedValues[e.target.name] = e.target.value;
    await updateDoc(doc(db, "policy-applications", policyID), {
      standardCommodityPercentages: updatedValues,
    });
  };

  return (
    <>
      {conditional && (
        <div className="flex flex-col space-y-2">
          <label className="text-xs font-semibold">{label}:</label>
          <input
            name={field}
            type="text"
            onChange={handleChangeStandardCommodityExplanation}
            className="p-2 border rounded focus:outline-none focus:border-blue-500"
            value={formData.standardCommodityPercentages[field] || ""}
          />
        </div>
      )}
    </>
  );
}
