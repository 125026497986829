import React, { useEffect, useState } from 'react'
import { chooseKeysToPickInPayload, superSearcherFn } from "../../utils/helpers";
import useGeneralState from '../../Context/actions/useGeneralState';

function useDynamicTable(props) {
  const {
    filterKeys,
    tableData,
    setTableData,
    alternativeTableData,
    setAlternativeTableData,
    deletionMode,
    deleteMode,
    superEndoSearcher
  } = props;

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle sorting
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  // Function to handle search
  const handleSearch = (event) => {
    setCurrentPage(0);
    setSearchTerm(event.target.value);
  };

  const filteredData = tableData.filter((item) => {
    const foundMatchesInEndo = superSearcherFn(
      superEndoSearcher,
      item?.data,
      searchTerm?.toLowerCase()
    )
    const parsedData = chooseKeysToPickInPayload(filterKeys, item);
    const searchInLowercase = searchTerm.toLowerCase();
    const joinedData = Object.values(parsedData)
      .join(" ")
      .toLowerCase()
      .includes(searchInLowercase);
    return joinedData || foundMatchesInEndo;
  });
  
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const offset = currentPage * itemsPerPage;
  const currentItems =
    !deletionMode || (deleteMode && deletionMode) ?
    sortedData.slice(offset, offset + itemsPerPage) : sortedData

  const pageCount = Math.ceil(sortedData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const { controlToast } = useGeneralState()

  const handleDeletionBehavior = (
    e,
    dataIndex
  ) => {
    e.preventDefault()
    const newValue = { ...alternativeTableData[dataIndex] }
    const newArray = [ ...alternativeTableData ]
    newValue['tableInfo']['actions']['actions'][0]['checkbox'] = false
    newArray[dataIndex] = newValue;
    const filteredArray = newArray.filter(elem => {
      return Boolean(elem?.tableInfo?.actions?.actions?.[0]?.checkbox)
    })
    const mappedArray = filteredArray.map(elem => {
      return {
        ...elem,
        tableInfo: {
          ...elem.tableInfo,
          actions: {
            ...elem.tableInfo.actions,
            actions: ["deletion-behavior"]
          }
        }
      }
    })
    setAlternativeTableData(newArray)
    setTableData(mappedArray)
  }

  const handleSingleCheckbox = (
    dataIndex,
    action
  ) => {
    if (alternativeTableData?.length >= 10) {
      controlToast(true, 'You can not delete more than 10 drivers', 'info')
      return
    }
    const newValue = { ...tableData[dataIndex] }
    const newArray = [ ...tableData ]
    newValue['tableInfo']['actions']['actions'][0]['checkbox'] = !Boolean(action.checkbox)
    newArray[dataIndex] = newValue;
    const filteredArray = newArray.filter(elem => {
      return Boolean(elem?.tableInfo?.actions?.actions?.[0]?.checkbox)
    })
    const mappedArray = filteredArray.map(elem => {
      return {
        ...elem,
        tableInfo: {
          ...elem.tableInfo,
          actions: {
            ...elem.tableInfo.actions,
            actions: ["deletion-behavior"]
          }
        }
      }
    })
    setTableData(newArray)
    setAlternativeTableData(mappedArray)
  }

  return {
    searchTerm,
    handleSearch,
    handleSort,
    currentItems,
    pageCount,
    currentPage,
    handlePageChange,
    sortConfig,
    handleSingleCheckbox,
    handleDeletionBehavior
  }
}

export default useDynamicTable;
