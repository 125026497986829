import {
  collection,
  getDocs,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { db } from "../../../../firebase";
import config from "../../../../config/env";
import Modal from "react-modal";

export default function CreateNewQuote({
  modalIsOpen,
  setIsOpen,
  policyID,
  formData,
  setFormData,
  taxSchedule,
  insuredInfo,
  showQuoteSuccess,
  setShowQuoteSuccess,
  loading,
  setLoading,
  createNewQuote,
  adjustmentFormData,
  setAdjustmentFormData,
  truckListData,
  setTruckListData
}) {
  const { REACT_APP_SOUTHERN_BACKEND } = config;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const getTruckListData = async () => {
    const agencyID = formData.agencyID;
    const insuredID = formData.insuredID;
    // get truck list
    let truckList = [];
    let totalRating = 0;
    const truckSnapshot = await getDocs(
      collection(db, "agencies", agencyID, "insureds", insuredID, "power-units")
    );
    truckSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      truckList.push({ ...doc.data(), id: doc.id });
      totalRating += doc.data().rating;
    });
    setTruckListData([totalRating, truckList]);
    setLoading(false);
  };

  function afterOpenModal() {}
  function closeModal() {
    setIsOpen(false);
    setAdjustmentFormData({
      percentage: 0,
      debitOrCredit: "Debit",
      recipientFields: [""],
      renewalCredit: 0,
      renewalCreditDebitOrCredit: "Credit",
      biLiabilityDeductible: "$1,000",
      lossRatioAdjustmentPercentage: 0,
      safetyAdjustmentPercentage: 0,
    });
  }

  useEffect(() => {
    getTruckListData();
  }, [loading]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {loading ? (
        <></>
      ) : (
        <>
          <button
            onClick={(e) => {
              closeModal();
              setAdjustmentFormData({
                percentage: 0,
                debitOrCredit: "Debit",
                recipientFields: [""],
                renewalCredit: 0,
                renewalCreditDebitOrCredit: "Credit",
                biLiabilityDeductible: "$1,000",
                lossRatioAdjustmentPercentage: 0,
                safetyAdjustmentPercentage: 0,
              });
            }}
            className="text-[#8B0000] mb-2"
          >
            close
          </button>
          {showQuoteSuccess ? (
            <p className="max-w-[300px]">
              Your quote is being created. It will appear soon.
            </p>
          ) : (
            <div className="max-w-[300px]">
              <form
                onSubmit={(e) => {
                  closeModal();
                  createNewQuote(e, false, adjustmentFormData);
                }}
              >
                <label>Quote Title:</label>
                <input
                  required
                  className="standard-form-input"
                  type="text"
                  value={adjustmentFormData.description}
                  onChange={(e) =>
                    setAdjustmentFormData({
                      ...adjustmentFormData,
                      description: e.target.value,
                    })
                  }
                  placeholder="Quote Title"
                />
                <div className="my-2 p-2 border-top">
                  <label htmlFor="manualAdjustment" className="d-block w-full">
                    Manual Adjustment:
                  </label>
                  <div className="flex items-center">
                    <input
                      required
                      id="manualAdjustment"
                      className="w-1/2 p-2 m-1 border rounded"
                      type="number"
                      value={adjustmentFormData.percentage}
                      onChange={(e) => {
                        let multiplier = 0;
                        if (adjustmentFormData.debitOrCredit === "Debit") {
                          multiplier = 1;
                        } else {
                          multiplier = -1;
                        }
                        setAdjustmentFormData({
                          ...adjustmentFormData,
                          percentage: e.target.value,
                        });
                      }}
                    />
                    %
                    <select
                      required
                      className="w-1/2 p-2 m-1 border rounded"
                      value={adjustmentFormData.debitOrCredit}
                      onChange={(e) => {
                        let multiplier = 0;
                        setAdjustmentFormData({
                          ...adjustmentFormData,
                          debitOrCredit: e.target.value,
                        });
                      }}
                    >
                      <option value="Debit">Debit</option>
                      <option value="Credit">Credit</option>
                    </select>
                  </div>
                </div>
                <div className="my-2 p-2 border-top">
                  <label htmlFor="renewalCredit" className="d-block w-full">
                    Renewal Credit:
                  </label>
                  <div className="flex items-center">
                    <select
                      required
                      id="renewalCredit"
                      className="w-1/2 p-2 m-1 border rounded"
                      value={adjustmentFormData.renewalCredit}
                      onChange={(e) => {
                        let multiplier = 0;
                        if (
                          adjustmentFormData.renewalCreditDebitOrCredit ===
                          "Debit"
                        ) {
                          multiplier = 1;
                        } else {
                          multiplier = -1;
                        }
                        setAdjustmentFormData({
                          ...adjustmentFormData,
                          renewalCredit: e.target.value,
                        });
                      }}
                    >
                      <option value={0}>0</option>
                      <option value={7.5}>7.5</option>
                      </select>
                    %
                    <select
                    disabled={true}
                      required
                      className="w-1/2 p-2 m-1 border rounded"
                      value={adjustmentFormData.renewalCreditDebitOrCredit}
                      onChange={(e) => {
                        let multiplier = 0;
                        setAdjustmentFormData({
                          ...adjustmentFormData,
                          renewalCreditDebitOrCredit: e.target.value,
                        });
                      }}
                    >
                      <option value="Debit">Debit</option>
                      <option value="Credit">Credit</option>
                    </select>
                  </div>
                </div>
                <div className="my-2 p-2 border-top">
                  <label
                    htmlFor="lossRatioAdjustment"
                    className="d-block w-full"
                  >
                    Loss Ratio Adjustment:
                  </label>
                  <div className="flex items-center">
                    <input
                      required
                      id="lossRatioAdjustment"
                      className="w-1/2 p-2 m-1 border rounded"
                      type="number"
                      value={adjustmentFormData.lossRatioAdjustmentPercentage}
                      onChange={(e) => {
                        let multiplier = 0;
                        if (
                          adjustmentFormData.lossRatioAdjustmentDebitOrCredit ===
                          "Debit"
                        ) {
                          multiplier = 1;
                        } else {
                          multiplier = -1;
                        }
                        setAdjustmentFormData({
                          ...adjustmentFormData,
                          lossRatioAdjustmentPercentage: e.target.value,
                        });
                      }}
                    />
                    %
                    <select
                      required
                      className="w-1/2 p-2 m-1 border rounded"
                      value={
                        adjustmentFormData.lossRatioAdjustmentDebitOrCredit
                      }
                      onChange={(e) => {
                        let multiplier = 0;
                        setAdjustmentFormData({
                          ...adjustmentFormData,
                          lossRatioAdjustmentDebitOrCredit: e.target.value,
                        });
                      }}
                    >
                      <option value="Debit">Debit</option>
                      <option value="Credit">Credit</option>
                    </select>
                  </div>
                </div>
                <div className="my-2 p-2 border-top">
                  <label htmlFor="safetyAdjustment" className="d-block w-full">
                    Safety Adjustment:
                  </label>
                  <div className=" flex items-center">
                    <input
                      required
                      id="safetyAdjustment"
                      className="w-1/2 p-2 m-1 border rounded"
                      type="number"
                      value={adjustmentFormData.safetyAdjustmentPercentage}
                      onChange={(e) => {
                        let multiplier = 0;
                        if (
                          adjustmentFormData.safetyAdjustmentDebitOrCredit ===
                          "Debit"
                        ) {
                          multiplier = 1;
                        } else {
                          multiplier = -1;
                        }
                        setAdjustmentFormData({
                          ...adjustmentFormData,
                          safetyAdjustmentPercentage: e.target.value,
                        });
                      }}
                    />
                    %
                    <select
                      required
                      className="w-1/2 p-2 m-1 border rounded"
                      value={
                        adjustmentFormData.safetyAdjustmentDebitOrCredit || 0
                      }
                      onChange={(e) => {
                        let multiplier = 0;
                        setAdjustmentFormData({
                          ...adjustmentFormData,
                          safetyAdjustmentDebitOrCredit: e.target.value,
                        });
                      }}
                    >
                      <option value="Debit">Debit</option>
                      <option value="Credit">Credit</option>
                    </select>
                  </div>
                </div>
                <button
                  className="bg-[#072a48] hover:bg-[#116582] w-full rounded mt-3 p-2 text-white"
                  type="submit"
                >
                  Create Quote
                </button>
              </form>
            </div>
          )}
        </>
      )}
    </Modal>
  );
}
