import axios from "axios";
import getAgentRecipientList from "./utils/getAgentRecipientList";
import getUnderwriterRecipientList from "./utils/getUnderwriterRecipientList";
import config from "../../../config/env";

const endoStatusChangeNotification = async (
  endoType,
  endoNumber,
  newStatus,
  endoID,
  policyID,
  agencyID
) => {

  const { REACT_APP_SOUTHERN_BACKEND } = config
  let agentRecipientList = await getAgentRecipientList(
    agencyID,
    "endorsementNotificationRecipients"
  );

  let underwriterRecipientList = await getUnderwriterRecipientList(
    "endorsementNotificationRecipients"
  );

  const endoURL = window?.location?.href
  const request = {
    to: agentRecipientList,
    cc: underwriterRecipientList,
    status: newStatus,
    number: endoNumber,
    type: endoType,
    url: endoURL,
  };

  try {
    const response = await axios.post(
      `${REACT_APP_SOUTHERN_BACKEND}/notify/endorsement-status-change`,
      request
    );
    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error:", error);
  }
};

export default endoStatusChangeNotification;
