import React from "react";
import { t } from "i18next";
import { useMediaQuery } from "@mui/material";
import handleBlurCommodity from "../utils/handleBlurCommodity";
import handleSubtractCommodity from "../utils/handleSubtractCommodity";
import handleChangeCommodity from "../utils/handleChangeCommodity";
import handleChangeCommodityPercentage from "../utils/handleChangeCommodityPercentage";

export default function CustomCommodity({
  formData,
  index,
  field,
}) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const policyID = formData.id;

  return (
    <div key={`commodity${index}`} className="my-3">
      <h2 className="text-lg text-[#072a48] font-semibold mb-2">
        {t(`Commodity ${index + 1}`)}
      </h2>
      <div className={`flex flex-col ${isMobileScreen ? "flex-col" : "md:grid md:grid-cols-2 lg:grid-cols-2"} gap-2`}>
        <div className="flex flex-col">
          <label className="text-sm font-semibold mb-1">
            {t("Describe Commodity")} {index + 1}:
          </label>
          <input
            name="commodityDescription"
            onChange={(e) => handleChangeCommodity(index, e, formData, policyID)}
            onBlur={e => handleBlurCommodity(e, policyID, formData)}
            className="p-2 border rounded focus:outline-none focus:border-blue-500"
            type="text"
            value={field.commodityDescription}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-semibold mb-1">
            {t("Commodity % of Hauls")} {index + 1}:
          </label>
          <input
            name="commodityLoad"
            onChange={(e) => handleChangeCommodityPercentage(index, e, formData, policyID)}
            onBlur={e => handleBlurCommodity(e, policyID, formData)}
            className="p-2 border rounded focus:outline-none focus:border-blue-500"
            type="number"
            value={field.commodityLoad}
          />
        </div>
        <div className={isMobileScreen ? "" : "col-span-2"}>
          <div className="flex flex-col">
            <label className="text-sm font-semibold mb-1">
              {t("Commodity")} {index + 1} {t("Specifics")}:
            </label>
            <input
              name="commodityDetails"
              onChange={(e) => handleChangeCommodity(index, e, formData, policyID)}
              onBlur={e => handleBlurCommodity(e, policyID, formData)}
              className="p-2 border rounded focus:outline-none focus:border-blue-500"
              type="text"
              value={field.commodityDetails}
            />
          </div>
        </div>
      </div>
      {formData.documentType === "Application" && (
        <button
          className="mt-2 text-[#8B0000] hover:text-red-700"
          onClick={(e) => handleSubtractCommodity(e, index, formData, policyID)}
        >
          {t(`Remove Commodity ${index + 1}`)}
        </button>
      )}
    </div>
  );
}