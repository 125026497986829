import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { LocalMall, Person, DirectionsCar, LocalShipping, Assignment, Print } from '@mui/icons-material';
import { Flag } from '@mui/icons-material';

const options = [
    { icon: <Print fontSize="large" />, label: "Print My Policy" },
  { icon: <Person fontSize="large" />, label: "Modify Drivers" },
  { icon: <DirectionsCar fontSize="large" />, label: "Modify Units" },
  { icon: <LocalShipping fontSize="large" />, label: "Modify Trailers" },
  { icon: <Assignment fontSize="large" />, label: "Modify Questionnaire" },
  { icon: <LocalMall fontSize="large" />, label: "Modify Commodities" }, 
];

const Client = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        mb: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
        // bgcolor: '#f5f5f5', // Light background color for a clean look
        padding: 3,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: '#333', // Dark text for contrast
        }}
      >
        What would you like to do?
      </Typography>

      <Box
      sx={{
        display: 'flex',
        mb: 2,
        alignItems: 'center',
        gap: 2, // Spacing between the flags and text
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <img
          src="https://flagcdn.com/us.svg" // URL for the US flag
          alt="English"
          style={{ width: 24, height: 16, marginRight: 8 }}
        />
        <Typography>English</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <img
          src="https://flagcdn.com/mx.svg" // URL for the Spain flag
          alt="Español"
          style={{ width: 24, height: 16, marginRight: 8 }}
        />
        <Typography>Español</Typography>
      </Box>
    </Box>

      <Grid container spacing={3} justifyContent="center">
        {options.map((option) => (
          <Grid item xs={12} sm={6} md={4} key={option.label}>
            <Card
              sx={{
                boxShadow: 4, // Drop shadow to make cards pop
                borderRadius: 3, // Rounded corners for a modern look
                transition: 'transform 0.3s ease', // Smooth hover effect
                '&:hover': {
                  transform: 'scale(1.05)', // Slight zoom on hover
                },
              }}
            >
              <CardActionArea>
                <CardContent
                  sx={{
                    textAlign: 'center',
                    padding: 3,
                  }}
                >
                  {option.icon}
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 2,
                      fontWeight: 'medium',
                      color: '#555', // Muted text for a subtle touch
                    }}
                  >
                    {option.label}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Client;
