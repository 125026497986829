import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../../firebase";

const clearQuoteList = async (e, quoteHistory, setQuoteHistory, policyID) => {
    e.preventDefault();
    let verify = prompt(
      "You are about to delete an entire list! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      quoteHistory.forEach(async (quote) => {
        await deleteDoc(
          doc(db, "policy-applications", policyID, "quotes", quote.id)
        );
      });
      setQuoteHistory([]);
    }
  };

export default clearQuoteList;