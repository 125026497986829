import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { POLICY_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import Pagination from "../assets/lists/DynamicTable/Pagination";
import TableSkeleton from "../generals/TableSkeleton";
import { Typography, Box, Divider, CardContent, Card } from "@mui/material";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";

const policysPerPage = 5;

function PoliciesUpForRenewal({ userInfo, loading, setLoading }) {
  const { t } = useTranslations();

  const initialSortConfig = {
    key: "",
    direction: "asc",
  };

  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");
  const [policyList, setPolicyList] = useState([]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    if (userInfo.agencyID) {
      const policiesCollection = collection(db, "policy-applications");
      const q = query(
        policiesCollection,
        where("documentType", "==", "Policy"),
        where("agencyID", "==", userInfo.agencyID),
        where("status", "in", ["In-Force", "Bound", "In-Renewal"])
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedPolicyListPromises = snapshot.docs.map(
          async (policyDoc) => {
            const policyData = policyDoc.data();
            const { namedInsured, agencyID, ...rest } = policyData;
            if (agencyID) {
              const insuredDocRef = doc(
                db,
                "agencies",
                userInfo.agencyID,
                "insureds",
                policyData.insuredID
              );
              const insuredDocSnapshot = await getDoc(insuredDocRef);
              if (insuredDocSnapshot.exists()) {
                const companyName = insuredDocSnapshot.data().company;
                return {
                  id: policyDoc.id,
                  namedInsured: companyName,
                  agencyID,
                  ...rest,
                };
              }
            } else {
              console.error(
                "userInfo.agencyID is null or undefined. Skipping database operation."
              );
            }
            return null;
          }
        );
        Promise.all(updatedPolicyListPromises).then((updatedPolicyList) => {
          setPolicyList(updatedPolicyList.filter(Boolean));
          setLoading(false);
        });
      });
      return () => unsubscribe();
    }
  }, [userInfo.agencyID]);

  const filteredData = policyList.filter((policy) =>
    `${policy.status} ${policy.documentType} ${policy.namedInsured} ${policy.effectiveDate} ${policy.totalWrittenPremium}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  sortedData.sort((a, b) => {
    return (
      new Date(a.effectiveDate?.toDate()) - new Date(b.effectiveDate?.toDate())
    );
  });

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  const navigate = useNavigate();
  const handleRowClick = (application) => {
    navigate(`${POLICY_ROUTE}/${application.id}`);
  };

  const calculateExpirationDate = (effectiveDate) => {
    const expirationDate = new Date(effectiveDate);
    expirationDate.setDate(expirationDate.getDate() + 365); // Adding 365 days
    return expirationDate;
  };

  // Filter policies that are up for renewal
  const policiesUpForRenewal = policyList.filter((policy) => {
    if (policy.effectiveDate) {
      const expirationDate = calculateExpirationDate(
        policy.effectiveDate.toDate()
      );
      const today = new Date();
      const daysUntilExpiration = Math.floor(
        (expirationDate - today) / (1000 * 60 * 60 * 24)
      );
      return daysUntilExpiration <= 45 && daysUntilExpiration >= 0;
    }
    return false;
  });

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * policysPerPage;
  const currentItems = sortedData.slice(offset, offset + policysPerPage);
  const pageCount = Math.ceil(policiesUpForRenewal.length / policysPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <main>
      {loading ? (
        <TableSkeleton rows={5} columns={5} />
      ) : policyList.length === 0 ? (
        <p>{t("manage-policies.no-policies")}</p>
      ) : (
        <Box sx={{ maxWidth: 700 }}>
          <Card sx={{ marginBottom: 4 }}>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <AlarmOnIcon
                  sx={{ fontSize: "xx-large", mr: 0.5, color: "#8B0000" }}
                />
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontSize: "19px",
                    fontWeight: "bold",
                    color: "#8B0000",
                  }}
                >
                  {policiesUpForRenewal?.length} {t("manage-policies.upcoming-renewals")}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Table>
                <Thead>
                  <Tr>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
                        {
                          "hover:bg-[#072a48]": sortConfig.key !== "status",
                          "bg-blue-500": sortConfig.key === "status",
                        }
                      )}
                      onClick={() => handleSort("status")}
                    >
                      {t("manage-policies.status")}
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer",
                        {
                          "hover:bg-[#072a48]":
                            sortConfig.key !== "namedInsured",
                          "bg-blue-500": sortConfig.key === "namedInsured",
                        }
                      )}
                      onClick={() => handleSort("namedInsured")}
                    >
                      {t("manage-policies.named-insured")}
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer rounded-tr-md",
                        {
                          "hover:bg-[#072a48]":
                            sortConfig.key !== "effectiveDate",
                          "bg-blue-500": sortConfig.key === "effectiveDate",
                        }
                      )}
                      onClick={() => handleSort("effectiveDate")}
                    >
                      {t("manage-policies.effective-date")}
                    </Td>
                  </Tr>
                </Thead>

                <Tbody>
                  {currentItems.map((policy, index) => (
                    <Tr
                      key={policy.id}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleRowClick(policy)}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          hoveredRow === index ? "#e6f7ff" : "inherit",
                      }}
                    >
                      <Td>{policy?.status} </Td>
                      <Td title={policy.insuredID}>{policy?.namedInsured} </Td>
                      <Td>
                        {policy?.effectiveDate && (
                          <>
                            {policy?.effectiveDate
                              ?.toDate()
                              .toLocaleDateString("en-US", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                              })}
                          </>
                        )}{" "}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                pageCount={pageCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </main>
  );
}

export default PoliciesUpForRenewal;
