import React from "react";
import { Tbody, Td, Thead, Tr, Table } from "react-super-responsive-table";
import { Col, Row } from "react-bootstrap";
import { t } from "i18next";
import { TextField, useMediaQuery } from "@mui/material";
import { Box, Typography, Divider } from "@mui/material";
import numberWithCommas from "../../../../utils/numberWithCommas";

export default function MTCPremiums(formData) {
  const data = formData.formData;
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

    // total premium but with parseFloat values
    const totalPremium = (
        parseFloat(data?.mtcDetails?.totalPremiumBeforeTaxes || 0) +
        parseFloat(data?.mtcDetails?.otherFees || 0) +
        parseFloat(data?.mtcDetails?.stampTaxAmount || 0) +
        parseFloat(data?.mtcDetails?.surplusLinesTaxAmount || 0)
    );


  return (
    <Row className="mb-3">
      {/* Premiums */}
      <Col sm={12}>
        <section>
          <h1 className="header-text" id="premiums">
            {t("MTC")} {t("Premiums")}
          </h1>
          <article className="mt-2">
            {data.rerateRequired && data.documentType !== "Policy" ? (
              <p>
                {t("Please rerate the policy to view premium information.")}
              </p>
            ) : (
              <section>
                {!isMobileScreen && (
                  <Table>
                    <Thead>
                      <Tr>
                        <Td className="bg-[#072a48] text-white rounded-tl-md">
                          {t("Item")}
                        </Td>
                        <Td className="bg-[#072a48] text-white rounded-tr-md text-right mr-3">
                          {t("Amount")}:
                        </Td>
                      </Tr>
                    </Thead>
                    {formData?.selectedQuote !== "#" && data?.mtcDetails?.totalPremiumBeforeTaxes &&
                    data?.mtcDetails?.totalPremiumBeforeTaxes !== 0 ? (
                      <Tbody>
                        <Tr>
                          <Td>{t("Gross Written Premium")}</Td>
                          <Td className="text-right mr-3">
                            $
                            {numberWithCommas(
                              (
                                data?.mtcDetails?.totalPremiumBeforeTaxes || 0
                              ).toFixed(2)
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>{t("Stamp Fee")}</Td>
                          <Td className="text-right mr-3">
                            $
                            {numberWithCommas(
                              (data.mtcDetails?.stampTaxAmount || 0).toFixed(2)
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>{t("Surplus Lines Tax")}</Td>
                          <Td className="text-right mr-3">
                            $
                            {numberWithCommas(
                              (
                                data.mtcDetails?.surplusLinesTaxAmount || 0
                              ).toFixed(2)
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>{t("Total Premium")}</Td>
                          <Td className="text-right mr-3">
                            $
                            {numberWithCommas(
                              (totalPremium || 0).toFixed(2)
                            )}
                          </Td>
                        </Tr>
                      </Tbody>
                    ) : (
                      <Tbody>
                        <Tr>
                          <Td colSpan={2}>
                            {t(
                              "MTC premiums will be visible once an underwriter enters the premium information."
                            )}
                          </Td>
                        </Tr>
                      </Tbody>
                    )}
                  </Table>
                )}
                {isMobileScreen && (
                  <Box>
                    {data?.mtcDetails?.totalPremiumBeforeTaxes &&
                    data?.mtcDetails?.totalPremiumBeforeTaxes !== 0 ? (
                      <>
                        <Divider
                          style={{
                            backgroundColor: "#000",
                            marginBottom: "15px",
                            marginTop: "10px",
                          }}
                        />
                        <Box mb={2}>
                          <TextField
                            InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                            label={t("Gross Written Premium")}
                            variant="outlined"
                            fullWidth
                            value={`$${numberWithCommas(
                              (
                                data?.mtcDetails?.totalPremiumBeforeTaxes || 0
                              ).toFixed(2)
                            )}`}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>

                        <Box mb={2}>
                          <TextField
                            InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                            label={t("Stamp Fee")}
                            variant="outlined"
                            fullWidth
                            value={`$${numberWithCommas(
                              (data.mtcDetails?.stampTaxAmount || 0).toFixed(2)
                            )}`}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>

                        <Box mb={2}>
                          <TextField
                            InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                            label={t("Surplus Lines Tax")}
                            variant="outlined"
                            fullWidth
                            value={`$${numberWithCommas(
                              (
                                data.mtcDetails?.surplusLinesTaxAmount || 0
                              ).toFixed(2)
                            )}`}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                            label={t("Total Premium")}
                            variant="outlined"
                            fullWidth
                            value={`$${numberWithCommas(
                              (totalPremium || 0).toFixed(2)
                            )}`}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      </>
                    ) : (
                      <Typography variant="body1" align="center">
                        {t(
                          "MTC premiums will be visible once an underwriter enters the premium information."
                        )}
                      </Typography>
                    )}
                  </Box>
                )}
              </section>
            )}
          </article>
        </section>
      </Col>
    </Row>
  );
}
