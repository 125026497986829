import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PdfGenerator from "../../generals/PdfGenerator";
import usePoliciesPdfState from "../../../Context/actions/usePoliciesPdfState";
import { naftaAlPolicyPayload } from "../../../utils/policiesPdfPayloads";
import { useMediaQuery } from "@mui/material";
import MenuBar from "./MenuBar";
import excludeEmptyStrings from "../../utils/excludeEmptyStrings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import GavelIcon from "@mui/icons-material/Gavel";
import ReplyIcon from "@mui/icons-material/Reply";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PrintIcon from "@mui/icons-material/Print";
import bindPolicy from "../../utils/bindPolicy";
import PrintOptionsModal from "../PrintOptionsModal";
import RequestBindModal from "../RequestBindModal";
import RenewPolicyModal from "../RenewPolicyModal";
import { isPastSomeMinutes } from "../../../utils/helpers";
import expiresWithin60Days from "../../utils/expiresWithin60Days";
import returnToAgent from "../../utils/returnToAgent";
import ReasonsModal from "../ReturnToAgentModal";
import { AGENT_DIRECTORY_ROUTE } from "../../../utils/routes";
import requestQuote from "../../utils/requestQuote";
import RequestQuoteModal from "../RequestQuoteModal";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import SendQuoteModal from "../SendQuoteModal";
import { HistoryEdu } from "@mui/icons-material";

const returnToAgentFn = returnToAgent;

const ActionButtons = ({
  isAgentOrSubUser,
  formSaved,
  isAdminOrUnderwriter,
  isQuoteRequest,
  isApplication,
  isPolicy,
  isSubmitted,
  isFulfilled,
  isSubmittedOrFulfilled,
  isMissingAtLeastOneCoverage,
  formData,
  insuredInfo,
  driverList,
  returnToAgent,
  setDisplayEndorsementManager,
  isBindOrder,
  printPolicy,
  printPolicyProgress,
  policyID,
  setDisplayUnderwritingPanel,
  handleOpenRequestQuoteModal,
  handleCloseRequestQuoteModal,
  openRequestQuoteModal,
  handleOpenRenewPolicyModal,
  handleCloseRenewPolicyModal,
  openRenewPolicyModal,
  handleOpenReturnToAgentModal,
  handleCloseReturnToAgentModal,
  openReturnToAgentModal,
  openRequestBindModal,
  handleCloseRequestBindModal,
  handleOpenRequestBindModal,
  openPrintOptionsModal,
  handleOpenPrintOptionsModal,
  handleClosePrintOptionsModal,
  openSendQuoteModal,
  handleOpenSendQuoteModal,
  handleCloseSendQuoteModal,
  powerUnitCount,
  driverCount,
  trailerCount,
  userInfo,
  agencyInfo,
  nextAL,
  nextGL,
  insuredID,
  eligibleForRenewal
}) => {
  const { percentage } = printPolicyProgress;
  const { policiesPdf } = usePoliciesPdfState();

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const isNotRenewal = formData.status !== "In-Renewal";

  const { user: userAuth } = UserAuth();
  const { user: userState } = useUsersState();

  const buttonIcons = [
    isApplication ? <RequestQuoteIcon /> : "",
    isPolicy ? <PlaylistAddCheckIcon /> : "",
    eligibleForRenewal && isNotRenewal && isPolicy ? <AutorenewIcon /> : "",
    isAdminOrUnderwriter && isSubmitted ? <AttachEmailIcon /> : "",
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest ? <GavelIcon /> : "",
    isSubmittedOrFulfilled ? <ReplyIcon /> : "",
    isSubmittedOrFulfilled && isBindOrder && isAdminOrUnderwriter ? (
      <LibraryAddCheckIcon />
    ) : (
      ""
    ),
    isAdminOrUnderwriter && isPolicy ? (
      <HistoryEdu />
    ) : (
      ""
    ),
    isPolicy && formData.hasALCoverage ? <PrintIcon /> : "",
  ];
  const buttonLabels = [
    isApplication ? "Request Quote" : "",
    isPolicy ? "Endorsements" : "",
    eligibleForRenewal && isNotRenewal && isPolicy ? "Renewal Options" : "",
    isAdminOrUnderwriter && isSubmitted ? "Send Quotes" : "",
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest ? "Request Bind" : "",
    isSubmittedOrFulfilled ? "Return To Agent" : "",
    isSubmittedOrFulfilled && isBindOrder && isAdminOrUnderwriter ? "Bind Policy" : "",
    isAdminOrUnderwriter && isPolicy ? "Send Letters" : "",
    isPolicy && formData.hasALCoverage ? "Print Policy" : "",
  ];
  const onClickFunctions = [
    isApplication ? (e) => handleOpenRequestQuoteModal() : "",
    isPolicy ? () => setDisplayEndorsementManager(true) : "",
    eligibleForRenewal && isPolicy && isNotRenewal
      ? (e) => handleOpenRenewPolicyModal()
      : "",
    isAdminOrUnderwriter && isSubmitted ? () => handleOpenSendQuoteModal() : "",
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest
      ? () => handleOpenRequestBindModal()
      : "",
    isSubmittedOrFulfilled ? (e) => returnToAgent(e) : "",
    isSubmittedOrFulfilled && isBindOrder && isAdminOrUnderwriter
      ? () =>
          bindPolicy(
            formData,
            policyID,
            powerUnitCount,
            driverCount,
            trailerCount,
            userInfo,
            insuredInfo,
            nextAL,
            nextGL,
            insuredID,
            userAuth,
            userState
          )
      : "",
    isAdminOrUnderwriter && isPolicy
      ? () => setDisplayUnderwritingPanel(true)
      : "",
    isPolicy && formData.hasALCoverage
      ? (e) => handleOpenPrintOptionsModal()
      : "",
  ];

  const handleSubmit = async (e, reasons) => {
    try {
      await returnToAgentFn(
        e,
        policyID,
        reasons,
        formData,
        userAuth,
        userState,
        insuredInfo,
        userInfo
      );
    } catch (error) {
      throw error;
    }
  };

  if (isMobileScreen) {
    return (
      <>
        <ReasonsModal
          title="Reasons for Return"
          text="Please enter the reason(s) why the application is being returned to the agent."
          addButtonText="Add A Reason"
          onSubmit={handleSubmit}
          open={openReturnToAgentModal}
          onClose={handleCloseReturnToAgentModal}
        />
        <RenewPolicyModal
          open={openRenewPolicyModal}
          onClose={handleCloseRenewPolicyModal}
          policyID={policyID}
          formData={formData}
          insuredInfo={insuredInfo}
        />
        <RequestQuoteModal
          open={openRequestQuoteModal}
          onClose={handleCloseRequestQuoteModal}
          policyID={policyID}
          formData={formData}
          insuredInfo={insuredInfo}
        />
        <SendQuoteModal
          open={openSendQuoteModal}
          onClose={handleCloseSendQuoteModal}
          policyID={policyID}
          formData={formData}
          insuredInfo={insuredInfo}
        />
        <PrintOptionsModal
          open={openPrintOptionsModal}
          onClose={handleClosePrintOptionsModal}
          policiesPdf={policiesPdf}
          percentage={percentage}
          printPolicy={(e, option) =>
            printPolicy(
              e,
              "NAFTA AL",
              naftaAlPolicyPayload(
                formData,
                insuredInfo,
                driverList,
                agencyInfo
              ),
              option
            )
          }
        />
      </>
    );
  }

  return (
    <div>
      <MenuBar
        icons={excludeEmptyStrings(buttonIcons)}
        labels={excludeEmptyStrings(buttonLabels)}
        onClickFunctions={excludeEmptyStrings(onClickFunctions)}
      />
      <ReasonsModal
        title="Reasons for Return"
        text="Please enter the reason(s) why the application is being returned to the agent."
        addButtonText="Add A Reason"
        onSubmit={handleSubmit}
        open={openReturnToAgentModal}
        onClose={handleCloseReturnToAgentModal}
      />
      <RenewPolicyModal
        open={openRenewPolicyModal}
        formData={formData}
        onClose={handleCloseRenewPolicyModal}
        policyID={policyID}
        insuredInfo={insuredInfo}
      />
      <RequestQuoteModal
        open={openRequestQuoteModal}
        onClose={handleCloseRequestQuoteModal}
        policyID={policyID}
        formData={formData}
        insuredInfo={insuredInfo}
      />
      <SendQuoteModal
        open={openSendQuoteModal}
        onClose={handleCloseSendQuoteModal}
        policyID={policyID}
        formData={formData}
        insuredInfo={insuredInfo}
      />
      <RequestBindModal
        open={openRequestBindModal}
        onClose={handleCloseRequestBindModal}
        policyID={policyID}
        formData={formData}
        insuredInfo={insuredInfo}
      />
      <PrintOptionsModal
        open={openPrintOptionsModal}
        onClose={handleClosePrintOptionsModal}
        policiesPdf={policiesPdf}
        percentage={percentage}
        printPolicy={(e, option) =>
          printPolicy(
            e,
            "NAFTA AL",
            naftaAlPolicyPayload(formData, insuredInfo, driverList, agencyInfo),
            option
          )
        }
      />
    </div>
  );
};

export default ActionButtons;