import React, { useEffect, useRef, useState } from "react";
import {
  convertToNestedString,
  handleDeleteFilesFromStorage,
  handleDynamicQuestionnaireChange,
  handleMultiUploading,
} from "../../../../utils/helpers";
import { CircularProgress, Box } from "@mui/material";
import {
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import PDFPreviewer from "../../../generals/PDFPreviewer";
import { FileUploader } from "react-drag-drop-files";
import ApprovalButtons from "../ApprovalButtons";
import Popup from "../../../generals/Popup";
import BlueButton from "../BlueButton";
import { Dropdown } from "react-bootstrap";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import useTranslations from "../../../../Context/actions/useTranslations";

const MultiUpload = ({
  setFormSaved,
  policyID,
  property,
  formData,
  setFormData,
  doNotDebounce,
  label,
  multiple,
  storageFile,
  changeNormal = false,
  required = false,
  fileInButton,
  width,
  dissapearWhenImgExists,
  disabled,
  showPastingOption = false,
  showInAPopup = false,
  onSecondaryUploadFn,
  onSecondaryDeleteBeforeFn,
  onSecondaryDeleteAfterFn,
  dropdownStyleType = false,
  notClickable = false,
  isViewMode = false,
  valueToCompare,
  list,
}) => {
  const [changeInProgress, setChangeInProgress] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [uploadingMode, setUploadingMode] = useState(false)

  const { controlToast } = useGeneralState()
  const { t } = useTranslations()

  const inputValue = convertToNestedString(property, formData)
    ? convertToNestedString(property, formData)
    : Array.isArray(formData)
    ? formData
    : [];

  const valueIsArrayType = Array.isArray(inputValue);

  const changingValue = async (value) => {
    try {
      await handleDynamicQuestionnaireChange(
        policyID,
        property,
        value,
        formData,
        setFormData,
        setFormSaved,
        doNotDebounce
      );
    } catch (error) {
      throw error;
    }
  };

  const handleChange = async (e) => {
    if (e && e?.preventDefault) {
      e?.preventDefault();
    }
    try {
      if (!changeInProgress) {
        setChangeInProgress(true);
        const urlResults = await handleMultiUploading(
          e,
          property,
          storageFile,
          valueToCompare,
          list
        );
        let values = urlResults || inputValue;
        const urls = values.map((value) => value.url);
        let realUrls = [...urls];
        if (!changeNormal) {
          await changingValue(urls);
        } else {
          if (multiple) {
            realUrls = [...inputValue, ...urls];
          }
          if (valueToCompare) {
            setFormData(realUrls, urlResults);
          } else {
            setFormData(realUrls);
          }
        }
        setChangeInProgress(false);
        setUploadingMode(false)

        if (onSecondaryUploadFn) {
          await onSecondaryUploadFn(realUrls, urlResults)
        }
      }
    } catch (error) {
      console.error("Error uploading file", error);
      setChangeInProgress(false);
      if (
        typeof error === "string" &&
        error.includes('does not contain the correct data')
      ) {
        controlToast(
          true,
          `${error}, the filename should contain ${valueToCompare}-YourFileName.extension`,
          "error"
        )
      } else if (
        typeof error === "string" &&
        error.includes('licenseNumber Endo Manager.pdf does not exist in this policy, type YourLicenseNumber-YourFileName.extension')
      ) {
        controlToast(true, error, "error")
      }
      console.error(error)
    }
  };

  const handleDelete = async (specificFileToDelete) => {
    const confirmation = prompt("To confirm deletion, type 'DELETE'");
    if (confirmation === "DELETE") {
      let valuesToDelete = [...inputValue];
      let valuesToKeep = [...inputValue];
      if (specificFileToDelete) {
        valuesToKeep = valuesToKeep.filter(
          (value) => value !== specificFileToDelete
        );
        valuesToDelete = valuesToDelete.filter(
          (value) => value === specificFileToDelete
        );
      }
      try {
        setDeleteInProgress(true);
        if (onSecondaryDeleteBeforeFn) {
          await onSecondaryDeleteBeforeFn()
        }
        const value = [];
        if (!changeNormal) {
          await changingValue(value);
        } else {
          setFormData(multiple && specificFileToDelete ? valuesToKeep : []);
        }

        if (onSecondaryDeleteAfterFn) {
          await onSecondaryDeleteAfterFn()
        }

        setDeleteInProgress(false);
        try {
          await handleDeleteFilesFromStorage(valuesToDelete);
          setDeleteInProgress(false);
        } catch (error) {
          console.error("Deletion in storage error", error);
        }
      } catch (error) {
        setDeleteInProgress(false);
        throw error;
      }
    } else {
      alert("Deletion cancelled.");
    }
  };

  const thereIsAValue = Boolean(
    valueIsArrayType ? inputValue?.length !== 0 : inputValue
  );

  const disableButton = thereIsAValue || deleteInProgress || disabled;
  const reallyDissapearWhenImgExists = dissapearWhenImgExists && disableButton;

  const imageContainerRef = useRef(null);

  useEffect(() => {
    const handlePaste = async (event) => {
      if (!showPastingOption || imageContainerRef?.current?.style?.display !== "none") {
        return;
      }
      if (event && event?.preventDefault) event?.preventDefault();
      try {
        setChangeInProgress(true);
        const items = (
          event.clipboardData ||
          event.originalEvent.clipboardData
        ).items;
        const files = [];
        for (const item of items) {
          if (item.type.indexOf("image") !== -1) {
            const blob = item.getAsFile();
            const file = new File([blob], `${property}.png`, {
              type: "image/png",
            });
            files.push(file);
          }
        }
        const syntheticEvent = {
          target: { files },
        };
        await handleChange(syntheticEvent);
      } catch (error) {
        console.log("error", error);
      }
    };
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <>
      <Popup
        isOpen={uploadingMode}
        onRequestClose={() => setUploadingMode(false)}
        maxWidth={'500px'}
      >
        <div>
          {showPastingOption ? (
            <div ref={imageContainerRef} style={{ display: "none" }} />
          ) : null}
          <div>
            {!reallyDissapearWhenImgExists || (thereIsAValue && multiple) ? (
              <div
                className="my-2 relative h-200px"
              >
                <label
                  htmlFor={property}
                  className="block text-md font-medium text-gray-700 mb-4 text-center"
                >
                  {label}
                </label>
                {!changeInProgress ?
                  <FileUploader
                    className='w-full'
                    multiple={multiple}
                    name={property}
                    handleChange={(value) => {
                      let files = []
                      if (multiple) files = value
                      if (!multiple) files.push(value)
                      handleChange({
                        target: { files }
                      })
                    }}
                    types={["JPG", "JPEG", "PNG", "PDF"]}
                    label={
                      showPastingOption ?
                      t('plm.ctrlvpaste') :
                      t('plm.dragdrop')
                    }
                    dropMessageStyle={{ width: '100%', textAlign: 'center'}}
                    labelStyle={{ width: '100%', textAlign: 'center' }}
                    disabled={disableButton && !multiple}
                    required={required}
                  /> : null
                }
                {changeInProgress ?
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <p className="mr-3 mb-1">{t('plm.uploading-file')}...</p>
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  </Box> : null
                }
              </div>
            ) : null}
          </div>
        </div>
        <div className="mt-4">
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline={t('plm.cancel')}
            onDecline={() => setUploadingMode(false)}
          />
        </div>
      </Popup>
      {dropdownStyleType ?
        <Dropdown.Item onClick={() => {
          if (!notClickable) {
            setUploadingMode(true)
          }
        }}>
          {label}
        </Dropdown.Item> :
      !thereIsAValue || (multiple && !disabled) ?
        <BlueButton
          text={`${t('plm.upload')} ${label}`}
          onClickFunction={() => setUploadingMode(true)}
          hasContentToBottom={true}
          icon={<CloudUploadIcon />}
          className={"w-full"}
        /> : null
      }
      {thereIsAValue && !dropdownStyleType ?
        <label
          htmlFor={property}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label> : null
      }
      {!dropdownStyleType ?
        <>
          {!multiple ? (
            <div className="flex">
              {valueIsArrayType && !changeInProgress
                ? inputValue?.map((file, index) => {
                    return (
                      <div key={index} className="max-w-full mt-2 mr-2">
                        <a href={file} target="_blank" rel="noopener noreferrer">
                          <PDFPreviewer
                            showInAPopup={showInAPopup}
                            className="max-w-full h-auto"
                            file={file}
                            alt={property}
                            width={width ? width : 50}
                            height={file?.includes("pdf") ? 400 : 50}
                            fileInButton={fileInButton}
                            dissapearElem={changeInProgress}
                          />
                        </a>
                      </div>
                    );
                  })
                : null}
                {thereIsAValue && !deleteInProgress && !multiple && !isViewMode ? (
                  <button
                    className="text-[#8B0000] mt-[7px]"
                    disabled={deleteInProgress}
                    onClick={handleDelete}
                  >
                    <DeleteIcon /> Delete
                  </button>
                ) : null}
            </div>
          ) : null}
          <>
            {multiple && valueIsArrayType && !changeInProgress ?
              <ul>
                {inputValue.map((file, index) => (
                  <li key={index} className="my-1 flex items-center">
                    {!isViewMode ?
                      <button
                        onClick={() => handleDelete(file)}
                        className="bg-[#8B0000] text-white rounded-full p-1 ml-2 mr-2"
                      >
                        <DeleteIcon />
                      </button> : null
                    }
                    <div className="flex flex-col">
                      <a href={file} target="_blank" rel="noopener noreferrer">
                        {isViewMode ?
                          <VisibilityIcon className="mr-1 text-[#072a48]" /> : null
                        }
                        {file?.split("/")[file?.split("/")?.length - 1]}
                      </a>
                    </div>
                  </li>
                ))}
              </ul> : null  
            }
          </>
        </> : null
      }
    </>
  );
};

export default MultiUpload;