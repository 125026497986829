import React from "react";
import BindDetailsInfo from "./BindDetailsInfo";
import GoBack from "../generals/GoBack";

export default function BindPanel(props) {

  const formData = props.formData;
  const setDisplayCompanyDetails = props.setDisplayCompanyDetails;

  return (
    <div className="max-w-[800px] mx-auto bg-white rounded-md shadow-md p-6 my-4 w-full">
      <GoBack
        handleFn={() => setDisplayCompanyDetails(false)}
      />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          Bind Details
        </span>
      </div>
      <fieldset>
        <BindDetailsInfo
          formData={formData}
        />
      </fieldset>
    </div>
  );
}
