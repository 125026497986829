import React, { useEffect, useState } from "react";
import TextInput from "../../../assets/forms/TextInput";
import NumberInput from "../../../assets/forms/NumberInput";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import DatePicker from "react-datepicker";
import calculateAge from "../../../utils/calculateAge";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import useTranslations from "../../../../Context/actions/useTranslations";
import "react-datepicker/dist/react-datepicker.css";

export default function Step1({
  parentProps,
  driverData,
  setDriverData,
  setEndoEffectiveDate,
  endoEffectiveDate,
  dobDate,
  setDobDate,
  setOpenModalAddDriverException,
  goToSecondStep,
  goBack,
  appearATutorInput,
  activeDriversWithAtLeas5YearsOfExperience
}) {
  const { controlToast } = useGeneralState();
  const { t } = useTranslations()

  const submitAddDriverStep1 = async (e) => {
    e.preventDefault();
    const age = calculateAge(dobDate);
    if (age > 22 && age < 71) {
      if (driverData.yoe < 2) {
        setOpenModalAddDriverException(true);
      } else {
        goToSecondStep();
      }
    } else {
      controlToast(
        true,
        "A driver must be at least 23 and younger than 71",
        "error"
      );
    }
  };

  return (
    <form onSubmit={(e) => submitAddDriverStep1(e)}>
      <div>
        {parentProps.formData.documentType === "Policy" ||
        parentProps.formData.status === "In-Renewal" ? (
          <>
            <label className="">{t('plm.drivers.details.effective-date')}:</label>
            <DatePicker
              wrapperClassName="datePicker"
              required
              selected={endoEffectiveDate}
              onChange={(date) => {
                setEndoEffectiveDate(date);
              }}
              className="w-full p-2 border"
            />
          </>
        ) : null}
        <TextInput
          required={true}
          label={t('plm.drivers.driver-first-name:')}
          property={"driverFirst"}
          setFormData={setDriverData}
          formData={driverData}
          saveForm={() => {}}
          setFormSaved={() => {}}
          formSaved={""}
          policyID={parentProps.policyID}
          withoutSpaces={true}
        />
        <TextInput
          required={true}
          label={t('plm.drivers.driver-last-name:')}
          property={"driverLast"}
          setFormData={setDriverData}
          formData={driverData}
          saveForm={parentProps.saveForm}
          setFormSaved={parentProps.setFormSaved}
          formSaved={parentProps.formSaved}
          policyID={parentProps.policyID}
        />
        <NumberInput
          required={true}
          label={t('plm.drivers.years-experience:')}
          property={"yoe"}
          setFormData={setDriverData}
          formData={driverData}
          saveForm={parentProps.saveForm}
          setFormSaved={parentProps.setFormSaved}
          formSaved={parentProps.formSaved}
          policyID={parentProps.policyID}
        />
        {appearATutorInput ?
          <div className="mt-1 mb-2">
            <label htmlFor="tutor" className="d-block">
              {t('plm.drivers.tutor')}
            </label>
            <select
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  tutor: e.target.value,
                })
              }
              value={driverData.tutor}
              type="text"
              id="tutor"
              className="w-full p-2 border"
            >
              <option>{t('plm.drivers.select-tutor')}</option>
              {activeDriversWithAtLeas5YearsOfExperience.map((driver) => {
                return (
                  <option key={driver.id} value={driver.id}>
                    {driver?.driverFirst} {driver?.driverLast ? driver.driverLast : ''}
                  </option>
                );
              })}
            </select>
          </div> : null
        }
        <>
          <label className="">{t("Date of Birth")}:</label>
          <DatePicker
            wrapperClassName="datePicker"
            required
            selected={dobDate}
            onChange={(date) => {
              setDriverData({
                ...driverData,
                dob: date,
              });
              setDobDate(date);
            }}
            className="w-full p-2 border"
          />
        </>
        <label htmlFor="licenseCountry" className="mt-1 d-block">
          {t("Driver Country:")}
        </label>
        <select
          required
          onChange={(e) =>
            setDriverData({
              ...driverData,
              licenseCountry: e.target.value,
            })
          }
          value={driverData.licenseCountry}
          type="text"
          id="licenseCountry"
          className="w-full p-2 border"
        >
          <option></option>
          <option value="USA">{t("United States")}</option>
          <option value="MX">{t("Mexico")}</option>
          <option value="CA">{t("Canada")}</option>
        </select>
      </div>
      <ApprovalButtons
        onApprove={null}
        handleAsSubmit={true}
        onDecline={(e) => {
          goBack(e);
        }}
        titleAccept="Continue"
        titleDecline="Go Back"
      />
    </form>
  );
}
