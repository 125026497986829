import BlueButton from "../Components/assets/forms/BlueButton";
import useUsersState from "../Context/actions/useUsersState";
import {
    Dangerous as DangerousIcon,
    ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import {
  ACCOUNT_ROUTE,
  AGENT_COMPANY_PROFILE_ROUTE,
  AGENT_MANAGE_APPLICATIONS_ROUTE,
  AGENT_MANAGE_NOTIFICATIONS_ROUTE,
  AGENT_MANAGE_POLICIES_ROUTE,
  AGENT_MANAGE_USERS_ROUTE,
  APPLICATION_ROUTE,
  POLICY_ROUTE,
  UNDERWRITING_ENDORSEMENT_ROUTE,
  UNDERWRITING_ROUTE
} from "../utils/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import config from "../config/env";

const PermissionValidator = ({ children, validRoles = [] }) => {

  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config
  const { user } = useUsersState();
  const {
    role,
    userPermissions,
    applicationPermissions,
    policyPermissions,
    agencyID
  } = user || {
    role: '',
    userPermissions: false,
    applicationPermissions: false,
    policyPermissions: false,
    agencyID: ''
  }

  const isLiconaInsuranceGroup = agencyID === REACT_APP_LICONA_INSURANCE_GROUP_ID;

  const userPermissionsAreGrantedToView = userPermissions === true
  const applicationPermissionsAreGrantedToView = applicationPermissions === true
  const policyPermissionsAreGrantedToView = policyPermissions === true

  const location = useLocation()

  const [permissionGranted, setPermissionGranted] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (role === 'Sub-User') {
      if (
        (applicationPermissionsAreGrantedToView) &&
        (
          location.pathname.includes(AGENT_MANAGE_APPLICATIONS_ROUTE) ||
          location.pathname.includes(APPLICATION_ROUTE)
        )
      ) {
        setPermissionGranted(true)
      } else if (
        policyPermissionsAreGrantedToView &&
        (
          
          location.pathname.includes(AGENT_MANAGE_POLICIES_ROUTE) ||
          location.pathname.includes(POLICY_ROUTE) ||
          location.pathname.includes(UNDERWRITING_ROUTE) ||
          location.pathname.includes(UNDERWRITING_ENDORSEMENT_ROUTE)
        )
      ) {
        setPermissionGranted(true)
      } else if (
        userPermissionsAreGrantedToView &&
        (
          location.pathname.includes(AGENT_COMPANY_PROFILE_ROUTE) ||
          location.pathname.includes(AGENT_MANAGE_USERS_ROUTE) ||
          location.pathname.includes(AGENT_MANAGE_NOTIFICATIONS_ROUTE)
        )
      ) {
        setPermissionGranted(true)
      } else if (
        isLiconaInsuranceGroup
      ) {
        setPermissionGranted(true)
      } else {
        setPermissionGranted(false)
      }
    } else {
      const permissionGrantedByDataBase = validRoles.includes(role);
      setPermissionGranted(permissionGrantedByDataBase)
    }
    setLoading(false)
  }, [location.pathname])
  
  const navigate = useNavigate()

  const handleNavigate = (e) => {
    e.preventDefault();
    navigate(ACCOUNT_ROUTE);
  }

  if (loading) {
    return <></>
  } else if (permissionGranted) {
    return children;
  } else {
    return (
        <div className="flex w-full justify-center items-center h-[75vh] flex-col">
            <DangerousIcon sx={{ color: 'red', width: 100, height: 100, margin: '0 0 8px' }}  />
            <p className="mb-4" style={{ fontSize: 24 }}>Permission Denied</p>
            <BlueButton
                onClickFunction={handleNavigate}
                icon={<ArrowBackIcon />}
                text="Go Back To Dashboard"
            />
        </div>
    )
  }
};

export default PermissionValidator;
