import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_ROUTE, SIGN_IN_ROUTE } from "../../utils/routes";
import useCompaniesState from "../../Context/actions/useCompaniesState";
import useUsersState from "../../Context/actions/useUsersState";
import {
  businessStructureOptions,
  companyUSStateOptions,
  countryCodeOptions
} from "../../utils/constants";
import useGeneralState from "../../Context/actions/useGeneralState";
import ApiService from "../../services/southern-api/apiService";

const useSignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { createCompany } = useCompaniesState()
  const { setUser } = useUsersState()
  const { controlToast } = useGeneralState()
  const [loading, setLoading] = useState(false)
  const apiService = new ApiService('southern')

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiService.createUser(email, password)
      navigate(ACCOUNT_ROUTE);
    } catch (e) {
      alert(e.message);
    }
  };

  const handleSubmitSignUpWithCompanyId = async (e, formData) => {
    e.preventDefault();
    try {
      const values = {
        ...formData,
        companyOwner: true
      }
      setLoading(true)
      const data = await apiService.createUser(values)
      const { user, company } = data
      const { uid } = user
      createCompany(company, true)
      setUser(uid, user, true)
      controlToast(true, 'You create a successful account', 'success')
      setLoading(false)
      navigate(SIGN_IN_ROUTE);
    } catch (error) {
      controlToast(true, error.message, 'error')
      setLoading(false)
      return error
    }
  };

  const formSettingsSignUpWithCompanyId = {
    title: "Sign Up",
    onSubmit: handleSubmitSignUpWithCompanyId,
    inputs: [
      {
        label: "Email",
        type: "email",
        name: "email",
        required: true
      },
      {
        label: "Password",
        type: "password",
        name: "password",
        required: true
      },
      {
        label: "Name(s)",
        type: "text",
        name: "first",
        required: true
      },
      {
        label: "Last Name",
        type: "text",
        name: "last",
        required: true
      },
      {
        label: "Title",
        type: "text",
        name: "title",
        required: true
      },
      {
        label: "Phone Number",
        type: "number",
        name: "phone",
        required: true
      },
      {
        label: "Company Name",
        type: "text",
        name: "name",
        required: true
      },
      {
        label: "Company Primary Contact",
        type: "text",
        name: "primaryContact",
        required: true
      },
      {
        label: "Company Primary Contact Email",
        type: "text",
        name: "primaryContactEmail",
        required: true
      },
      {
        label: "Company Mailing Address",
        type: "text",
        name: "mailingAddress",
        required: true
      },
      {
        label: "Company City",
        type: "text",
        name: "city",
        required: true
      },
      {
        label: "Company State",
        type: "select",
        name: "state",
        options: companyUSStateOptions,
        required: true
      },
      {
        label: "Company Zip Code",
        type: "text",
        name: "zip",
        required: true
      },
      {
        label: "Company Phone Country Code",
        type: "select",
        name: "countryCode",
        options: countryCodeOptions,
        required: true
      },
      {
        label: "Company Phone Number",
        type: "text",
        name: "phoneNumber",
        required: true
      },
      {
        label: "Company Business Structure",
        type: "select",
        name: "businessStructure",
        options: businessStructureOptions,
        required: true
      },
    ],
    buttonLabel: "Sign Up",
  };

  return {
    setEmail,
    setPassword,
    handleSubmit,
    formSettingsSignUpWithCompanyId,
    loading
  }
};

export default useSignUp;
