import React, { useState } from "react";
import LoadingScreen from "react-loading-screen";
import ALLossHistory from "./AL/ALLossHistory";
import APDLossHistory from "./APDMTC/APDLossHistory";
import MTCLossHistory from "./APDMTC/MTCLossHistory";
import Breadcrumbs from "../../assets/Breadcrumbs";
import OtherLosses from "./OtherLosses";
import DragDropImportList from "../../assets/forms/DragDropFileUpload/DragDropImportList";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Popup from "../../generals/Popup";
import BlueButton from "../../assets/forms/BlueButton";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import { importLossesData } from "./utils/importLossesData";
import { t } from "i18next";
import ApiService from "../../../services/southern-api/apiService";
import {
  Download as DownloadIcon,
} from "@mui/icons-material";
import "../../styling/styles.css";

export default function Losses({formData, setFormData, policyID, insuredInfo}) {
  const isApplication = formData.documentType === "Application";
  const isPolicy = formData.documentType === "Policy";
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [importedFile, setImportedFile] = useState(null)

  const { controlToast } = useGeneralState();

  const currentYear = parseInt(new Date().toString().slice(11, 15));

  const [displayALLossHistory, setDisplayALLossHistory] = useState(
    isApplication
  );

  const [displayAPDLossHistory, setDisplayAPDLossHistory] = useState(
    isApplication
  );

  const [displayMTCLossHistory, setDisplayMTCLossHistory] = useState(
    isApplication
  );

  const breadcrumbs = [{ label: formData.documentType }, { label: "Losses" }];

  const hasAtLeastOneCoverage = formData?.hasALCoverage || formData?.hasAPDCoverage || formData?.hasMTCCoverage;

  const onClose = () => {
    if (!loading) {
      setOpen(false)
      setOpenConfirmation(false)
      setImportedFile(null)
    }
  }

  const submitFn = async (file) => {
    try {
      const fileObj = file ? file : importedFile
      await importLossesData(
        fileObj,
        policyID,
        controlToast,
        setLoading
      )
      onClose()
    } catch (error) {
      console.error(error);
      controlToast(true, "There was an error import the file", "error");
    }
  }

  const [resultAggregateLossRatio, setResultAggregateLossRatio] = useState(null)

  const apiService = new ApiService()

  const handleExportLosses = async () => {
    try {
      controlToast(true, "The excel is being created", "info")
      const lossExperienceAnalysis = await apiService.lossExperienceAnalysisExcel({
        namedInsured: insuredInfo.company || '',
        insuredCarrier1: formData?.lossHistory?.year1ALLossData?.carrier || 'N/A',
        insuredCarrier2: formData?.lossHistory?.year2ALLossData?.carrier || 'N/A',
        insuredCarrier3: formData?.lossHistory?.year3ALLossData?.carrier || 'N/A',
        noUnits1: formData?.lossHistory?.year1ALLossData?.numberOfUnits || 0,
        noUnits2: formData?.lossHistory?.year2ALLossData?.numberOfUnits || 0,
        noUnits3: formData?.lossHistory?.year3ALLossData?.numberOfUnits || 0,
        premiumPPU1: formData?.lossHistory?.year1ALLossData?.premiumPPU || 0,
        premiumPPU2: formData?.lossHistory?.year2ALLossData?.premiumPPU || 0,
        premiumPPU3: formData?.lossHistory?.year3ALLossData?.premiumPPU || 0,
        losses1: formData?.lossHistory?.year1ALLossData?.losses || 0,
        losses2: formData?.lossHistory?.year2ALLossData?.losses || 0,
        losses3: formData?.lossHistory?.year3ALLossData?.losses || 0,
        openBIClaims1: formData?.lossHistory?.year1ALLossData?.openBIClaims || 0,
        openBIClaims2: formData?.lossHistory?.year2ALLossData?.openBIClaims || 0,
        openBIClaims3: formData?.lossHistory?.year3ALLossData?.openBIClaims || 0,
        openAtFaultBIClaims1: formData?.lossHistory?.year1ALLossData?.openAtFaultBIClaims || 0,
        openAtFaultBIClaims2: formData?.lossHistory?.year2ALLossData?.openAtFaultBIClaims || 0,
        openAtFaultBIClaims3: formData?.lossHistory?.year3ALLossData?.openAtFaultBIClaims || 0,
        totalClaims1: formData?.lossHistory?.year1ALLossData?.totalOpenClaims || 0,
        totalClaims2: formData?.lossHistory?.year2ALLossData?.totalOpenClaims || 0,
        totalClaims3: formData?.lossHistory?.year3ALLossData?.totalOpenClaims || 0,
      })
      controlToast(true, "The excel was succesfully created", "success")
      const excelUrl = lossExperienceAnalysis.excelUrl
      const link = document.createElement('a');
      link.href = excelUrl;
      link.download = 'Loss-Experience-Analysis.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error)
      controlToast(true, "There was an error exporting the losses", "error")
    }
  }

  const didNotOperateInYear1ALPrior =
    formData?.didNotOperateInYear1ALPrior || false;
  const didNotOperateInYear2ALPrior =
    formData?.didNotOperateInYear2ALPrior || false;
  const didNotOperateInYear3ALPrior =
    formData?.didNotOperateInYear3ALPrior || false;

  const allYearsComplete =
    (didNotOperateInYear1ALPrior ||
      formData?.lossHistory?.year1ALLossData?.lossRatioCalculated) &&
    (didNotOperateInYear2ALPrior ||
      formData?.lossHistory?.year2ALLossData?.lossRatioCalculated) &&
    (didNotOperateInYear3ALPrior ||
      formData?.lossHistory?.year3ALLossData?.lossRatioCalculated);

  return (
    <div>
      <Popup
        isOpen={open}
        onRequestClose={onClose}
        maxWidth='100%'
      >
        <div className="h-[300px] flex justify-center items-center">
          <LoadingScreen
            loading={loading}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            padding="0"
            logoSrc=""
            text={`Printing... Don't leave the screen`}
          >
            <p className="text-2xl mt-2 font-semibold mb-3">Import your xlsx with Losses</p>
            <DragDropImportList
              file={file}
              setFile={setFile}
              handleUpload={async (file) => {
                if (resultAggregateLossRatio) {
                  setImportedFile(file)
                  setOpenConfirmation(true)
                } else {
                  await submitFn(file)
                }
              }}
              fileTypes={["XLSX"]}
            />
          </LoadingScreen>
        </div>
      </Popup>
      <Popup
        isOpen={openConfirmation}
        onRequestClose={onClose}
      >
        <div className="flex flex-col justify-center items-center">
          <p className="text-2xl mt-2 font-semibold mb-3 text-center">
            Do you want to overwrite existing loss run data?
          </p>
          <div className="w-full">
            <ApprovalButtons
              onApprove={async (e) => {
                e.preventDefault()
                await submitFn()
              }}
              onDecline={onClose}
              titleAccept={"SUBMIT"}
              titleDecline={"CANCEL"}
            />
          </div>
        </div>
      </Popup>
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="text-3xl mt-2 font-semibold md:mb-0">Losses</h1>
      <p className="mt-3 mb-2">
        {isApplication && (
          <>
            <span>
              The following loss history is adjusted automatically based on
              selected coverages.
            </span>
            <span className="mt-2">
              Please complete all fields to ensure expedient underwriting.
            </span>
          </>
        )}
        {!isApplication && (
          <>Losses may only be modified during the application phase..</>
        )}
      </p>
      {<div className="flex">
        {isApplication ? (
          <div>
            <BlueButton
              text='Import Losses'
              onClickFunction={() => setOpen(true)}
              hasContentToBottom={true}
              hasContentToTop={true}
            />
          </div>
        ) : null}
        {allYearsComplete ?
          <div>
            <BlueButton
              text='Export Losses'
              onClickFunction={handleExportLosses}
              hasContentToBottom={true}
              hasContentToTop={true}
              hasContentToLeft={Boolean(isApplication)}
              icon={<DownloadIcon />}
            />
          </div> : null
        }
      </div>}
      {isPolicy && (
        <p className="mb-4">
          {t(
            "This insured has suffered no reported losses while insured with Southern Star MGA."
          )}
        </p>
      )}
      {formData?.hasALCoverage && (
        <ALLossHistory
          policyID={policyID}
          formData={formData}
          setFormData={setFormData}
          currentYear={currentYear}
          displayALLossHistory={displayALLossHistory}
          setDisplayALLossHistory={setDisplayALLossHistory}
          setResultAggregateLossRatio={setResultAggregateLossRatio}
        />
      )}
      {formData?.hasAPDCoverage && (
        <APDLossHistory
          policyID={policyID}
          formData={formData}
          setFormData={setFormData}
          currentYear={currentYear}
          displayAPDLossHistory={displayAPDLossHistory}
          setDisplayAPDLossHistory={setDisplayAPDLossHistory}
        />
      )}
      {formData?.hasMTCCoverage && (
        <MTCLossHistory
          policyID={policyID}
          formData={formData}
          setFormData={setFormData}
          currentYear={currentYear}
          displayMTCLossHistory={displayMTCLossHistory}
          setDisplayMTCLossHistory={setDisplayMTCLossHistory}
        />
      )}
      {hasAtLeastOneCoverage && (
        <OtherLosses
          policyID={policyID}
          formData={formData}
          setFormData={setFormData}
          currentYear={currentYear}
        />        
      )}
    </div>
  );
}
