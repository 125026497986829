import {
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { auth, db } from "../../../firebase";
import useTranslations from "../../../Context/actions/useTranslations";
import calculateAge from "../../utils/calculateAge";
import deleteFileFromStorage from "../../utils/deleteFromStorage";
import { IconButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  DeleteOutline as DeleteOutlineIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { pdfjs } from "react-pdf";
import DriverDetailFlags from "../RedFlags/DriverDetailFlags";
import GoBack from "../../generals/GoBack";
import LicenseUploadForm from "./fileUpload/LicenseUploadForm";
import BlueButton from "../../assets/forms/BlueButton";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Notes from "../Notes/agent";
import MiniLossControl from "../../LossControl/MiniLossControl";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import MiniPoliciesList from "../../LossControl/FullViolationsList/MiniPoliciesList";
import ApiService from "../../../services/southern-api/apiService";
import TableSkeleton from "../../generals/TableSkeleton";
import MultiUpload from "../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import DriverTrainingPanel from "./DriverTrainingPanel";
import qs from "qs";
import { validateIfBlacklisted } from "../../utils/validateIfBlacklisted";
import Popup from "../../generals/Popup";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import RaiseDriverFlag from "./RaiseDriverFlag";

export default function DriverDetails(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const {
    selectedDriver: driverID,
    setShowDriverDetails,
    policyID,
    setShowTable,
    userInfo,
    formData,
    insuredInfo
  } = props;

  const apiService = new ApiService("southern");
  const { t } = useTranslations();
  const [progressState, setProgressState] = useState(0);
  const [imgUrl, setImgUrl] = useState("");
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const isUnderwriter = userInfo?.role === "Underwriter" || userInfo?.role === "Admin";

  const { agencyID, insuredID } = formData;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goBack = (e) => {
    e.preventDefault();
    setShowDriverDetails(false);
    if (setShowTable) setShowTable(true);
  };

  const eraseDriverLicenseURL = async () => {
    const docRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      "drivers",
      driverID
    );
    updateDoc(docRef, {
      licensePhoto: "",
    });
  };

  function formatDate(dateStr) {
    const formattedDate = new Date(dateStr.split("/").reverse().join("/"))
      .toDateString()
      .split(" ")
      .slice(1, 4)
      .join(" ");
    return formattedDate;
  }

  const [driver, setDriver] = useState({});
  const [driverPopulated, setDriverPopulated] = useState(false);
  useEffect(() => {
    if (!auth.currentUser) {
      apiService.renewalProcessDriver({
        agencyID,
        insuredID,
        driverID,
      }).then((res) => {
        const driver = res.driver;
        setDriver(driver);
        setDriverPopulated(true);
      }).catch((error) => {
        console.error(error)
        console.log("No such document!");
      })
      return
    }
    const docRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      "drivers",
      driverID
    );
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setDriver({ ...docSnap.data(), id: docSnap.id });
        setDriverPopulated(true);
      } else {
        console.log("No such document!");
      }
    });
    return () => {
      unsubscribe();
    };
  }, [policyID, driverID]);

  const [fileObjectsLicenseFront, setFileObjectsLicenseFront] = useState([]);
  const [fileObjectsLicenseBack, setFileObjectsLicenseBack] = useState([]);
  const [fileObjectsMvr, setFileObjectsMvr] = useState([]);
  const [fileObjectsCaDriverAbstract, setFileObjectsCaDriverAbstract] =
    useState([]);
  const [showScore, setShowScore] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showSCTResults, setShowSCTResults] = useState(false);
  const [showPolicies, setShowPolicies] = useState(false);
  const [showDriverMonthlyReport, setShowDriverMonthlyReport] = useState(false);
  const [showTraining, setShowTraining] = useState(false);
  const [fileObjectsMedicalForm, setFileObjectsMedicalForm] = useState([]);
  const [fileObjectsAdditionalForm, setFileObjectsAdditionalForm] = useState(
    []
  );
  const [fileObjectsDriverMonthlyReport, setFileObjectsDriverMonthlyReport] =
    useState([]);
  const [fileObjectsOtherDocuments, setFileObjectsOtherDocuments] = useState(
    []
  );

  const [violations, setViolations] = useState([]);

  useEffect(() => {
    if (!auth.currentUser) {
      return
    }
    if (driverPopulated) {
      const getViolationsListener = () => {
        const q = query(
          collection(db, "loss-control", "driverData", "violations"),
          where("licenseNumber", "==", driver.licenseNumber)
        );

        // Listen for changes in the query results
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const promises = [];
          querySnapshot.forEach((doc) => {
            const violationData = doc.data();
            const eventualitiesRef = collection(
              db,
              "loss-control",
              "tables",
              "eventualities"
            );

            // Query eventualities collection for matching code
            const q1 = query(
              eventualitiesRef,
              where("code", "==", violationData.violationCode)
            );
            const promise1 = getDocs(q1).then((snapshot) => {
              let matchingEventuality = null;
              snapshot.forEach((doc) => {
                matchingEventuality = doc.data();
              });
              // Perform first join operation
              const joinedViolation = {
                ...violationData,
                ...matchingEventuality,
              };
              // Query classifications collection for matching classification
              const classificationsRef = collection(
                db,
                "loss-control",
                "tables",
                "classifications"
              );
              const q2 = query(
                classificationsRef,
                where(
                  "classification",
                  "==",
                  matchingEventuality.classification
                )
              );
              const promise2 = getDocs(q2).then((snapshot) => {
                let matchingClassification = null;
                snapshot.forEach((doc) => {
                  matchingClassification = doc.data();
                });
                // Perform second join operation
                const fullyJoinedViolation = {
                  ...joinedViolation,
                  ...matchingClassification,
                };
                return fullyJoinedViolation;
              });
              return promise2;
            });
            promises.push(promise1);
          });

          // Wait for all promises to resolve
          const fullyJoinedViolations = await Promise.all(promises);
          // Update violations state with the fully joined violations
          setViolations(fullyJoinedViolations);
        });

        return unsubscribe;
      };

      const unsubscribe = getViolationsListener();

      return () => unsubscribe();
    }
  }, [driver]);

  useEffect(() => {
    if (driver?.driverLicenseFront)
      setFileObjectsLicenseFront([driver?.driverLicenseFront]);
    if (driver?.driverLicenseBack)
      setFileObjectsLicenseBack([driver?.driverLicenseBack]);
    if (driver?.MVR) setFileObjectsMvr([driver?.MVR]);
    if (driver?.caDriverAbstract)
      setFileObjectsLicenseFront([driver?.caDriverAbstract]);
    if (driver?.medicalLongForm)
      setFileObjectsMedicalForm([driver?.medicalLongForm]);
    if (driver?.additionalDocument)
      setFileObjectsAdditionalForm(driver?.additionalDocument);
    if (driver?.driverMonthlyReport)
      setFileObjectsDriverMonthlyReport(driver?.driverMonthlyReport);
    if (driver?.otherDocuments)
      setFileObjectsOtherDocuments(driver?.otherDocuments);
  }, [driver]);

  const { controlToast } = useGeneralState();

  const handleSubmit = async (e) => {
    try {
      if (e) e?.preventDefault();
      const payload = {};
      payload["driverLicenseFront"] = fileObjectsLicenseFront[0]
        ? fileObjectsLicenseFront[0]
        : "";
      payload["driverLicenseBack"] = fileObjectsLicenseBack[0]
        ? fileObjectsLicenseBack[0]
        : "";
      payload["MVR"] = fileObjectsMvr[0] ? fileObjectsMvr[0] : "";
      payload["caDriverAbstract"] = fileObjectsCaDriverAbstract[0]
        ? fileObjectsCaDriverAbstract[0]
        : "";
      payload["medicalLongForm"] = fileObjectsMedicalForm[0]
        ? fileObjectsMedicalForm[0]
        : "";
      payload["additionalDocument"] = fileObjectsAdditionalForm?.[0]
        ? fileObjectsAdditionalForm
        : [];
      payload["driverMonthlyReport"] = fileObjectsDriverMonthlyReport?.[0]
        ? fileObjectsDriverMonthlyReport
        : [];
      payload["otherDocuments"] = fileObjectsOtherDocuments?.[0]
        ? fileObjectsOtherDocuments
        : [];

      if (auth?.currentUser) {
        await setDoc(
          doc(
            db,
            "agencies",
            agencyID,
            "insureds",
            insuredID,
            "drivers",
            driverID
          ),
          payload,
          { merge: true }
        );
      } else {
        await apiService.renewalProcessUpdateDriver({
          agencyID,
          insuredID,
          driverID,
          payload,
        })
      }
    } catch (error) {
      controlToast(true, "There was an error in the images upload", "error");
      throw error;
    }
  };

  const [didRender, setDidRender] = useState(false);

  useEffect(() => {
    if (!didRender) {
      setDidRender(true);
      return;
    }
    handleSubmit();
  }, [
    fileObjectsLicenseFront,
    fileObjectsLicenseBack,
    fileObjectsMvr,
    fileObjectsCaDriverAbstract,
    fileObjectsMedicalForm,
    fileObjectsAdditionalForm,
    fileObjectsDriverMonthlyReport,
    fileObjectsOtherDocuments,
  ]);

  const [sctUpdateLoading, setSctUpdateLoading] = useState(false);

  const isWithinWorkingHours = () => {
    const now = new Date();
    const currentHour = now.getHours();
    return currentHour >= 8 && currentHour < 18;
  }

  const handleSCTResults = async (e) => {
    e.preventDefault();
    if (isWithinWorkingHours()) {
      const { licenseNumber, mxNMP } = driver;
      try {
        setSctUpdateLoading(true);
        const results = await apiService.updateSCT({
          driverLicenseNumber: licenseNumber,
          driverSCTNumber: mxNMP,
        });
        const responseData = qs.parse(results);
        const payload = {
          driverLookupStatus: "Incomplete",
        };
        if (responseData.error === "No se encontro información") {
          payload["driverLookupStatus"] = "No information found";
          payload["driverLookupDate"] = new Date()
          controlToast(
            true,
            "The driver probably does not have a valid NPM or License Number to request SCT results", "error",
            "error"
          );
          setSctUpdateLoading(false);
          return
        } else {
          payload["driverLookupStatus"] = "Complete";
          payload["driverLookupDate"] = new Date()
          payload["sctResults"] = responseData;
        }
        if (auth?.currentUser) {
          await setDoc(
            doc(
              db,
              "agencies",
              agencyID,
              "insureds",
              insuredID,
              "drivers",
              driverID
            ),
            payload,
            { merge: true }
          );
        } else {
          await apiService.renewalProcessUpdateDriver({
            agencyID,
            insuredID,
            driverID,
            payload,
          })
        }
        setSctUpdateLoading(false);
      } catch (error) {
        setSctUpdateLoading(false);
        console.error(error);
      }
    } else {
      controlToast(true, "Please try again during working hours from 8:00am to 6:00pm", "error");
    }
  };

  const [wasBlacklistedBefore, setWasBlacklistedBefore] = useState(false);

  useEffect(() => {
    if (driver) {
      validateIfBlacklisted([driver], true, auth?.currentUser).then((verifiedInBlacklistDrivers) => {
        const blacklistData = verifiedInBlacklistDrivers[0];
        const wasBlacklistedButNotAnymore =
          blacklistData.blacklistExpirationDate
            ? blacklistData.blacklistExpirationDate.toDate() < new Date()
            : false;
        setWasBlacklistedBefore(wasBlacklistedButNotAnymore);
      });
    }
  }, [driver]);

  const [openGoodToGoDriverModal, setOpenGoodToGoDriverModal] = useState(false)

  const handleOpenGoodToGoDriverModal = () => {
    setOpenGoodToGoDriverModal(!openGoodToGoDriverModal)
  }

  const isGoodToGo = Boolean(driver?.goodToGo)
  const handleGoodToGoDriver = async () => {
    try {
      await setDoc(
        doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          "drivers",
          driverID
        ),
        {
          goodToGo: !isGoodToGo
        },
        { merge: true }
      );
      controlToast(true, `Driver is ${isGoodToGo ? 'unaccepted again' : 'good to go'}`, 'success')
      handleOpenGoodToGoDriverModal()
    } catch (error) {
      console.error(error)
    }
  }

  const location = useLocation()
  const isTemporalUrl = location?.pathname?.includes('temporarily')

  return (
    <div>
      <Popup
        isOpen={openGoodToGoDriverModal}
        onRequestClose={handleOpenGoodToGoDriverModal}
      >
        <div className="flex flex-col justify-center items-center">
          <p className="text-2xl mt-2 font-semibold mb-3 text-center">
            Do you want to {!isGoodToGo ? 'accept' : 'unaccept'} this driver? This action is reversible.
          </p>
          <div className="w-full">
            <ApprovalButtons
              onApprove={async (e) => {
                e.preventDefault()
                await handleGoodToGoDriver()
              }}
              onDecline={handleOpenGoodToGoDriverModal}
              titleAccept={"SUBMIT"}
              titleDecline={"CANCEL"}
            />
          </div>
        </div>
      </Popup>
      <Container className="p-0">
        <GoBack handleFn={(e) => goBack(e)} className="my-3 cancel-text" />
        <Row>
          <Col xs={12} className="mb-2">
            <DriverDetailFlags singleIcon={false} driver={driver} />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12} className="mb-2">
            <p className="capitalize font-bold text-2xl">
              {driver.driverFirst} {driver.driverLast}
            </p>
            <div className="pl-4 mt-2 mb-3">
              <p className="capitalize text-lg font-md">
                DOB:{" "}
                {driver.dob
                ? driver.dob.toDate().toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  })
                : ""}
                {driver.dob
                  ? ` (Age: ${calculateAge(driver?.dob?.toDate())})`
                  : ""}
              </p>
              <p className="capitalize text-lg font-md">
                {t("plm.drivers.country")}: {driver.licenseCountry}
              </p>
              {driver.licenseCountry === "USA" && (
                <p className="capitalize text-lg font-md">
                  {t("plm.drivers.state")}: {driver.licenseState}
                </p>
              )}
              <p className="capitalize text-lg font-md">
                {t("plm.drivers.license-number")}: {driver.licenseNumber}
              </p>
              {driver.licenseCountry === "MX" && (
                <p className="capitalize text-lg font-md">
                  {t("plm.drivers.nmp")}: {driver.mxNMP}
                </p>
              )}
              <p className="capitalize text-lg font-md">
                  {t('plm.drivers.years-experience:')} {driver.yoe}
              </p>
              {wasBlacklistedBefore ? (
                <p className="capitalize text-lg font-md">
                  {t('plm.drivers.blacklisted-before')}
                </p>
              ) : null}
              {isUnderwriter && (<>
              {!isTemporalUrl && driverID && (
                <RaiseDriverFlag
                handleOpenGoodToGoDriverModal={handleOpenGoodToGoDriverModal}
                  isTemporalUrl={isTemporalUrl}
                  formData={formData}
                  driverID={driverID}
                  policyID={policyID}
                  driver={driver}
                  insuredInfo={insuredInfo}
                />
              )}</>)}
            </div>
          </Col>
          <Col lg={6} md={12} className="mb-2">
            <div className="card">
              <div className="card-header flex justify-between items-center">
                <span className="text-lg">{t("Driver's License")}</span>
                <span className="text-[#072a48]">
                  {driver.licensePhoto ? (
                    <div className="flex items-center">
                      <Link
                        to={driver.licensePhoto}
                        target="_blank"
                        className="capitalize text-[#072a48] flex items-center"
                      >
                        <IconButton size="small">
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </Link>
                      <button
                        onClick={() => {
                          deleteFileFromStorage(driver.licensePhoto);
                          eraseDriverLicenseURL();
                        }}
                        className="capitalize text-[#8B0000] flex items-center ml-2"
                      >
                        <IconButton size="small">
                          <DeleteOutlineIcon />
                        </IconButton>
                      </button>
                    </div>
                  ) : null}
                </span>
              </div>

              <div className="card-body h-100">
                <LicenseUploadForm
                  licenseNumber={driver?.licenseNumber}
                  dob={driver?.dob}
                  type={driver?.licenseCountry}
                  tutor={driver?.tutor}
                  fileObjectsLicenseFront={fileObjectsLicenseFront}
                  setFileObjectsLicenseFront={setFileObjectsLicenseFront}
                  fileObjectsLicenseBack={fileObjectsLicenseBack}
                  setFileObjectsLicenseBack={setFileObjectsLicenseBack}
                  fileObjectsMvr={fileObjectsMvr}
                  setFileObjectsMvr={setFileObjectsMvr}
                  fileObjectsCaDriverAbstract={fileObjectsCaDriverAbstract}
                  setFileObjectsCaDriverAbstract={
                    setFileObjectsCaDriverAbstract
                  }
                  fileObjectsMedicalForm={fileObjectsMedicalForm}
                  setFileObjectsMedicalForm={setFileObjectsMedicalForm}
                  fileObjectsAdditionalForm={fileObjectsAdditionalForm}
                  setFileObjectsAdditionalForm={setFileObjectsAdditionalForm}
                  fileObjectsOtherDocuments={fileObjectsOtherDocuments}
                  setFileObjectsOtherDocuments={setFileObjectsOtherDocuments}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="my-2">
            {driver.licenseCountry === "MX" ? (
              <div className="card mb-4">
                <div className="card-header">
                  {t("plm.drivers.details.sct-results")}
                  <ExpandCollapseArrow
                    show={showSCTResults}
                    setShow={setShowSCTResults}
                  />
                </div>
                {showSCTResults && (
                  <div className="card-body">
                    {driver?.licenseCountry === "MX" &&
                    driver?.licenseNumber &&
                    driver?.mxNMP ? (
                      <BlueButton
                        text={t("plm.drivers.details.update-sct-results")}
                        onClickFunction={handleSCTResults}
                        hasContentToBottom={true}
                        icon={<RefreshIcon />}
                        disabled={sctUpdateLoading}
                      />
                    ) : null}
                    {sctUpdateLoading ? (
                      <TableSkeleton rows={2} columns={1} />
                    ) : (
                      <>
                        <p>
                          <b>{t("plm.drivers.details.lookup-status")}:</b>{" "}
                          {driver.driverLookupStatus || "Incomplete"}
                        </p>
                        {driver.driverLookupStatus === "Complete" &&
                          driver?.sctResults && (
                            <div>
                              <p>
                                <b>{t("plm.drivers.details.name-sct")}:</b>{" "}
                                {driver.sctResults?.driverName}
                              </p>
                              <p>
                                <b>{t("plm.drivers.details.determination")}:</b>{" "}
                                {driver.sctResults?.determination}
                              </p>
                              <p>
                                <b>{t("plm.drivers.details.observations")}:</b>{" "}
                                {driver.sctResults?.observations}
                              </p>
                              <p>
                                <b>{t("plm.drivers.details.license-class")}:</b>{" "}
                                {driver.sctResults?.licenseClass}
                              </p>
                              <p>
                                <b>{t("plm.drivers.details.fit-drive")}:</b>{" "}
                                {driver.sctResults?.driverFit}
                              </p>
                              <p>
                                <b>{t("plm.drivers.details.license-expires")}:</b>{" "}
                                {formatDate(driver.sctResults?.expires)}
                              </p>
                              <p>
                                <b>{t("plm.drivers.details.medical-exam-expires")}:</b>{" "}
                                {formatDate(driver.sctResults?.examExpiration)}
                              </p>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                )}
              </div>
            ) : null}
            {!isTemporalUrl ?
              <>
                <div className="card my-4">
                  <div className="card-header">
                    {t("plm.drivers.details.full-policies-list")}
                    <ExpandCollapseArrow
                      show={showPolicies}
                      setShow={setShowPolicies}
                    />{" "}
                  </div>

                  {showPolicies && (
                    <div className="card-body">
                      <MiniPoliciesList userInfo={userInfo} driver={driver} />
                    </div>
                  )}
                </div>
                <div className="card my-4">
                  <div className="card-header">
                    {t("plm.drivers.details.driver-score")}
                    <ExpandCollapseArrow
                      show={showScore}
                      setShow={setShowScore}
                    />{" "}
                  </div>

                  {showScore && (
                    <div className="card-body">
                      <MiniLossControl userInfo={userInfo} driver={driver} />
                    </div>
                  )}
                </div>
                <div className="card my-4">
                  <div className="card-header">
                    {t('plm.drivers.details.driver-training')}
                    <ExpandCollapseArrow
                      show={showTraining}
                      setShow={setShowTraining}
                    />
                  </div>
                  {showTraining && (
                    <div className="card-body">
                      <DriverTrainingPanel
                        driver={driver}
                        driversLicenseNumber={driver.licenseNumber}
                      />
                    </div>
                  )}
                </div>
                <div className="card">
                  <div className="card-header">
                    {t("plm.drivers.details.driver-notes")}
                    <ExpandCollapseArrow show={showNotes} setShow={setShowNotes} />
                  </div>
                  {showNotes && (
                    <div className="card-body">
                      <Notes
                        firstBreadcrumb={t('plm.drivers.details.driver-details')}
                        hideBreadcrumbs={true}
                        formData={formData}
                        userInfo={userInfo}
                        documentID={driver.id}
                        auth={auth}
                        collectionPath={`driver-notes/${driver.licenseNumber}/notes`}
                      />
                    </div>
                  )}
                </div>
              </> : null
            }
            {driver.tutor ? (
              <div className="card my-4">
                <div className="card-header">
                  {t('driver-monthly-report')}
                  <ExpandCollapseArrow
                    show={showDriverMonthlyReport}
                    setShow={setShowDriverMonthlyReport}
                  />{" "}
                </div>
                {showDriverMonthlyReport && (
                  <div className="card-body">
                    <div className="mb-2">
                      <MultiUpload
                        label={t('driver-monthly-report')}
                        property="driverMonthlyReport"
                        formData={fileObjectsDriverMonthlyReport}
                        setFormData={setFileObjectsDriverMonthlyReport}
                        storageFile={`files/drivers/${driver.licenseNumber}/monthly-report`}
                        multiple={true}
                        changeNormal={true}
                        fileInButton={true}
                        width="100%"
                        dissapearWhenImgExists={true}
                        showInAPopup={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
