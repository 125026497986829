import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import SendLetterPanel from "./letters/SendLetterPanel";
import DriverSection from "./DriverSection";
import GoBack from "../../generals/GoBack";
// import { useLocation, useNavigate } from "react-router-dom";

export default function UnderwriterPanel({
  setDisplayUnderwritingPanel,
  tabProps,
  getEndorsements,
  policyID,
}) {
  const { formData, insuredInfo, userInfo } = tabProps?.driverPanel;

  // const location = useLocation();
  // const navigate = useNavigate();

  const [displaySendLetterPanel, setDisplaySendLetterPanel] = useState(true);
  const [displayDriverSection, setDisplayDriverSection] = useState(true);
  const theme = useTheme();
  const screenNotSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const [showDriverExclusions, setShowDriverExclusions] = useState(false);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   if (params.has('sendLetter')) {
  //     params.delete('sendLetter');
  //     const newSearch = params.toString();
  //     const newPath = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
  //     navigate(newPath, { replace: true });
  //     setDisplaySendLetterPanel(true);
  //   }
  // }, [location]);

  return (
    <div className={"card my-3 w-full" + (screenNotSmall ? " ml-3" : "")}>
      <div className="card-body">
        <GoBack handleFn={() => setDisplayUnderwritingPanel(false)} />
        {/* <h1 className="text-3xl mt-3">Underwriter Panel</h1>
        <div className="my-2">
          <p>
            Here you may issue notices, underwrite drivers, and perform other
            important underwriting actions.
          </p>
          <p className="mt-2">Make a selection below to get started.</p>
        </div> */}
        {/* <div className="mt-4 mb-5">
          {displayDriverSection && (
            <DriverSection
              setDisplaySendLetterPanel={setDisplaySendLetterPanel}
              getEndorsements={getEndorsements}
              tabProps={tabProps}
              formData={formData}
              showDriverExclusions={showDriverExclusions}
              setShowDriverExclusions={setShowDriverExclusions}
              insuredInfo={insuredInfo}
            />
          )}
        </div> */}
        {formData.documentType === "Policy" && !showDriverExclusions ? (
          <SendLetterPanel
            policyID={policyID}
            formData={formData}
            userInfo={userInfo}
            insuredInfo={insuredInfo}
            agencyID={userInfo.agencyID}
            display={displaySendLetterPanel}
            setDisplay={setDisplaySendLetterPanel}
            setDisplayOthers={setDisplayDriverSection}
          />
        ) : null}
      </div>
    </div>
  );
}
