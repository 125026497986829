import React, { useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import UserProfileAvatar from "../user/Dashboard/UserProfileAvatar";
import useTranslations from "../../Context/actions/useTranslations";
import {
  ACCOUNT_ROUTE,
  AGENT_MANAGE_APPLICATIONS_ROUTE,
  AGENT_MANAGE_POLICIES_ROUTE,
  LOSS_RUNS_ROUTE,
  NEW_SUPPORT_TICKET_ROUTE,
  PROOF_OF_FILINGS_ROUTE,
  SIGN_IN_ROUTE,
  SUBMIT_BUG_ROUTE,
  VIEW_BUGS_ROUTE,
} from "../../utils/routes";
import config from "../../config/env";
import useGeneralState from "../../Context/actions/useGeneralState";
import AgentManageMenu from "./agent/AgentManageMenu";
import AdminManageMenu from "./admin/AdminManageMenu";
import UnderwriterManagerMenu from "./underwriter/UnderwriterManagerMenu";
import { useMediaQuery } from "@mui/material";
import { AttachFile } from "@mui/icons-material";

export default function NavItems(props) {
  const navigate = useNavigate();
  const userInfo = props.userInfo;

  const { t } = useTranslations();
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const { resetState } = useGeneralState();

  const logout = () => {
    signOut(auth)
      .then(() => {
        resetState();
        setIsLoggedIn(false);
        navigate(SIGN_IN_ROUTE);
      })
      .catch(() => {
        console.log("Error trying to sign out");
      });
  };

  const [initializing, setInitializing] = useState(true);
  const [listenUser, setListenUser] = useState(false);
  const [user, setUser] = useState(null);

  /** Listen for auth state changes */
  useEffect(() => {
    const authListener = auth.onAuthStateChanged((result) => {
      setUser(result);
      if (initializing && !listenUser) {
        setInitializing(false);
        setListenUser(true);
      }
    });

    return () => {
      if (authListener) {
        authListener();
      }
    };
  }, [initializing, listenUser]);

  /** Listen for user changes */
  useEffect(() => {
    let userListener;

    if (listenUser) {
      // TODO @react-native-firebase/auth provides `onUserChanged` which is this and more.
      // what else can we add and still be web-compatible?
      userListener = auth.onIdTokenChanged((result) => {
        setUser(result);
      });
    }

    return () => {
      if (userListener) {
        userListener();
      }
    };
  }, [listenUser]);

  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config
  const isLiconaInsuranceGroup = userInfo.agencyID === REACT_APP_LICONA_INSURANCE_GROUP_ID

  return (
    <>
      <Nav className={`me-auto my-2 my-lg-0 w-full ${!isMobileScreen ? 'items-center' : ''}`}>
        <Nav.Link className="px-3" href={LOSS_RUNS_ROUTE}>
          {t("navbar.loss-runs")}
        </Nav.Link>
        <Nav.Link className="px-3" href={NEW_SUPPORT_TICKET_ROUTE}>
          {t("navbar.support")}
        </Nav.Link>
        {auth.currentUser && (
          <>
            <Nav.Link className="px-3" href={ACCOUNT_ROUTE}>
              {" "}
              {t("navbar.dashboard")}{" "}
            </Nav.Link>
            {isLiconaInsuranceGroup ?
              <NavDropdown
              className="px-3"
              title={t("navbar.manage")}
              id="basic-nav-dropdown"
            >
              <div className="max-h-[400px] overflow-y-auto">
                {(userInfo.role === "Agent" || userInfo.role === "Sub-User") && (
                  <AgentManageMenu />
                )}
                {userInfo.role === "Admin" && (
                  <>
                    <AdminManageMenu />
                    <NavDropdown.Divider />
                  </>
                )}
                {(userInfo.role === "Underwriter" || userInfo.role === "Admin") && (
                  <UnderwriterManagerMenu />
                )}
                <NavDropdown.Item href={PROOF_OF_FILINGS_ROUTE}>
                  <AttachFile className="mr-2" /> {t("navbar.filings")}
                </NavDropdown.Item>
              </div>
            </NavDropdown> : null
            }
            {(
              (userInfo.role === "Underwriter" ||
              userInfo.role === "Admin" ||
              userInfo.role === "Agent" ||
              userInfo.role === "Sub-User"
            ) &&
              isLiconaInsuranceGroup
            ) && (
              <>
                <Nav.Link
                  className="px-3"
                  href={AGENT_MANAGE_APPLICATIONS_ROUTE}
                >
                  {t("navbar.applications")}
                </Nav.Link>
                <Nav.Link className="px-3" href={AGENT_MANAGE_POLICIES_ROUTE}>
                  {t("navbar.policies")}
                </Nav.Link>
              </>
            )}

            <div className={`flex ${!isMobileScreen ? 'justify-end flex-grow' : ''}`}>
              <UserProfileAvatar userInfo={userInfo} logout={logout} />
            </div>
          </>
        )}

        {!auth.currentUser && (
          <Nav.Link href={SIGN_IN_ROUTE}>{t("navbar.login")}</Nav.Link>
        )}
        {!isLiconaInsuranceGroup ? (
          <Nav.Link onClick={logout}>Logout</Nav.Link>
        ) : null}
      </Nav>
    </>
  );
}
