import React from "react";
import {
  ADMIN_ROUTE,
  CALENDAR_ROUTE
} from "../../../utils/routes";
import { NavDropdown } from "react-bootstrap";
import { AdminPanelSettings, CalendarToday } from '@mui/icons-material';

export default function AdminManageMenu() {
  return (
    <>
      <NavDropdown.Item href={ADMIN_ROUTE}>
        <AdminPanelSettings className="mr-2" /> Admin
      </NavDropdown.Item>
      <NavDropdown.Item href={CALENDAR_ROUTE}>
        <CalendarToday className="mr-2" /> Calendar
      </NavDropdown.Item>
    </>
  );
}