import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import getTaxSchedule from "../getTaxSchedule";
import getStampFee from "./getStampFee";
import getSurplusLinesRate from "./getSurplusLinesRate";
import numberWithCommas from "../numberWithCommas";

const calculateTaxesAPD = async (policyID, formData, apdPremium) => {
  if (!formData.hasAPDCoverage) return 0;

  const taxSchedule = await getTaxSchedule();
  const taxesAndFees = { ...taxSchedule };

  const stampFee = getStampFee(formData.stateOfEntry, taxesAndFees);
  const surplusLinesTax = getSurplusLinesRate(
    formData.stateOfEntry,
    taxesAndFees
  );

  const taxAmounts = {
    stampTaxAmount: (apdPremium * stampFee * 100) / 100,
    surplusLinesTaxAmount: (apdPremium * surplusLinesTax * 100) / 100,
  };

  updateDoc(doc(db, "policy-applications", policyID), {
    apdDetails: { ...formData?.apdDetails, ...taxAmounts },
  });

  return taxAmounts;
};


export default calculateTaxesAPD