import React, { useEffect } from "react";
import updatePolicyField from "../../utils/updatePolicyField";
import { debounce } from "lodash";

const TextInput = (props) => {
  
  const debouncedUpdateField = debounce((value) => {
    if (props.doNotDebounce) return;
    updatePolicyField(props.policyID, [props.property], value);
  }, 300);

  useEffect(() => {
    return () => debouncedUpdateField.cancel();
  }, [props.formData, props.property]);

  const handleChange = (e) => {
    let inputValue = e.target.value;
    if (props.withoutSpaces) {
      inputValue = inputValue.replace(/\s/g, '');
    }
    props.setFormData({
      ...props.formData,
      [props.property]: inputValue,
    });
    debouncedUpdateField(inputValue);
  };

  return (
    <div className="my-2">
      <label
        htmlFor={props?.property}
        className={props?.labelCustomClass ? props.labelCustomClass : "block text-sm font-medium text-gray-700"}
      >
        {props?.label}
      </label>
      <input
        id={props?.property}
        type="text"
        className={props?.customClass ? props.customClass : "mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
        value={props?.formData?.[props?.property] || ""}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextInput;