import React, { useEffect, useState } from "react";
import {
  getDocs,
  addDoc,
  collection,
  query,
  deleteDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import "react-datepicker/dist/react-datepicker.css";
import "../styling/app.css";
import { getAuth } from "firebase/auth";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import useUsersState from "../../Context/actions/useUsersState";

function FilingLog() {
  const auth = getAuth();
  const { user } = useUsersState();
  const userID = user.uid;

  const [namedInsured, setNamedInsured] = useState("");

  const submitFiling = async () => {
    await addDoc(collection(db, "filings"), {
      createdOn: new Date(),
      authorName: auth.currentUser.displayName,
      authorID: auth.currentUser.uid,
      namedInsured,
      filingType,
      notes,
    });
    getFilings();
    setNamedInsured("");
    setFilingType("");
    setNotes("");
  };

  const deleteFiling = async (docID) => {
    let verify = prompt(
      "You are about to delete an Filing! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "filings", docID));
      getFilings();
    }
  };

  const [filingList, setFilingList] = useState([]);
  const [gotFilings, setGotFilings] = useState(false);

  const getFilings = async () => {
    setGotFilings(false);
    const q = query(collection(db, "filings"), orderBy("createdOn"));
    const queryData = await getDocs(q);
    const queryResults = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setFilingList(queryResults.reverse());
    setGotFilings(true);
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toDateString();
  };

  const convertDateLong = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString();
  };

  const [filingType, setFilingType] = useState();
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (gotFilings === false) getFilings();
  }, [userID, gotFilings, user.role]);

  return (
    <>
      <main className="max-w-[1200px] mx-auto">
        <div className="my-2">
          <a href="/letterlog" className="text-[#072a48]">
            Letter Log
          </a>
          {" | "}
          <a href="/systemlog" className="text-[#072a48]">
            System Log
          </a>
        </div>
        <h1 className="text-[#072a48] text-2xl font-bold mb-2">Filings Log</h1>
        <section className="bg-gray-200 p-3 rounded-md mb-3">
          <h2 className="text-xl font-medium mb-2">New Filing Log Entry</h2>
          <form>
            <div className="flex items-center mb-4">
              <select
                value={filingType}
                onChange={(e) => setFilingType(e.target.value)}
                className="form-control w-full"
              >
                <option value="">Filing Type</option>
                <option value="Federal">Federal Filings</option>
                <option value="Cancel (Fed)">Federal Cancellation</option>
                <option value="Cancel (State)">State Cancellation</option>
                <option value="Texas">Texas State Filings</option>
                <option value="California">California State Filings</option>
                <option value="Arizona">Arizona State Filings</option>
                <option value="New Mexico">New Mexico State Filings</option>
              </select>
            </div>
            <input
              type="text"
              placeholder="Named Insured"
              value={namedInsured}
              onChange={(e) => setNamedInsured(e.target.value)}
              className="text-input w-full"
            />
            <input
              type="text"
              placeholder="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="text-input w-full"
            />
          </form>
          <button
            onClick={() => {
              setNamedInsured("");
              setNotes("");
              setFilingType("");
            }}
            className="button-1"
          >
            Cancel
          </button>
          <button onClick={submitFiling} className="button-2">
            Submit
          </button>
        </section>

        <p className="mb-3">
          This log was created on 06/07/2023 and does not include filings sent
          prior to that date.
        </p>
        <Table className="data-table w-full">
          <Thead>
            <Tr className="data-table-header">
              <Td>Log Date</Td>
              <Td>Type</Td>
              <Td>Named Insured</Td>
              <Td>Author</Td>
              <Td>Notes</Td>
              {user.role === "Admin" && <Td>Actions</Td>}
            </Tr>
          </Thead>
          <Tbody>
            {filingList.map((filing, i) => {
              return (
                <Tr
                  key={i}
                  title={convertDateLong(filing.createdOn.seconds)}
                  className="mb-2"
                >
                  <Td>{convertDate(filing.createdOn.seconds)}</Td>
                  <Td>{filing.filingType}</Td>
                  <Td>{filing.namedInsured}</Td>
                  <Td>{filing.authorName}</Td>
                  <Td>{filing.notes}</Td>
                  {user.role === "Admin" && (
                    <Td>
                      <button
                        onClick={(e) => deleteFiling(filing.id)}
                        className="text-[#072a48]"
                      >
                        Delete
                      </button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </main>
    </>
  );
}

export default FilingLog;
