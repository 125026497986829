import React, { useMemo, useState } from "react";
import AddDriver from "./AddDriver";
import ImportDrivers from "./ImportDrivers";
import DriverDetails from "./DriverDetails";
import DriversList from "./DriversList";
import BlueButton from "../../assets/forms/BlueButton";
import RedButton from "../../assets/forms/RedButton";
import Breadcrumbs from "../../assets/Breadcrumbs";
import useTranslations from "../../../Context/actions/useTranslations";
import useDriversList from "./useDriversList";
import FormPopup from "../../generals/Popup/FormPopup";
import { isExpired } from "../../../utils/helpers";
import TableSkeleton from "../../generals/TableSkeleton";
import { useMediaQuery } from "@mui/material";
import { generateXlsxFile } from "../../../utils/excelCreator";
import {
  Download as DownloadIcon,
  IosShare as IosShareIcon,
} from "@mui/icons-material";
import { filterRightPoliciesDetails } from "./setPolicyInfo";
import DropdownMultiUpload from "../../assets/forms/DynamicPropertiesInputs/DropdownMultiUpload";
import { handleDropdown } from "../../assets/lists/DynamicTable/utils";
import ImportAllLists from "./ImportAllLists";
import ImportInspas from "./ImportInspas";
import { useLocation } from "react-router-dom";
import ApiService from "../../../services/southern-api/apiService";
import useGeneralState from "../../../Context/actions/useGeneralState";
import "../../styling/styles.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function PolicyDrivers(props) {
  const {
    formData,
    policyID,
    insuredInfo,
    userInfo,
    driverList,
    isEndoManager,
    getEndorsements,
    setDidSuccess
  } = props;

  const [display, setDisplay] = useState(true);
  const [showAddDriver, setShowAddDriver] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const [showDriverDetails, setShowDriverDetails] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState();
  const { t } = useTranslations();
  const driverHook = useDriversList({
    ...props,
    setSelectedDriver,
    setShowTable,
    setShowDriverDetails,
    setShowImport,
    setShowAddDriver,
  });
  const { setDeletionMode, handleSubmit, deletionMode, skeletonTableLoader } =
    driverHook;

  const breadcrumbs = [{ label: formData.documentType }, { label: "Drivers" }];

  const { controlToast } = useGeneralState()

  const breadcrumbsDetails = [
    { label: formData.documentType },
    { label: "Drivers" },
    { label: "Driver Details" },
  ];

  const breadcrumbsDelete = [
    { label: formData.documentType },
    { label: "Drivers" },
    { label: "Delete" },
  ];

  const count = useMemo(() => {
    const effectiveElementsList = driverList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const counter = effectiveElementsList?.length
      ? effectiveElementsList.length
      : 0;
    return counter;
  }, [driverList]);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const activeList = driverList?.filter(elem => Boolean(
    filterRightPoliciesDetails(elem.policiesDetails, policyID)
      ?.status === 'Active'
  ))

  const handleImportFn = () => {
    setShowImport(true);
    setShowTable(false);
  }

  const [showImportAll, setShowImportAll] = useState(false)
  const [showImportInspas, setShowImportInspas] = useState(false)

  const handleImportAll = () => {
    setShowImportAll(true)
    setShowTable(false);
  }

  const handleImportInspas = () => {
    setShowImportInspas(true)
    setShowTable(false);
  }

  const titleDocumentType = formData?.documentType || ''

  const location = useLocation()
  const isTemporalUrl = location?.pathname?.includes('temporarily')

  const apiService = new ApiService()
  const handleFinishOperation = async () => {
    try {
      await apiService.renewalProcessDriverUpdateConfirmation({
        policyData: formData
      })
      setDidSuccess(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInsuredEditDrivers = async () => {
    try {
      const insuredDocRef = doc(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID
      );
      const insuredDocSnapshot = await getDoc(insuredDocRef);
      const insuredData = insuredDocSnapshot?.data();
      if (!insuredData?.email && !insuredData?.contactEmail) {
        alert(
          "The insured does not have any company email address or primary contact email. Please provide one in the Edit Insured's Details to continue."
        )
        return;
      }
      const linkToRedirect = window?.location?.href?.replace(
        "policy",
        "temporarily"
      );
      await apiService.renewalProcessDriverUpdate({
        link: linkToRedirect,
        policyData: formData,
      });
      controlToast(
        true,
        "The insured has been notified to update the drivers",
        "success"
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {display && (
        <div className={`card-body ${isEndoManager ? "p-0" : ""}`}>
          {showTable && !showImport && !showAddDriver && (
            <>
              {!isEndoManager && !isTemporalUrl ? (
                <Breadcrumbs
                  items={deletionMode ? breadcrumbsDelete : breadcrumbs}
                />
              ) : null}
              <h1
                className={`${
                  isEndoManager
                    ? "text-2xl mt-2.5 font-bold md:mb-0"
                    : "text-3xl mt-2 font-semibold md:mb-0"
                }`}
              >
                {!isEndoManager
                  ? `${
                      deletionMode
                        ? t("Delete")
                        : `${titleDocumentType} Drivers`
                    } ${deletionMode ? "" : `(${count})`}`
                  : `${
                      deletionMode ? t("Delete") : t("Endorse Drivers")
                    }`}
              </h1>
              <p className="my-3">
                {deletionMode
                  ? t(
                      "Select drivers from the bottom list to add to the deletion list. Click submit once you have selected all necessary drivers."
                    )
                  : t("plm.drivers.explanation")}
                {deletionMode && formData.documentType === "Policy" && (
                  <p className="my-2">
                    {t("The driver")}
                    <b> {t("will not be removed from the policy")}</b>{" "}
                    {t("until the endorsement is approved by an underwriter.")}
                  </p>
                )}
                {formData.documentType !== "Application" &&
                  formData.documentType !== "Policy" && (
                    <>Drivers may not be edited during the quoting phase.</>
                  )}
              </p>
              {skeletonTableLoader ? (
                <TableSkeleton rows={1} columns={4} />
              ) : (
                (formData.documentType === "Application" ||
                  formData.documentType === "Policy") && (
                  <div className="my-3 flex items-center flex-wrap">
                    <BlueButton
                      text={t("plm.drivers.add")}
                      onClickFunction={() => {
                        setShowAddDriver(true);
                        setShowTable(false);
                      }}
                      disabled={deletionMode}
                      hasContentToBottom={true}
                      hasContentToRight={!isMobileScreen}
                      className={isMobileScreen ? "w-[100%]" : ""}
                    />
                    {isTemporalUrl ?
                      <BlueButton
                        text='Finish operation'
                        onClickFunction={handleFinishOperation}
                        hasContentToBottom={true}
                        hasContentToRight={!isMobileScreen}
                        className={isMobileScreen ? "w-[100%]" : ""}
                        icon='no-icon'
                      /> : null
                    }
                    {!isTemporalUrl ?
                      <>
                        <DropdownMultiUpload
                          containerStyle={`bg-transparent border-none p-0
                            ${isMobileScreen ? "w-[100%]" : ""}
                            ${isMobileScreen ? 'dropdown-multiupload' : ''}
                          `}
                          wrapperStyle={`
                            text-[#8B0000] hover:text-[#8B0000] d-inline
                            ${isMobileScreen ? "w-[100%]" : ""}
                          `}
                          handleDropdown={() => handleDropdown(
                            null,
                            "import",
                            "drivers",
                            handleImportFn,
                            handleImportAll,
                            handleImportInspas,
                            t
                          )}
                          formData={formData}
                          disabled={deletionMode}
                          item={null}
                          icon="import"
                          dropdownType="import"
                          list={driverList}
                        />
                        {activeList?.length > 0 ?
                          <BlueButton
                            text={t('plm.drivers.export')}
                            onClickFunction={(e) => {
                              e.preventDefault()
                              generateXlsxFile(
                                activeList,
                                'Active Drivers',
                                'Active Drivers.xlsx'
                              )
                            }}
                            disabled={deletionMode}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                            icon={<DownloadIcon />}
                          /> : null
                        }
                        {(
                          formData?.documentType === "Application" &&
                          formData.status !== "In-Renewal"
                        ) &&
                        Boolean(deletionMode) ? (
                          <RedButton
                            text={t("plm.drivers.delete")}
                            onClickFunction={(e) => {
                              handleSubmit(e, "multiple", "massive");
                            }}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                        {!Boolean(deletionMode) && count !== 0 ? (
                          <RedButton
                            text={t('plm.drivers.delete')}
                            onClickFunction={(e) => {
                              setDeletionMode(true);
                            }}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                        {userInfo?.role === "Agent" || userInfo?.role === "Admin" ? (
                          <BlueButton
                            text="SHARE"
                            hasContentToBottom={true}
                            onClickFunction={handleInsuredEditDrivers}
                            icon={<IosShareIcon />}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                      </> : null
                    }
                  </div>
                )
              )}
            </>
          )}
          {showTable && (
            <>
              {formData.documentType === "Policy" && (
                <p className="mb-2">{t("plm.drivers.see-driver-details")}</p>
              )}
              <DriversList
                formData={formData}
                policyID={policyID}
                userInfo={userInfo}
                insuredInfo={insuredInfo}
                driverList={driverList}
                setDriverList={props.setDriverList}
                setShowTable={setShowTable}
                setSelectedDriver={setSelectedDriver}
                setShowDriverDetails={setShowDriverDetails}
                isEndoManager={isEndoManager}
                getEndorsements={getEndorsements}
                {...driverHook}
              />
              <FormPopup
                isOpen={driverHook.openModal}
                onRequestClose={driverHook.handleCloseModal}
                title={`Select the expiration date of the drivers
                ${driverHook.deletionMode ? " and the deletion reason" : ""}`}
                formSettings={driverHook.formSettings}
                loading={driverHook.modalSubmitting}
              />
            </>
          )}
          {showAddDriver && (
            <AddDriver
              isEndoManager={isEndoManager}
              getEndorsements={getEndorsements}
              driverList={driverList}
              setDriverList={props.setDriverList}
              formData={formData}
              insuredInfo={insuredInfo}
              setShowAddDriver={setShowAddDriver}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              saveForm={props.saveForm}
              setFormSaved={props.setFormSaved}
              formSaved={props.formSaved}
            />
          )}
          {showImport && (
            <ImportDrivers
              insuredInfo={insuredInfo}
              setDriverList={props.setDriverList}
              setShowAddDriver={setShowAddDriver}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImport}
            />
          )}
          {showImportAll && (
            <ImportAllLists
              insuredInfo={insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportAll}
            />
          )}
          {showImportInspas && (
            <ImportInspas
              insuredInfo={insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportInspas}
            />
          )}
          {showDriverDetails && (
            <>
              {!isEndoManager ? (
                <Breadcrumbs items={breadcrumbsDetails} />
              ) : null}
              <DriverDetails
                formData={formData}
                userInfo={userInfo}
                driverList={driverList}
                selectedDriver={selectedDriver}
                setShowDriverDetails={setShowDriverDetails}
                setShowTable={setShowTable}
                policyID={policyID}
                insuredInfo={insuredInfo}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
