import React, { useState } from "react";
import {
  Modal,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Renew from "./Renew";
import NonRenew from "./NonRenew";

const RenewPolicyModal = ({
  open,
  onClose,
  policyID,
  formData,
  insuredInfo
}) => {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [renew, setRenew] = useState(false);
  const [nonRenew, setNonRenew] = useState(false);

  return (
    <Modal open={open} onClose={null}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "100vh",
          overflowY: "auto",
          width: isMobileScreen ? "100%" : 400,
          height: isMobileScreen ? "100%" : "auto",
          borderRadius: isMobileScreen ? 0 : 4,
        }}
      >
        {!renew && !nonRenew && (
          <>
            <Typography className="mt-2" variant="h6" gutterBottom>
              Renewal Options
            </Typography>
            <p className="mb-2">
              Here you may mark a policy as non-renewal or begin the renewal
              process.
            </p>
            <p className="mb-3">
              Beginning the renewal process will mirror the current policy
              information in to a new application.
            </p>
            {formData.status !== "Renewal" && (
              <ApprovalButtons
                onApprove={(e) => {
                  setRenew(true);
                }}
                onDecline={onClose}
                titleAccept={"Renew Policy"}
                titleDecline={"Cancel"}
                disabledAccept={false}
                disabledDecline={false}
                handleAsSubmit={false}
                dissapearDecline={true}
              />
            )}
            {!formData?.nonRenewal && (
              <ApprovalButtons
                onApprove={(e) => {}}
                onDecline={(e) => setNonRenew(true)}
                titleAccept={"Doesn't matter"}
                titleDecline={"Mark As Non-Renewal"}
                disabledAccept={false}
                disabledDecline={false}
                handleAsSubmit={false}
                dissapearAccept={true}
              />
            )}
            <ApprovalButtons
              onApprove={(e) => {}}
              onDecline={onClose}
              titleAccept={"Doesn't matter"}
              titleDecline={"Cancel"}
              disabledAccept={false}
              disabledDecline={false}
              handleAsSubmit={false}
              dissapearAccept={true}
            />
          </>
        )}{" "}
        {renew && (
          <Renew
            onClose={onClose}
            policyID={policyID}
            setRenew={setRenew}
            insuredInfo={insuredInfo}
            formData={formData}
          />
        )}
        {nonRenew && (
          <NonRenew
            onClose={onClose}
            policyID={policyID}
            setNonRenew={setNonRenew}
          />
        )}
      </Box>
    </Modal>
  );
};

export default RenewPolicyModal;
