import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
  handleCopyToClipboardPolicy,
  itemBindDate,
  vinItemBordereauxAddress1,
  vinItemBordereauxAddress2,
  vinItemBordereauxBordereauNotes,
  vinItemBordereauxCity,
  vinItemBordereauxCountry,
  vinItemBordereauxCounty,
  vinItemBordereauxCoverageDeductible,
  vinItemBordereauxCoverageLimit,
  vinItemBordereauxCurrentPolicyStatus,
  vinItemBordereauxEndReportingPeriod,
  vinItemBordereauxEndoEffectiveDate,
  vinItemBordereauxEventTask,
  vinItemBordereauxFetTaxAmount,
  vinItemBordereauxFrontingFeeAmount,
  vinItemBordereauxFrontingFeePercentage,
  vinItemBordereauxGarageAddress,
  vinItemBordereauxGarageCity,
  vinItemBordereauxGarageMake,
  vinItemBordereauxGaragePuNet,
  vinItemBordereauxGarageState,
  vinItemBordereauxGarageType,
  vinItemBordereauxGarageVIN,
  vinItemBordereauxGarageVehModel,
  vinItemBordereauxGarageYear,
  vinItemBordereauxGarageZip,
  vinItemBordereauxGrossWrittenPremium,
  vinItemBordereauxId,
  vinItemBordereauxInsuredName,
  vinItemBordereauxLineOfBusiness,
  vinItemBordereauxMGA,
  vinItemBordereauxMGACommissionAmount,
  vinItemBordereauxMGACommissionPercentage,
  vinItemBordereauxManagementFeeAmount,
  vinItemBordereauxMunicipalTaxAmount,
  vinItemBordereauxNetAmountDueAmount,
  vinItemBordereauxOtherFeesAmount,
  vinItemBordereauxPolicyCurrency,
  vinItemBordereauxPolicyEffectiveDate,
  vinItemBordereauxPolicyNo,
  vinItemBordereauxPolicyState,
  vinItemBordereauxPolicyType,
  vinItemBordereauxPowerUnitsCount,
  vinItemBordereauxPrimaryClass,
  vinItemBordereauxPrimaryDescription,
  vinItemBordereauxProgram,
  vinItemBordereauxRiskManagementFeeAmount,
  vinItemBordereauxState,
  vinItemBordereauxStatePremiumTaxAmount,
  vinItemBordereauxTermExpiryDate,
  vinItemBordereauxTotalTaxAmount,
  vinItemBordereauxTransactionCode,
  vinItemBordereauxTransactionDate,
  vinItemBordereauxTransactionType,
  vinItemBordereauxZipCode,
  mainItemBordereauxAddress1,
  mainItemBordereauxAddress2,
  mainItemBordereauxBordereauNotes,
  mainItemBordereauxCity,
  mainItemBordereauxCountry,
  mainItemBordereauxCounty,
  mainItemBordereauxCoverageDeductible,
  mainItemBordereauxCoverageLimit,
  mainItemBordereauxCurrentPolicyStatus,
  mainItemBordereauxEndReportingPeriod,
  mainItemBordereauxEndoEffectiveDate,
  mainItemBordereauxEventTask,
  mainItemBordereauxFetTaxAmount,
  mainItemBordereauxFrontingFeeAmount,
  mainItemBordereauxFrontingFeePercentage,
  mainItemBordereauxGarageAddress,
  mainItemBordereauxGarageCity,
  mainItemBordereauxGarageMake,
  mainItemBordereauxGaragePuNet,
  mainItemBordereauxGarageState,
  mainItemBordereauxGarageType,
  mainItemBordereauxGarageVIN,
  mainItemBordereauxGarageVehModel,
  mainItemBordereauxGarageYear,
  mainItemBordereauxGarageZip,
  mainItemBordereauxGrossWrittenPremium,
  mainItemBordereauxId,
  mainItemBordereauxInsuredName,
  mainItemBordereauxLineOfBusiness,
  mainItemBordereauxMGA,
  mainItemBordereauxMGACommissionAmount,
  mainItemBordereauxMGACommissionPercentage,
  mainItemBordereauxManagementFeeAmount,
  mainItemBordereauxMunicipalTaxAmount,
  mainItemBordereauxNetAmountDueAmount,
  mainItemBordereauxOtherFeesAmount,
  mainItemBordereauxPolicyCurrency,
  mainItemBordereauxPolicyEffectiveDate,
  mainItemBordereauxPolicyNo,
  mainItemBordereauxPolicyState,
  mainItemBordereauxPolicyType,
  mainItemBordereauxPowerUnitsCount,
  mainItemBordereauxPrimaryClass,
  mainItemBordereauxPrimaryDescription,
  mainItemBordereauxProgram,
  mainItemBordereauxRiskManagementFeeAmount,
  mainItemBordereauxState,
  mainItemBordereauxStatePremiumTaxAmount,
  mainItemBordereauxTermExpiryDate,
  mainItemBordereauxTotalTaxAmount,
  mainItemBordereauxTransactionCode,
  mainItemBordereauxTransactionDate,
  mainItemBordereauxTransactionType,
  mainItemBordereauxZipCode,
  titleItemBordereauxUniqueReferenceNumber,
  titleItemBordereauxPolicyNumber,
  titleItemBordereauxProgram,
  titleItemBordereauxBeginningReportingPeriod,
  titleItemBordereauxPolicyEffectiveDate,
  titleItemBordereauxTransactionEffectiveDate,
  titleItemBordereauxTermExpiryDate,
  titleItemBordereauxCountry,
  titleItemBordereauxTransactionCode,
  titleItemBordereauxTransactionType,
  titleItemBordereauxPolicyType,
  titleItemBordereauxCurrentPolicyStatus,
  titleItemBordereauxInsuredName,
  titleItemBordereauxAddress1,
  titleItemBordereauxAddress2,
  titleItemBordereauxCity,
  titleItemBordereauxState,
  titleItemBordereauxZipCode,
  titleItemBordereauxCounty,
  titleItemBordereauxMGA,
  titleItemBordereauxLineOfBusiness,
  titleItemBordereauxPrimaryClass,
  titleItemBordereauxPrimaryDescription,
  titleItemBordereauxPolicyState,
  titleItemBordereauxPolicyCurrency,
  titleItemBordereauxCoverageLimit,
  titleItemBordereauxCoverageDeductible,
  titleItemBordereauxBordereauNotes,
  titleItemBordereauxGrossWrittenPremium,
  titleItemBordereauxMGACommission$,
  titleItemBordereauxMGACommissionPer,
  titleItemBordereauxFrontingFee,
  titleItemBordereauxFETTax$,
  titleItemBordereauxStatePremiumTax$,
  titleItemBordereauxMunicipalTax$,
  titleItemBordereauxTotalTax$,
  titleItemBordereauxOtherFees,
  titleItemBordereauxNetAmountDue$,
  titleItemBordereauxEndReportingPeriod,
  titleItemBordereauxFrontingFeePer,
  titleItemBordereauxRiskManagementFee,
  titleItemBordereauxManagementFee,
  titleItemBordereauxPowerUnitsCount,
  titleItemBordereauxGarageAddress,
  titleItemBordereauxGarageCity,
  titleItemBordereauxGarageState,
  titleItemBordereauxGarageZip,
  titleItemBordereauxVIN,
  titleItemBordereauxYear,
  titleItemBordereauxMake,
  titleItemBordereauxVehModel,
  titleItemBordereauxType,
  titleItemBordereauxPUNET,
  titleItemBordereauxEventTask,
  handleCopyToClipboardBordereaux,
} from "./utils";
import useGeneralState from "../../../Context/actions/useGeneralState";

export const BordereauxTable = ({
  currentItems,
  handleSort,
  sortConfig,
  handleMouseEnter,
  handleMouseLeave,
  hoveredRow,
  bordereauxType
}) => {

  const { controlToast } = useGeneralState()
  
  // console.log('currentItems', currentItems)

  return (
    <Table>
      <Thead>
        <Tr>
          <Td
            className={classNames(
              "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "uniqueReferenceNumber",
                "bg-blue-500": sortConfig.key === "uniqueReferenceNumber",
              }
            )}
            onClick={() => handleSort("uniqueReferenceNumber")}
          >
            {titleItemBordereauxUniqueReferenceNumber}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPolicyNumber}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxProgram}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxBeginningReportingPeriod}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPolicyEffectiveDate}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxTransactionEffectiveDate}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxTermExpiryDate}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxCountry}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxTransactionCode}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxTransactionType}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPolicyType}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxCurrentPolicyStatus}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxInsuredName}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxAddress1}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxAddress2}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxCity}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxState}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxZipCode}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxCounty}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxMGA}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxLineOfBusiness}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPrimaryClass}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPrimaryDescription}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPolicyState}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPolicyCurrency}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxCoverageLimit}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxCoverageDeductible}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxBordereauNotes}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxGrossWrittenPremium}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxMGACommission$}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxMGACommissionPer}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxFrontingFee}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxFETTax$}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxStatePremiumTax$}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxMunicipalTax$}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxTotalTax$}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxOtherFees}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxNetAmountDue$}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxEndReportingPeriod}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxFrontingFeePer}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxRiskManagementFee}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxManagementFee}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPowerUnitsCount}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxGarageAddress}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxGarageCity}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxGarageState}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxGarageZip}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxVIN}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxYear}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxMake}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxVehModel}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxType}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "policyNo",
              "bg-blue-500": sortConfig.key === "policyNo",
            })}
            onClick={() => handleSort("policyNo")}
          >
            {titleItemBordereauxPUNET}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer rounded-tr-md",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "eventTask",
                "bg-blue-500": sortConfig.key === "eventTask",
              }
            )}
            onClick={() => handleSort("eventTask")}
          >
            {titleItemBordereauxEventTask}
          </Td>
        </Tr>
      </Thead>

      <Tbody>
        {currentItems.map((item, index) => {
          return (
            <Tr
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleCopyToClipboardBordereaux(item, controlToast, bordereauxType)}
              style={{
                cursor: "pointer",
                backgroundColor: hoveredRow === index ? "#e6f7ff" : "inherit",
              }}
            >
              <Td>{bordereauxType === 'main' ? mainItemBordereauxId(item) : vinItemBordereauxId(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxPolicyNo(item) : vinItemBordereauxPolicyNo(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxProgram(item) : vinItemBordereauxProgram(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxEndoEffectiveDate(item) : vinItemBordereauxEndoEffectiveDate(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxPolicyEffectiveDate(item) : vinItemBordereauxPolicyEffectiveDate(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxTransactionDate(item) : vinItemBordereauxTransactionDate(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxTermExpiryDate(item) : vinItemBordereauxTermExpiryDate(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxCountry(item) : vinItemBordereauxCountry(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxTransactionCode(item) : vinItemBordereauxTransactionCode(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxTransactionType(item) : vinItemBordereauxTransactionType(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxPolicyType(item) : vinItemBordereauxPolicyType(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxCurrentPolicyStatus(item) : vinItemBordereauxCurrentPolicyStatus(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxInsuredName(item) : vinItemBordereauxInsuredName(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxAddress1(item) : vinItemBordereauxAddress1(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxAddress2(item) : vinItemBordereauxAddress2(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxCity(item) : vinItemBordereauxCity(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxState(item) : vinItemBordereauxState(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxZipCode(item) : vinItemBordereauxZipCode(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxCounty(item) : vinItemBordereauxCounty(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxMGA(item) : vinItemBordereauxMGA(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxLineOfBusiness(item) : vinItemBordereauxLineOfBusiness(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxPrimaryClass(item) : vinItemBordereauxPrimaryClass(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxPrimaryDescription(item) : vinItemBordereauxPrimaryDescription(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxPolicyState(item) : vinItemBordereauxPolicyState(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxPolicyCurrency(item) : vinItemBordereauxPolicyCurrency(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxCoverageLimit(item) : vinItemBordereauxCoverageLimit(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxCoverageDeductible(item) : vinItemBordereauxCoverageDeductible(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxBordereauNotes(item) : vinItemBordereauxBordereauNotes(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGrossWrittenPremium(item) : vinItemBordereauxGrossWrittenPremium(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxMGACommissionAmount(item) : vinItemBordereauxMGACommissionAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxMGACommissionPercentage(item) : vinItemBordereauxMGACommissionPercentage(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxFrontingFeeAmount(item) : vinItemBordereauxFrontingFeeAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxFetTaxAmount(item) : vinItemBordereauxFetTaxAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxStatePremiumTaxAmount(item) : vinItemBordereauxStatePremiumTaxAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxMunicipalTaxAmount(item) : vinItemBordereauxMunicipalTaxAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxTotalTaxAmount(item) : vinItemBordereauxTotalTaxAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxOtherFeesAmount(item) : vinItemBordereauxOtherFeesAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxNetAmountDueAmount(item) : vinItemBordereauxNetAmountDueAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxEndReportingPeriod(item) : vinItemBordereauxEndReportingPeriod(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxFrontingFeePercentage(item) : vinItemBordereauxFrontingFeePercentage(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxRiskManagementFeeAmount(item) : vinItemBordereauxRiskManagementFeeAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxManagementFeeAmount(item) : vinItemBordereauxManagementFeeAmount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxPowerUnitsCount(item) : vinItemBordereauxPowerUnitsCount(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageAddress(item) : vinItemBordereauxGarageAddress(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageCity(item) : vinItemBordereauxGarageCity(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageState(item) : vinItemBordereauxGarageState(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageZip(item) : vinItemBordereauxGarageZip(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageVIN(item) : vinItemBordereauxGarageVIN(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageYear(item) : vinItemBordereauxGarageYear(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageMake(item) : vinItemBordereauxGarageMake(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageVehModel(item) : vinItemBordereauxGarageVehModel(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGarageType(item) : vinItemBordereauxGarageType(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxGaragePuNet(item) : vinItemBordereauxGaragePuNet(item)}</Td>
              <Td>{bordereauxType === 'main' ? mainItemBordereauxEventTask(item) : vinItemBordereauxEventTask(item)}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
