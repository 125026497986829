import React, { useState, useEffect } from "react";
import useTranslations from "../../Context/actions/useTranslations";
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  onSnapshot,
  writeBatch,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../firebase";
import DriverPanel from "../../Components/PolicyLifecycleManager/Drivers";
import PowerUnitPanel from "../../Components/PolicyLifecycleManager/Units";
import TrailerPanel from "../../Components/PolicyLifecycleManager/Trailers";
import Questionnaire from "../../Components/PolicyLifecycleManager/Questionnaire";
import Commodities from "../../Components/PolicyLifecycleManager/Commodities";
import PremiumsQuotesPanel from "../../Components/PolicyLifecycleManager/Quotes";
import Losses from "../../Components/PolicyLifecycleManager/Losses";
import rateAllUnits from "../../Components/utils/rateAllUnits";
import getApplication from "../../Components/utils/getApplication";
import getTaxSchedule from "../../Components/utils/getTaxSchedule";
import Documents from "../../Components/PolicyLifecycleManager/Documents";
import APD from "../../Components/PolicyLifecycleManager/APD";
import MTC from "../../Components/PolicyLifecycleManager/MTC/";
import NotesPanel from "../../Components/PolicyLifecycleManager/Notes";
import useEndorsementsState from "../../Context/actions/useEndorsementsState";
import ApiService from "../../services/southern-api/apiService";
import useWebSocketSimulator from "../../services/southern-api/useWebSocketSimulator";
import usePoliciesPdfState from "../../Context/actions/usePoliciesPdfState";
import { filePolicyStructuring } from "../../utils/constants";
import { setProgressToZero } from "../../services/firebase-api/websocketSimulator";
import findNextPolicyNumbers from "../../Components/utils/findNextPolicyNumbers";
import useUsersState from "../../Context/actions/useUsersState";
import { UserAuth } from "../../Context/AuthContent";
import useRenderCount from "../../Components/utils/useRenderCount";
import axios from "axios";
import updatePolicyField from "../../Components/utils/updatePolicyField";
import { isPastSomeMinutes, parseStaticDate } from "../../utils/helpers";
import expiresWithin60Days from "../../Components/utils/expiresWithin60Days";
import {
  activeFilters,
  manageEndorsementOptions,
} from "../../utils/searchFilters";
import UnderwriterTasks from "../../Components/PolicyLifecycleManager/UnderwriterTasks";
import { SIGN_IN_ROUTE } from "../../utils/routes";

const useManagerTabs = () => {
  let { paramRefID } = useParams();
  const policyID = paramRefID;
  const { user: userInfo } = useUsersState();
  const auth = UserAuth();
  const uid = auth?.user?.uid;
  const userID = userInfo?.uid;
  const policyRef = doc(db, "policy-applications", policyID);

  const renderCount = useRenderCount();

  const [openReturnToAgentModal, setOpenReturnToAgentModal] = useState(false);
  const [openRequestQuoteModal, setOpenRequestQuoteModal] = useState(false);
  const [openRenewPolicyModal, setOpenRenewPolicyModal] = useState(false);
  const [openRequestBindModal, setOpenRequestBindModal] = useState(false);
  const [openPrintOptionsModal, setOpenPrintOptionsModal] = useState(false);
  const [openSendQuoteModal, setOpenSendQuoteModal] = useState(false);

  const handleOpenReturnToAgentModal = () => {
    setOpenReturnToAgentModal(true);
  };

  const handleCloseReturnToAgentModal = () => {
    setOpenReturnToAgentModal(false);
  };

  const handleOpenRenewPolicyModal = () => {
    setOpenRenewPolicyModal(true);
  };

  const handleCloseRenewPolicyModal = () => {
    setOpenRenewPolicyModal(false);
  };

  const handleOpenRequestQuoteModal = () => {
    setOpenRequestQuoteModal(true);
  };

  const handleCloseRequestQuoteModal = () => {
    setOpenRequestQuoteModal(false);
  };

  const handleOpenRequestBindModal = () => {
    setOpenRequestBindModal(true);
  };

  const handleCloseRequestBindModal = () => {
    setOpenRequestBindModal(false);
  };

  const handleOpenPrintOptionsModal = () => {
    setOpenPrintOptionsModal(true);
  };

  const handleClosePrintOptionsModal = () => {
    setOpenPrintOptionsModal(false);
  };

  const handleOpenSendQuoteModal = () => {
    setOpenSendQuoteModal(true);
  };

  const handleCloseSendQuoteModal = () => {
    setOpenSendQuoteModal(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveForm = async () => {
    try {
      await setDoc(policyRef, formData, { merge: true });
      setFormSaved(true);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const [insuredInfo, setInsuredInfo] = useState({});
  const [nextAL, setNextAL] = useState("");
  const [nextGL, setNextGL] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    alPremiums: {
      GWP: 0,
      totalPremium: 0,
      stampFee: 0,
      stampTaxAmount: 0,
      surplusLinesTax: 0,
      surplusLinesTaxAmount: 0,
      otherFees: 0,
      processingFee: 0,
      processingFeeAmount: 0,
    },
    effectiveDate: "",
  });
  const [driverCount, setDriverCount] = useState(0);
  const [powerUnitCount, setPowerUnitCount] = useState(0);
  const [trailerCount, setTrailerCount] = useState(0);
  const [formSaved, setFormSaved] = useState(true);
  const [taxSchedule, setTaxSchedule] = useState({});
  const [driverList, setDriverList] = useState([]);
  const [truckList, setTruckList] = useState([]);
  const [trailerList, setTrailerList] = useState([]);
  const [waiversList, setWaiversList] = useState([]);

  const status = formData?.status;
  const isAdminOrUnderwriter =
    userInfo?.role === "Admin" || userInfo?.role === "Underwriter";
  const isAgentOrSubUser =
    userInfo.role === "Sub-User" || userInfo.role === "Agent";
  const isQuoteRequest = formData?.documentType === "Quote Request";
  const isApplication = formData?.documentType === "Application";
  const isPolicy = formData?.documentType === "Policy";
  const isSubmitted = status === "Submitted";
  const isFulfilled = status === "Fulfilled";
  const isSubmittedOrFulfilled = isSubmitted || isFulfilled;
  const isBindOrder = formData?.documentType === "Bind Order";
  const isMissingAtLeastOneCoverage =
    !formData.hasALCoverage ||
    !formData.hasGLCoverage ||
    !formData.hasAPDCoverage ||
    !formData.hasMTCCoverage;

  const { language, t } = useTranslations();

  const unratePolicy = async (property, e) => {
    e && e.preventDefault();
    if (isPolicy)
      return console.log("NOT UNRATING BECAUSE DOCUMENTTYPE POLICY");
    console.log("UNRATING POLICY");
    const batch = writeBatch(db);
    truckList.forEach((truck) => {
      const truckRef = doc(
        db,
        "policy-applications",
        policyID,
        "power-units",
        truck.id
      );
      batch.update(truckRef, {
        rating: 0,
      });
    });
    if (!property || !e.target.value || e.target.value === "") {
      setFormData({ ...formData, rerateRequired: true });
      batch.update(policyRef, {
        rerateRequired: true,
      });
      await batch.commit();
      return;
    } else {
      setFormData({
        ...formData,
        rerateRequired: true,
        [property]: e.target.value,
      });
      batch.update(policyRef, {
        rerateRequired: true,
        [property]: e.target.value,
      });
      await batch.commit();
      return;
    }
  };

  const apdAndMtc = {};

  const returnToAgent = async (e) => {
    e.preventDefault();
    handleOpenReturnToAgentModal();
  };

  const getUniqueIdentifierAutoLiabilityNAFTA = async () => {};

  const [endorsements, setEndorsements] = useState([]);
  const { setEndorsements: setEndorsementsContext } = useEndorsementsState();
  const getEndorsements = async () => {};

  const [currentEndoFilters, setCurrentEndoFilters] = useState({
    submitted: true,
  });
  const [skeletonEndoTableLoader, setSkeletonEndoTableLoader] = useState(false);
  const filters = activeFilters(manageEndorsementOptions, currentEndoFilters);

  useEffect(() => {
    setSkeletonEndoTableLoader(true);
    const otherFilters = filters.filter(
      (filter) => filter !== "Exception Request"
    );
    if (filters.includes("Approved")) {
      otherFilters.push("Issued");
    }
    const buildQuery1 = () => {
      const endorsementsRef = collection(
        db,
        "policy-applications",
        policyID,
        "endorsements"
      );
      let q = endorsementsRef;
      q = query(q, where("documentType", "==", "Exception Request"));
      return q;
    };
    const buildQuery2 = () => {
      const endorsementsRef = collection(
        db,
        "policy-applications",
        policyID,
        "endorsements"
      );
      let q = endorsementsRef;
      const otherFilters = filters?.filter(
        (filter) => filter !== "Exception Request"
      );
      if (filters.includes("Approved")) {
        otherFilters.push("Issued");
      }
      if (otherFilters.length > 0) {
        q = query(q, where("status", "in", otherFilters));
      }
      return q;
    };
    let allResults = [];
    const endoQuery1 = buildQuery1();
    const unsubscribe1 = onSnapshot(endoQuery1, (querySnapshot) => {
      let values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });
      if (filters.includes("Exception Request")) {
        allResults = [...allResults, ...values];
        setEndorsements(allResults);
        setEndorsementsContext(allResults);
      }
      setSkeletonEndoTableLoader(false);
    });
    const endoQuery2 = buildQuery2();
    const unsubscribe2 = onSnapshot(endoQuery2, (querySnapshot) => {
      let values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });
      if (otherFilters.length > 0) {
        const allResultsToFilterUniqueValues = [...allResults, ...values];
        allResults = Array.from(
          new Set(allResultsToFilterUniqueValues.map((a) => a.id))
        ).map((id) => {
          return allResultsToFilterUniqueValues.find((a) => a.id === id);
        });
        setEndorsements(allResults);
        setEndorsementsContext(allResults);
      }
      setSkeletonEndoTableLoader(false);
    });
    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [currentEndoFilters]);

  const [loadingText, setLoadingText] = useState(t("manage-policies.loading"));
  const [loading, setLoading] = useState(false);
  const [displayEndorsementManager, setDisplayEndorsementManager] =
    useState(false);

  const [displayUnderwritingPanel, setDisplayUnderwritingPanel] =
    useState(false);

  const currentYear = parseInt(new Date().toString().slice(11, 15));

  const [showOverviewAndHistory, setShowOverviewAndHistory] = useState(false);

  const [displayCompanyDetails, setDisplayCompanyDetails] = useState(false);
  const [displayBindDetailsInfo, setDisplayBindDetailsInfo] = useState(false);

  const [policies, setPolicies] = useState({
    activeAPDPolicies: [],
    activeMTCPolicies: [],
  });

  const [loadedPolicies, setLoadedPolicies] = useState(false);

  const getAPDMTCPolicies = async (token) => {
    // TODO: Hide this URL from public view
    const dotNumber = insuredInfo?.dot;
    const requestURL =
      "https://kpbrokersapi.com/api/policy/getpolicies?agentId=a1ptN3ZQcGhyTnM9";
    // TODO: Add error handling to this, including on components where results are displayed, so they know there was an error and they should try again later
    axios
      .get(requestURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        const filteredResults = results.data.filter(
          // (obj) => obj.dotNumber !== null && obj.dotNumber !== '0'
          (obj) => parseInt(obj.dotNumber) === parseInt(dotNumber)
        );
        const currentDate = new Date();
        const activeAPDPolicies = filteredResults.filter((obj) => {
          const expiryDate = new Date(obj.expiryDate);
          return (
            expiryDate > currentDate &&
            obj.coverageType === "Auto Physical Damage"
          );
        });
        const activeMTCPolicies = filteredResults.filter((obj) => {
          const expiryDate = new Date(obj.expiryDate);
          return (
            expiryDate > currentDate && obj.coverageType === "Motor Truck Cargo"
          );
        });
        setPolicies({
          activeAPDPolicies,
          activeMTCPolicies,
        });
        const hasActiveAPD = activeAPDPolicies.length > 0;
        const hasActiveMTC = activeMTCPolicies.length > 0;
        setLoadedPolicies(true);
        hasActiveAPD &&
          !(formData.hasAPDCoverage === hasActiveAPD) &&
          updatePolicyField(policyID, "hasAPDCoverage", hasActiveAPD);
        hasActiveMTC &&
          !(formData.hasMTCCoverage === hasActiveMTC) &&
          updatePolicyField(policyID, "hasMTCCoverage", hasActiveMTC);
      });
  };

  const getToken = async () => {
    // TODO: Hide this URL from public view
    const requestURL =
      "https://kpbrokersapi.com/api/access?username=southernstarmga&password=S0utH3rNP@assWord";
    axios.get(requestURL).then((results) => {
      getAPDMTCPolicies(results.data.token);
    });
  };

  const [agencyInfo, setAgencyInfo] = useState({});

  useEffect(() => {
    insuredInfo.dot && !loadedPolicies && getToken();
  }, [loadedPolicies, insuredInfo.dot]);

  const tabProps = {
    waiverOfSubrogationPanel: {
      formData,
      waiversList,
      setWaiversList,
      insuredInfo,
      agencyInfo,
      policyID,
      userInfo,
      userID,
      saveForm,
      setFormSaved,
      formSaved,
    },
    additionalInsuredsPanel: {
      formData,
      insuredInfo,
      policyID,
      userInfo,
      userID,
      saveForm,
      setFormSaved,
      formSaved,
    },
    driverPanel: {
      formData,
      setDriverList,
      insuredInfo,
      driverCount,
      policyID,
      userInfo,
      userID,
      saveForm,
      setFormSaved,
      driverList,
      formSaved,
    },
    powerUnitPanel: {
      powerUnitCount,
      insuredInfo,
      truckList,
      setTruckList,
      rateAllUnits,
      getApplication,
      policyID,
      setPowerUnitCount,
      userInfo,
      userID,
      formData,
      setFormData,
      saveForm,
      setFormSaved,
      formSaved,
    },
    trailerPanel: {
      insuredInfo,
      trailerList,
      setTrailerList,
      rateAllUnits,
      getApplication,
      policyID,
      userInfo,
      userID,
      formData,
      setFormData,
      saveForm,
      setFormSaved,
      formSaved,
    },
    questionnaire: {
      insuredInfo,
      formData,
      saveForm,
      formSaved,
      setFormSaved,
      setFormData,
      policyID,
      displayCompanyDetails,
      setDisplayCompanyDetails,
      userInfo,
    },
    commodities: {
      formData,
      saveForm,
      formSaved,
      setFormSaved,
      setFormData,
      insuredInfo,
      policyID,
    },
    losses: {
      userInfo,
      insuredID: insuredInfo.id,
      agencyID: userInfo.agencyID,
      insuredInfo,
      setInsuredInfo,
      policyID,
      setFormData,
      formData,
    },
    notes: {
      formData,
      userInfo,
      policyID,
      auth,
    },
    tasks: {
      formData,
      userInfo,
      policyID,
      insuredInfo,
      agencyInfo,
      driverList
    },
    documents: {
      policyID,
      formData,
      setFormData,
      currentYear,
      insuredInfo
    },
    premiums: {
      trailerList,
      taxSchedule,
      truckList,
      driverList,
      policyID,
      auth,
      formData,
      insuredInfo,
      userInfo,
      setFormData,
      apdAndMtc,
      powerUnitCount,
    },
    audit: {
      formData,
    },
    apd: {
      saveForm,
      setFormData,
      formData,
      policyID,
      isAdminOrUnderwriter, isAgentOrSubUser,
      userInfo,
      insuredInfo,
      loadedPolicies,
      policies,
    },
    mtc: {
      saveForm,
      setFormData,
      formData,
      policyID,
      isAdminOrUnderwriter, isAgentOrSubUser,
      formSaved,
      setFormSaved,
      userInfo,
      insuredInfo,
      loadedPolicies,
      policies,
    },
  };

  const apiService = new ApiService("southern");
  const { progress, initialSetup } = useWebSocketSimulator();
  const { policiesPdf, setPoliciesPdf } = usePoliciesPdfState();

  const cancelPolicyPrinting = async () => {
    try {
      setPoliciesPdf({
        loadingCancel: true,
        dateWasCancel: parseStaticDate(new Date()),
      });
      await apiService.abortPolicyPrinting();
      const realType = filePolicyStructuring("NAFTA AL");
      await setProgressToZero(realType);
      setPoliciesPdf({
        loadingCancel: false,
        loading: false,
      });
    } catch (error) {
      setPoliciesPdf({
        loadingCancel: false,
        loading: false,
      });
      console.log("error cancel policy", error);
      throw error;
    }
  };

  useEffect(() => {
    if (initialSetup) {
      setPoliciesPdf({
        error: false,
        cancelRequest: cancelPolicyPrinting,
      });
    }
  }, [initialSetup]);

  const downloadFile = async (url) => {
    if (isPastSomeMinutes(policiesPdf?.dateWasCancel)) {
      try {
        window.open(url);
      } catch (error) {
        console.error("Error al descargar el archivo:", error);
      }
    }
  };

  const printPolicy = async (e, type, data, option) => {
    const realType = filePolicyStructuring(type);
    setPoliciesPdf({
      type: realType,
      loading: true,
      error: false,
    });
    try {
      e.preventDefault();
      const result = await apiService.printPolicy({
        type,
        payload: data,
        id: formData?.id,
        option,
        userId: uid,
      });
      if (result) {
        setPoliciesPdf({
          ...result,
          loading: false,
          error: false,
        });
        downloadFile(result.url, "NAFTA AL Policy.pdf");
      }
    } catch (error) {
      const abortedError = error?.response?.data?.error === "Operation aborted";
      try {
        await setProgressToZero(realType);
      } catch (suberror) {
        console.error(suberror);
      } finally {
        console.log(
          "abortedError ? false : error",
          abortedError ? false : error
        );
        setPoliciesPdf({
          loading: false,
          error: abortedError ? false : error,
        });
      }
      return error;
    }
  };

  const [eligibleForRenewal, setEligibleForRenewal] = useState(false);

  useEffect(() => {
    if (formData && formData.expirationDate) {
      const isEligible = expiresWithin60Days(formData.expirationDate);
      setEligibleForRenewal(isEligible);
    }
  }, [formData?.expirationDate]);

  const listenToAgencyInfo = (userInfo, formData) => {
    if (!userInfo || !userInfo.agencyID) {
      return () => {};
    }
    const docRef = doc(db, "agencies", userInfo.agencyID);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setAgencyInfo({ ...docSnap.data(), id: docSnap.id });
      } else {
        console.log("No such document!");
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = listenToAgencyInfo(userInfo, formData);
    return () => {
      unsubscribe();
    };
  }, [userInfo, formData]);

  const actionButtonProps = {
    formSaved,
    isAdminOrUnderwriter, isAgentOrSubUser,
    isQuoteRequest,
    isApplication,
    isPolicy,
    isSubmitted,
    isFulfilled,
    isSubmittedOrFulfilled,
    isMissingAtLeastOneCoverage,
    insuredInfo,
    agencyInfo,
    formData,
    policyID,
    driverList,
    driverList,
    truckList,
    setFormData,
    taxSchedule,
    rateAllUnits,
    returnToAgent,
    setDisplayEndorsementManager,
    setDisplayUnderwritingPanel,
    isBindOrder,
    printPolicy,
    printPolicyProgress: progress,
    handleOpenRequestBindModal,
    handleCloseRequestBindModal,
    openRequestBindModal,
    handleOpenReturnToAgentModal,
    handleCloseReturnToAgentModal,
    handleOpenPrintOptionsModal,
    handleClosePrintOptionsModal,
    openPrintOptionsModal,
    openReturnToAgentModal,
    handleOpenRequestQuoteModal,
    handleCloseRequestQuoteModal,
    openRequestQuoteModal,
    handleOpenRenewPolicyModal,
    handleCloseRenewPolicyModal,
    openSendQuoteModal,
    handleOpenSendQuoteModal,
    handleCloseSendQuoteModal,
    openRenewPolicyModal,
    powerUnitCount,
    driverCount,
    trailerCount,
    userInfo,
    nextAL,
    nextGL,
    userInfo,
    insuredID: insuredInfo.id,
    eligibleForRenewal,
    isSubmittedOrFulfilled,
    isAgentOrSubUser
  };

  const appOverviewProps = {
    showOverviewAndHistory: showOverviewAndHistory,
    setShowOverviewAndHistory: setShowOverviewAndHistory,
    trailerCount: trailerCount,
    userInfo: userInfo,
    formData: formData,
    powerUnitCount: powerUnitCount,
    driverCount: driverCount,
    value,
  };

  const coverageProps = {
    language,
    nextAL,
    nextGL,
    agencyID: userInfo.agencyID,
    insuredInfo,
    setInsuredInfo,
    formData,
    saveForm,
    setFormData,
    policyID,
    userInfo,
    disabled,
    userID: userID,
    displayCompanyDetails,
    setDisplayCompanyDetails,
    displayBindDetailsInfo,
    setDisplayBindDetailsInfo,
  };

  const tabComponents = [
    {
      label: "Drivers",
      component: (
        <DriverPanel
          getEndorsements={getEndorsements}
          {...tabProps.driverPanel}
        />
      ),
    },
    {
      label: "Units",
      component: (
        <PowerUnitPanel
          getEndorsements={getEndorsements}
          {...tabProps.powerUnitPanel}
        />
      ),
    },
    {
      label: "Trailers",
      component: (
        <TrailerPanel
          getEndorsements={getEndorsements}
          {...tabProps.trailerPanel}
        />
      ),
    },
    {
      label: "Questionnaire",
      component: <Questionnaire {...tabProps.questionnaire} />,
    },
    {
      label: "Commodities",
      component: <Commodities {...tabProps.commodities} />,
    },
    { label: "Losses", component: <Losses {...tabProps.losses} /> },
    { label: "Notes", component: <NotesPanel {...tabProps.notes} /> },
    { label: "Documents", component: <Documents {...tabProps.documents} /> },

    { label: "APD", component: <APD {...tabProps.apd} /> },
    { label: "MTC", component: <MTC {...tabProps.mtc} /> },
    // { label: "Audit", component: <AppCompletion {...tabProps.audit} /> },
    {
      label: formData.documentType === "Policy" ? "Premiums" : "Quotes",
      component: <PremiumsQuotesPanel {...tabProps.premiums} />,
    },
  ];

  // If the user is an underwriter or admin, ad the tasks tab before the premiums tab
  const tasksTab = {
    label: "Tasks",
    component: <UnderwriterTasks {...tabProps.tasks} />,
  };
  if (userInfo.role === "Underwriter" || userInfo.role === "Admin") {
    const insertionIndex = tabComponents.findIndex(
      (tab) => tab.label === "Premiums" || tab.label === "Quotes"
    );
    tabComponents.splice(insertionIndex, 0, tasksTab);
  }

  const filteredTabs = tabComponents.filter((tab) => {
    if (tab.label === "APD") {
      return formData?.hasAPDCoverage;
    } else if (tab.label === "MTC") {
      return formData?.hasMTCCoverage;
    } else if (tab.label === "Premiums") {
      return formData?.documentType !== "Application";
    } else {
      return true;
    }
  });

  useEffect(() => {
    if (!formData.agencyID || !formData.insuredID || !showOverviewAndHistory) {
      setDriverCount(0);
      setPowerUnitCount(0);
      setTrailerCount(0);
      return;
    }

    const driversQuery = query(
      collection(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        "drivers"
      ),
      where("policies", "array-contains", policyID)
    );
    const powerUnitsQuery = query(
      collection(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        "power-units"
      ),
      where("policies", "array-contains", policyID)
    );
    const trailersQuery = query(
      collection(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        "trailers"
      ),
      where("policies", "array-contains", policyID)
    );

    const unsubscribeDrivers = onSnapshot(driversQuery, (snapshot) => {
      setDriverCount(snapshot.size);
    });

    const unsubscribePowerUnits = onSnapshot(powerUnitsQuery, (snapshot) => {
      setPowerUnitCount(snapshot.size);
    });

    const unsubscribeTrailers = onSnapshot(trailersQuery, (snapshot) => {
      setTrailerCount(snapshot.size);
    });

    return () => {
      unsubscribeDrivers();
      unsubscribePowerUnits();
      unsubscribeTrailers();
    };
  }, [formData, showOverviewAndHistory]);
  useEffect(() => {
    let isMounted = true;

    const fetchTaxSchedule = async () => {
      try {
        await getTaxSchedule(setTaxSchedule);
      } catch (error) {
        console.error("Error fetching tax schedule:", error);
      }
    };

    fetchTaxSchedule();

    // Clean up function
    return () => {
      isMounted = false;
    };
  }, []);

  // Get App/Policy Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let formDataDocRef;
        if (policyID) {
          formDataDocRef = policyRef;
          const unsubscribe = onSnapshot(formDataDocRef, (doc) => {
            if (doc.exists()) {
              const formDataFromFirestore = {
                ...doc.data(),
                id: doc.id,
              };
              setFormData(formDataFromFirestore);
              setLoading(false);
            } else {
              // TODO: Handle this more gracefully
              setLoading(false);
            }
          });
          return () => {
            unsubscribe();
          };
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    };
    fetchData();
  }, [policyID]);

  // const navigate = useNavigate();

  const listenToCompanyInfo = (userInfo, formData) => {
    if (!userInfo || !userInfo.agencyID || !formData.insuredID) {
      return () => {};
    }
    const docRef = doc(
      db,
      "agencies",
      userInfo.agencyID,
      "insureds",
      formData.insuredID
    );
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setInsuredInfo({ ...docSnap.data(), id: docSnap.id });
        if (
          (formData.hasALCoverage && nextAL === "") ||
          (formData.hasGLCoverage && nextGL === "")
        ) {
          findNextPolicyNumbers(
            formData,
            { ...docSnap.data(), id: docSnap.id },
            null,
            null,
            setNextAL,
            setNextGL
          );
        }
      } else {
        console.log("No such document!");
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = listenToCompanyInfo(userInfo, formData);

    return () => {
      unsubscribe();
    };
  }, [userInfo, formData]);

  return {
    loading,
    loadingText,
    coverageProps,
    displayEndorsementManager,
    displayUnderwritingPanel,
    displayCompanyDetails,
    actionButtonProps,
    appOverviewProps,
    showOverviewAndHistory,
    setShowOverviewAndHistory,
    policyID,
    value,
    handleChange,
    filteredTabs,
    userInfo,
    insuredInfo,
    agencyInfo,
    setDisplayCompanyDetails,
    setDisplayEndorsementManager,
    setDisplayUnderwritingPanel,
    displayBindDetailsInfo,
    setDisplayBindDetailsInfo,
    endorsements,
    getEndorsements,
    tabProps,
    getUniqueIdentifierAutoLiabilityNAFTA,
    formData,
    openReturnToAgentModal,
    handleOpenReturnToAgentModal,
    handleCloseReturnToAgentModal,
    openRequestBindModal,
    handleOpenRenewPolicyModal,
    handleCloseRenewPolicyModal,
    openRenewPolicyModal,
    handleOpenRequestQuoteModal,
    handleCloseRequestQuoteModal,
    openRequestQuoteModal,
    handleCloseRequestBindModal,
    handleOpenRequestBindModal,
    handleOpenSendQuoteModal,
    skeletonEndoTableLoader,
    currentEndoFilters,
    setCurrentEndoFilters,
  };
};

export default useManagerTabs;
