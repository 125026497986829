import React, { useState } from "react";
import { auth } from "../../firebase";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import SelectDropdown from "../assets/forms/SelectDropdown";
import ApprovalButtons from "../assets/forms/ApprovalButtons";
import MyCheckbox from "../assets/forms/Checkbox";
import TextInput from "../assets/forms/TextInput";
import CancellationReasons from "./CancellationReasons";

export default function CancelPolicyPanel({ goBack }) {
  const [cancellationReasons, setCancellationReasons] = useState({});

  const [cancellationData, setCancellationData] = useState({
    requestDate: new Date(),
    requestingUserID: auth.currentUser.uid,
    requestingUser: auth.currentUser.displayName,
    cancellationDate: new Date(),
    cancellationReasons,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: Add logic to submit cancellation data
    console.log("Cancellation Data:", cancellationData);
  };

  const customClass =
    "appearance-none block w-full bg-white border border-gray-300 rounded-md py-3 px-3 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300";
  const labelCustomClass = "text-md d-block";

  return (
    <main>
      <h1 className="text-2xl mt-2.5 font-bold md:mb-0">Cancel Policy</h1>
      <p className="mt-3">
        Cancellations must be approved by an underwriter. To cancel the policy,
        please complete the following form.
      </p>
      <Box sx={{ p: 2 }}>
        <form onSubmit={handleSubmit}>
          <label className={labelCustomClass}>Cancellation Date</label>
          <DatePicker
            wrapperClassName="datePicker"
            selected={cancellationData.cancellationDate}
            onChange={(date) => {
              setCancellationData({
                ...cancellationData,
                cancellationDate: date,
              });
            }}
            className={customClass}
          />
          <CancellationReasons
            setCancellationReasons={setCancellationReasons}
            cancellationReasons={cancellationReasons}
          />
          <ApprovalButtons
            onApprove={null}
            handleAsSubmit={true}
            onDecline={(e) => {
              goBack(e);
            }}
            titleAccept="Cancel Policy"
            titleDecline="Do Not Cancel Policy"
            disabledAccept={false}
          />
        </form>
      </Box>
    </main>
  );
}
