import React, { useState } from "react";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import submitAddTrailerEndorsement from "./utils/submitAddTrailerEndorsement";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import useGeneralState from "../../../Context/actions/useGeneralState";
import { validateIfExists } from "../../utils/validateIfBlacklisted";
import "react-datepicker/dist/react-datepicker.css";

export default function AddTrailer(props) {
  const formData = props.formData;
  const rerateRequired = props.rerateRequired;
  const setRerateRequired = props.setRerateRequired;
  const isEndoManager = props.isEndoManager;
  const policyID = props.policyID;
  const trailerList = props.trailerList;

  const [trailerData, setTrailerData] = useState({});
  const { controlToast } = useGeneralState()

  const goBack = () => {
    props.setShowAddTrailer(false);
    props.setShowTable(true);
  };

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Trailers" },
    { label: "Add Trailer" },
  ];

  const [success, setSuccess] = useState(false);
  const [endoEffectiveDate, setEndoEffectiveDate] = useState(new Date());

  const mirrorLogic = async (e) => {
    e.preventDefault()
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" &&
      formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";

    const { agencyID, insuredID } = formData;

    const verifiedInExistingData = await validateIfExists(
      [trailerData],
      agencyID,
      insuredID,
      "trailers"
    )

    try {
      if (notARenewalProcess) {
        await submitAddTrailerEndorsement(
          e,
          policyID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          formData.documentType,
          false,
          null,
          null,
          trailerList
        )
        const isPolicy = formData.documentType === "Policy";
        const message =
          isPolicy ?
          'You created an Add Trailer endorsement successfully!' :
          'You created a Trailer successfully!'
        controlToast(true, message, 'success')
      }
      if (isRenewalApplication) {
        const mirroredIds = await submitAddTrailerEndorsement(
          e,
          policyID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Application",
          true,
          null,
          null,
          trailerList
        )
        await submitAddTrailerEndorsement(
          e,
          formData.renewalPolicyID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Policy",
          true,
          mirroredIds,
          policyID,
          trailerList
        )
        const message =
          'You created an Add Trailer endorsement successfully!'
        controlToast(true, message, 'success')
      }
      if (isRenewalPolicy) {
        const mirroredIds = await submitAddTrailerEndorsement(
          e,
          formData.renewalApplicationID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Application",
          true,
          null,
          null,
          trailerList
        )
        await submitAddTrailerEndorsement(
          e,
          policyID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Policy",
          true,
          mirroredIds,
          policyID,
          trailerList
        )
        const message =
          'You created an Add Trailer endorsement successfully!'
        controlToast(true, message, 'success')
      }
    } catch (error) {
      throw error
    }
  };

  return (
    <>
      {success ? (
        <main className="bg-green-100 p-6 rounded-md shadow-md text-green-700">
          <p className="text-2xl font-semibold mb-4">
            Your endorsement has been successfully submitted.
          </p>
          <p className="mb-4">
            An underwriter will review your endorsement, and you will receive a
            notification when the status is updated.
          </p>
          <button
            onClick={(e) => goBack(e)}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            OK
          </button>
        </main>
      ) : (
        <main>
          <header>
            {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
            <h1
              className={`${
                isEndoManager
                  ? "text-2xl mt-2.5 font-bold md:mb-0"
                  : "text-3xl mt-2 font-semibold md:mb-0"
              }`}
            >
              {!isEndoManager ? `${t("Add Trailer")}` : `${t("Add Trailer")}`}
            </h1>
            <p className="mt-2 mb-3">
              {(formData.documentType === "Application" ||
                formData.documentType === "Policy") &&
                t(
                  "Fill out the following form to Add Trailer. Click submit once the form is complete."
                )}
              {formData.documentType !== "Application" &&
                formData.documentType !== "Policy" && (
                  <>Trailers may not be edited during the quoting phase.</>
                )}
            </p>
          </header>
          {/* Form */}
          <section className="card mx-auto max-w-[750px] mb-4 shadow-lg">
            <div className="card-body">
              <form onSubmit={mirrorLogic}>
                {formData.documentType === "Policy" ||
                formData.status === "In-Renewal" ? (
                  <>
                    <label className="">{t("Effective Date")}:</label>
                    <DatePicker
                      wrapperClassName="datePicker"
                      required
                      selected={endoEffectiveDate}
                      onChange={(date) => {
                        setEndoEffectiveDate(date);
                      }}
                      className="standard-form-input"
                    />
                  </>
                ) : null}
                <label className="d-block mt-1">Trailer VIN:</label>
                {trailerData?.trailerVIN &&
                  trailerData?.trailerVIN.length !== 17 && (
                    <p className="text-[#8B0000]">VIN must be 17 characters.</p>
                  )}
                <input
                  required
                  onChange={(e) =>
                    setTrailerData({
                      ...trailerData,
                      trailerVIN: e.target.value,
                    })
                  }
                  value={trailerData.trailerVIN}
                  type="text"
                  id="trailerVIN"
                  className="standard-form-input"
                />
                <label className="mt-1">Trailer Year:</label>
                {trailerData?.trailerYear &&
                  trailerData.trailerYear.length !== 4 && (
                    <p>Year must be 4 numbers.</p>
                  )}
                <input
                  required
                  onChange={(e) =>
                    setTrailerData({
                      ...trailerData,
                      trailerYear: e.target.value,
                    })
                  }
                  value={trailerData.trailerYear}
                  type="text"
                  id="trailerYear"
                  className="standard-form-input"
                />
                <label htmlFor="trailerMake" className="mt-1">
                  Trailer Make:
                </label>
                <select
                  required
                  onChange={(e) =>
                    setTrailerData({
                      ...trailerData,
                      trailerMake: e.target.value,
                    })
                  }
                  value={trailerData.trailerMake}
                  id="trailerMake"
                  className="standard-form-input"
                >
                  <option value=""></option>
                  <option value="ACASA">ACASA</option>
                  <option value="APRILIA">APRILIA</option>
                  <option value="ATLAS">ATLAS</option>
                  <option value="ATRO">ATRO</option>
                  <option value="AUTO CAR">AUTO CAR</option>
                  <option value="CAPACITY">CAPACITY</option>
                  <option value="CHEVROLET">CHEVROLET</option>
                  <option value="CHASSIS">CHASSIS</option>
                  <option value="CHRYSLER">CHRYSLER</option>
                  <option value="CIMC">CIMC</option>
                  <option value="CONDE">CONDE</option>
                  <option value="CONDOR">CONDOR</option>
                  <option value="CONSTR. SPECIALISTS">
                    CONSTR. SPECIALISTS
                  </option>
                  <option value="DESTEFANO">DESTEFANO</option>
                  <option value="DIAMOND">DIAMOND</option>
                  <option value="DINA">DINA</option>
                  <option value="DOONAN">DOONAN</option>
                  <option value="DORSEY">DORSEY</option>
                  <option value="ECONOLINE">ECONOLINE</option>
                  <option value="FAMSA">FAMSA</option>
                  <option value="FERBUS">FERBUS</option>
                  <option value="FONTAINE">FONTAINE</option>
                  <option value="FORD">FORD</option>
                  <option value="FRUEHAUF">FRUEHAUF</option>
                  <option value="GMC">GMC</option>
                  <option value="GREAT DANE">GREAT DANE</option>
                  <option value="HERON">HERON</option>
                  <option value="HENDRICKSON">HENDRICKSON</option>
                  <option value="HINNO">HINNO</option>
                  <option value="HONDA">HONDA</option>
                  <option value="INLAND">INLAND</option>
                  <option value="INSENSE">INSENSE</option>
                  <option value="INTER Trailer">INTER Trailer</option>
                  <option value="IVECO">IVECO</option>
                  <option value="J.M.G.">J.M.G.</option>
                  <option value="JEEP">JEEP</option>
                  <option value="KENWORTH">KENWORTH</option>
                  <option value="KILOTON">KILOTON</option>
                  <option value="KINGHAM">KINGHAM</option>
                  <option value="KODIAK">KODIAK</option>
                  <option value="LOZANO">LOZANO</option>
                  <option value="MACK">MACK</option>
                  <option value="MAZDA">MAZDA</option>
                  <option value="MARMOL">MARMOL</option>
                  <option value="MERCEDEZ BENZ">MERCEDEZ BENZ</option>
                  <option value="MCI">MCI</option>
                  <option value="MITSUBISHI">MITSUBISHI</option>
                  <option value="MOND">MOND</option>
                  <option value="MONON">MONON</option>
                  <option value="NAVISTAR">NAVISTAR</option>
                  <option value="NISSAN">NISSAN</option>
                  <option value="NO ESPECIFICADA">NO ESPECIFICADA</option>
                  <option value="NORTE">NORTE</option>
                  <option value="OJEDA MOTORS">OJEDA MOTORS</option>
                  <option value="OTTAWA">OTTAWA</option>
                  <option value="PAISANO">PAISANO</option>
                  <option value="PENA MOTORS">PENA MOTORS</option>
                  <option value="PEYCHA">PEYCHA</option>
                  <option value="PETERBILT">PETERBILT</option>
                  <option value="PLYMOUTH">PLYMOUTH</option>
                  <option value="RAMIREZ">RAMIREZ</option>
                  <option value="RANGER">RANGER</option>
                  <option value="REDILAS">REDILAS</option>
                  <option value="REMOLQUE">REMOLQUE</option>
                  <option value="RENAULT">RENAULT</option>
                  <option value="RENOVA">RENOVA</option>
                  <option value="RMZ MOTOR">RMZ MOTOR</option>
                  <option value="ROADKING">ROADKING</option>
                  <option value="SCANIA">SCANIA</option>
                  <option value="SEAT">SEAT</option>
                  <option value="SILVER">SILVER</option>
                  <option value="SPRINTER">SPRINTER</option>
                  <option value="STERLING">STERLING</option>
                  <option value="TOYOTA">TOYOTA</option>
                  <option value="TRACTOCAZA">TRACTOCAZA</option>
                  <option value="UTILITY">UTILITY</option>
                  <option value="VAN GUARD">VAN GUARD</option>
                  <option value="VOLKSWAGEN">VOLKSWAGEN</option>
                  <option value="VOLVO">VOLVO</option>
                  <option value="WAINE">WAINE</option>
                  <option value="WESTERN STAR">WESTERN STAR</option>
                  <option value="XPO">XPO</option>
                  <option value="ZAMARRIPA">ZAMARRIPA</option>
                </select>
                <label htmlFor="trailerACV" className="mt-1">
                  Actual Cash Value:
                </label>
                <input
                  required
                  onChange={(e) =>
                    setTrailerData({
                      ...trailerData,
                      trailerACV: e.target.value,
                    })
                  }
                  value={trailerData.trailerACV}
                  type="number"
                  id="trailerACV"
                  className="standard-form-input"
                />
                <ApprovalButtons
                  onApprove={null}
                  handleAsSubmit={true}
                  onDecline={(e) => {
                    goBack();
                  }}
                  titleAccept="Submit"
                  titleDecline="Go Back"
                />
              </form>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
