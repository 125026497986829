import React, { useState } from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import TableWithFormulas from "../../Components/DriverScoring/Tables/TableWithFormulas";
import useLossControlFormulas from "../../Components/utils/useLossControlFormulas";
import UploadExcelFile from "../../Components/LossControl/UploadExcelFile";
import DriverFitness from "../../Components/LossControl/FullViolationsList/DriverFitness";
import DriverProfile from "../../Components/LossControl/FullViolationsList/DriverProfile";
import aggregateDriverInfo from "../../Components/LossControl/utils/listeners/aggregateDriverInfo";
import DataMatrix from "../../Components/DriverScoring/Tables/DataMatrix";
import TableSelection from "../../Components/LossControl/TableSelection";
import Tables from "../../Components/DriverScoring/Tables";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchType from "../../Components/LossControl/Search/SearchType";
import ApprovalButtons from "../../Components/assets/forms/ApprovalButtons";
import mapEventualities from "../../Components/LossControl/utils/getData/mapEventualities";
import mapClassifications from "../../Components/LossControl/utils/getData/mapClassifications";
import getViolationsQuery from "../../Components/LossControl/utils/getData/getViolationsQuery";
import searchByLicense from "../../Components/LossControl/utils/getData/searchByLicense";
import performJoins from "../../Components/LossControl/utils/getData/performJoins";
import searchByPolicy from "../../Components/LossControl/utils/getData/searchByPolicy";
import Search from "../../Components/LossControl/Search";
import processQueryInChunks from "../../Components/LossControl/utils/getData/processQueryInChunks";
import searchByName from "../../Components/LossControl/utils/getData/searchByName";
import DriverDetailsModal from "../../Components/LossControl/FullViolationsList/modals/DriverDetailsModal";

export default function LossControl({ userInfo }) {
  const [drivers, setDrivers] = useState({});
  const [violations, setViolations] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [test, setTest] = useState(false);

  const [driverModalIsOpen, setDriverModalIsOpen] = useState(false);

  const openDriverModal = () => {
    setDriverModalIsOpen(true);
  };

  const closeDriverModal = () => {
    setDriverModalIsOpen(false);
  };

  // // Aggregate information for each driver
  const [aggregatedDriverInfo, setAggregatedDriverInfo] = useState([]);

  const { driverProfileScalingSwitch, driverProfileScalingSortWay } =
    useLossControlFormulas();
  const [selectedOption, setSelectedOption] = useState("");
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("");
  const [selectedInsured, setSelectedInsured] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const fetchViolations = async (e, doNotFetchDrivers) => {
    e.preventDefault();
    try {
      // Search Drivers
      let driversMap;
      if (searchType === "license") {
        driversMap = await searchByLicense(searchValue);
      } else if (searchType === "name") {
        driversMap = await searchByName(searchValue);
      } else if (searchType === "policy")
        driversMap = await searchByPolicy(
          searchValue,
          selectedInsured,
          selectedPolicy
        );
      const licenseArray = Object.values(driversMap).map((driver) => {
        return driver.licenseNumber;
      });
      console.log(licenseArray);

      // Search Violations
      // Fetch eventualities table
      const eventualitiesMap = await mapEventualities();

      // Fetch classifications table
      const classificationsMap = await mapClassifications();

      const violationsQuerySnapshot =
        searchType === "policy"
          ? null
          : await getViolationsQuery(searchType, searchValue, licenseArray);

      // Fetch violations
      const violationsQuery =
        searchType === "license"
          ? violationsQuerySnapshot.docs
          : searchType === "policy" || searchType === "name"
          ? await processQueryInChunks(licenseArray)
          : null; // replace null with other functions

      // Perform joins on these 3 queries
      const fullyJoinedViolations = await performJoins(
        violationsQuerySnapshot,
        eventualitiesMap,
        classificationsMap,
        searchType === "policy" || searchType === "name" ? true : false,
        violationsQuery
      );

      // Aggregate the data for use in the table
      const aggregatedData = await aggregateDriverInfo(
        fullyJoinedViolations,
        driversMap,
        setDataReady
      );
      setAggregatedDriverInfo(aggregatedData);
    } catch (error) {
      console.error("Error fetching violations:", error);
    }
  };

  return (
    <div>
      <>
        {test && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={null}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h6">Please Wait...</Typography>
          </Backdrop>
        )}
        <>
          <p className="my-4 text-3xl font-bold">Driver Scoring</p>
          <SearchType
            selectedOption={searchType}
            setSelectedOption={setSearchType}
          />
          {searchType !== "" && (
            <>
              <form onSubmit={(e) => fetchViolations(e, false)}>
                <Search
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  searchType={searchType}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  selectedInsured={selectedInsured}
                  setSelectedInsured={setSelectedInsured}
                  fetchViolations={fetchViolations}
                  selectedPolicy={selectedPolicy}
                  setSelectedPolicy={setSelectedPolicy}
                />
                <ApprovalButtons
                  onApprove={null}
                  handleAsSubmit={true}
                  onDecline={(e) => {
                    setSearchType("");
                    setSearchValue("");
                    setSelectedOption("");
                    setAggregatedDriverInfo([]);
                  }}
                  titleAccept="Search"
                  titleDecline="Cancel"
                  disabledAccept={false}
                />
              </form>
              {aggregatedDriverInfo.length > 0 ? (
                <>
                  <p className="my-4">
                    Results found. Select a table below to view the results.
                  </p>
                </>
              ) : (
                <>
                  <p className="my-4">No results found. Start a new search.</p>
                </>
              )}
            </>
          )}

          <TableSelection
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            disabled={!(aggregatedDriverInfo.length > 0)}
          />
          {selectedOption === "upload" && <UploadExcelFile />}
          {selectedOption === "fitness" && (
            <div className="my-4">
              <DriverFitness
                userInfo={userInfo}
                setDriverModalIsOpen={setDriverModalIsOpen}
                openDriverModal={openDriverModal}
                driverModalIsOpen={driverModalIsOpen}
                closeDriverModal={closeDriverModal}
                dataReady={dataReady}
                aggregatedDriverInfo={aggregatedDriverInfo}
              />
            </div>
          )}
          {selectedOption === "profile" && (
            <div className="my-4">
              <DriverProfile
                userInfo={userInfo}
                setDriverModalIsOpen={setDriverModalIsOpen}
                openDriverModal={openDriverModal}
                driverModalIsOpen={driverModalIsOpen}
                closeDriverModal={closeDriverModal}
                dataReady={dataReady}
                aggregatedDriverInfo={aggregatedDriverInfo}
              />
            </div>
          )}
          {selectedOption === "scaling" && (
            <TableWithFormulas
              mainCollection="tables"
              tableName="driverProfileScaling"
              getDisplayValue={driverProfileScalingSwitch}
              customOrder={driverProfileScalingSortWay}
            />
          )}
          {/* Need to redo full violations list according to new data
           */}
          {/* <FullViolationsList /> */}

          {selectedOption === "matrices" && (
            <>
              <DataMatrix tableName={"frequencyMatrix"} />
              <DataMatrix tableName={"severityMatrix"} />
              <DataMatrix tableName={"combinedMatrix"} />
            </>
          )}
          {selectedOption === "tables" && <Tables />}
        </>
        {showButton && (
          <Fab
            color="primary"
            size="large"
            aria-label="scroll back to top"
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            onClick={scrollToTop}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        )}
      </>
    </div>
  );
}
