import {
  addDoc,
  collection,
  doc,
  getDocs,
  runTransaction,
} from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import updateSystemLog from "../../../utils/updateSystemLog";
import endoStatusChangeNotification from "../../../utils/notifications/endoStatusChangeNotification";
import { setPolicyInfo } from "../../Drivers/setPolicyInfo";
import { selectIfNewOrExistingSubcollection } from "../../../utils/validateIfBlacklisted";

const submitAddTrailerEndorsement = async (
  e,
  policyID,
  trailerData,
  insuredInfo,
  userInfo,
  setSuccess,
  setTrailerData,
  endorsementEffectiveDate,
  formData,
  goBack,
  isEndoManager,
  getEndorsements,
  documentTypeToUse,
  renewalProcess,
  mirroredIds,
  originalPolicyID,
  trailerList
) => {
  e?.preventDefault();
  let endoEffectiveDate;
  if (documentTypeToUse === "Policy" || renewalProcess) {
    endoEffectiveDate = endorsementEffectiveDate;
  } else {
    endoEffectiveDate = "";
  }
  if (trailerData.trailerVIN.length !== 17)
    return alert("VIN must be 17 characters.");
  if (trailerData.trailerYear.length !== 4)
    return alert("Year must be 4 characters.");
  if (trailerData.trailerACV === "0")
    return alert("Actual Cash Value must be greater than $0.00");

  const foundTrailer = trailerList.find(
    (trailer) => trailer.trailerVIN === trailerData.trailerVIN
  );

  if (foundTrailer?.id && !mirroredIds) {
    goBack(e);
    return alert(
      `Trailer with VIN ${trailerData.trailerVIN} already exists in the policy.`
    );
  } else {
    const { agencyID, insuredID } = formData
    const payloadResult = setPolicyInfo(
      {
        ...trailerData,
        dateCreated: new Date(),
      }, {
        ...formData,
        policyEffectiveDate: formData?.effectiveDate,
        glPolicyNo: formData?.glPolicyNo,
        alPolicyNo: formData?.alPolicyNo,
        policyID,
        status: !renewalProcess && documentTypeToUse === "Application" ? 'Active' : 'Pending',
        documentType: documentTypeToUse,
        policyStatus: formData?.status,
        agencyID,
        insuredID
      },
      !renewalProcess && documentTypeToUse === "Application" ? 'direct-addition' : 'endo-addition'
    )
    
    const response = await selectIfNewOrExistingSubcollection(
      payloadResult,
      agencyID,
      insuredID,
      "trailers"
    )

    if (documentTypeToUse === "Policy") {
      const countersDocRef = doc(db, "unique-numbers", "endorsements"); 
      await runTransaction(db, async (transaction) => {
        const countersDoc = await transaction.get(countersDocRef);

        const currentCounter = countersDoc.data().lastCount;
        const newCounter = currentCounter + 1;
        transaction.update(countersDocRef, {
          lastCount: newCounter,
        });

        const endosRef = collection(
          db,
          "policy-applications",
          policyID,
          "endorsements"
        );
        const type = "Add Trailer"
        const status = "Submitted"

        const payload = {
          ...payloadResult,
          id: response.id,
        }
        if (mirroredIds) {
          payload['mirroredIds'] = mirroredIds
          payload['mirroredPolicyId'] =
            formData.renewalApplicationID ?
            formData.renewalApplicationID :
            originalPolicyID
        }

        const docRef = await addDoc(endosRef, {
          endorsementNumber: String(newCounter),
          type,
          data: payload,
          dateCreated: new Date(),
          policyID,
          status,
          namedInsured: insuredInfo.company,
          alPolicyNo: formData.alPolicyNo,
          policyEffectiveDate: formData.effectiveDate,
          endoEffectiveDate,
          author: auth.currentUser.displayName,
          authorID: auth.currentUser.uid,
          agencyID: formData.agencyID,
          insuredID: formData.insuredID,
        });

        endoStatusChangeNotification(
          type,
          newCounter,
          status,
          docRef.id,
          policyID,
          userInfo.agencyID
        );
        setTrailerData({});
        if (isEndoManager) {
          await getEndorsements()
        }
        setSuccess(true);
      });
      updateSystemLog(
        auth,
        policyID,
        "Add Trailer Endorsement (" +
          trailerData.trailerVIN +
          ") Submitted For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Add Trailer Endorsement",
        trailerData
      );
    }

    let mirroredIdsResult = []
    if (renewalProcess && !mirroredIds) {
      mirroredIdsResult.push(response.id)
    }

    updateSystemLog(
      auth,
      policyID,
      "Trailer (" +
        trailerData.trailerVIN +
        ") Added To Application For " +
        insuredInfo.company +
        " by " +
        auth.currentUser.displayName,
      "Trailer Added",
      trailerData
    );
    setTrailerData({});
    goBack(e);

    return mirroredIdsResult
  }
};

export default submitAddTrailerEndorsement;
