import React from "react";
import { t } from "i18next";
import { Col } from "react-bootstrap";
import Checkbox from "../../../assets/forms/Checkbox";
import FileUploadForm from "../fileUpload/FileUploadForm";
import FileDisplayAndReplacement from "../fileUpload/FileDisplayAndReplacement";
import LossDataDisplay from "./APDorMTCLossDataDisplay";
import LossDataForm from "./APDorMTCLossDataForm";

export default function YearCard({
  currentYear,
  showYearX,
  setShowYearX,
  formData,
  setFormData,
  setProgressState,
  setImgUrl,
  policyID,
  resetLosses,
  handleSubmit,
  year,
  numberOfYearsPrior,
  yearXLossRunsFile,
  didNotOperateInYearXPrior,
  progressState, coverageType
}) {
  return (
      <div className="card bg-white shadow-md rounded-md overflow-hidden ">
        <div className="card-header text-center bg-sky-900 text-white py-2">
          <button
            onClick={() => setShowYearX(!showYearX)}
            className="hover:text-[#072a48]"
          >
            {currentYear - numberOfYearsPrior - 1} {t("to")}{" "}
            {currentYear - numberOfYearsPrior}
          </button>
        </div>
        {showYearX && (
          <div className="card-body">
            {!formData?.lossHistory?.[`year${year + "" + coverageType}LossData`]?.lossRatioCalculated && (
            <fieldset disabled={formData[`year${year + "" + coverageType}LossRunsFile`]}>
              <Checkbox
                label={t("The insured did not operate/have coverage during this term.")}
                field={`didNotOperateInYear${year + "" + coverageType}Prior`}
                policyID={policyID}
                setFormData={setFormData}
                formData={formData}
              />
            </fieldset>)}
            {!formData[`didNotOperateInYear${year + "" + coverageType}Prior`] && (
              <section className="mt-2">
                <div className="mb-2">
                  {!formData[`year${year + "" + coverageType}LossRunsFile`] ||
                  formData[`year${year + "" + coverageType}LossRunsFile`] === "" ? (
                    <FileUploadForm
                    coverageType={coverageType}
                      year={year}
                      policyID={policyID}
                      formData={formData}
                      setFormData={setFormData}
                      setImgUrl={setImgUrl}
                      progressState={progressState}
                      setProgressState={setProgressState}
                      parentFolder={'policies'}
                    />
                  ) : (
                    <FileDisplayAndReplacement 
                    coverageType={coverageType}
                    year={year}
                    policyID={policyID}
                    formData={formData}
                    setFormData={setFormData}
                    setProgressState={setProgressState}
                    />
                  )}
                </div>
                {formData.lossHistory?.[`year${year + "" + coverageType}LossData`] ? (
                  <LossDataDisplay 
                  coverageType={coverageType}
                  formData={formData}
                  year={year}
                  resetLosses={resetLosses}
                  />
                
                ) : (
                  <LossDataForm 
                  coverageType={coverageType}
                  handleSubmit={handleSubmit}
                  currentYear={currentYear}
                  numberOfYearsPrior={numberOfYearsPrior}
                  formData={formData}
                  setFormData={setFormData}
                  year={year}
                  />
                )}
              </section>
            )}
          </div>
        )}
      </div>
  );
}
