import {
  collection,
  doc,
  getDoc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import config from "../../config/env";
import updateSystemLog from "./updateSystemLog";

const { REACT_APP_PORTAL_URL } = config

const renewPolicy = async (
  e,
  policyID,
  onClose,
  formData,
  userAuth,
  userState,
  insuredInfo
) => {
  e.preventDefault();

  try {
    const batch = writeBatch(db);

    // Get the source document data
    const sourceDocRef = doc(db, "policy-applications", policyID);
    const sourceDocSnapshot = await getDoc(sourceDocRef);
    if (!sourceDocSnapshot.exists()) {
      throw new Error("Source document does not exist");
    }
    const sourceDocData = sourceDocSnapshot.data();

    // Create a new document with a randomly generated ID
    const newDocRef = doc(collection(db, "policy-applications"));

    const documentType = "Policy";
    const status = "In-Renewal";
    const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
    const agencyID = sourceDocSnapshot.data().agencyID;
    const insuredID = sourceDocSnapshot.data().insuredID;

    // Set the source document to a "Renewal Policy"
    batch.set(
      sourceDocRef,
      {
        status,
        documentType,
        renewalApplicationID: newDocRef.id,
        inRenewal: true,
        nonRenewal: false,
        nonRenewalReasons: [""],
        nonRenewalDate: "",
      },
      { merge: true }
    );

    // Remove unnecessary fields from sourceDocData
    delete sourceDocData.inspasData;
    delete sourceDocData.selectedQuote;
    delete sourceDocData.alPremiums;
    delete sourceDocData.glPremums;
    delete sourceDocData.bindData;
    delete sourceDocData.alPolicyNo;
    delete sourceDocData.glPolicyNo;
    delete sourceDocData.saferReport;
    delete sourceDocData.signedApplication;
    delete sourceDocData.ssImportList;
    delete sourceDocData.cabReport;
    delete sourceDocData.driverAndEquipmentList;
    
    const expirationDate = sourceDocData.expirationDate.toDate();
    // add one year
    const newExpirationDate = sourceDocData.expirationDate.toDate();
    newExpirationDate.setFullYear(
      newExpirationDate.getFullYear() + 1
    );
    // Set the source document data to the new document
    batch.set(newDocRef, {
      ...sourceDocData,
      renewalPolicyID: sourceDocRef.id,
      inRenewal: true,
      status,
      documentType: "Application",
      powerUnitCountAtBind: 0,
      authorID: auth.currentUser.uid,
      author: auth.currentUser.displayName,
      createdOn: new Date(),
      effectiveDate: expirationDate,
      // expiration date one year from sourceDocData.expirationDate
      expirationDate: newExpirationDate,
      newOrRenewal: "Renewal",
    });

    async function addDriversUnitsTrailers(newApplicationDocRef) {
      console.log("adding stuff");
      try {
        // Iterate through each subcollection (drivers, power-units, and trailers)
        const subcollections = ["drivers", "power-units", "trailers"];
        for (const subcollection of subcollections) {
          const subcollectionRef = collection(
            db,
            `agencies/${agencyID}/insureds/${insuredID}/${subcollection}`
          );
          const subcollectionDocs = await getDocs(subcollectionRef);

          subcollectionDocs.forEach((doc) => {
            const docData = doc.data();
            if (
              docData.policiesDetails.some((policyDetail) => {
                console.log("on this policy");
                return policyDetail.id === policyID;
              })
            ) {
              const newPolicyDetail = {
                addedDate: new Date(),
                removedDate: null,
                id: newApplicationDocRef.id,
                status: "Active",
              };

              const updatedPolicies = [
                ...(docData.policies || []),
                newApplicationDocRef.id,
              ];

              // Add the new policy detail to the policiesDetails array
              const updatedPoliciesDetails = [
                ...(docData.policiesDetails || []),
                newPolicyDetail,
              ];

              // Add document update to batch
              batch.update(doc.ref, {
                policiesDetails: updatedPoliciesDetails,
                policies: updatedPolicies,
              });

              console.log(
                `Added policy detail to ${subcollection} document with ID ${doc.id}`
              );
            } else {
              console.log("not on this policy");
            }
          });
        }

        // Commit the batched write
        await batch.commit();
        console.log("Batched write committed successfully.");
        policyStatusChangeNotification(
          documentType,
          policyNumber,
          status,
          policyID,
          agencyID,
          formData,
          userAuth,
          userState,
          insuredInfo
        );
        updateSystemLog(
          auth,
          policyID,
          "Renewal Initiated for " + insuredInfo.company + ".",
          "Renewal Initiated",
          { agencyID, policyID }
        );
        onClose(e);
      } catch (error) {
        console.error("Error adding policy to documents:", error);
      }
    }

    await addDriversUnitsTrailers(newDocRef);
    // Redirect to the policy page after renewal
    window.open(
      `${REACT_APP_PORTAL_URL}/application/${newDocRef.id}`,
      "_blank"
    );

    console.log("Policy renewed successfully.");
  } catch (error) {
    console.error("Error renewing policy:", error);
  }
};

export default renewPolicy;
