import React from 'react';
import { Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

const ResponsiveButtonBar = ({ labels, icons, routes }) => {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: isMobileScreen ? 'column' : 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '15px'
  };

  const buttonStyle = {
    flex: '1',
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: isMobileScreen ? '' : 'gray',
    backgroundColor: '#116582',
    color: '#fff',
    marginRight: 0,
    marginBottom: isMobileScreen ? '8px' : 0,
    '&:hover': {
      backgroundColor: '#8B0000',
    },
  };

  const iconStyle = {
    marginRight: '8px',
  };

  return (
    <div style={buttonContainerStyle}>
    {labels.map((label, index) => (
      <Button
        key={label}
        variant="contained"
        style={buttonStyle}
        component={Link}
        to={routes[index]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {React.cloneElement(icons[index], { style: iconStyle })}
          <span>{label}</span>
        </div>
      </Button>
    ))}
  </div>
  );
};

export default ResponsiveButtonBar;
