import React, { useState } from "react";
import AddInsured from "../../Components/PolicyLifecycleManager/CreateNewApp/SelectInsured";
import "../../assets/styles/styles.css";

export default function NewApplication(props) {
  const userInfo = props.userInfo;
  const agencyID = userInfo.agencyID;
  const setShowCreatePolicyPanel = props.setShowCreatePolicyPanel;

  const [showVerification, setShowVerification] = useState(false);

  return (
    <main className="d-flex justify-content-center">
      <div className="card mt-3 max-w-[500px]">
        <div className="card-body">
          <AddInsured
            setShowCreatePolicyPanel={setShowCreatePolicyPanel}
            agencyID={agencyID}
            showVerification={showVerification}
            setShowVerification={setShowVerification}
          />
        </div>
      </div>
    </main>
  );
}
