import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
    handleCopyToClipboardEndo,
    item355366Days,
    itemEndoALExp,
    itemEndoALNoPolicy,
    itemEndoALPremium,
    itemEndoCoverageLimit,
    itemEndoDaysAL,
    itemEndoEffectiveDate,
    itemEndoMake,
    itemEndoNumber,
    itemEndoOtherFees,
    itemEndoProcessingFee,
    itemEndoProgram,
    itemEndoRadius,
    itemEndoStampFee,
    itemEndoState,
    itemEndoSurplusLinesPlusTaxes,
    itemEndoTypeAddDelete,
    itemEndoVIN,
    itemEndoYear,
    itemInsuredName,
    titleItem355366Days,
    titleItemEndoALExp,
    titleItemEndoALNoPolicy,
    titleItemEndoALPremium,
    titleItemEndoCoverageLimit,
    titleItemEndoDaysAL,
    titleItemEndoEffectiveDate,
    titleItemEndoMake,
    titleItemEndoNumber,
    titleItemEndoOtherFees,
    titleItemEndoProcessingFee,
    titleItemEndoRadius,
    titleItemEndoStampFee,
    titleItemEndoState,
    titleItemEndoSurplusLinesPlusTaxes,
    titleItemEndoTypeAddDelete,
    titleItemEndoVIN,
    titleItemEndoYear,
    titleItemInsuredName,
    titleItemProgram
} from "./utils";
import useGeneralState from "../../../Context/actions/useGeneralState";

export const EndorsementsTable = ({
  currentItems,
  handleSort,
  sortConfig,
  handleMouseEnter,
  handleMouseLeave,
  hoveredRow
}) => {
 
  const { controlToast } = useGeneralState()

  return (
    <Table>
      <Thead>
        <Tr>
          <Td
            className={classNames(
              "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "endorsementNumber",
                "bg-blue-500": sortConfig.key === "endorsementNumber",
              }
            )}
            onClick={() => handleSort("endorsementNumber")}
          >
            {titleItemEndoNumber}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "program",
              "bg-blue-500": sortConfig.key === "program",
            })}
            onClick={() => handleSort("program")}
          >
            {titleItemProgram}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "namedInsured",
              "bg-blue-500": sortConfig.key === "namedInsured",
            })}
            onClick={() => handleSort("namedInsured")}
          >
            {titleItemInsuredName}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "effectiveDate",
              "bg-blue-500": sortConfig.key === "effectiveDate",
            })}
            onClick={() => handleSort("effectiveDate")}
          >
            {titleItemEndoEffectiveDate}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "type",
              "bg-blue-500": sortConfig.key === "type",
            })}
            onClick={() => handleSort("type")}
          >
            {titleItemEndoTypeAddDelete}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "year",
              "bg-blue-500": sortConfig.key === "year",
            })}
            onClick={() => handleSort("year")}
          >
            {titleItemEndoYear}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "radius",
              "bg-blue-500": sortConfig.key === "radius",
            })}
            onClick={() => handleSort("radius")}
          >
            {titleItemEndoRadius}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "make",
              "bg-blue-500": sortConfig.key === "make",
            })}
            onClick={() => handleSort("make")}
          >
            {titleItemEndoMake}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "VIN",
              "bg-blue-500": sortConfig.key === "VIN",
            })}
            onClick={() => handleSort("VIN")}
          >
            {titleItemEndoVIN}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "365",
              "bg-blue-500": sortConfig.key === "365",
            })}
            onClick={() => handleSort("365")}
          >
            {titleItem355366Days}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "state",
              "bg-blue-500": sortConfig.key === "state",
            })}
            onClick={() => handleSort("state")}
          >
            {titleItemEndoState}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "alPolicyNo",
                "bg-blue-500": sortConfig.key === "alPolicyNo",
              }
            )}
            onClick={() => handleSort("alPolicyNo")}
          >
            {titleItemEndoALNoPolicy}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "alExp",
                "bg-blue-500": sortConfig.key === "alExp",
              }
            )}
            onClick={() => handleSort("alExp")}
          >
            {titleItemEndoALExp}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "daysAl",
                "bg-blue-500": sortConfig.key === "daysAl",
              }
            )}
            onClick={() => handleSort("daysAl")}
          >
            {titleItemEndoDaysAL}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "alPremium",
                "bg-blue-500": sortConfig.key === "alPremium",
              }
            )}
            onClick={() => handleSort("alPremium")}
          >
            {titleItemEndoALPremium}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "pFees",
                "bg-blue-500": sortConfig.key === "pFees",
              }
            )}
            onClick={() => handleSort("pFees")}
          >
            {titleItemEndoProcessingFee}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "otherFees",
                "bg-blue-500": sortConfig.key === "otherFees",
              }
            )}
            onClick={() => handleSort("otherFees")}
          >
            {titleItemEndoOtherFees}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "slTaxes",
                "bg-blue-500": sortConfig.key === "slTaxes",
              }
            )}
            onClick={() => handleSort("slTaxes")}
          >
            {titleItemEndoSurplusLinesPlusTaxes}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "stampFees",
                "bg-blue-500": sortConfig.key === "stampFees",
              }
            )}
            onClick={() => handleSort("stampFees")}
          >
            {titleItemEndoStampFee}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer rounded-tr-md",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "coverageLimit",
                "bg-blue-500": sortConfig.key === "coverageLimit",
              }
            )}
            onClick={() => handleSort("coverageLimit")}
          >
            {titleItemEndoCoverageLimit}
          </Td>
        </Tr>
      </Thead>

      <Tbody>
        {currentItems.map((endo, index) => {
          return (
            <Tr
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleCopyToClipboardEndo(endo, controlToast)}
              style={{
                cursor: "pointer",
                backgroundColor: hoveredRow === index ? "#e6f7ff" : "inherit",
              }}
            >
              <Td>{itemEndoNumber(endo)}</Td>
              <Td>{itemEndoProgram(endo)}</Td>
              <Td>{itemInsuredName(endo)}</Td>
              <Td>{itemEndoEffectiveDate(endo)}</Td>
              <Td>{itemEndoTypeAddDelete(endo)}</Td>
              <Td>{itemEndoYear(endo)}</Td>
              <Td>{itemEndoRadius(endo)}</Td>
              <Td>{itemEndoMake(endo)}</Td>
              <Td>{itemEndoVIN(endo)}</Td>
              <Td>{item355366Days(endo)}</Td>
              <Td>{itemEndoState(endo)}</Td>
              <Td>{itemEndoALNoPolicy(endo)}</Td>
              <Td>{itemEndoALExp(endo)}</Td>
              <Td>{itemEndoDaysAL(endo)}</Td>
              <Td>{itemEndoALPremium(endo)}</Td>
              <Td>{itemEndoProcessingFee(endo)}</Td>
              <Td>{itemEndoOtherFees(endo)}</Td>
              <Td>{itemEndoSurplusLinesPlusTaxes(endo)}</Td>
              <Td>{itemEndoStampFee(endo)}</Td>
              <Td>{itemEndoCoverageLimit(endo)}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
